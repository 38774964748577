<template>
  <div class="container drafts-container">
    <h1 class="header-title noPrint mb-3">Drafts</h1>
    <div
      class="
        d-flex
        mb-4
        m-2
        mx-sm-0
        align-items-end
        justify-content-end
        flex-column flex-sm-row
      "
    >
      <div class="mr-auto">
        <span class="mr-3 drafts-count-container desktop-tablet-only-force">
          <b v-if="!loading">
            <i class="fas fa-spin fa-circle-notch" v-if="busy"></i>
            {{ busy ? "" : drafts.length }}/{{ total }}</b
          >
          <i class="fas fa-spin fa-circle-notch" v-if="loading"></i>
          Draft{{ loading || total != 1 ? "s" : "" }}
        </span>
      </div>
      <div class="sort-container d-flex flex-wrap">
        <router-link
          to="/tasks/create"
          class="btn btn-outline-blue ml-3 mb-3 mx-md-3 mb-md-0"
        >
          <icon type="plus" /> Add New Task
        </router-link>
        <span
          class="
            mr-0
            ml-3 ml-sm-0
            mr-sm-3
            drafts-count-container
            mobile-only-force
          "
        >
          <b v-if="!loading">
            <i class="fas fa-spin fa-circle-notch" v-if="busy"></i>
            {{ busy ? "" : drafts.length }}/{{ total }}</b
          >
          <i class="fas fa-spin fa-circle-notch" v-if="loading"></i>
          Draft{{ loading || total != 1 ? "s" : "" }}
        </span>
        <div class="ml-auto">
          <button
            v-if="!loading && drafts.length"
            class="btn btn-outline-link mr-1"
            @click="toggleReverse"
            :disabled="loading"
            :title="reverseDraftsOrder ? 'Sort asc' : 'Sort desc'"
          >
            <i
              class="fa fa-lg"
              :class="
                reverseDraftsOrder
                  ? 'fa-sort-amount-up-alt'
                  : 'fa-sort-amount-down'
              "
            ></i>
          </button>
          <button
            v-if="!loading && drafts.length"
            :class="`btn ${
              listDisplay == 'list'
                ? 'btn-header-blue'
                : 'btn-outline-header-blue'
            }`"
            style="border-bottom-right-radius: 0; border-top-right-radius: 0"
            @click="setDisplayType('list')"
          >
            <i class="fas fa-list"></i>
          </button>
          <button
            v-if="!loading && drafts.length"
            :class="`btn ${
              listDisplay == 'table'
                ? 'btn-header-blue'
                : 'btn-outline-header-blue'
            }`"
            style="border-bottom-left-radius: 0; border-top-left-radius: 0"
            @click="setDisplayType('table')"
          >
            <i class="fas fa-th"></i>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="table-outer-container border-0 radius-36 overflow-auto"
      :class="[
        this.listDisplay == 'table' ? 'nice-scroll' : '',
        drafts.length ? '' : 'card light-shadow',
      ]"
    >
      <div class="p-3 no-drafts mb-0" v-if="drafts.length == 0">
        No Drafts Found.
      </div>
      <table
        v-show="drafts.length > 0 && listDisplay == 'table'"
        class="table table-sm table-striped mb-0"
      >
        <thead>
          <tr>
            <th>Subject</th>
            <th>Assignee</th>
            <th>Description</th>
            <th>Priority</th>
            <th>Date Created</th>
            <th>Due Date</th>
            <th>Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="position-relative" v-for="draft in drafts" :key="draft.id">
            <td>
              <b class="mobile-tablet-only mr-2">Subject:</b>{{ draft.name }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Assignee:</b>
              <span v-if="draft.assignee"
                >{{ draft.assignee.last }}, {{ draft.assignee.first }}</span
              >
              <span v-else>-</span>
            </td>
            <td style="word-break: break-word">
              <b class="mobile-tablet-only mr-2">Description:</b>
              <div
                class="draft-description-table-cell ck ck-content"
                v-html="
                  $options.filters.nl2br(
                    $options.filters.slash2hr(draft.description || '')
                  )
                "
              ></div>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Priority:</b
              >{{ draft.priority }}
            </td>

            <td>
              <b class="mobile-tablet-only mr-2">Date Created:</b
              >{{ prettyDate(draft.created_at) }}
            </td>

            <td>
              <b class="mobile-tablet-only mr-2">Due Date:</b
              >{{ prettyDate(draft.due_date) }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Type:</b>
              {{ getDraftType(draft.type) }}
            </td>
            <td class="text-right desktop-only">
              <div class="dropdown">
                <i
                  class="fas fa-ellipsis-h text-info fa-2x"
                  aria-hidden="true"
                  type="button"
                  id="dropdownMoreActions"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div
                  class="dropdown-menu"
                  aria-labelledby="dropdownMoreActions"
                >
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="navigate(`/tasks/drafts/${draft.id}`)"
                    >View/Edit</a
                  >
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="deleteDraft(draft.id)"
                    >Delete</a
                  >
                </div>
              </div>
            </td>
            <td class="mobile-tablet-only">
              <hr />
              <div
                class="d-flex align-items-center justify-content-center mt-4"
              >
                <button
                  class="btn btn-outline-blue mr-3"
                  @click.prevent="navigate(`/tasks/drafts/${draft.id}`)"
                >
                  View/Edit
                </button>
                <button
                  class="btn btn-danger"
                  @click.prevent="deleteDraft(draft.id)"
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-show="drafts.length > 0 && listDisplay == 'list'">
        <div class="accordion draft-accordion" id="draftsAccordion">
          <div
            v-for="(draft, draftIndex) in drafts"
            :key="draft.id"
            class="card light-shadow mb-0 overflow-visible"
            :class="[
              `item-index_${
                draftIndex == 0 && drafts.length == 1
                  ? 'first item-index_last'
                  : draftIndex == 0
                  ? 'first'
                  : draftIndex == drafts.length - 1
                  ? 'last'
                  : draftIndex
              }`,
            ]"
          >
            <div
              class="card-header p-0"
              style="height: auto; min-height: 60px"
              :id="`headingDraft_${draft.id}`"
              @click="accordionClicked(draft)"
            >
              <h2 class="mb-0">
                <button
                  class="btn btn-link text-left w-100 shadow-none"
                  type="button"
                  data-toggle="collapse"
                  :data-target="`#collapseDraft_${draft.id}`"
                  aria-expanded="false"
                  :aria-controls="`collapseDraft_${draft.id}`"
                >
                  <div
                    class="
                      d-flex
                      heading-draft-content
                      text-dark
                      align-items-center
                      pr-4
                    "
                  >
                    <img
                      class="avatar-draft-img rounded-circle mt-5 mt-lg-0"
                      :src="
                        draft.owner.profile_picture || profileImagePlaceholder
                      "
                    />
                    <p class="draft-owner-name text-bold mb-0 ml-2">
                      {{ draft.owner.last }}, {{ draft.owner.first }}
                    </p>
                    <p class="draft-action-type mb-0 ml-1">{{ draft.name }}</p>
                    <div
                      :class="`draft-type-header ellipsis ${draft.className}`"
                    >
                      {{ draft.displayType }}
                    </div>
                    <i class="fas fa-angle-down rotate-icon text-dark"></i>
                  </div>
                </button>
              </h2>
            </div>
            <div
              :id="`collapseDraft_${draft.id}`"
              class="card-body-accordion collapse"
              :aria-labelledby="`headingDraft_${draft.id}`"
            >
              <div class="card-body pb-0">
                <div class="draft-title text-bold text-center">
                  Subject: {{ draft.name }}
                </div>
                <br />
                <div
                  class="card shadow-none p-2 ck ck-content"
                  v-html="
                    $options.filters.nl2br(
                      $options.filters.slash2hr(draft.description || '')
                    )
                  "
                ></div>
                <div class="mb-2">
                  Assignee:
                  <span v-if="draft.assignee"
                    ><b
                      >{{ draft.assignee.last }}, {{ draft.assignee.first }}</b
                    ></span
                  >
                  <span v-else>-</span>
                </div>
                <div class="mb-2">
                  Type:
                  <b>{{ getDraftType(draft.type) }}</b>
                </div>
                <div class="mb-2">
                  Priority:
                  <b>{{ draft.priority }}</b>
                </div>
                <div
                  class="
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-center
                    p-3
                    mb-4
                    accordion-actions
                  "
                  style="background-color: #e6e0e069; border-radius: 4px"
                >
                  <button
                    class="btn btn-black mr-3 col-6 col-sm-auto mt-3 mt-lg-0"
                    @click="navigate(`/tasks/drafts/${draft.id}`)"
                  >
                    View/Edit
                  </button>
                  <button
                    class="btn btn-danger mr-3 col-6 col-sm-auto mt-3 mt-lg-0"
                    @click="deleteDraft(draft.id)"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mugen-scroll :handler="loadMore" :should-handle="!busy && !loading">
        <alert
          v-if="busy"
          class="
            text-center
            p-2
            mb-0
            rounded-0
            border-0
            bg-gray
            alert alert-basic
            bg-secondary
            text-white
          "
          ><i class="fas fa-spin fa-circle-notch mr-2"></i>Loading more
          drafts...</alert
        >
      </mugen-scroll>
    </div>
    <alert v-if="loading" class="light-shadow" />
  </div>
</template>

<script>
import helpers from "@/utils/helpers";
import { mapState, mapActions } from "vuex";
import MugenScroll from "vue-mugen-scroll";
import Swal from "sweetalert2";
import CONSTANT from "./constants";

export default {
  name: "Drafts",
  components: {
    MugenScroll,
  },
  data() {
    return {
      listDisplay: "list",
      reverseDraftsOrder: false,
      limit: 50,
      busy: false,
      draftTypes: CONSTANT.TASK_TYPES,
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
    };
  },
  mounted() {
    this.getPaginatedDrafts();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      originDrafts: (state) => state.task.draftPagination.data,
      current: (state) => state.task.draftPagination.current,
      total: (state) => state.task.draftPagination.total || 0,
      loading: (state) => state.task.isLoading,
    }),
    drafts: function () {
      if (!this.originDrafts || !this.originDrafts.length) {
        return [];
      }
      const draftsToDisplay = this.originDrafts;
      draftsToDisplay.forEach((draft) => {
        if (!draft.displayType) {
          draft.displayType = this.getTypeName(draft.type);
          draft.className = this.getDraftColor(draft.type);
        }
      });
      return draftsToDisplay;
    },
  },
  methods: {
    ...mapActions({
      getDrafts: "task/getDrafts",
      deleteDrafts: "task/deleteDraft",
    }),
    canEdit(draft) {
      return draft.user_id == this.user.id;
    },
    getDraftType(type) {
      return (
        (this.draftTypes.filter((item) => item.value == type)[0] || {}).label ||
        "-"
      );
    },
    getDraftColor(type) {
      let className = "";
      switch (type) {
        case "1":
          className = "btn-secondary";
          break;
        case "2":
          className = "btn-info";
          break;
        case "3":
          className = "btn-secondary";
          break;
        case "4":
          className = "btn-deep-red";
          break;
        case "5":
          className = "btn-info";
          break;
        case "6":
          className = "btn-info";
          break;
        case "7":
          className = "btn-secondary";
          break;
        case "8":
          className = "btn-info";
          break;
        case "9":
          className = "btn-7ac98b";
          break;
        case "10":
          className = "btn-info";
          break;
        case "11":
          className = "btn-deep-red";
          break;
        case "12":
          className = "btn-deep-warning";
          break;
        case "13":
          className = "btn-warning";
          break;
        case "14":
          className = "btn-success";
          break;
        case "15":
          className = "btn-deep-success";
          break;
        case "16":
          className = "btn-danger";
          break;

        case "17":
          className = "btn-danger";
          break;
        case "18":
          className = "btn-deep-red";
          break;
        case "19":
          className = "btn-info";
          break;
        case "20":
          className = "btn-7ac98b";
          break;
        case "21":
          className = "btn-639db9";
          break;
        case "22":
          className = "btn-a84bc6";
          break;
        case "23":
          className = "btn-e27c1d";
          break;
        case "24":
          className = "btn-e0511d";
          break;
        case "25":
          className = "btn-dd1c59";
          break;
        case "26":
          className = "btn-a31cdd";
          break;
        case "27":
          className = "btn-0859d3";
          break;
        case "28":
          className = "btn-157477";
          break;
        case "29":
          className = "btn-1a3f3f";
          break;
        case "30":
          className = "btn-0d62a8";
          break;
        case "39":
          className = "btn-dark";
          break;
        case "40":
          className = "btn-deep-red";
          break;
        default:
          className = "btn-danger";
          break;
      }
      return className;
    },
    getTypeName(type) {
      return (this.draftTypes.filter((item) => item.value == type)[0] || {})
        .label;
    },
    setDisplayType(type) {
      this.listDisplay = type;
    },
    prettyDate: function (date) {
      if (!date) {
        return "";
      }
      return helpers.prettyDate(date);
    },
    navigate: function (url) {
      this.$router.push({ path: url });
    },
    accordionClicked(draft) {
      draft.opened = !draft.opened;
    },
    toggleReverse: function () {
      this.reverseDraftsOrder = !this.reverseDraftsOrder;
      this.getPaginatedDrafts();
    },
    getPaginatedDrafts: function (page) {
      const payload = {
        limit: this.limit,
        asc: this.reverseDraftsOrder,
      };

      if (page) {
        payload.page = page;
      }
      this.getDrafts(payload).then(() => {
        this.busy = false;
      });
    },
    loadMore: function () {
      if (this.drafts.length < this.total) {
        this.busy = true;
        this.getPaginatedDrafts(this.current + 1);
      }
    },
    deleteDraft(id) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to delete this draft?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            vm.deleteDrafts(id).then((success) => {
              if (success) {
                vm.getPaginatedDrafts(this.current);
              }
            });
          }
        });
    },
  },
};
</script>
<style lang="scss">
.drafts-container {
  .draft-accordion {
    .avatar-draft-img {
      width: 35px;
      min-width: 35px;
      height: 35px;
    }
    .card-header {
      margin-bottom: 0;
    }
    .rotate-icon {
      position: absolute;
      right: 0px;
      transition: all 0.3s ease-in-out;
    }
    .draft-type-header {
      position: absolute;
      right: 24px;
      padding: 2px 12px;
      border-radius: 8px;
      pointer-events: none;
      font-size: 14px;
      max-width: 350px;
      @media (max-width: 1024px) {
        max-width: calc(100% - 48px);
      }
      @media (max-width: 767px) {
        position: static;
        max-width: 100%;
      }
    }
    button[aria-expanded="true"] {
      .rotate-icon {
        transform: rotate(180deg);
      }
    }
  }
  hr {
    margin-top: 4px;
    margin-bottom: 4px;

    & + br {
      display: none;
    }
  }

  .draft-description-table-cell {
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-outline-header-blue {
    &,
    &:focus {
      color: #062157;
      border-color: #062157;
      background-color: white;
    }

    &:hover {
      color: #0e43ad;
      border-color: #0e43ad;
      background-color: white;
    }
  }

  .btn-header-blue {
    &,
    &:hover,
    &:focus {
      color: white;
      border-color: #222222;
      background-color: #222222;
    }
  }

  @media (max-width: 1024px) {
    .heading-draft-content {
      flex-direction: column;
    }
    .no-drafts {
      margin-bottom: 1.5rem;
      border-color: #edf2f9;
      background-color: #ffffff;
      background-clip: border-box;
      border: 1px solid #b8c2d0;
      border-radius: 0.5rem;
    }

    table {
      display: flex;
    }
    .table-outer-container {
      box-shadow: none;
      background-color: transparent;
    }
    thead {
      display: none;
    }
    tbody {
      width: 100%;
    }
    tr {
      display: flex;
      flex-direction: column;
      background-color: white !important;
      margin-bottom: 24px;
      padding: 8px;
      box-shadow: rgb(0 0 0 / 17%) 0px 0px 10px;
      &:last-child {
        margin-bottom: 0 !important;
      }
      td {
        padding: 0 4px !important;
        margin-bottom: 8px;
        text-align: left !important;
        border: 0 !important;
        b {
          display: inline-block;
          padding: 4px 0;
        }

        &.buttons-td {
          display: flex;
          justify-content: center;
          text-align: center !important;
          margin-bottom: 8px;
          margin-top: 12px;
          padding-top: 12px;
          border-top: 1px solid #ccc !important;

          .btn-secondary {
            padding: 4px 8px;
            color: #fff !important;
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
.drafts-container {
  .overflow-visible {
    overflow: visible !important;
  }
  .drafts-count-container {
    display: inline-block;
    min-width: 100px;
  }

  .card {
    position: static;
  }
  .heading-draft-content {
    position: relative;
  }

  @media (max-width: 767px) {
    .sort-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 100%;
    }
  }
}
</style>
