<template>
  <div
    class="d-flex flex-wrap flex-lg-column flex-lg-nowrap col-12 col-lg-3"
    :class="
      user && user.isPatient
        ? 'patient-sidebar'
        : user && user.isProvider
        ? 'provider-sidebar'
        : 'admin-sidebard'
    "
  >
    <div class="col-12 col-md-6 col-lg-auto p-0 pr-md-4 pr-lg-0">
      <div
        class="
          user-profile-container
          light-shadow
          d-flex
          align-items-center
          justify-content-between
          px-3
          pt-3
          dashboard-card
          bg-img-card
        "
      >
        <div
          class="user-profile-logos-container col-6 d-flex align-items-center"
        >
          <img
            v-if="user"
            :src="
              userLogo != null
                ? userLogo.split('?response-content')[0]
                : userImagePlaceholder
            "
            class="user-picture"
          />
        </div>
        <div
          class="
            user-profile-info
            col-6
            d-flex
            flex-column
            justify-content-between
          "
        >
          <div class="user-profile-name text-main" v-if="user">
            {{ user.last + ", " + user.first }}
          </div>

          <div class="user-profile-role text-main">
            <span class="user-profile-role-name"
              >{{ user != null ? getUserRoleDescription() : "" }}
            </span>
            <p
              class="user-profile-company-title ellipsis mt-2 mb-0"
              :title="
                companyData != null && companyData.name != ''
                  ? companyData.name
                  : ''
              "
            >
              {{
                companyData != null && companyData.name != ""
                  ? companyData.name
                  : ""
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 col-12 col-md-6 col-lg-auto">
      <div class="user-profile-links col-12 px-0">
        <div
          class="user-profile-link profile-link text-center"
          :style="user && user.isPatient ? 'min-width: 35%' : 'min-width: 40%'"
        >
          <a
            href="/"
            @click.prevent="navigateToProfile"
            class="nav-link btn-theme w-100"
          >
            <i class="far fa-user-circle"></i>
            My Profile</a
          >
        </div>

        <div
          class="user-profile-link form-link ml-3"
          style="min-width: 40%"
          v-if="user && !user.isPatient"
        >
          <router-link
            :to="{ path: '/templates' }"
            class="nav-link text-main w-100"
            ><i class="far fa-file-alt"></i> Form Templates</router-link
          >
        </div>
        <div
          class="user-profile-link form-link ml-3 pointer-cursor"
          style="min-width: 40%"
          v-if="user && user.isPatient"
        >
          <a class="nav-link text-main w-100" @click="openCreateTaskModal"
            ><i class="far fa-file-alt"></i> Message Provider</a
          >
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-auto p-0 pr-md-4 pr-lg-0">
      <div
        class="
          dashboard-card
          d-flex
          flex-column
          monthly-hours-card
          justify-content-between
        "
        v-if="user && (user.isAdmin || user.isManagingAdmin)"
      >
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h3 class="card-title mb-0">Monthly Hours</h3>
          <select-control
            v-model="selectedMonthYear.billablePercentage"
            :options="lastMonths"
            :key="selectedMonthYear.billablePercentage"
            labelClass="d-none"
            wrapClass="mb-0"
            inputClass="radius-99 font-weight-bold"
            :disabled="billablePercentageData.loading"
            @input="getBillablePercentage()"
          ></select-control>
        </div>
        <div class="monthly-chart-container text-center">
          <div
            class="
              chart-img
              loader
              d-flex
              align-items-center
              justify-content-center
            "
            v-if="billablePercentageData.loading"
          >
            <span class="fa fa-3x fa-spin fa-circle-notch"></span>
          </div>
          <div class="chart-img" v-html="biChartSVGContent"></div>
        </div>
        <div class="monthly-details">
          <div class="row mx-0 justify-content-between">
            <div class="d-flex align-items-center">
              <div class="mr-2 info-devider secondary-info"></div>
              <div class="info-text">
                Non billable:
                <span class="value" v-if="!billablePercentageData.loading"
                  >{{
                    billablePercentageData.nonBillable.percent.toFixed(2)
                  }}%</span
                >
                <span
                  v-if="billablePercentageData.loading"
                  class="fa fa-spin fa-circle-notch"
                ></span>
              </div>
            </div>
            <div class="secondary-info info-tip radius-99">
              <span
                v-if="billablePercentageData.loading"
                class="fa fa-spin fa-circle-notch p-2"
              ></span>
              <span v-if="!billablePercentageData.loading">{{
                calculateHoursMinutes(billablePercentageData.nonBillable.amount)
              }}</span>
            </div>
          </div>
          <div class="row mx-0 justify-content-between mt-1">
            <div class="d-flex align-items-center">
              <div class="mr-2 info-devider main-info"></div>
              <div class="info-text">
                Billable:
                <span class="value" v-if="!billablePercentageData.loading"
                  >{{
                    billablePercentageData.billable.percent.toFixed(2)
                  }}%</span
                >
                <span
                  v-if="billablePercentageData.loading"
                  class="fa fa-spin fa-circle-notch"
                ></span>
              </div>
            </div>
            <div class="main-info info-tip radius-99">
              <span
                v-if="billablePercentageData.loading"
                class="fa fa-spin fa-circle-notch p-2"
              ></span>
              <span v-if="!billablePercentageData.loading">{{
                calculateHoursMinutes(billablePercentageData.billable.amount)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-auto p-0">
      <div
        class="
          dashboard-card
          tools-card
          d-flex
          flex-column
          justify-content-between
          justify-content-md-start
          justify-content-lg-between
        "
        style="height: calc(100% - 4px)"
        v-if="user && !user.isPatient"
      >
        <h3 class="card-title">Tools and Integrations</h3>
        <div class="tools-info">
          <div class="d-flex flex-wrap mt-5 align-items-start">
            <a
              class="integration-item mr-3"
              href="https://app.communitycareconnection.com"
              target="_blank"
            >
              <img class="tools-img" src="@/assets/img/c3-logo.png" />
              <p class="title">C3 Web <br />App</p>
            </a>
            <a
              class="integration-item mr-3"
              href="https://www.okaya.me"
              target="_blank"
            >
              <img class="tools-img" src="@/assets/img/okaya-logo.png" />
              <p class="title">Okaya</p>
            </a>
            <a
              class="integration-item mr-3"
              href="https://firsthx.com"
              target="_blank"
            >
              <img
                class="tools-img light-shadow rounded-circle border"
                src="@/assets/img/firstHx-logo1.png"
              />
              <p class="title">FirstHx</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dateFormat from "@/utils/dateFormat";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "dashboard-sidebar",
  props: ["sidebarSections"],
  data() {
    return {
      userImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
      biChartSVGContent: "",
      lastYears: [],
      lastMonths: [],
      billablePercentageData: {
        loading: true,
        billable: {
          amount: 0,
          percent: 0,
        },
        nonBillable: {
          amount: 0,
          percent: 0,
        },
      },
      selectedMonthYear: {
        billablePercentage: `${new Date().getFullYear()}__${new Date().getMonth()}`,
      },
    };
  },
  watch: {
    user: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        if (newVal.isAdmin || newVal.isManagingAdmin) {
          this.getBillablePercentage();
        }
      }
    },
  },
  mounted() {
    this.setYearsMonths();
    fetch("images/bi-chart.svg")
      .then((response) => response.text())
      .then((data) => {
        this.biChartSVGContent = data;
        if (this.user && (this.user.isAdmin || this.user.isManagingAdmin)) {
          this.getBillablePercentage();
        }
      })
      .catch((error) => console.error(error));
  },
  computed: {
    ...mapState({
      userLogo: (state) => (state.auth.userInfo || {}).profile_picture,
      companyData: (state) => state.settings.companyProfile,
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    setYearsMonths: function () {
      const lastYears = [];
      let year = new Date().getFullYear();
      for (let i = 0; i <= 50; i++) {
        lastYears.push({
          label: year - i,
          value: year - i,
        });
      }
      this.lastYears = lastYears;

      let month = new Date().getMonth();
      let lastMonths = [];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      for (let i = 0; i <= 200; i++) {
        lastMonths.push({
          label: `${months[month]} ${year}`,
          value: `${year}__${month--}`,
        });
        if (month < 0) {
          month = 11;
          year--;
        }
      }
      this.lastMonths = lastMonths;
    },
    getIconImageUrl(iconName, active) {
      if (iconName == "" || iconName == undefined) {
        return "";
      }
      if (active) {
        return require("@/assets/img/dashboard/" + iconName + "-active.svg");
      }
      return require("@/assets/img/dashboard/" + iconName + ".svg");
    },
    calculateBiChartPercentage(percentage) {
      const radius = 72;
      const centerX = 89;
      const centerY = 89;
      if (percentage == 100) {
        percentage = 99.99999;
      }

      const endAngle = (percentage / 100) * 2 * Math.PI;
      const largeArcFlag = percentage > 50 ? 1 : 0;

      const x = centerX + radius * Math.cos(endAngle - Math.PI / 2);
      const y = centerY + radius * Math.sin(endAngle - Math.PI / 2);

      const d = `M ${centerX},${
        centerY - radius
      } A ${radius},${radius} 0 ${largeArcFlag} 1 ${x},${y} L ${centerX},${centerY} Z`;
      if (document.getElementById("monthlyArcPath")) {
        document.getElementById("monthlyArcPath").setAttribute("d", d);
      }
    },
    getUserRoleDescription() {
      if (this.user.isAdmin || this.user.isManagingAdmin) {
        return "Admin";
      } else if (this.user.isPatient) {
        return "Client";
      } else if (this.user.isProvider) {
        return "Provider";
      } else {
        return "";
      }
    },
    navigateToProfile() {
      if (this.user && (this.user.isAdmin || this.user.isManagingAdmin)) {
        this.$router.push({ path: "/admin/profile" });
      } else if (this.user && this.user.isProvider) {
        this.navigateToProviderDetails();
      } else {
        this.navigateToPatientDetails();
      }
    },
    navigateToProviderDetails: function () {
      this.$router.push({ path: `/providers/${this.user.provider_id}` });
    },
    navigateToPatientDetails: function () {
      this.$router.push({ path: `/clients/${this.user.patient_id}` });
    },
    openCreateTaskModal: function () {
      window.openCreateTaskModal();
    },
    getBillablePercentage: async function () {
      try {
        const selectedYear =
          this.selectedMonthYear.billablePercentage.split("__")[0];
        const selectedMonth =
          parseInt(this.selectedMonthYear.billablePercentage.split("__")[1]) +
          1;

        const startOfMonth = `${selectedYear}-${
          selectedMonth > 9 ? selectedMonth : "0" + selectedMonth
        }-01T00:00:00.000000Z`;
        const endOfMonth = `${dateFormat(
          dayjs(
            `${selectedYear}-${
              selectedMonth > 9 ? selectedMonth : "0" + selectedMonth
            }-01`
          ).endOf("month"),
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;

        const canadaOffsetStart = dayjs
          .tz(new Date(startOfMonth), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(endOfMonth), "America/Toronto")
          .utcOffset();

        const startDateTime = dayjs(startOfMonth)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        const endDateTime = dayjs(endOfMonth)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        this.billablePercentageData.loading = true;

        const res = await this.$http.get(
          `dashboard/billablePercentage?start=${startDateTime}&end=${endDateTime}`
        );
        const data = res.data;
        data["billable"] = data["billable"] || 0;
        data["non-billable"] = data["non-billable"] || 0;

        const total = data["billable"] + data["non-billable"];

        this.billablePercentageData = {
          billable: {
            amount: data["billable"],
            percent: total == 0 ? 0 : (data["billable"] / total) * 100,
          },
          nonBillable: {
            amount: data["non-billable"],
            percent: total == 0 ? 0 : (data["non-billable"] / total) * 100,
          },
          loading: false,
        };
      } catch (err) {
        this.billablePercentageData = {
          loading: false,
          billable: {
            amount: 0,
            percent: 0,
          },
          nonBillable: {
            amount: 0,
            percent: 0,
          },
        };
      } finally {
        this.calculateBiChartPercentage(
          this.billablePercentageData.nonBillable.percent
        );
      }
    },
    calculateHoursMinutes(value) {
      if (parseInt(value) == value) {
        return `${value}h`;
      } else {
        const hours = parseInt(value);
        const minutes = ((value - hours) * 60).toFixed(0);
        return `${hours}h, ${minutes}m`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bg-img-card {
  background-image: var(--profile-img-path);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto 50%;
}
.dashboard-card {
  border-radius: 36px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
}

.tools-card {
  min-height: 136px;
  background-image: var(--tools-img-path);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto 80%;
  padding: 16px 24px;
  margin-top: 4px;

  .tools-info {
    .integration-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align: center;
      transition: all 0.2s ease-in-out;

      &:hover {
        filter: drop-shadow(2px 3px 5px #828282);
      }

      .tools-img {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }
      .title {
        margin-top: 8px;
        color: #000000;
        font-weight: bold;
        line-height: normal;
      }
    }
  }
}

.provider-sidebar {
  .tools-card {
    min-height: 270px;
    background-size: auto 60%;

    .tools-subtitle {
      margin-bottom: 70px;
    }
  }
}

.monthly-hours-card {
  min-height: 136px;
  padding: 16px 24px;
  margin-top: 4px;

  .monthly-chart-container {
    margin-top: 12px;
  }
  .monthly-details {
    margin-top: 16px;

    .info-devider {
      width: 3px;
      border-radius: 1px;
      height: 11px;
    }
    .info-tip {
      padding: 0 8px;
    }
    .main-info {
      background-color: var(--theme-color);
      color: var(--btn-text-color);
    }
    .secondary-info {
      background-color: #000000;
      color: #ffffff;
    }
    .info-text {
      font-size: 14px;
    }
    .value {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.card-title {
  font-size: 20px;
  font-weight: 500;
  color: var(--main-text-color);
}
.user-profile-container {
  min-height: 194px;
  padding-bottom: 50px;
  height: auto;
}

.user-profile-logos-container {
  .user-picture {
    width: 100%;
    max-height: 100%;
    min-height: 210px;
    border-radius: 36px;
    object-position: center;
    object-fit: cover;
  }
}
.user-profile-name {
  padding: 8px;
  font-size: 24px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-profile-role {
  margin-top: 12px;
}

.user-profile-role-name,
.user-profile-company-title {
  border-radius: 99px;
  background-color: rgba(255, 255, 255, 0.75);
  color: var(--main-text-color);
  padding: 4px 10px;
  font-size: 14px;
  font-weight: normal;
  height: 29px;
}

.company-profile-picture {
  display: none;
  width: 56px;
  height: 56px;
  margin-top: 104px;
  margin-left: 132px;
  padding: 2px;
  background-color: #ffffff;
}
.user-profile-logos-container {
  width: 60%;
  padding: 0 8px 0 0;
}
.user-profile-links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  font-weight: 500;
}

.user-profile-info {
  background-color: rgba(235, 236, 235, 0.5);
  border-radius: 36px;
  min-height: 170px;
  padding: 12px;
}
.user-profile-link {
  .nav-link {
    text-decoration: none;
    display: inline-block;
    background-color: #ffffff;
    border-radius: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &.profile-link {
    .nav-link {
      background-color: var(--theme-color);
      color: var(--btn-text-color);
    }
  }
}

@media (max-width: 1150px) {
  .company-profile-picture {
    margin-left: 120px;
  }
}
</style>

<style lang="scss">
.monthly-chart-container {
  position: relative;
  .chart-img {
    svg {
      max-width: 100%;
      width: 250px;
      height: 250px;
    }
    &.loader {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.75);
    }
  }
}
</style>
