<template>
  <button
    :class="classes"
    @click="handleClick"
    :disabled="saving || shouldDisable"
  >
    <span class="spinner-border spinner-border-sm" v-if="saving"></span>
    <span v-else>
      <icon :type="iconType" class="mr-2" />
      <slot>Save</slot>
    </span>
    <span v-if="saving && savingText" class="pl-2">{{ savingText }}</span>
  </button>
</template>
<script>
export default {
  name: "Save",
  props: {
    saving: Boolean,
    savingText: String,
    shouldDisable: Boolean,
    iconType: {
      type: String,
      default: "check-circle",
    },
    type: {
      type: String,
      default: "button",
    },
    classes: {
      type: String,
      default: "btn btn-theme",
    },
  },
  methods: {
    handleClick: function (event) {
      this.$emit("click", event);
    },
  },
};
</script>
