<template>
  <div v-if="user && !user.isPatient">
    <form @submit.prevent="saveForm" validate>
      <div class="d-flex justify-content-between flex-wrap mx-sm-4">
        <div class="mt-3 mb-4">
          <button
            type="button"
            class="btn btn-black mr-auto mb-2 mb-md-0"
            v-if="!previewOnly"
            @click="navigateToGuide"
          >
            <i class="fa fa-info-circle fa-lg mr-3"></i>How It Works?
          </button>
          <button
            type="button"
            class="btn btn-deep-success ml-3 mr-3 mb-2 mb-md-0"
            @click="exportForm"
            v-if="
              !previewOnly && user && (user.isAdmin || user.isManagingAdmin)
            "
          >
            <i class="fas fa-file-download fa-lg mr-3"></i>Export
          </button>
          <input
            class="d-none"
            id="file-upload"
            v-if="!previewOnly && user && user.isAdmin"
            type="file"
            accept=".json"
            @change="importForm"
          />
          <label
            class="btn btn-deep-success pointer-cursor mb-0"
            v-if="
              !previewOnly && user && (user.isAdmin || user.isManagingAdmin)
            "
            for="file-upload"
          >
            <i class="fas fa-file-upload fa-lg mr-3"></i>Import
          </label>
        </div>
        <div class="mt-3 mb-4">
          <div style="display: none">
            <div
              class="
                form-group
                d-flex
                pl-3
                pr-3
                col-sm-6 col-md-5 col-12
                mb-0
                align-items-center
              "
            >
              <select-control
                type="vertical"
                class="parent-light-shadow"
                v-model="selectedDemo"
                :options="demoOptions"
              ></select-control>
              <button
                type="button"
                class="btn btn-theme ml-3 mb-4"
                @click="useSelectedDemo"
              >
                Apply
              </button>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-outline-dark mr-3"
            v-if="!id && !previewOnly"
            @click.ctrl="useDemo2"
            @click.shift="useDemo2"
            @click.exact="useDemo"
          >
            Client Info
          </button>
          <button
            type="button"
            class="btn btn-black mr-3"
            @click="togglePreview"
            v-if="!previewOnly"
          >
            {{ isRenderer ? "Edit" : "Preview" }}
          </button>
          <button
            type="button"
            v-if="!previewOnly"
            class="btn btn-danger mr-3"
            @click="resetForm"
          >
            Reset
          </button>
          <button
            v-if="!previewOnly"
            class="btn btn-theme"
            type="submit"
            :disabled="submitting"
          >
            {{ submitting ? "Saving" : "Save" }}
          </button>
        </div>
      </div>
      <div v-if="!isRenderer && !previewOnly && !isLoading">
        <div class="d-flex flex-wrap">
          <div class="col-12 row m-0 p-0">
            <div class="form-group pl-3 pr-3 col-sm-6 col-md-5 col-12">
              <label>Template Title</label>
              <input
                type="text"
                class="form-control light-shadow"
                v-model="template.title"
                required
              />
            </div>
            <div
              class="
                form-group
                pl-3
                pr-3
                col-sm-6 col-md- col-12
                mb-0
                d-flex
                align-items-center
              "
              v-if="template.type == 'template'"
            >
              <input
                id="selectedRole"
                type="checkbox"
                class="mr-3 mt-md-4 pointer-cursor form-group"
                v-model="selectedRole"
                v-if="user && (user.isAdmin || user.isManagingAdmin)"
              />
              <label
                for="selectedRole"
                class="form-group mt-md-4 btn-link pointer-cursor"
                v-if="user && (user.isAdmin || user.isManagingAdmin)"
                >Available To Admins Only?</label
              >
            </div>
          </div>
          <div class="form-group pl-3 pr-3 col-sm-6 col-md-5 col-12 mb-0">
            <select-control
              class="parent-shadow-card"
              type="vertical"
              v-model="template.type"
              :options="typeOptions"
              labelClass="mb-2"
              required
              >Template Type</select-control
            >
          </div>
          <div
            class="form-group pl-3 pr-3 col-sm-6 col-md-4 col-12 mb-0"
            v-if="template.type == 'template'"
          >
            <select-control
              class="parent-shadow-card"
              type="vertical"
              v-if="!processing && categoryOptions.length"
              v-model="template.category"
              :options="categoryOptions"
              labelClass="mb-2"
              required
              >Category</select-control
            >
            <alert v-if="processing" class="mt-4 pt-2 pb-2" />
          </div>
          <div class="col-auto d-flex flex-column justify-content-center mt-0">
            <button
              v-if="!previewOnly && template.type == 'template'"
              class="btn btn-link pt-0 pt-md-3"
              @click.prevent="addCategory"
            >
              Add Category
            </button>
          </div>
        </div>
        <div class="d-flex mt-1 mb-4 align-items-end custom-control-builder">
          <div class="form-group pl-3 pr-3 col-sm-6 col-md-5 col-10">
            <select-control
              class="m-0 parent-shadow-card"
              type="vertical"
              v-model="selectedControl"
              :options="controlOptions"
              labelClass="mb-2"
              >Custom Control</select-control
            >
          </div>
          <button
            class="btn btn-theme control-add"
            type="button"
            @click="addCustomControl"
            :disabled="!selectedControl"
          >
            Add
          </button>
        </div>
      </div>
    </form>
    <div
      v-if="!isRenderer && !previewOnly && !isLoading && !processing"
      class="card light-shadow pt-4 mt-4 print-container"
    >
      <FormBuilder
        v-model="configuration"
        :baseURL="baseUrl"
        :key="configKey"
      ></FormBuilder>
    </div>
    <div
      v-if="(isRenderer || previewOnly) && !isLoading && configuration"
      class="card light-shadow pt-4 mt-4 print-container"
    >
      <FormRenderer
        class="col-12"
        :form-configuration="configuration"
        v-model="formInputData"
        :baseURL="baseUrl"
      />
    </div>
    <alert v-if="isLoading" class="mt-5" />
  </div>
</template>

<script>
import { FormBuilder } from "@ray-solutions/v-form-builder";
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";
import CONSTANT from "./constants";
import { isEmpty } from "lodash";

export default {
  name: "TemplateCreateComponent",
  components: { FormBuilder },
  created() {
    this.$formEvent.$on("builder.sidebar.opened", this.sidebarOpened);
    this.$formEvent.$on("builder.sidebar.after_closed", this.sidebarClosed);
    if (this.id) {
      this.getTemplateById(this.id).then((res) => {
        if (res) {
          const selectedTemplate = res;
          this.initialSavedForm = selectedTemplate.body;
          this.processing = true;
          this.configuration = Object.assign({}, this.initialSavedForm);
          setTimeout(() => {
            this.processing = false;
          }, 300);
          this.template = {
            title: selectedTemplate.title,
            role_id: selectedTemplate.role_id,
            category: `${selectedTemplate.category}`,
            type: selectedTemplate.type,
            id: this.id,
          };
          this.selectedRole = selectedTemplate.role_id == "2";
        }
      });
    }
    if (!this.serverControls.length) {
      this.getTemplates();
    }
    this.getTemplateCategories().then((res) => {
      this.categoryOptions = res.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      this.categoryOptions.unshift({
        value: "",
        label: "Select Category",
      });
    });
    this.uploadFromLS();
  },
  beforeDestroy() {
    window.localStorage.removeItem("template_form");
    this.$formEvent.$destroy();
    this.closeBuilderNav();
  },
  watch: {
    ...[
      "template",
      "selectedRole",
      "selectedControl",
      "initialSavedForm",
      "formInputData",
      "configuration",
      "selectedDemo",
    ].reduce((acc, currentKey) => {
      acc[currentKey] = {
        handler() {
          this.saveValuesToLS();
        },
        deep: true,
      };
      return acc;
    }, {}),
  },
  data: () => ({
    configuration: null,
    configKey: 0,
    selectedRole: false,
    submitting: false,
    saved: false,
    initialSavedForm: null,
    selectedDemo: "",
    processing: false,
    template: {
      title: "",
      role_id: "",
      category: "",
      type: "template",
    },
    formInputData: null,
    isRenderer: false,
    selectedControl: null,
    permissionOptions: [
      { value: "1", label: "All" },
      { value: "2", label: "Admins" },
      { value: "3", label: "Providers" },
      { value: "4", label: "Clients" },
    ],
    categoryOptions: [],
    typeOptions: [
      { value: "template", label: "Template" },
      { value: "control", label: "Control" },
    ],
  }),
  methods: {
    ...mapActions({
      getTemplateById: "templates/getTemplateById",
      createUpdateTemplate: "templates/createUpdateTemplate",
      getTemplateCategories: "templates/getTemplateCategories",
      getTemplates: "templates/getTemplates",
    }),
    generateRandomString(len) {
      let text = "";
      const chars = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return text;
    },
    exportForm() {
      const data = JSON.stringify(this.configuration);
      const blob = new Blob([data], { type: "text/plain" });
      const a = document.createElement("a");
      a.download = `${this.template.title || "no_title"}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
      a.click();
    },
    importForm(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      this.readFile(files[0]);
    },
    readFile(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        const content = JSON.parse(e.target.result);
        this.processing = true;
        this.configuration = Object.assign({}, content);
        this.configKey++;
        setTimeout(() => {
          this.processing = false;
        }, 0);
      };
      reader.readAsText(file);
    },
    saveValuesToLS: function () {
      try {
        window.localStorage.setItem(
          "template_form",
          JSON.stringify({
            template: this.template,
            selectedRole: this.selectedRole,
            selectedControl: this.selectedControl,
            configuration: this.configuration,
            formInputData: this.formInputData,
            selectedDemo: this.selectedDemo,
            initialSavedForm: this.initialSavedForm,
          })
        );
      } catch (err) {
        //eslint-disable-next-line
        console.log(err);
      }
    },
    uploadFromLS: function () {
      try {
        const templateForm =
          JSON.parse(window.localStorage.getItem("template_form")) || {};
        if (!isEmpty(templateForm)) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-black ml-4",
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          return swalWithBootstrapButtons
            .fire({
              title: "Did you lose your data?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, restore!",
              cancelButtonText: "No, cancel!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.template = {
                  ...this.template,
                  ...(templateForm.template || {}),
                };
                this.selectedControl = templateForm.selectedControl || null;
                this.configuration = templateForm.configuration || null;
                this.selectedRole = templateForm.selectedRole || false;
                this.selectedDemo = templateForm.selectedDemo || "";
                this.initialSavedForm = templateForm.initialSavedForm || null;
                this.formInputData = templateForm.formInputData || null;
              }
              window.localStorage.removeItem("template_form");
            });
        }
      } catch (e) {
        //eslint-disable-next-line
        console.log(e);
      }
    },
    saveForm: function () {
      this.closeBuilderNav();
      if (
        !this.configuration.sections ||
        !Object.keys(this.configuration.sections).length
      ) {
        Swal.fire({
          title: "Error",
          text: "No Sections Added To The Form",
          icon: "error",
        });
        return;
      }
      this.template.body = this.configuration;
      if (this.template.type != "template") {
        this.template.role_id = null;
        this.template.category = null;
      } else {
        if (this.selectedRole) {
          this.template.role_id = "2";
        } else {
          this.template.role_id = "1";
        }
      }
      this.submitting = true;
      this.createUpdateTemplate(this.template).then((res) => {
        this.submitting = false;
        if (res) {
          this.saved = true;
          window.localStorage.removeItem("template_form");
          this.$router.push({ name: "templates" });
        }
      });
    },
    resetForm: function () {
      this.processing = true;
      this.configuration = this.id
        ? Object.assign({}, this.initialSavedForm)
        : null;
      this.configKey++;
      setTimeout(() => {
        this.processing = false;
      }, 0);
      this.closeBuilderNav();
    },
    togglePreview: function () {
      this.closeBuilderNav();
      this.isRenderer = !this.isRenderer;
    },
    useSelectedDemo: function () {
      this.processing = true;
      this.configuration = Object.assign(
        {},
        CONSTANT.DEMOES[this.selectedDemo]
      );
      this.configKey++;
      setTimeout(() => {
        this.processing = false;
      }, 0);
    },
    useDemo: function () {
      this.processing = true;
      this.configuration = Object.assign({}, CONSTANT.DEMO);
      this.configKey++;
      setTimeout(() => {
        this.processing = false;
      }, 0);
    },
    useDemo2: function () {
      this.processing = true;
      this.configuration = Object.assign({}, CONSTANT.DEMO2);
      this.configKey++;
      setTimeout(() => {
        this.processing = false;
      }, 0);
    },
    closeBuilderNav: function () {
      // We need to close the sidebar in many cases like leaving the page; this is not handled in the library.
      // Trigger click for close button in sidebar to call library close events and methods.
      window.$(".vue-form-builder .sidebar .close").trigger("click");
      // Remove style added on sidebar open.
      this.sidebarClosed();
    },
    sidebarOpened: function () {
      // FormBuilder lib is modifying body tag and adding margin-right 300px.
      // However, we don't need to do margin for the body; only for page content to avoid resizing header and footer.
      const mainContainer =
        document.getElementsByClassName("main-container")[0];
      if (mainContainer.style.removeProperty) {
        document
          .getElementsByTagName("body")[0]
          .style.removeProperty("margin-right");
      } else {
        document
          .getElementsByTagName("body")[0]
          .style.removeAttribute("margin-right");
      }
      mainContainer.style.marginRight = "300px";
      mainContainer.style.width = "auto";
    },
    sidebarClosed: function () {
      // FormBuilder lib is modifying body tag, so we need to revert that change on close.
      const mainContainer =
        document.getElementsByClassName("main-container")[0];
      if (mainContainer.style.removeProperty) {
        mainContainer.style.removeProperty("margin-right");
        mainContainer.style.removeProperty("width");
        document
          .getElementsByTagName("body")[0]
          .style.removeProperty("margin-right");
      } else {
        mainContainer.style.removeAttribute("margin-right");
        mainContainer.style.removeAttribute("width");
        document
          .getElementsByTagName("body")[0]
          .style.removeAttribute("margin-right");
      }
    },
    addCustomControl: function () {
      if (this.selectedControl) {
        if (!this.configuration.sections) {
          Swal.fire({
            title: "Error",
            text: "No sections added to the Form",
            icon: "error",
          });
        } else {
          this.processing = true;
          const controls = {};
          const tempControls =
            this.customControls[parseInt(this.selectedControl)].body.controls;
          Object.keys(tempControls).forEach((key) => {
            const newKey = `control-${this.generateRandomString(50)}`;
            controls[newKey] = JSON.parse(
              JSON.stringify(tempControls[key]).replace(
                new RegExp(key, "g"),
                newKey
              )
            );
            const newBody = JSON.stringify(
              this.customControls[parseInt(this.selectedControl)].body
            ).replace(new RegExp(key, "g"), newKey);
            this.customControls[parseInt(this.selectedControl)].body =
              JSON.parse(newBody);
          });

          const sections = {};
          let lastSectionOrder = 0;

          const tempSections =
            this.customControls[parseInt(this.selectedControl)].body.sections;

          Object.keys(this.configuration.sections).forEach((key) => {
            if (this.configuration.sections[key].sortOrder > lastSectionOrder) {
              lastSectionOrder = this.configuration.sections[key].sortOrder;
            }
          });

          Object.keys(tempSections).forEach((key) => {
            const newKey = `section-${this.generateRandomString(50)}`;
            sections[newKey] = JSON.parse(
              JSON.stringify(tempSections[key]).replace(
                new RegExp(key, "g"),
                newKey
              )
            );
            sections[newKey].sortOrder = ++lastSectionOrder;
          });

          this.configuration.sections = {
            ...(this.configuration.sections || {}),
            ...sections,
          };
          this.configuration.controls = {
            ...(this.configuration.controls || {}),
            ...controls,
          };
          this.configuration.rows = {
            ...(this.configuration.rows || {}),
            ...((this.initialSavedForm || {}).rows || {}),
          };
          setTimeout(() => {
            this.processing = false;
          }, 0);
        }
      }
    },
    navigateToGuide: function () {
      this.$router.push({ path: "/templates/guide" });
    },
    addCategory: function () {
      Swal.fire({
        title: "Enter Category Name",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Add",
        showLoaderOnConfirm: true,
        preConfirm: (categoryName) => {
          if (!categoryName) {
            return Swal.showValidationMessage("Category name is required.");
          }
          return this.$http
            .post("form-templates-category/", { name: categoryName })
            .then((res) => {
              return res;
            })
            .catch((err) => {
              if (!err.accessDenied) {
                Swal.showValidationMessage(
                  `${err.data && err.data.error ? err.data.error.message : err}`
                );
              }
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "",
            text: "Category Added Successfully",
            icon: "success",
          });
          this.getTemplateCategories().then((res) => {
            this.categoryOptions = res.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            });
          });
        }
      });
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.templates.isLoading,
      baseUrl: (state) => state.appConfig.api,
      serverControls: (state) => {
        return (state.templates || {}).templates || [];
      },
      customControls: (state) => {
        const templates = (state.templates || {}).templates || [];
        let controls = [];
        if (templates.length) {
          controls = templates.filter((item) => item.type == "control");
        }
        const systemControls = CONSTANT.CONTROLS;
        systemControls.forEach((control) => {
          control["systemSupport"] = true;
        });
        controls = [...controls, ...systemControls];
        return JSON.parse(JSON.stringify(controls));
      },
      controlOptions: function () {
        const controls = [];
        this.customControls.forEach((element, index) => {
          controls.push({
            value: index,
            label: element.title,
            class: element.systemSupport ? "text-danger-light border" : "",
          });
        });
        return controls;
      },
      user: (state) => state.auth.user,
    }),
    id: function () {
      return this.$route.params.id;
    },
    previewOnly: function () {
      return (
        this.$route.query.preview ||
        (this.$route.params.id &&
          (!this.user || (!this.user.isAdmin && !this.user.isManagingAdmin)))
      );
    },
    demoOptions: function () {
      return Object.keys(CONSTANT.DEMOES).map((key) => {
        return {
          value: key,
          label: key,
        };
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.saved || this.previewOnly) {
      return next();
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-black ml-4",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    return swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "Any changes you made may not be saved.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, leave!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          return next();
        }
        return next(false);
      });
    // called when the route that renders this component is about to be navigated away from.
    // As with `beforeRouteUpdate`, it has access to `this` component instance.
  },
};
</script>
<style>
.custom-control-builder div,
.custom-control-builder .form-group {
  margin-bottom: 0;
}
#selectedRole {
  width: 1.25rem;
  height: 1.25rem;
}
</style>
<style lang="scss">
.parent-shadow-card {
  .form-control {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
  }
}
</style>
