export default [
  {
    path: "/admin/professions",
    name: "professions",
    component: () => import("../views/admin/professions/Index.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Professions",
    },
  },
  {
    path: "/admin/professions/create",
    name: "professions.add",
    component: () => import("../views/admin/professions/Create.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Create Profession",
    },
  },
  {
    path: "profession",
    name: "admin.profession",
    component: () => import("../views/admin/profile/profession/Index.vue"),
    meta: {
      title: "Profession",
    },
  },
  {
    path: "/admin/professions/:id",
    name: "professions.edit",
    component: () => import("../views/admin/professions/Create.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Edit Profession",
    },
  },
  {
    path: "/admin/:id/profession/create",
    name: "admin.profession.create",
    component: () => import("../views/admin/profile/profession/Create.vue"),
    meta: {
      title: "Profession",
      auth: true,
    },
  },
  {
    path: "/admin/services",
    name: "services.list",
    component: () => import("../views/admin/services/ServicesList"),
    meta: {
      auth: true,
      admin: true,
      title: "Services",
    },
  },

  {
    path: "/admin/services/create",
    name: "create.service",
    component: () => import("../views/admin/services/Create"),
    meta: {
      auth: true,
      admin: true,
      title: "Create Service",
    },
  },
  {
    path: "/admin/reports/billing",
    name: "billingReports",
    component: () => import("../views/admin/reports/Billing"),
    meta: {
      auth: true,
      admin: true,
      title: "Billing Reports",
    },
  },
  {
    path: "/admin/services/:parentServiceId/create-sub-services",
    name: "create.sub-service",
    component: () => import("../views/admin/services/Create"),
    meta: {
      auth: true,
      admin: true,
      title: "Create Sub Service",
    },
  },
  {
    path: "/admin/services/:parentServiceId/:id/edit-sub-services",
    name: "create.sub-service",
    component: () => import("../views/admin/services/Create"),
    meta: {
      auth: true,
      admin: true,
      title: "Edit Sub Service",
    },
  },
  {
    path: "/admin/services/:id",
    name: "services",
    component: () => import("../views/admin/services/Index"),
    children: [
      {
        path: "",
        name: "services.update",
        component: () => import("../views/admin/services/Create"),
        meta: {
          auth: true,
          admin: true,
          title: "Update Service",
        },
      },
      {
        path: "update",
        name: "services.update",
        component: () => import("../views/admin/services/Create"),
        meta: {
          auth: true,
          title: "Update Service",
        },
      },
    ],
  },

  {
    path: "/admin/users",
    name: "users",
    component: () => import("../views/admin/users/Index.vue"),
    meta: {
      auth: true,
      admin: true,
      noManager: true,
      title: "Admin Users",
    },
  },

  {
    path: "/admin/users-availability",
    name: "users.availability",
    component: () => import("../views/admin/users/Availability.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Users Availability",
    },
  },

  {
    path: "/admin/clients-import",
    name: "users",
    component: () => import("../views/admin/users-import/ClientsImport.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Import Clients",
    },
  },

  {
    path: "/admin/providers-import",
    name: "users",
    component: () => import("../views/admin/users-import/ProvidersImport.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Import Providers",
    },
  },

  {
    path: "/admin/reports/payroll",
    name: "payrollReports",
    component: () => import("../views/admin/reports/Payroll"),
    meta: {
      auth: true,
      admin: true,
      title: "Payroll Reports",
    },
  },

  {
    path: "/admin/reports/payroll",
    name: "payrollReports",
    component: () => import("../views/admin/reports/Payroll"),
    meta: {
      auth: true,
      admin: true,
      title: "Payroll Reports",
    },
  },
  {
    path: "/admin/reports/maxhours",
    name: "payrollReports",
    component: () => import("../views/admin/reports/Maxhours"),
    meta: {
      auth: true,
      admin: true,
      title: "Budget Reports",
    },
  },
  {
    path: "/admin/reports/hcai",
    component: () => import("../views/admin/reports/HCAI/Index"),
    children: [
      {
        path: "",
        name: "HCAIBillingReport",
        component: () => import("../views/admin/reports/HCAI/HCAIReport"),
      },
      {
        path: "ocf21b",
        name: "ocf21b",
        component: () => import("../views/admin/reports/HCAI/OCF21B"),
      },
      {
        path: "ocf21b-sent-invoices",
        name: "ocf21b-sent-invoices",
        component: () =>
          import("../views/admin/reports/HCAI/OCF21BSentInvoices"),
      },
    ],
    meta: {
      auth: true,
      admin: true,
      title: "HCAI Reports",
    },
  },
  {
    path: "/admin/locations",
    name: "locations",
    component: () => import("../views/admin/locations/Index.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Locations",
    },
  },
  {
    path: "/admin/users/create",
    name: "users.add",
    component: () => import("../views/admin/users/Create.vue"),
    meta: {
      auth: true,
      admin: true,
      noManager: true,
      title: "Create Users",
    },
  },

  {
    path: "/admin/users/:id",
    name: "users.edit",
    component: () => import("../views/admin/users/Edit.vue"),
    meta: {
      auth: true,
      admin: true,
      noManager: true,
      title: "Edit User",
    },
  },
  {
    path: "/admin/documents",
    name: "admin.documents",
    component: () => import("../views/admin/profile/documents/Index.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/admin/profile",
    name: "admin.profile",
    component: () => import("../views/admin/profile/Index.vue"),
    meta: {
      auth: true,
      admin: true,
    },
    children: [
      {
        path: "",
        component: () => import("../views/admin/profile/AdminProfile.vue"),
        children: [
          {
            path: "",
            name: "admin.profile.basic",
            component: () => import("../views/myProfile/Profile.vue"),
          },
          {
            path: "address",
            name: "admin.profile.address",
            component: () => import("../views/admin/profile/address/Index.vue"),
          },
          {
            path: "address/create",
            name: "admin.profile.address.create",
            component: () =>
              import("../views/admin/profile/address/Create.vue"),
          },
          {
            path: "phones",
            name: "admin.profile.phones",
            component: () => import("../views/admin/profile/phones/Index.vue"),
          },
          {
            path: "phones/create",
            name: "admin.profile.phones.create",
            component: () => import("../views/admin/profile/phones/Create.vue"),
          },
        ],
      },
      {
        path: "/documents",
        name: "admin.profile.documents",
        component: () => import("../views/admin/profile/documents/Index.vue"),
      },
      {
        path: "/timeline",
        name: "admin.profile.timeline",
        component: () => import("../views/admin/timeline/Index"),
      },
      {
        path: "/payroll",
        name: "admin.profile.payroll",
        component: () => import("../views/admin/payroll/Index.vue"),
      },
      {
        path: "/my-professions",
        name: "admin.profile.professions",
        component: () => import("../views/admin/profile/profession/Index.vue"),
      },
      {
        path: "/shared-recordings",
        name: "admin.profile.shared-recordings",
        component: () => import("../views/admin/profile/SharedRecordings.vue"),
      },
      {
        path: "/availability",
        name: "admin.profile.availability",
        component: () => import("../views/admin/profile/Availability.vue"),
      },
      {
        path: "/c3-integration",
        name: "admin.c3.integration",
        component: () => import("../views/myProfile/C3Integration.vue"),
      },
    ],
  },
  {
    path: "/clients-contacts",
    name: "clients-contacts",
    component: () => import("../views/admin/clientsContacts/Index.vue"),
    meta: {
      title: "Clients Contacts",
      auth: true,
      admin: true,
    },
  },
  {
    path: "/templates",
    name: "templates",
    component: () => import("../views/admin/templates/Index.vue"),
    meta: {
      title: "templates",
      auth: true,
    },
  },
  {
    path: "/templates/add",
    name: "templates.add",
    component: () => import("../views/admin/templates/Create.vue"),
    meta: {
      title: "add template",
      // admin: true,
      auth: true,
    },
  },
  {
    path: "/templates/guide",
    name: "templates.guide",
    component: () => import("../views/admin/templates/Guide.vue"),
    meta: {
      title: "Template Guide",
      auth: true,
    },
  },
  {
    path: "/templates/:id",
    name: "templates.Edit",
    component: () => import("../views/admin/templates/Create.vue"),
    meta: {
      title: "edit-view template",
      auth: true,
    },
  },
  {
    path: "/admin/timeline",
    name: "admin.timeline",
    component: () => import("../views/admin/timeline/Index"),
    meta: {
      admin: true,
      title: "Timeline",
      auth: true,
    },
  },
  {
    path: "/admin/settings/general/profile",
    name: "companyProfile",
    component: () => import("../views/admin/settings/general/profile"),
    meta: {
      admin: true,
      noManager: true,
      title: "company-profile",
      auth: true,
    },
  },

  {
    path: "/admin/settings/security/permissions",
    name: "roles",
    component: () => import("../views/admin/settings/security/permissions"),
    meta: {
      admin: true,
      title: "Roles",
      auth: true,
    },
  },

  {
    path: "/admin/settings",
    name: "settings",
    component: () => import("../views/admin/settings/Index"),
    meta: {
      admin: true,
      title: "Settings",
      auth: true,
    },
  },

  {
    path: "/admin/settings/security/users-roles",
    name: "users-roles",
    component: () => import("../views/admin/settings/security/users-roles"),
    meta: {
      admin: true,
      title: "Users Roles",
      auth: true,
    },
  },
  {
    path: "/admin/clinics",
    name: "clinics",
    component: () => import("../views/admin/clinics/Index.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Clinics",
    },
  },
  {
    path: "/admin/clinics/create",
    name: "clinics.add",
    component: () => import("../views/admin/clinics/Create.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Create Clinic",
    },
  },
  {
    path: "/admin/clinics/:id",
    name: "clinics.edit",
    component: () => import("../views/admin/clinics/Edit.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Edit Clinic",
    },
  },
  {
    path: "/admin/classes",
    name: "classes",
    component: () => import("../views/admin/classifications/Index.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Classes",
    },
  },
  {
    path: "/admin/classes/create",
    name: "classes.add",
    component: () => import("../views/admin/classifications/Create.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Create Class",
    },
  },
  {
    path: "/admin/classes/:id",
    name: "class.edit",
    component: () => import("../views/admin/classifications/Edit.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Edit Class",
    },
  },
  {
    path: "/admin/referral-sources",
    name: "referral-sources",
    component: () => import("../views/admin/referral-sources/Index.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Referral Sources",
    },
  },
  {
    path: "/admin/referral-sources/create",
    name: "referral-sources.add",
    component: () => import("../views/admin/referral-sources/Create.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Create a Referral Source",
    },
  },

  {
    path: "/admin/referral-sources/:id",
    name: "referral-sources.edit",
    component: () => import("../views/admin/referral-sources/Edit.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Edit Referral Sources",
    },
  },
];
