<template>
  <div class="container">
    <h1 class="text-center">Import Providers</h1>
    <div class="d-flex justify-content-between file-input-container">
      <div
        class="custom-file light-shadow radius-36"
        :class="`${isValid ? 'has-btn' : ''}`"
      >
        <input
          type="file"
          class="custom-file-input"
          @click="onClick"
          @change="onChange"
          accept=".xls, .xlsx"
        />
        <label class="custom-file-label radius-36">Attach File</label>
      </div>
      <button
        class="btn btn-black btn-sm"
        v-if="isValid"
        @click="viewContent = !viewContent"
      >
        <i
          class="fa mr-2"
          :class="`${viewContent ? 'fa-eye-slash' : 'fa-eye'}`"
        ></i>
        {{ viewContent ? "Hide" : "Show" }} File
      </button>
    </div>
    <div
      class="alert alert-danger mt-4"
      role="alert"
      v-if="error"
      v-html="error"
    ></div>
    <div class="alert alert-warning mt-4" role="alert" v-if="warningLines">
      <b><i class="fas fa-exclamation-triangle mr-1"></i> Warning:</b><br />
      Below <b>{{ warningCount }}</b> line{{
        warningCount !== 1 ? "s" : ""
      }}
      will be ignored due to missing requirements:<br />
      <div
        v-html="warningLines"
        style="max-height: 400px; overflow: auto"
      ></div>
    </div>
    <div class="text-center" v-if="isValid">
      <button class="btn btn-theme mt-2 mb-3" @click="uploadProviders">
        <i class="fa fa-lg mr-2 fa-file-upload"></i>
        Import {{ warningLines ? "anyway" : "Providers" }}
      </button>
    </div>
    <xlsx-read :file="file">
      <div
        class="file-content radius-36"
        :class="`${viewContent ? 'expanded' : 'collapsed'}`"
      >
        <div
          class="table table-striped mb-0 overflow-auto excel-table border-0"
        >
          <xlsx-table v-if="isValid" sheet="Providers" />
        </div>
      </div>
      <xlsx-json class="u-display-none" sheet="Providers" @parsed="parseSheet">
        <template #default="{ collection }">
          <div>
            {{ collection }}
          </div>
        </template>
      </xlsx-json>
    </xlsx-read>
    <hr />
    <h2
      class="mt-5 text-main accordion-header"
      data-toggle="collapse"
      href="#collapseInstuctions"
      role="button"
      aria-expanded="true"
      aria-controls="collapseInstuctions"
    >
      Instructions <i class="ml-2 fas fa-caret-up"></i>
    </h2>
    <div class="collapse show" id="collapseInstuctions">
      <div class="card theme-card border-0 card-body">
        <ul>
          <li class="mt-2">
            The imported excel file should have sheet called
            <span
              style="
                background-color: #e3ebf6;
                padding-left: 4px;
                padding-right: 4px;
                color: blueviolet;
                border-radius: 3px;
              "
              >Providers</span
            >
            (<span style="text-decoration: underline">case sensitive</span>).
          </li>
          <li class="mt-3">
            The sheet should contain below headers (<span
              style="text-decoration: underline"
              >case sensitive</span
            >):
            <ul>
              <li class="mt-2">
                <b>First Name</b>
                <span
                  class="
                    bg-danger
                    pt-1
                    pb-1
                    pl-2
                    pr-2
                    text-white
                    required-badge
                  "
                  >Required</span
                >
              </li>
              <li class="mt-3">
                <b>Last Name</b>
                <span
                  class="
                    bg-danger
                    pt-1
                    pb-1
                    pl-2
                    pr-2
                    text-white
                    required-badge
                  "
                  >Required</span
                >
              </li>
              <li class="mt-3">
                <b>Email</b>
                <span
                  class="
                    bg-danger
                    pt-1
                    pb-1
                    pl-2
                    pr-2
                    text-white
                    required-badge
                  "
                  >Required</span
                >
              </li>
              <li class="mt-3">
                <b>Status</b>
                <br />The value can be:
                <ul>
                  <li>
                    Active
                    <span
                      class="
                        bg-secondary
                        pt-1
                        pb-1
                        pl-2
                        pr-2
                        text-white
                        required-badge
                      "
                      >Default</span
                    >
                  </li>
                  <li>Pending</li>
                  <li>Inactive</li>
                </ul>
              </li>
              <li class="mt-3">
                <b>Birth Date</b> <br />If provided, the format
                <span style="text-decoration: underline">should be</span>:
                <span class="bg-warning pt-1 pb-1 pl-2 pr-2 required-badge"
                  >yyyy-mm-dd (Ex: 1980-12-30)</span
                >
              </li>
              <li class="mt-3">
                <b>Gender</b> <br />
                If provided, the value can be:
                <ul>
                  <li>Other</li>
                  <li>Male</li>
                  <li>Female</li>
                </ul>
              </li>
              <li class="mt-3">
                <b>Mobile Phone</b
                ><span
                  class="
                    bg-danger
                    pt-1
                    pb-1
                    pl-2
                    pr-2
                    text-white
                    required-badge
                  "
                  >Required</span
                >
                <br />Optional if Home Phone is provided.
              </li>
              <li class="mt-3">
                <b>Home Phone</b
                ><span
                  class="
                    bg-danger
                    pt-1
                    pb-1
                    pl-2
                    pr-2
                    text-white
                    required-badge
                  "
                  >Required</span
                >
                <br />Optional if Mobile Phone is provided.
              </li>
              <li class="mt-3">
                <b>S.I.N</b
                ><span
                  class="
                    bg-danger
                    pt-1
                    pb-1
                    pl-2
                    pr-2
                    text-white
                    required-badge
                  "
                  >Required</span
                >
              </li>
              <li class="mt-3"><b>HST Number</b></li>
              <li class="mt-3"><b>Hire Date</b></li>
              <li class="mt-3"><b>Bio</b></li>
              <li class="mt-3">
                <b>Administrators</b><br />
                If provided, the format
                <span style="text-decoration: underline">should be</span>:
                <span class="bg-warning pt-1 pb-1 pl-2 pr-2 required-badge"
                  >LastName1, FirstName1 ; LastName2, FirstName2</span
                >
                <br />Where the administrators are seperated by semicolon.
                <br />The Administrators should be exist in the system.
                Otherwise, will be ignored.
              </li>
              <li class="mt-3">
                <b>Provider Class</b><br />
                If provided, the format
                <span style="text-decoration: underline">should be</span>:
                <span class="bg-warning pt-1 pb-1 pl-2 pr-2 required-badge"
                  >Class1 ; Class2</span
                >
                <br />Where the classes are seperated by semicolon. <br />The
                classes should be exist in the system. Otherwise, will be
                ignored.
              </li>
              <li class="mt-3">
                <b>Clinic</b><br />
                If provided, the format
                <span style="text-decoration: underline">should be</span>:
                <span class="bg-warning pt-1 pb-1 pl-2 pr-2 required-badge"
                  >Clinic1 ; Clinic2</span
                >
                <br />Where the clinics are seperated by semicolon. <br />The
                clinics should be exist in the system. Otherwise, will be
                ignored.
              </li>
              <li class="mt-3">
                <b>Password</b>
                <span
                  class="
                    bg-secondary
                    pt-1
                    pb-1
                    pl-2
                    pr-2
                    text-white
                    required-badge
                  "
                  >Default: demopass</span
                >
              </li>
              <li class="mt-3">
                <b>Mailing/Billing Address</b> <br />If provided, the address
                should be accurate as much as possible. <br />
                Otherwise, wrong address will be used or no address will be
                added.
              </li>
              <li class="mt-3">
                <b>Can Verify Events</b>
                <br />The value can be:
                <ul>
                  <li>Yes</li>
                  <li>
                    No
                    <span
                      class="
                        bg-secondary
                        pt-1
                        pb-1
                        pl-2
                        pr-2
                        text-white
                        required-badge
                      "
                      >Default</span
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="mt-3">The order for sheet headers is not mandatory.</li>
          <li class="mt-3">Make sure the sheet has no empty rows.</li>
          <li class="mt-3">
            Make sure the format for all cells is
            <b class="text-danger">Text</b>
            <span
              class="bg-danger pt-1 pb-1 pl-2 pr-2 text-white required-badge"
              >Important</span
            >
            <br />
            <br />
            <img class="excel-image" src="@/assets/img/excel-format.png" />
          </li>
        </ul>
        <hr />
        <div class="d-flex justify-content-center">
          <a
            download="clients_providers.xlsx"
            class="btn btn-theme mt-3"
            :href="`${publicPath}clients_providers.xlsx`"
            rel="xlsx"
          >
            Download Example
          </a>
        </div>
      </div>
    </div>
    <loader v-if="processing"
      ><b class="mt-4 text-center" v-html="processingText"></b>
    </loader>
  </div>
</template>
<script>
import { XlsxRead, XlsxJson, XlsxTable } from "vue-xlsx";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "ProvidersImport",
  components: {
    XlsxRead,
    XlsxJson,
    XlsxTable,
  },
  data() {
    return {
      viewContent: false,
      isValid: false,
      providers: [],
      warningLines: "",
      warningCount: 0,
      error: "",
      loading: false,
      processing: false,
      processingText: "",
      file: null,
      publicPath: process.env.BASE_URL,
      admins: [],
      errors: [],
      percent: -1,
    };
  },
  methods: {
    ...mapActions({
      importProviders: "providers/importProviders",
    }),
    onClick(event) {
      if (event.target) {
        event.target.value = "";
      }
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    parseSheet(data) {
      if (data.length) {
        this.isValid = true;
        this.error = "";
        if (!this.admins.length) {
          this.processing = true;
          this.processingText = "Getting necessary data...";
          this.$http.get("admins-managers-info").then((usersRes) => {
            this.admins = usersRes.data;
            this.processProvidersData(data);
            this.processing = false;
          });
        } else {
          this.processProvidersData(data);
        }
      } else {
        this.isValid = false;
        if (this.file.type.indexOf("sheet") == -1) {
          this.error = "Invalid file.";
        } else {
          this.error = "Empty file.";
        }
      }
    },
    processProvidersData(data) {
      this.providers = [];
      this.warningLines = "";
      this.warningCount = 0;
      const emails = [];
      data.forEach((record, index) => {
        const provider = {
          first: (record["First Name"] || "").toString().trim(),
          last: (record["Last Name"] || "").toString().trim(),
          email: (record["Email"] || "").toString().trim(),
          username: (record["Email"] || "").toString().trim(),
          password: (record["Password"] || "").toString().trim() || "demopass",
          birth_date: (record["Birth Date"] || "").toString().trim(),
          phone_number: this.getRecordPhone(record),
          mobile_number: (record["Mobile Phone"] || "")
            .toString()
            .trim()
            .replace(/-/g, ""),
          home_number: (record["Home Phone"] || "")
            .toString()
            .trim()
            .replace(/-/g, ""),
          hired_at: (record["Hire Date"] || "").toString().trim(),
          hstNumber: (record["HST Number"] || "").toString().trim(),
          sin: (record["S.I.N"] || "").toString().trim(),
          bio: (record["Bio"] || "").toString().trim(),
          status: this.getRecordStatus(record),
          gender: this.getRecordGender(record),
          can_verify_event:
            (record["Can Verify Events"] || "")
              .toString()
              .trim()
              .toLowerCase() == "yes"
              ? true
              : false,
          enable_sending_email: false,
          enable_sending_sms: false,
          address: (record["Mailing/Billing Address"] || "").toString().trim(),
        };

        if (provider.hired_at) {
          provider.hired_at += "T00:00:00.000Z";
        }
        if (provider.birth_date && provider.birth_date.indexOf("-") > -1) {
          provider.birth_date += "T00:00:00.000Z";
        } else {
          provider.birth_date = "";
        }

        if ((record["Administrators"] || "").toString().trim()) {
          const administrator_ids = [];
          const mentionedAdmins = record["Administrators"]
            .toString()
            .trim()
            .split(";");
          mentionedAdmins.forEach((mentionedAdmin) => {
            this.admins.forEach((admin) => {
              if (
                mentionedAdmin.toLowerCase().trim() &&
                admin.name.toLowerCase().trim() ==
                  mentionedAdmin.toLowerCase().trim()
              ) {
                administrator_ids.push(admin.id);
              }
            });
          });
          if (administrator_ids.length) {
            provider.administrator_ids = administrator_ids;
          }
        }
        if ((record["Provider Class"] || "").toString().trim()) {
          const classes = [];
          const mentionedClasses = record["Provider Class"]
            .toString()
            .trim()
            .split(";");
          mentionedClasses.forEach((mentionedClass) => {
            if (mentionedClass.toLowerCase().trim()) {
              classes.push(mentionedClass.toLowerCase().trim());
            }
          });
          if (mentionedClasses.length) {
            provider.provider_class = classes;
          }
        }
        if ((record["Clinic"] || "").toString().trim()) {
          const clinics = [];
          const mentionedClinics = record["Clinic"]
            .toString()
            .trim()
            .split(";");
          mentionedClinics.forEach((mentionedClinic) => {
            if (mentionedClinic.toLowerCase().trim()) {
              clinics.push(mentionedClinic.toLowerCase().trim());
            }
          });
          if (mentionedClinics.length) {
            provider.provider_clinic = clinics;
          }
        }
        if (!this.checkWarning(provider, index, emails)) {
          this.providers.push(provider);
          emails.push(provider.email);
        }
      });
    },
    getRecordPhone: function (record) {
      return (record["Mobile Phone"] || record["Home Phone"] || "")
        .toString()
        .trim()
        .replace(/-/g, "");
    },
    getRecordStatus: function (record) {
      const value = (record["Status"] || "").toString().trim().toLowerCase();
      if (value == "inactive") {
        return "INACTIVE";
      } else if (value == "pending") {
        return "PENDING";
      } else {
        return "ACTIVE";
      }
    },
    getRecordGender: function (record) {
      const value = (record["Gender"] || "").toString().trim().toLowerCase();
      if (value == "male") {
        return "1";
      } else if (value == "female") {
        return "2";
      }
      return "0";
    },
    checkEmail: function (email) {
      const emailDotIndex = (email || "").lastIndexOf(".");
      if (emailDotIndex <= (email || "").indexOf("@") + 1) {
        return false;
      }
      return true;
    },
    checkWarning: function (provider, index, emailsList) {
      let exactError = "";
      if (!provider.email) {
        exactError = "Email is missing.";
      } else if (!this.checkEmail(provider.email)) {
        exactError = "Invalid email is used.";
      } else if (!provider.first) {
        exactError = "First Name is missing.";
      } else if (!provider.last) {
        exactError = "Last Name is missing.";
      } else if (!provider.phone_number) {
        exactError = "Phone Number is missing.";
      } else if (!provider.sin) {
        exactError = "S.I.N is missing.";
      } else if (provider.sin.length != 9) {
        exactError = "S.I.N should be 9 digits.";
      } else if (emailsList.includes(provider.email)) {
        exactError = "Same email is used in another record.";
      }
      if (exactError) {
        this.warningCount++;
        this.warningLines = `${this.warningLines}<b>${
          index + 2
        }</b> - ${exactError}<br>`;
        return true;
      }
      return false;
    },
    setProgressMessage: function (message) {
      const percentMessage = `<h1 style="color: mediumspringgreen">${this.percent} %</h1>`;
      this.processingText = `${
        this.percent > -1 ? percentMessage : ""
      }${message}`;
    },
    sendProvidersToServer: function () {
      this.percent = 0;
      this.setProgressMessage(
        "Preparing providers to import... (uploading providers)<br>This may take few moments. Please don't refresh the page."
      );
      let providersToSend = new Array(...this.providers);
      var providersSepaated = [];
      while (providersToSend.length) {
        providersSepaated.push(providersToSend.splice(0, 15));
      }

      this.errors = [];
      const providersPromises = [];
      providersSepaated.forEach((chuck, index) => {
        if (index == 0) {
          providersPromises.push(this.importProvidersChunck(chuck));
        } else {
          providersPromises[index] = providersPromises[index - 1].then(() => {
            this.percent = Math.ceil(
              ((index * 15) / this.providers.length) * 100
            );
            this.setProgressMessage(
              "Preparing providers to import... (uploading providers)<br>This may take few moments. Please don't refresh the page."
            );
            return this.importProvidersChunck(chuck);
          });
        }
      });
      providersPromises[providersSepaated.length - 1].then(() => {
        this.fireError();
        if (this.errors.length) {
          this.error = `<b>Issues while importing the providers:</b><br><ul><li>${this.errors.join(
            "</li><li>"
          )}</li></ul>`;
        } else {
          Swal.fire({
            title: "Providers Imported",
            text: "Providers Imported Successfully",
            icon: "success",
          });
        }
      });
    },
    importProvidersChunck: function (data) {
      return new Promise((resolve) => {
        this.importProviders({ providers: data }).then((res) => {
          if (res.errors && res.errors.length) {
            this.errors.push(...res.errors);
          }
          resolve();
        });
      });
    },
    uploadProviders: function () {
      const addressPromises = [];
      this.processing = true;
      this.setProgressMessage(
        "Preparing providers to import...<br>This may take few moments. Please don't refresh the page."
      );
      if (!this.providers.length) {
        this.fireError("No valid providers to import!");
      } else {
        this.setProgressMessage(
          "Preparing providers to import... (getting providers addresses)<br>This may take few moments. Please don't refresh the page."
        );
        let addressesRequestsLength = 0;
        this.providers.forEach((provider, index) => {
          if (provider.address) {
            addressesRequestsLength++;
            setTimeout(() => {
              addressPromises.push(
                this.getProviderAddress(provider.address, index)
              );
            }, 70 * index);
          }
        });
        setTimeout(() => {
          Promise.all(addressPromises).then(() => {
            this.sendProvidersToServer();
          });
        }, 70 * addressesRequestsLength);
      }
    },
    fireError: function (error) {
      this.error = error;
      this.warningLines = "";
      this.warningCount = 0;
      this.processing = false;
      this.processingText = "";
      this.percent = -1;
    },
    getProviderAddress: function (address, index) {
      let vm = this;
      return new Promise(function (resolve) {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${
              process.env.VUE_APP_GOOGLE ||
              "AIzaSyATebGots3HbXasYoIJsf8Ok63eDU_llX0"
            }`
          )
          .then((res) => {
            if (res.status == 200 && res.data.status == "OK") {
              vm.selectAddress(res.data.results[0], index);
            }
            resolve();
          });
      });
    },
    selectAddress: function (addressRecord, index) {
      if (addressRecord.address_components.length > 0) {
        let keyMatch = {
          subpremise: "unit",
          street_number: "house",
          route: "street",
          locality: "city",
          administrative_area_level_1: "state",
          country: "country",
          postal_code: "zip",
        };
        let addressData = [];

        addressRecord.address_components.forEach((d) => {
          if (typeof keyMatch[d.types[0]] != "undefined") {
            let key = keyMatch[d.types[0]];
            let value = d.short_name;
            addressData[key] = value;
          }
        });
        let latLng = {
          lat: null,
          lng: null,
        };
        if (addressRecord.geometry.location) {
          latLng = {
            lat: parseFloat(addressRecord.geometry.location.lat),
            lng: parseFloat(addressRecord.geometry.location.lng),
          };
        }

        const addressToSave = {
          ...addressData,
          ...latLng,
          billing: false,
          shipping: true,
        };
        this.providers[index].address = addressToSave;
      }
    },
  },
};
</script>

<style scoped lang="scss">
hr {
  border-color: var(--secondary-color) !important;
}
.has-btn {
  width: calc(100% - 100px);
}
.file-input-container {
  border-radius: 9px;
}
.accordion-header {
  i {
    transition: all 0.3s ease-in-out;
  }
  &[aria-expanded="false"] {
    i {
      transform: rotate(180deg);
    }
  }
}
.file-content {
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  &.collapsed {
    max-height: 0;
  }
  &.expanded {
    max-height: 9999px;
    overflow-y: auto;
  }
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  max-height: 415px;
  overflow: auto;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.required-badge {
  font-size: 10px;
  margin-left: 8px;
  border-radius: 6px;
  font-weight: bold;
}

.excel-image {
  max-width: 90%;
}

.loader {
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.7);
  color: whitesmoke;
  flex-direction: column;
}
</style>

<style lang="scss">
.excel-table {
  color: var(--main-text-color);
  tr:first-child {
    td {
      background-color: rgba(0, 0, 0, 0.4);
      color: white;
      border-top: 0;
    }
  }
}
</style>
