import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import dateFormat from "@/utils/dateFormat";

dayjs.extend(utc);
export default {
  namespaced: true,
  state: {
    billingData: [],
    payrollData: [],
    paymentData: [],
    maxHoursData: [],
    maxHoursPeriods: [],
    isLoading: false,
    isSaving: false,
    pagination: {
      current: null,
      total: null,
    },
    payrollPagination: {
      current: null,
      total: null,
    },
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_PAGINATION: (state, value) => {
      state.pagination = value;
    },
    SET_PAYROLL_PAGINATION: (state, value) => {
      state.payrollPagination = value;
    },
    SET_PAYROLL_CURRENT_PAGE: (state, value) => {
      state.payrollPagination.current = value;
    },
    SET_BILLING_CURRENT_PAGE: (state, value) => {
      state.pagination.current = value;
    },
  },
  actions: {
    getPayments: function (context, id) {
      context.commit("SET_STATE", { key: "isLoading", value: true });
      if (typeof id == "undefined") {
        this.$http
          .get("") //TODO change for payrolls
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "paymentData",
                value: res.data.data,
              });
            }
            context.commit("SET_STATE", { key: "isLoading", value: false });
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
          });
      } else {
        this.$http
          .get(`providers/${id}`)
          .then((res) => {
            context.commit("SET_STATE", {
              key: "billingData",
              value: res.data.data,
            });
            context.commit("SET_STATE", { key: "isLoading", value: false });
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
          });
      }
    },
    getBillingSpecificDates: async function ({ commit, state }, payload = {}) {
      if (!payload.page) {
        commit("SET_STATE", { key: "isLoading", value: true });
      }
      let vm = this;
      let url =
        "billing-invoices-items" +
        `?start=${payload.start}&end=${payload.end}` +
        (payload.providerId ? `&providerId=${payload.providerId}` : ``) +
        (payload.clientId ? `&clientId=${payload.clientId}` : ``) +
        (payload.classes ? `&classes=${payload.classes}` : ``) +
        (payload.clinics ? `&clinics=${payload.clinics}` : ``) +
        (payload.services ? `&services=${payload.services}` : ``) +
        (payload.professions ? `&professions=${payload.professions}` : ``) +
        (payload.admin ? `&admin=${payload.admin}` : ``) +
        (payload.hcai ? `&hcai=${payload.hcai}` : ``) +
        (payload.sessionStatus != "" && payload.sessionStatus != undefined
          ? `&sessionStatus=${payload.sessionStatus}`
          : ``) +
        `&billable=${payload.billable}`;

      if (payload.page) {
        url = `${url}&page=${payload.page}`;
      }
      if (payload.limit) {
        url = `${url}&limit=${payload.limit}`;
      }
      return new Promise((resolve) => {
        vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              if (payload.page || payload.limit) {
                commit("SET_PAGINATION", {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                });
                commit("SET_STATE", {
                  key: "billingData",
                  value: payload.page
                    ? [...state.billingData, ...res.data.data]
                    : res.data.data,
                });
              } else {
                commit("SET_PAGINATION", {
                  total: res.data.data.length,
                  current: 1,
                });
                commit("SET_STATE", {
                  key: "billingData",
                  value: res.data.data,
                });
              }
            }
            commit("SET_STATE", { key: "isLoading", value: false });
            return resolve(res.data.data);
          })
          .catch((err) => {
            commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              return resolve();
            }
          });
      });
    },
    getPayrollSpecificDates: async function ({ commit, state }, payload = {}) {
      if (!payload.page) {
        commit("SET_STATE", { key: "isLoading", value: true });
      }
      let vm = this;
      let url =
        "payroll-invoices-items" +
        `?start=${payload.start}&end=${payload.end}` +
        (payload.providerId ? `&providerId=${payload.providerId}` : ``) +
        (payload.clientId ? `&clientId=${payload.clientId}` : ``) +
        (payload.classes ? `&classes=${payload.classes}` : ``) +
        (payload.clinics ? `&clinics=${payload.clinics}` : ``) +
        (payload.services ? `&services=${payload.services}` : ``) +
        (payload.admin ? `&admin=${payload.admin}` : ``) +
        (payload.sessionStatus != "" && payload.sessionStatus != undefined
          ? `&sessionStatus=${payload.sessionStatus}`
          : ``) +
        `&billable=${payload.billable}`;
      if (payload.page) {
        url = `${url}&page=${payload.page}`;
      }
      if (payload.limit) {
        url = `${url}&limit=${payload.limit}`;
      }
      return new Promise((resolve) => {
        vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              if (payload.page || payload.limit) {
                commit("SET_PAYROLL_PAGINATION", {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                });
                commit("SET_STATE", {
                  key: "payrollData",
                  value: payload.page
                    ? [...state.payrollData, ...res.data.data]
                    : res.data.data,
                });
              } else {
                commit("SET_PAYROLL_PAGINATION", {
                  total: res.data.data.length,
                  current: 1,
                });
                commit("SET_STATE", {
                  key: "payrollData",
                  value: res.data.data,
                });
              }
            }
            commit("SET_STATE", { key: "isLoading", value: false });
            return resolve(res.data.data);
          })
          .catch((err) => {
            commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              return resolve();
            }
          });
      });
    },
    getBillingsOneMonth: function (context) {
      context.commit("SET_STATE", { key: "isLoading", value: true });
      this.$http
        .get(
          "billing-invoices-items" +
            `?start=${dateFormat(
              dayjs().startOf("month"),
              "YYYY-MM-DD"
            )}&end=${dateFormat(dayjs().endOf("month"), "YYYY-MM-DD")}`
        )
        .then((res) => {
          if (res.status == 200) {
            context.commit("SET_STATE", {
              key: "billingData",
              value: res.data.data,
            });
          }
          context.commit("SET_STATE", { key: "isLoading", value: false });
        })
        .catch((err) => {
          context.commit("SET_STATE", { key: "isLoading", value: false });
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text:
                (err.data || err.response.data || {}).message ||
                "Something went wrong...",
              icon: "error",
            });
          }
        });

      this.$http
        .get("billing-invoices-items")
        .then((res) => {
          if (res.status == 200) {
            context.commit("SET_STATE", {
              key: "billingData",
              value: res.data.data,
            });
          }
          context.commit("SET_STATE", { key: "isLoading", value: false });
        })
        .catch((err) => {
          context.commit("SET_STATE", { key: "isLoading", value: false });
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text:
                (err.data || err.response.data || {}).message ||
                "Something went wrong...",
              icon: "error",
            });
          }
        });
    },
    getMaxhours: function (context, payload) {
      context.commit("SET_STATE", { key: "isLoading", value: true });
      let url = `client-maxhours-report`;
      const vm = this;
      if (payload) {
        if (payload.beginDateIntervals) {
          url = `${url}?beginDateIntervals=${payload.beginDateIntervals}`;
        }
        if (payload.endDateIntervals) {
          url = `${url}&endDateIntervals=${payload.endDateIntervals}`;
        }
        if (payload.period_type) {
          url = `${url}&period_type=${payload.period_type}`;
        }
        if (payload.client_id) {
          url = `${url}&client_id=${payload.client_id}&`;
        }
        if (payload.provider_id) {
          url = `${url}&provider_id=${payload.provider_id}&`;
        }
      }
      return new Promise((resolve) => {
        vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "maxHoursData",
                value: res.data.data,
              });
              context.commit("SET_STATE", {
                key: "maxHoursPeriods",
                value: res.data.periods,
              });
            }
            context.commit("SET_STATE", { key: "isLoading", value: false });
            return resolve(res.data);
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  (err.data || err.response?.data || {}).message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            return resolve();
          });
      });
    },
    getServices: function () {
      let vm = this;
      return new Promise((resolve) => {
        vm.$http
          .get("billing-services")
          .then((res) => {
            return resolve(res.data.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              return resolve();
            }
          });
      });
    },
    setPayrollCurrentPage: function ({ commit }, current_page) {
      commit("SET_PAYROLL_CURRENT_PAGE", current_page);
    },
    setBillingCurrentPage: function ({ commit }, current_page) {
      commit("SET_BILLING_CURRENT_PAGE", current_page);
    },
    submitHCAIReport: function (_, payload) {
      return new Promise((resolve) => {
        this.$http
          .post("send-hcai-excel", payload)
          .then((res) => {
            if (res.status == 200) {
              return resolve(true);
            }
            return resolve();
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              return resolve();
            }
          });
      });
    },
  },
};
