<template>
  <div>
    <div class="DashboardSection">
      <div class="row m-0 col-12">
        <div class="col-12 col-lg-7 dashboard-item-container my-0" id="Summary">
          <div class="row m-0 justify-content-between dashboard-item-header">
            <div class="row mx-0 col-12 p-0 justify-content-between">
              <div class="dashboard-item-title">Summary</div>
            </div>
          </div>
          <div
            class="
              flex-wrap
              row
              m-0
              justify-content-center
              justify-content-sm-start
              justify-content-md-center
              justify-content-xl-start
            "
          >
            <div
              class="
                col-12 col-sm-6 col-md-12 col-xl-6
                mt-4
                card-outer-container
              "
            >
              <div
                class="card-container btn-theme text-main font-weight-normal"
              >
                <div class="d-flex align-items-center mb-4">
                  <i class="fas fa-2x fa-clock mr-3 mb-0 card-icon large"></i>
                  <p class="text-capitalize title mb-0">Monthly Hours</p>
                </div>
                <div class="text-center" v-if="monthlyTotalHoursLoading">
                  <i class="fas fa-2x fa-spin fa-circle-notch mr-5"></i>
                </div>
                <p v-else class="value text-center large">
                  {{ (parseFloat(monthlyTotalHours) || 0).toFixed(2) }}
                </p>
              </div>
            </div>
            <div
              class="
                col-12 col-sm-6 col-md-12 col-xl-6
                mt-4
                card-outer-container
              "
            >
              <div class="card-container bg-white text-main bg-img-card">
                <div class="d-flex align-items-center mb-4">
                  <img
                    class="card-icon mr-3 mb-0 large"
                    src="@/assets/img/dashboard/accounts-receivable-black.svg"
                  />
                  <p class="text-capitalize title mb-0">
                    pending clinical notes
                  </p>
                  <i
                    class="fa fa-lg fa-info-circle cursor-pointer"
                    v-tooltip.top="
                      'Sessions booked without clinical notes attached'
                    "
                  />
                </div>
                <div class="text-center" v-if="pendingNotesLoading">
                  <i class="fas fa-2x fa-spin fa-circle-notch mr-5"></i>
                </div>
                <p v-else class="value text-center large">{{ pendingNotes }}</p>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-12 col-xl-4 mt-4">
              <div class="card-container bg-table text-white">
                <div class="d-flex align-items-center mb-2">
                  <img
                    class="card-icon mr-3 mb-0"
                    src="@/assets/img/dashboard/revenue-by-patient-active.svg"
                  />
                  <p class="text-capitalize title mb-0">clients</p>
                </div>
                <div class="text-center" v-if="numberOfAssignedClients.loading">
                  <i class="fas fa-lg fa-spin fa-circle-notch mr-2"></i>
                </div>
                <p v-else class="value text-center">
                  {{ numberOfAssignedClients.data }}
                  <br />
                  <span
                    class="btn btn-sm text-white btn-link"
                    @click="openCardInfo('patients', 'Assigned Clients')"
                    >View details</span
                  >
                </p>
              </div>
            </div>

            <div class="col-12 col-sm-4 col-md-12 col-xl-4 mt-4">
              <div class="card-container bg-table text-white">
                <div class="d-flex align-items-center mb-2">
                  <i class="fas fa-2x fa-user-md mr-3 mb-0 card-icon"></i>
                  <p class="text-capitalize title mb-0">professions</p>
                </div>
                <div
                  class="text-center"
                  v-if="numberOfAssignedProfessions.loading"
                >
                  <i class="fas fa-lg fa-spin fa-circle-notch mr-2"></i>
                </div>
                <p v-else class="value text-center">
                  {{ numberOfAssignedProfessions.data }}
                  <br />
                  <span
                    class="btn btn-sm text-white btn-link"
                    @click="openCardInfo('professions', 'Assigned Professions')"
                    >View details</span
                  >
                </p>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-12 col-xl-4 mt-4">
              <div class="card-container bg-table text-white">
                <div class="d-flex align-items-center mb-2">
                  <i class="fas fa-2x fa-stethoscope mr-3 mb-0 card-icon"></i>
                  <p class="text-capitalize title mb-0">classes</p>
                </div>
                <div class="text-center" v-if="numberOfAssignedClasses.loading">
                  <i class="fas fa-lg fa-spin fa-circle-notch text-center"></i>
                </div>
                <p v-else class="value text-center">
                  {{ numberOfAssignedClasses.data }}
                  <br />
                  <span
                    class="btn btn-sm text-white btn-link"
                    @click="openCardInfo('classes', 'Assigned Classes')"
                    >View details</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-5 mt-4 mt-lg-0">
          <timeline-bar :userId="userId" :maxItems="2" />
        </div>
      </div>
      <dashboard-appointments class="DashboardItem" :maxItems="100" />
      <div
        class="
          col-12
          dashboard-item-container dashboard-main-container
          d-flex
          p-0
        "
      >
        <div
          class="
            col-3
            menu-container
            py-4
            desktop-only-force
            d-flex
            flex-column
            justify-content-between
          "
        >
          <div>
            <div class="row m-0 dashboard-item-header">
              <div class="dashboard-item-title">Budget</div>
            </div>
            <div class="chart-menu">
              <div
                class="
                  chart-menu-item
                  pointer-cursor
                  d-flex
                  justify-content-between
                  align-items-center
                "
                :class="{ selected: menuItem.name === selectedBudgetName }"
                v-for="menuItem in budgetMenuItems"
                :key="menuItem.name"
                @click="updateSelectedBudgetChart(menuItem.name)"
              >
                <div class="title-img">
                  <img class="menu-icon mr-3" :src="menuItem.icon" />
                  <span class="title">{{ menuItem.title }}</span>
                </div>
                <span class="total" v-if="menuItem.total">{{
                  menuItem.total
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="
              chart-layout
              justify-content-between
              d-flex
              align-items-center
              mt-5
              px-0
              pt-5
              flex-wrap
            "
          >
            <span class="label pr-4 mb-1 font-weight-bold">Chart Layout</span>
            <div class="control radius-99 d-inline-flex">
              <button
                class="btn radius-99 text-main"
                :class="{
                  selected: selectedBudgetDirection == 'horizontal',
                }"
                @click="updateSelectedBudgetLayout('horizontal')"
                :disabled="chartLoading[selectedBudgetName]"
              >
                Horizontal
              </button>
              <button
                class="btn radius-99 text-main right-btn"
                :class="{
                  selected: selectedBudgetDirection == 'vertical',
                }"
                @click="updateSelectedBudgetLayout('vertical')"
                :disabled="chartLoading[selectedBudgetName]"
              >
                Vertical
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-9 py-4 charts-outer-container">
          <div class="row m-0 dashboard-item-header small-display flex-column">
            <div class="dashboard-item-title">Budget</div>
            <select-control
              v-model="selectedBudgetName"
              :options="BudgetChartOptions"
              labelClass="d-none"
              inputClass="font-weight-bold"
              @input="updateSelectedBudgetChart(selectedBudgetName)"
              wrapClass="m-0 w-100 p-0"
            ></select-control>
          </div>

          <div v-show="selectedBudgetName == 'maxHoursByClient'">
            <div class="row m-0 justify-content-between dashboard-item-header">
              <div class="dashboard-item-title">
                Monthly Budget in Hours by Client
              </div>
              <div class="d-flex mt-2 mb-2 mb-lg-5">
                <select-control
                  v-model="selectedMonthYear.maxHoursByClient"
                  :options="lastMonths"
                  :key="selectedMonthYear.maxHoursByClient"
                  labelClass="d-none"
                  :disabled="chartLoading.maxHoursByClient"
                  @input="getMaxhoursByClient(true)"
                  wrapClass="mb-0"
                ></select-control>
              </div>
            </div>
            <alert v-if="chartLoading.maxHoursByClient" />
            <alert
              v-if="
                noChartData.maxHoursByClient && !chartLoading.maxHoursByClient
              "
              :hideLoader="true"
              >No data to show</alert
            >
            <div class="chart-container">
              <div
                id="maxHoursByClient"
                v-show="!noChartData.maxHoursByClient"
              ></div>
            </div>
          </div>
          <div v-show="selectedBudgetName == 'budgetByClient'">
            <div class="row m-0 justify-content-between dashboard-item-header">
              <div class="dashboard-item-title">
                Monthly Budget in $ by Client
                <i
                  class="fa fa-xs fa-info-circle pointer-cursor"
                  v-tooltip.top="
                    `The green bars on the chart represent the monthly amount spent
                  for the selected month and the red lines represent the total
                  budget. <br/> You can see the remaining balance and the spending
                  percentage by hovering over the bars.`
                  "
                ></i>
              </div>
              <div class="d-flex mt-2 mb-2 mb-lg-5">
                <select-control
                  v-model="selectedMonthYear.budgetByClient"
                  :options="lastMonths"
                  :key="selectedMonthYear.budgetByClient"
                  labelClass="d-none"
                  :disabled="chartLoading.budgetByClient"
                  @input="getBudgetByClient(true)"
                  wrapClass="mb-0"
                ></select-control>
              </div>
            </div>
            <alert v-if="chartLoading.budgetByClient" />
            <alert
              v-if="noChartData.budgetByClient && !chartLoading.budgetByClient"
              :hideLoader="true"
              >No data to show</alert
            >
            <div class="chart-container">
              <div
                id="budgetByClient"
                v-show="!noChartData.budgetByClient"
              ></div>
            </div>
          </div>

          <div
            class="
              chart-layout
              col-12
              justify-content-between
              d-flex
              align-items-center
              px-0
              flex-wrap
              small-display
              mt-3
            "
          >
            <span class="label pr-4 mb-1 font-weight-bold">Chart Layout</span>
            <div class="control radius-99 d-inline-flex">
              <button
                class="btn radius-99 text-main"
                :class="{
                  selected: selectedBudgetDirection == 'horizontal',
                }"
                @click="updateSelectedBudgetLayout('horizontal')"
                :disabled="chartLoading[selectedBudgetName]"
              >
                Horizontal
              </button>
              <button
                class="btn radius-99 text-main right-btn"
                :class="{
                  selected: selectedBudgetDirection == 'vertical',
                }"
                @click="updateSelectedBudgetLayout('vertical')"
                :disabled="chartLoading[selectedBudgetName]"
              >
                Vertical
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12 chartContainer dashboard-item-container pb-0"
        id="Locations"
      >
        <div class="row m-0 justify-content-between dashboard-item-header">
          <div class="dashboard-item-title mb-4">Client locations</div>
          <div class="see-all pointer-cursor u-display-none">
            See Full Map <i class="fas fa-chevron-right"></i>
          </div>
        </div>
        <UserLocations class="DashboardItem mapItem" />
      </div>
      <div class="col-12 chartContainer dashboard-item-container" id="toDo">
        <div class="row m-0 justify-content-between dashboard-item-header">
          <div class="dashboard-item-title mb-4">To-Do List</div>
          <div class="see-all pointer-cursor u-display-none">
            See All <i class="fas fa-chevron-right"></i>
          </div>
        </div>
        <textarea class="form-control no-resize radius-36" rows="5"></textarea>
      </div>
    </div>

    <modal
      class="card-info-modal themed-modal"
      name="card-info-modal"
      transition="pop-out"
      :height="'auto'"
    >
      <div class="position-relative modal-body">
        <div
          class="
            vm--top-right-slot
            d-flex
            align-items-center
            justify-content-between
            mb-2
          "
          style="z-index: 1"
        >
          <h2 class="mb-0 ml-3 text-main">{{ learnMoreInfo.title }}</h2>
          <button class="btn p-2 border-0" @click="closeCardInfoModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="body-content modal-body-container">
          <Patients
            v-if="learnMoreInfo.name == 'patients'"
            :maxItems="99999"
            class="DashboardItem pl-2 pr-2 pl-lg-0 pr-lg-0"
          />
          <provider-classifications
            :propProviderId="user.provider_id"
            v-if="learnMoreInfo.name == 'classes'"
          ></provider-classifications>
          <provider-professions
            :propUserId="user.id"
            v-if="learnMoreInfo.name == 'professions'"
          ></provider-professions>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Patients from "./Patients";
import ProviderClassifications from "../providers/profile/classifications/Index.vue";
import ProviderProfessions from "../providers/profile/profession/Index.vue";
import ApexCharts from "apexcharts";
import dateFormat from "@/utils/dateFormat";
import * as dayjs from "dayjs";
import CONSTANT from "./constants";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "Index",
  props: ["userId"],
  components: {
    Patients,
    ProviderClassifications,
    ProviderProfessions,
  },
  computed: {
    ...mapState({
      patients: (s) => s.patients.data,
      user: (state) => state.auth.user,
    }),
    budgetMenuItems: function () {
      return [
        {
          name: "maxHoursByClient",
          title: "In Hours By Client",
          icon: require("@/assets/img/icons/hours-client.svg"),
        },
        {
          name: "budgetByClient",
          title: "In $ By Client",
          icon: require("@/assets/img/icons/money-client.svg"),
        },
      ];
    },
  },
  beforeDestroy() {
    this.chartRef.maxHoursByClient && this.chartRef.maxHoursByClient.destroy();
    this.chartRef.budgetByClient && this.chartRef.budgetByClient.destroy();
  },
  mounted() {
    this.setYearsMonths();
    this.getPendingNotes();
    this.getTotalHours();
    this.getNumberOfAssignedClients();
    this.getNumberOfAssignedProfessions();
    this.getNumberOfAssignedClasses();
    this.getMaxhoursByClient();
    this.getBudgetByClient();
  },
  data() {
    return {
      learnMoreInfo: {
        name: "",
        title: "",
      },
      pendingNotes: "",
      pendingNotesLoading: false,
      monthlyTotalHours: "",
      monthlyTotalHoursLoading: false,
      chartLoading: {
        maxHoursByClient: true,
        budgetByClient: true,
      },
      chartRef: {
        maxHoursByClient: null,
        budgetByClient: null,
      },
      noChartData: {
        maxHoursByClient: false,
        budgetByClient: false,
      },
      BudgetChartOptions: [
        {
          label: "In Hours By Client",
          value: "maxHoursByClient",
        },
        {
          label: "In $ By Client",
          value: "budgetByClient",
        },
      ],
      selectedBudgetName: "maxHoursByClient",
      selectedBudgetDirection: "vertical",
      lastMonths: [],
      selectedMonthYear: {
        maxHoursByClient: `${new Date().getFullYear()}__${new Date().getMonth()}`,
        budgetByClient: `${new Date().getFullYear()}__${new Date().getMonth()}`,
      },
      maxHoursByClient: CONSTANT.maxBarChart,
      maxHoursByClientHorizontal: CONSTANT.maxBarChartHorizontal,
      budgetByClient: CONSTANT.barChart4,
      budgetByClientHorizontal: CONSTANT.barChartHorizontal4,
      numberOfAssignedClients: {
        loading: false,
        data: "",
      },
      numberOfAssignedProfessions: {
        loading: false,
        data: "",
      },
      numberOfAssignedClasses: {
        loading: false,
        data: "",
      },
    };
  },
  methods: {
    setYearsMonths: function () {
      let year = new Date().getFullYear();
      let month = new Date().getMonth();
      let lastMonths = [];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      for (let i = 0; i <= 200; i++) {
        lastMonths.push({
          label: `${months[month]} ${year}`,
          value: `${year}__${month--}`,
        });
        if (month < 0) {
          month = 11;
          year--;
        }
      }
      this.lastMonths = lastMonths;
    },
    getPendingNotes: async function () {
      try {
        this.pendingNotesLoading = true;
        const res = await this.$http.get("dashboard/pendingNotes");
        if (res.data?.data?.pendingNotes || res.data?.data?.pendingNotes == 0) {
          this.pendingNotes = res.data.data.pendingNotes;
        }
        this.pendingNotesLoading = false;
      } catch (err) {
        this.pendingNotes = "";
      }
    },
    getTotalHours: async function () {
      try {
        this.monthlyTotalHoursLoading = true;
        const res = await this.$http.get("dashboard/monthlyHours");
        if (
          res.data.data.total_hours_for_month ||
          res.data.data.total_hours_for_month == 0
        ) {
          this.monthlyTotalHours = res.data.data.total_hours_for_month;
        }
        this.monthlyTotalHoursLoading = false;
      } catch (err) {
        this.monthlyTotalHours = "";
        this.monthlyTotalHoursLoading = false;
      }
    },
    getNumberOfAssignedClients: async function () {
      try {
        this.numberOfAssignedClients.loading = true;
        const res = await this.$http.get(
          "dashboard/number-of-assigned-clients"
        );
        if (res.data?.data || res.data?.data == 0) {
          this.numberOfAssignedClients.data = res.data.data;
        }
        this.numberOfAssignedClients.loading = false;
      } catch (err) {
        this.numberOfAssignedClients.data = "";
        this.numberOfAssignedClients.loading = false;
      }
    },
    getNumberOfAssignedProfessions: async function () {
      try {
        this.numberOfAssignedProfessions.loading = true;
        const res = await this.$http.get(
          "dashboard/number-of-assigned-professions"
        );
        if (res.data?.data || res.data?.data == 0) {
          this.numberOfAssignedProfessions.data = res.data.data;
        }
        this.numberOfAssignedProfessions.loading = false;
      } catch (err) {
        this.numberOfAssignedProfessions.data = "";
        this.numberOfAssignedProfessions.loading = false;
      }
    },
    getNumberOfAssignedClasses: async function () {
      try {
        this.numberOfAssignedClasses.loading = true;
        const res = await this.$http.get(
          "dashboard/number-of-assigned-classes"
        );
        if (res.data?.data || res.data?.data == 0) {
          this.numberOfAssignedClasses.data = res.data.data;
        }
        this.numberOfAssignedClasses.loading = false;
      } catch (err) {
        this.numberOfAssignedClasses.data = "";
        this.numberOfAssignedClasses.loading = false;
      }
    },
    closeCardInfoModal: function () {
      this.$modal.hide("card-info-modal");
    },
    openCardInfo: function (name, title) {
      this.learnMoreInfo.name = name;
      this.learnMoreInfo.title = title;
      this.$modal.show("card-info-modal");
    },
    navigateToProviderDetails: function () {
      this.$router.push({ path: `/providers/${this.user.provider_id}` });
    },
    navigateToTimeline: function () {
      this.$router.push({
        path: `/providers/${this.user.provider_id}/timeline`,
      });
    },
    navigateToMyClients: function () {
      this.$router.push({
        path: `/clients`,
      });
    },
    navigateToCalender: function () {
      this.$router.push({ path: "/calendar" });
    },
    getMaxhoursByClient: async function (monthChanged) {
      try {
        if (this.chartRef.maxHoursByClient && monthChanged) {
          this.chartLoading.maxHoursByClient = true;
          this.chartRef.maxHoursByClient.destroy();
        }
        const selectedYear =
          this.selectedMonthYear.maxHoursByClient.split("__")[0];
        const selectedMonth =
          parseInt(this.selectedMonthYear.maxHoursByClient.split("__")[1]) + 1;

        const startOfMonth = `${selectedYear}-${
          selectedMonth > 9 ? selectedMonth : "0" + selectedMonth
        }-01T00:00:00.000000Z`;
        const endOfMonth = `${dateFormat(
          dayjs(
            `${selectedYear}-${
              selectedMonth > 9 ? selectedMonth : "0" + selectedMonth
            }-01`
          ).endOf("month"),
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;

        const canadaOffsetStart = dayjs
          .tz(new Date(startOfMonth), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(endOfMonth), "America/Toronto")
          .utcOffset();

        const startDateTime = dayjs(startOfMonth)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        const endDateTime = dayjs(endOfMonth)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        this.noChartData.maxHoursByClient = false;
        const res = await this.$http.get(
          "dashboard/budgetPerClientService" +
            `?start=${startDateTime}&end=${endDateTime}`
        );
        const data = res.data;

        if (Array.isArray(data) && !data.length) {
          this.noChartData.maxHoursByClient = true;
        }

        this.maxHoursByClientHorizontal.chart.height =
          data.length < 4 ? 200 : data.length * 50;

        const seriesData = data.map((item) => {
          const zValue = `${item.maxHourValue}H ${item.period_type}`;
          let firstName = item.client_name.split(", ")[1].split(" ");
          let firstLetters = "";
          for (let i = 0; i < firstName.length; i++) {
            firstLetters = `${firstLetters}${firstName[i][0]}`;
          }
          let secondName = item.client_name.split(", ")[0].split(" ");
          let secondLetters = "";
          for (let i = 0; i < secondName.length; i++) {
            secondLetters = `${secondLetters}${secondName[i][0]}`;
          }
          const sureName = `${firstLetters}${secondLetters}`.toUpperCase();
          return {
            x: `${sureName}/${item.serviceName}`,
            fullName: `${item.client_name} (ID_${item.user_id})/${item.serviceName}`,
            y: parseFloat(item.thisMonthAppointmentsHrsTotal || "0").toFixed(2),
            z: `(${zValue.trim()})`,
            goals: [
              {
                name: "Max Hours",
                value: parseFloat(item.totalMaxHours),
                strokeColor: "#d7263d",
              },
            ],
          };
        });

        this.maxHoursByClient.series[0].data = this.handleDuplicateXChart(
          seriesData,
          true
        );
        const horizontalSeriesData = JSON.parse(JSON.stringify(seriesData));
        horizontalSeriesData.forEach((item) => {
          item.x = item.fullName;
        });
        this.maxHoursByClientHorizontal.series[0].data =
          this.handleDuplicateXChart(horizontalSeriesData);

        this.maxHoursByClient.xaxis.max =
          this.maxHoursByClient.xaxis.originMax >
          this.maxHoursByClient.series[0].data.length
            ? this.maxHoursByClient.series[0].data.length
            : this.maxHoursByClient.xaxis.originMax;

        if (this.selectedBudgetName == "maxHoursByClient") {
          this.updateSelectedBudgetChart(this.selectedBudgetName);
        }
      } catch (err) {
        this.noChartData.maxHoursByClient = true;
        if (document.querySelector("#maxHoursByClient")) {
          this.maxHoursByClient.series[0].data = [];
          this.chartRef.maxHoursByClient = new ApexCharts(
            document.querySelector("#maxHoursByClient"),
            this.maxHoursByClient
          );
          this.chartLoading.maxHoursByClient = false;
          this.chartRef.maxHoursByClient.render();
        }
      }
    },
    getBudgetByClient: async function (monthChanged) {
      try {
        if (this.chartRef.budgetByClient && monthChanged) {
          this.chartLoading.budgetByClient = true;
          this.chartRef.budgetByClient.destroy();
        }
        const selectedYear =
          this.selectedMonthYear.budgetByClient.split("__")[0];
        const selectedMonth =
          parseInt(this.selectedMonthYear.budgetByClient.split("__")[1]) + 1;

        const startOfMonth = `${selectedYear}-${
          selectedMonth > 9 ? selectedMonth : "0" + selectedMonth
        }-01T00:00:00.000000Z`;
        const endOfMonth = `${dateFormat(
          dayjs(
            `${selectedYear}-${
              selectedMonth > 9 ? selectedMonth : "0" + selectedMonth
            }-01`
          ).endOf("month"),
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;

        const canadaOffsetStart = dayjs
          .tz(new Date(startOfMonth), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(endOfMonth), "America/Toronto")
          .utcOffset();

        const startDateTime = dayjs(startOfMonth)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        const endDateTime = dayjs(endOfMonth)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        this.noChartData.budgetByClient = false;
        const res = await this.$http.get(
          "dashboard/budgetPerClientService" +
            `?start=${startDateTime}&end=${endDateTime}`
        );
        let data = res.data;
        data = data.filter(
          (item) => item.thisMonthAppointmentsBudgetTotal != null
        );
        if (Array.isArray(data) && !data.length) {
          this.noChartData.budgetByClient = true;
        }
        this.budgetByClientHorizontal.chart.height =
          data.length < 4 ? 200 : data.length * 50;

        let seriesData = data.map((item) => {
          let firstName = item.client_name.split(", ")[1].split(" ");
          let firstLetters = "";
          for (let i = 0; i < firstName.length; i++) {
            firstLetters = `${firstLetters}${firstName[i][0]}`;
          }
          let secondName = item.client_name.split(", ")[0].split(" ");
          let secondLetters = "";
          for (let i = 0; i < secondName.length; i++) {
            secondLetters = `${secondLetters}${secondName[i][0]}`;
          }
          const sureName = `${firstLetters}${secondLetters}`.toUpperCase();
          return {
            x: `${sureName}/${item.serviceName}`,
            fullName: `${item.client_name} (ID_${item.user_id})/${item.serviceName}`,
            y: parseFloat(item.thisMonthAppointmentsBudgetTotal).toFixed(2),
            spendingPercentage: parseFloat(
              item.spending_percentage || "0"
            ).toFixed(2),
          };
        });

        seriesData = this.handleDuplicateXChart(seriesData, true);

        const horizontalSeriesData = JSON.parse(JSON.stringify(seriesData));
        horizontalSeriesData.forEach((item) => {
          item.x = item.fullName;
        });

        this.budgetByClientHorizontal.series[0].data =
          this.handleDuplicateXChart(horizontalSeriesData);
        this.budgetByClient.series[0].data = seriesData;

        this.budgetByClient.xaxis.max =
          this.budgetByClient.xaxis.originMax >
          this.budgetByClient.series[0].data.length
            ? this.budgetByClient.series[0].data.length
            : this.budgetByClient.xaxis.originMax;

        if (this.selectedBudgetName == "budgetByClient") {
          this.updateSelectedBudgetChart(this.selectedBudgetName);
        }
      } catch (err) {
        this.noChartData.budgetByClient = true;
        if (document.querySelector("#budgetByClient")) {
          this.budgetByClient.series[0].data = [];
          this.chartRef.budgetByClient = new ApexCharts(
            document.querySelector("#budgetByClient"),
            this.budgetByClient
          );
          this.chartLoading.budgetByClient = false;
          this.chartRef.budgetByClient.render();
        }
      }
    },
    handleDuplicateXChart: function (data, splitSlash = false) {
      const dist = JSON.parse(JSON.stringify(data));
      const xValues = {};
      dist.forEach((item) => {
        if (!splitSlash) {
          if (Object.keys(xValues).includes(item.x)) {
            xValues[item.x]++;
            item.x = `${item.x} (${xValues[item.x]})`;
          } else {
            xValues[item.x] = 0;
          }
        } else {
          if (Object.keys(xValues).includes(item.x)) {
            xValues[item.x]++;
            item.x = `${item.x.split("/")[0]}/${item.x.split("/")[1]} (${
              xValues[item.x]
            })`;
          } else {
            xValues[item.x] = 0;
          }
        }
      });
      return dist;
    },
    changeChartType: function (chartSelector) {
      if (this.chartRef[chartSelector]) {
        this.chartLoading[chartSelector] = true;
        this.chartRef[chartSelector].destroy();
      }

      if (document.querySelector(`#${chartSelector}`)) {
        setTimeout(() => {
          if (document.querySelector(`#${chartSelector}`)) {
            if (this.selectedBudgetDirection == "vertical") {
              this.chartRef[chartSelector] = new ApexCharts(
                document.querySelector(`#${chartSelector}`),
                this[`${chartSelector}`]
              );
            } else {
              this.chartRef[chartSelector] = new ApexCharts(
                document.querySelector(`#${chartSelector}`),
                this[`${chartSelector}Horizontal`]
              );
            }

            this.chartRef[chartSelector].render();
          }
          this.chartLoading[chartSelector] = false;
        }, 100);
      }
    },
    updateSelectedBudgetChart: function (chartName) {
      this.selectedBudgetName = chartName;
      this.changeChartType(this.selectedBudgetName);
    },
    updateSelectedBudgetLayout: function (layout = "vertical") {
      this.selectedBudgetDirection = layout;
      this.changeChartType(this.selectedBudgetName);
    },
  },
};
</script>

<style lang="scss">
.small-display {
  @media (min-width: 1023px) {
    display: none !important;
  }

  .select-outer-container {
    width: 100%;
    margin-top: 8px;

    .col {
      padding: 0;
    }
  }
}
.charts-outer-container {
  .alert {
    margin-bottom: -45px;
  }
}
@media (max-width: 1023px) {
  .charts-outer-container {
    .small-display {
      .dashboard-item-title {
        display: block;
      }
    }
    .dashboard-item-title {
      display: none;
    }
  }
}
.dashboard-item-header {
  select,
  .see-all-btn {
    padding-bottom: 0;
    border-radius: 99px;
    height: 42px;
    background-color: rgba(255, 255, 255, 0.8);
  }
  select {
    padding-top: 0;
    font-size: 16px;
  }
}

.dashboard-selected-time {
  font-size: 18px;
  font-weight: normal;
}
</style>

<style lang="scss" scoped>
.bg-img-card {
  background-image: var(--notes-img-path);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto 60%;
}

.dashboard-widget-info {
  border-radius: 28px;
  padding: 16px 20px;
  background-color: rgba(255, 255, 255, 0.5);
}
.mb-20 {
  margin-bottom: 20px;
}
.chart-container {
  width: 100%;
  height: 365px;
  overflow-x: hidden;
  overflow-y: auto;
}
.dashboard-main-container {
  padding: 0 16px !important;
  margin-bottom: 20px;
}
.menu-container {
  border-right: 1px solid var(--secondary-color);
  padding-right: 24px;
  padding-left: 8px;
}
.chart-menu {
  margin-top: 16px;
  .chart-menu-item {
    color: var(--main-text-color);
    opacity: 0.52;
    margin-bottom: 4px;
    border-radius: 16px;
    padding: 12px;
    margin-bottom: 4px;

    .title {
      font-size: 20px;
    }
    .menu-icon {
      margin-bottom: 4px;
    }

    &.selected {
      opacity: 1;
      background-color: #ffffff;

      .title {
        font-weight: 500;
      }
      .total {
        opacity: 0.52;
      }
    }
  }
}
.chart-layout {
  .label {
    font-size: 18px;
  }
  .control {
    background-color: var(--secondary-color);
    padding: 2px;

    .selected {
      background-color: #ffffff;
      font-weight: bold;
    }

    .right-btn {
      margin-left: -8px;
    }
  }
}
</style>
