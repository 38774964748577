<template>
  <div>
    <div class="row flex-nowrap create-task-wrapper">
      <div class="col-xl-12 text-center">
        <div
          class="
            mt-2
            mb-5
            font-weight-bold
            col-lg-5
            offset-lg-7
            create-task-header-title
          "
        >
          <h1 class="col-lg-11 offset-lg-1" v-if="!loading">
            {{
              $route.params.id != undefined && !noRedirect ? "Update" : "Add"
            }}
            {{ isDraft ? "Draft" : "Task" }}
          </h1>
        </div>
        <alert v-if="loading" class="light-shadow text-left" />
        <form @submit.prevent="saveTask" validate v-if="!loading">
          <div class="row m-0 justify-content-center create-task-container">
            <div
              class="col-12 col-sm-10 col-xl-7 col-lg-7 px-0 message-container"
            >
              <div class="card light-shadow px-3 overflow-hidden">
                <input-control
                  type="vertical"
                  v-model="task.name"
                  required
                  :disabled="!canEdit && !isNew"
                  wrapClass="align-items-center subject-task-input mt-3"
                  labelClass="col-2 mt-2 col-form-label text-nowrap mb-2 font-weight-bold"
                  >Subject</input-control
                >

                <div
                  class="
                    form-group
                    column
                    align-items-center
                    position-relative
                    mb-3
                    pb-3
                  "
                >
                  <label
                    for="input-control-description"
                    class="
                      col-auto
                      mb-2
                      col-form-label
                      text-nowrap
                      font-weight-bold
                    "
                  >
                    Description
                  </label>
                  <span class="col m-0 p-0 text-black mention-hint"
                    >Use @ for mention</span
                  >
                  <div class="col p-0">
                    <ckeditor
                      :editor="editor"
                      v-model="task.description"
                      :config="editorConfig"
                      :disabled="!canEdit && !isNew"
                    >
                    </ckeditor>
                  </div>
                  <div class="emoji-reaction-container" v-if="!isDraft">
                    <EmojiReactions
                      v-if="
                        $route.params.id != undefined && !noRedirect && !loading
                      "
                      :emojiPickerToggled="emojiPickerToggled"
                      @togglePicker="togglePicker(task.id)"
                      @clickAway="clickAway"
                      @selectEmoji="selectEmoji"
                      :task="task"
                      :user="user"
                    />
                  </div>
                  <save
                    class="mt-4 mr-3"
                    :saving="saving"
                    :disabled="
                      !isNew && !noRedirect && !canEdit && !assigneeCanEdit
                    "
                    >{{
                      $route.params.id != undefined && !noRedirect
                        ? isDraft
                          ? "Send"
                          : "Update"
                        : "Save"
                    }}</save
                  >
                  <save
                    class="mt-4 btn-black"
                    :saving="saving"
                    @click="saveTaskAsDraft"
                    v-if="isDraft || isNew"
                    :disabled="
                      !isNew && !noRedirect && !canEdit && !assigneeCanEdit
                    "
                    >{{ isDraft ? "Update" : "Save As" }} Draft
                  </save>
                  <button
                    v-if="isDraft"
                    class="btn btn-danger mt-4 ml-3"
                    @click.prevent="deleteDraft(task.id)"
                  >
                    Delete
                  </button>
                </div>
                <div
                  class="seen-by"
                  v-if="
                    !isDraft &&
                    user &&
                    !user.isPatient &&
                    $route.params.id != undefined &&
                    !noRedirect &&
                    !isOldMessage(task.created_at)
                  "
                >
                  <button
                    class="btn btn-link text-dark-red py-0 mr-3 mb-1 small"
                    @click.prevent="showSeenBy()"
                  >
                    <u
                      >Seen by you
                      {{
                        seenBy.length > 0
                          ? "and " +
                            seenBy.length +
                            ` other${seenBy.length == 1 ? "" : "s"}.`
                          : "only"
                      }}</u
                    >
                  </button>
                </div>

                <div
                  class="edit-history"
                  v-if="
                    !isDraft &&
                    task.created_at != task.updated_at &&
                    $route.params.id != undefined &&
                    !noRedirect &&
                    (user.isAdmin || user.isManagingAdmin)
                  "
                >
                  <button
                    class="btn btn-black py-0 btn-sm rounded-sm ml-3"
                    :disabled="taskHistoryLoading"
                    @click.prevent="showHistory()"
                  >
                    <span
                      v-if="taskHistoryLoading"
                      class="fa fa-spin fa-circle-notch mr-2"
                    ></span>
                    <span>Show History</span>
                  </button>
                </div>
              </div>
              <div
                class="text-right"
                v-if="
                  task.id &&
                  $route.params.id != undefined &&
                  !noRedirect &&
                  !isDraft
                "
              >
                <button
                  class="btn btn-sm btn-black"
                  @click.prevent="replyToMessage()"
                >
                  Reply to message?
                </button>
              </div>

              <div class="timeline-task" v-if="!isDraft">
                <div class="timeline-dashboard-container d-flex">
                  <div
                    class="timeline-bar"
                    :style="{ backgroundImage: `url(${timelineBarImg})` }"
                  ></div>
                  <div
                    class="timeline-content"
                    v-if="(task.replies || []).length"
                  >
                    <h1 class="mb-0 pt-3">Threads Timeline</h1>
                    <div
                      v-for="reply in task.replies"
                      :key="reply.id"
                      :id="`reply_message_${reply.id}`"
                      :class="
                        reply.hidden && !reply.editMode ? 'hidden-message' : ''
                      "
                      class="position-relative"
                    >
                      <div class="image-info">
                        <img :src="timelineImgBorder" class="image-border" />
                        <img
                          class="avatar-task-img rounded-circle"
                          :src="
                            reply.user.profile_picture ||
                            profileImagePlaceholder
                          "
                        />
                      </div>
                      <div class="item-content">
                        <div class="timeline-date-info">
                          <p class="user-name mb-0">{{ reply.user.name }}</p>
                          <p class="date-time mb-0">
                            {{ prettyDate(reply.created_at) }}
                          </p>
                        </div>
                        <div class="timeline-subject">
                          <div
                            class="
                              d-flex
                              justify-content-end
                              border-bottom
                              pb-3
                            "
                          >
                            <button
                              class="btn btn-sm btn-black ml-2"
                              v-if="reply.user.id == user.id"
                              title="edit Reply"
                              @click.prevent="openEditMode(reply)"
                            >
                              <i class="fa fa-edit" />
                              Edit
                            </button>
                            <button
                              class="btn btn-sm btn-black ml-3"
                              v-if="
                                reply.user.id == user.id ||
                                user.isAdmin ||
                                user.isManagingAdmin
                              "
                              :title="
                                reply.hidden
                                  ? 'Visible to admins/managers only'
                                  : 'Visible to all'
                              "
                              @click.prevent="
                                !reply.hiding && showHideReply(reply)
                              "
                            >
                              <i
                                v-if="!reply.hiding"
                                class="fa"
                                :class="
                                  reply.hidden ? 'fa-eye-slash' : 'fa-eye'
                                "
                              />
                              <i
                                class="fas fa-spin fa-circle-notch"
                                v-if="reply.hiding"
                              ></i>
                              {{ reply.hidden ? "Hidden" : "Visible" }}
                            </button>
                          </div>
                          <div
                            class="
                              message-body
                              d-flex
                              align-items-start
                              justify-content-start
                              mt-3 mt-md-4
                            "
                          >
                            <div class="w-100" v-if="reply.editMode">
                              <ckeditor
                                :editor="editor"
                                v-model="reply.description"
                                :config="editorConfig"
                              >
                              </ckeditor>
                              <div>
                                <save
                                  class="mt-2 mr-3"
                                  :saving="reply.editing"
                                  :disabled="reply.editing"
                                  @click="updateReply(reply)"
                                  >Update</save
                                >
                                <button
                                  class="btn btn-dark mt-2"
                                  @click="closeEditMode(reply)"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                            <div
                              class="message-content"
                              v-if="!reply.editMode"
                              v-html="
                                $options.filters.nl2br(
                                  $options.filters.slash2hr(reply.description)
                                )
                              "
                            ></div>
                          </div>
                          <hr />
                          <div
                            class="
                              text-left
                              pl-3
                              d-flex
                              align-items-center
                              history-button-container
                            "
                            v-if="
                              reply.created_at != reply.updated_at &&
                              (user.isAdmin || user.isManagingAdmin)
                            "
                          >
                            <button
                              class="btn btn-black py-0 btn-sm rounded-sm mr-3"
                              :disabled="reply.historyLoading"
                              @click.prevent="showHistory(reply)"
                            >
                              <span
                                v-if="reply.historyLoading"
                                class="fa fa-spin fa-circle-notch mr-2"
                              ></span>
                              <span>Show History</span>
                            </button>
                            <p class="small text-muted mb-0">
                              Last Modify: {{ prettyDate(reply.updated_at) }}
                            </p>
                          </div>
                          <div class="emoji-reaction-container pl-2 pb-2">
                            <EmojiReactions
                              :emojiPickerToggled="replyEmojiPickerToggled"
                              @togglePicker="toggleReplyPicker(reply.id)"
                              :isReply="true"
                              @clickAway="clickAway"
                              @selectEmoji="selectEmoji"
                              :task="reply"
                              :user="user"
                            />
                          </div>
                          <div
                            v-if="reply.hidden"
                            class="small text-danger text-right px-3 py-2"
                            style="font-weight: 500"
                          >
                            This thread is hidden and only visible to ({{
                              reply.user.name
                            }}) and all admins/managers
                          </div>
                          <div
                            class="d-flex justify-content-end"
                            v-if="!reply.hidden"
                          >
                            <button
                              class="btn btn-sm btn-theme mr-3 mb-3"
                              @click.prevent="AddComment(reply)"
                            >
                              Comment on Thread
                            </button>
                          </div>

                          <div
                            class="
                              timeline-task
                              comments-chat-container
                              light-shadow
                            "
                            v-if="(reply.comments || []).length"
                          >
                            <div class="timeline-dashboard-container d-flex">
                              <div
                                class="timeline-bar"
                                :style="{
                                  backgroundImage: `url(${timelineBarImg})`,
                                }"
                              ></div>
                              <div class="timeline-content">
                                <h2 class="mb-0 pt-3">Comments Timeline</h2>

                                <div
                                  v-for="comment in reply.comments"
                                  :key="comment.id"
                                  :id="`reply_message_${comment.id}`"
                                  :class="
                                    comment.hidden && !comment.editMode
                                      ? 'hidden-message'
                                      : ''
                                  "
                                  class="position-relative"
                                >
                                  <div>
                                    <div class="image-info">
                                      <img
                                        :src="timelineImgBorder"
                                        class="image-border"
                                      />
                                      <img
                                        v-if="!comment.isNew"
                                        class="avatar-task-img rounded-circle"
                                        :src="
                                          comment.user.profile_picture ||
                                          profileImagePlaceholder
                                        "
                                      />
                                      <img
                                        v-if="comment.isNew"
                                        class="avatar-task-img rounded-circle"
                                        :src="
                                          user.profile_picture ||
                                          profileImagePlaceholder
                                        "
                                      />
                                    </div>
                                    <div class="item-content">
                                      <div class="timeline-date-info">
                                        <p class="user-name mb-0">
                                          {{
                                            comment.isNew
                                              ? "Me"
                                              : comment.user.name
                                          }}
                                        </p>
                                        <p class="date-time mb-0">
                                          {{
                                            comment.isNew
                                              ? "Now"
                                              : prettyDate(comment.created_at)
                                          }}
                                        </p>
                                      </div>
                                      <div class="timeline-subject">
                                        <div
                                          class="
                                            d-flex
                                            justify-content-end
                                            border-bottom
                                            pb-3
                                          "
                                          v-if="!comment.isNew"
                                        >
                                          <button
                                            class="btn btn-sm btn-black ml-2"
                                            v-if="comment.user.id == user.id"
                                            title="edit Reply"
                                            @click.prevent="
                                              openEditMode(comment)
                                            "
                                          >
                                            <i class="fa fa-edit" />
                                            Edit
                                          </button>
                                          <button
                                            class="btn btn-sm btn-black ml-3"
                                            v-if="
                                              comment.user.id == user.id ||
                                              user.isAdmin ||
                                              user.isManagingAdmin
                                            "
                                            :title="
                                              comment.hidden
                                                ? 'Visible to admins/managers only'
                                                : 'Visible to all'
                                            "
                                            @click.prevent="
                                              !comment.hiding &&
                                                showHideReply(comment, true)
                                            "
                                          >
                                            <i
                                              v-if="!comment.hiding"
                                              class="fa"
                                              :class="
                                                comment.hidden
                                                  ? 'fa-eye-slash'
                                                  : 'fa-eye'
                                              "
                                            />
                                            <i
                                              class="
                                                fas
                                                fa-spin fa-circle-notch
                                              "
                                              v-if="comment.hiding"
                                            ></i>
                                            {{
                                              comment.hidden
                                                ? "Hidden"
                                                : "Visible"
                                            }}
                                          </button>
                                        </div>
                                        <div
                                          class="
                                            comment-message-body
                                            d-flex
                                            align-items-start
                                            justify-content-start
                                            mt-3 mt-md-4
                                          "
                                        >
                                          <div
                                            class="w-100"
                                            v-if="comment.editMode"
                                          >
                                            <ckeditor
                                              :editor="editor"
                                              v-model="comment.description"
                                              :config="editorConfig"
                                            >
                                            </ckeditor>
                                            <div>
                                              <save
                                                class="mt-4 mr-3"
                                                :saving="comment.editing"
                                                :disabled="comment.editing"
                                                @click="
                                                  comment.isNew
                                                    ? createComment(comment)
                                                    : updateReply(comment)
                                                "
                                                >{{
                                                  comment.isNew
                                                    ? "Save"
                                                    : "Update"
                                                }}</save
                                              >
                                              <button
                                                class="btn btn-dark mt-4"
                                                @click.prevent="
                                                  comment.isNew
                                                    ? removeComment(reply)
                                                    : closeEditMode(comment)
                                                "
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                          <div
                                            class="comment-message-content"
                                            v-if="!comment.editMode"
                                            v-html="
                                              $options.filters.nl2br(
                                                $options.filters.slash2hr(
                                                  comment.description
                                                )
                                              )
                                            "
                                          ></div>
                                        </div>
                                        <hr />
                                        <div
                                          class="
                                            text-left
                                            pl-3
                                            d-flex
                                            align-items-center
                                            history-button-container
                                          "
                                          v-if="
                                            !comment.isNew &&
                                            comment.created_at !=
                                              comment.updated_at &&
                                            (user.isAdmin ||
                                              user.isManagingAdmin)
                                          "
                                        >
                                          <button
                                            class="
                                              btn btn-black
                                              py-0
                                              btn-sm
                                              rounded-sm
                                              mr-3
                                            "
                                            :disabled="comment.historyLoading"
                                            @click.prevent="
                                              showHistory(comment)
                                            "
                                          >
                                            <span
                                              v-if="comment.historyLoading"
                                              class="
                                                fa fa-spin fa-circle-notch
                                                mr-2
                                              "
                                            ></span>
                                            <span>Show History</span>
                                          </button>
                                          <p class="small text-muted mb-0">
                                            Last Modify:
                                            {{ prettyDate(comment.updated_at) }}
                                          </p>
                                        </div>
                                        <div
                                          class="
                                            emoji-reaction-container
                                            pl-2
                                            pb-2
                                          "
                                          v-if="!comment.isNew"
                                        >
                                          <EmojiReactions
                                            :emojiPickerToggled="
                                              replyEmojiPickerToggled
                                            "
                                            @togglePicker="
                                              toggleReplyPicker(comment.id)
                                            "
                                            :isReply="true"
                                            @clickAway="clickAway"
                                            @selectEmoji="selectEmoji"
                                            :task="comment"
                                            :user="user"
                                          />
                                        </div>
                                        <div
                                          v-if="comment.hidden || reply.hidden"
                                          class="
                                            small
                                            text-danger text-right
                                            px-3
                                            py-2
                                          "
                                          style="font-weight: 500"
                                        >
                                          This comment is hidden{{
                                            reply.hidden
                                              ? " as main thread is hidden"
                                              : ""
                                          }}
                                          and only visible to ({{
                                            comment.user.name
                                          }}) and all admins/managers
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="d-flex justify-content-end"
                              v-if="!reply.hidden && !reply.comments[0].isNew"
                            >
                              <button
                                class="btn btn-sm btn-theme mr-3 mt-3"
                                @click.prevent="AddComment(reply)"
                              >
                                Comment on Thread
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <alert
                class="my-2 text-left"
                v-if="!isDraft && task.id && !(task.replies || []).length"
                >No Threads Found</alert
              >
            </div>
            <div class="col-12 col-sm-10 col-xl-5 col-lg-5 controls-container">
              <div class="row flex-nowrap form-group align-items-center">
                <label class="col-form-label col-3">Task Type</label>
                <multiselect
                  v-model="selectedTaskType"
                  :options="
                    (!canEdit && !isNew) || (user && !user.isPatient)
                      ? taskTypes
                      : clientTaskTypes
                  "
                  :disabled="!canEdit && !isNew && !assigneeCanEdit"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="true"
                  placeholder="Search for Task Type"
                  Value="value"
                  label="label"
                  track-by="value"
                  class="app-select mx-3 no-labels"
                  :preselect-first="false"
                  :requied="true"
                  @input="updateTaskType()"
                >
                  <template #noResult>
                    <div class="no-result">Oops! No results found.</div>
                  </template>
                </multiselect>
              </div>

              <select-control
                v-model="task.priority"
                :options="priorityOptions"
                wrapClass="align-items-center"
                labelClass="col-3"
                v-if="!isNew || (user && !user.isPatient)"
                :disabled="!canEdit && !isNew && !assigneeCanEdit"
                required
                >Priority</select-control
              >

              <div
                class="form-group row align-items-center"
                v-if="!isNew || (user && !user.isPatient)"
              >
                <label
                  for="input-control-due-date"
                  class="col-3 col-form-label"
                >
                  Due Date
                </label>
                <div class="col">
                  <datetime
                    id="input-control-due-date"
                    v-model="task.due_date"
                    placeholder=""
                    :week-start="7"
                    zone="UTC"
                    format="yyyy-MM-dd"
                    input-class="form-control"
                    :disabled="!canEdit && !isNew"
                  ></datetime>
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label
                  class="assignee-label col-3 col-form-label"
                  for="assignee_id"
                  >Assignee</label
                >
                <div class="col">
                  <vue-tags-input
                    id="assignee_id"
                    name="assignee_id"
                    class="light-shadow"
                    v-model="tag"
                    :tags="tags"
                    :autocomplete-items="filteredItems"
                    :add-only-from-autocomplete="true"
                    :autocomplete-min-length="0"
                    :max-tags="1"
                    placeholder=""
                    :required="!tags || !tags.length"
                    :disabled="!canEdit && !isNew"
                    @tags-changed="(newTags) => (tags = newTags)"
                  />
                </div>
              </div>

              <div
                class="form-group row justify-content-between"
                style="margin-top: -16px"
                v-if="!isNew && !noRedirect && false"
              >
                <div class="col-2 col-form-label"></div>
                <button
                  class="btn btn-sm btn-black mr-3"
                  @click.prevent="replyToMessage()"
                >
                  Reply to message?
                </button>
              </div>

              <div class="form-group row">
                <div class="col-3 col-form-label">Notifications</div>
                <div class="col row m-0">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="message"
                      :disabled="!canEdit && !isNew"
                      v-model="task.message"
                    />
                    <label
                      class="custom-control-label pointer-cursor"
                      for="message"
                    >
                      Send Email/SMS message
                      <i
                        class="fa fa-info-circle ml-1"
                        v-tooltip.top="
                          'Send Email and/or SMS to the assigned and mentioned users, this will not include clients'
                        "
                      ></i>
                    </label>
                  </div>

                  <div
                    class="custom-control custom-switch mt-3"
                    v-if="user && !user.isPatient"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="clientAccess"
                      :disabled="!canEdit && !isNew"
                      :checked="task.clientAccess"
                      v-model="task.clientAccess"
                    />
                    <label
                      class="custom-control-label pointer-cursor"
                      for="clientAccess"
                    >
                      Send notification to client
                      <i
                        class="fa fa-info-circle ml-1"
                        v-tooltip.top="
                          'Send notification to mentioned and assigned clients with Email and/or SMS'
                        "
                      ></i>
                    </label>
                  </div>
                </div>
              </div>
              <div
                class="form-group row"
                v-if="!isNew && !noRedirect && !user.isPatient && !isDraft"
              >
                <div class="col-3 col-form-label">Resolved</div>
                <div class="col row m-0">
                  <div class="custom-control custom-checkbox mr-4">
                    <input
                      type="checkbox"
                      class="custom-control-input pointer-cursor"
                      id="resolved_checkbox"
                      :disabled="!canEdit && !assigneeCanEdit"
                      v-model="task.archived"
                    />
                    <label
                      class="custom-control-label"
                      for="resolved_checkbox"
                      >{{ task.archived ? "Yes" : "No" }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <taskReplyModal
          :task="task"
          :users="users"
          @replied="replyDone"
        ></taskReplyModal>
        <seenByModal :users="seenBy"></seenByModal>
        <taskHistoryModal
          :title="task.name"
          :history="taskHistory"
        ></taskHistoryModal>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VueTagsInput from "@johmun/vue-tags-input";
import Swal from "sweetalert2";
import { Datetime } from "vue-datetime";
import taskReplyModal from "./task-reply-modal.vue";
import seenByModal from "./seen-by-modal.vue";
import taskHistoryModal from "./task-history-modal.vue";
import { richTextConfig } from "./RichTextConfig";
import EmojiReactions from "./EmojiReactions.vue";
import Multiselect from "vue-multiselect";
import helpers from "@/utils/helpers";
import CONSTANT from "./constants";
import { isEmpty } from "lodash";

export default {
  components: {
    VueTagsInput,
    datetime: Datetime,
    taskReplyModal,
    taskHistoryModal,
    seenByModal,
    EmojiReactions,
    Multiselect,
  },
  props: {
    insideModal: {
      type: Boolean,
      default: false,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
    noRedirect: {
      type: Boolean,
      default: false,
    },
    defaultTaskType: {
      type: Number,
      default: -1,
    },
    defaultPriority: {
      type: Number,
      default: -1,
    },
    assignee: {
      type: Array,
    },
  },
  async mounted() {
    this.checkUserForInit();
  },
  created() {
    this.lSTaskForm = JSON.parse(window.localStorage.getItem("task_form"));
  },
  beforeDestroy() {
    if (!this.insideModal) {
      window.localStorage.removeItem("task_form");
    }
  },
  watch: {
    ...["task", "tag", "tags", "editorConfig"].reduce((acc, currentKey) => {
      acc[currentKey] = {
        handler() {
          this.saveValuesToLS();
        },
        deep: true,
      };
      return acc;
    }, {}),
  },
  data() {
    return {
      emojiPickerToggled: null,
      replyEmojiPickerToggled: null,
      saving: false,
      loading: true,
      shouldNavigateBack: false,
      lSTaskForm: undefined,
      selectedTaskType:
        this.defaultTaskType != -1
          ? CONSTANT.TASK_TYPES.find(
              (type) => type.value == this.defaultTaskType
            )
          : null,
      task: {
        name: null,
        due_date: null,
        status: null,
        priority: this.defaultPriority != -1 ? this.defaultPriority : null,
        type: this.defaultTaskType != -1 ? this.defaultTaskType : null,
        description: null,
        assignee_id: null,
        alert: true,
        message: null,
        tagged_users: null,
        read_at: null,
        replies: [],
      },
      priorityOptions: [
        { value: 3, label: "High" },
        { value: 2, label: "Medium" },
        { value: 1, label: "Low" },
      ],
      // correct values should be provided from BE
      //TODO update values to match BE
      taskTypes: CONSTANT.TASK_TYPES.sort((a, b) => {
        return a.label.localeCompare(b.label);
      }),
      clientTaskTypes: [
        { value: 5, label: "Follow up request" },
        { value: 7, label: "Message" },
        { value: 8, label: "Appointment request" },
      ],
      users: [],
      tag: "",
      tags: [],
      autocompleteItems: [],
      debounce: null,
      editor: richTextConfig.classicEditor,
      editorConfig: {
        placeholder: richTextConfig.placeholder,
        ...richTextConfig.basicToolbar,
        mention: {
          feeds: [
            {
              ...richTextConfig.feeds,
              feed: this.getFeedItems,
            },
          ],
        },
        plugins: richTextConfig.plugins,
      },
      taskHistory: [],
      taskHistoryLoading: false,
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
      timelineBarImg: require("@/assets/img/timeline-long.svg"),
      timelineImgBorder: require("@/assets/img/timeline-profile-container.svg"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      patientProviders: (state) => state.patients.providers,
    }),
    filteredItems() {
      if (this.user && this.user.isPatient) {
        const providers = this.patientProviders.data.map((provider) =>
          parseInt(provider.user_id)
        );
        return this.autocompleteItems.filter((i) => {
          return (
            i.text.toLowerCase().indexOf(this.tag.toLowerCase()) != -1 &&
            providers.includes(i.id)
          );
        });
      }
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) != -1;
      });
    },
    seenBy() {
      if (this.users.length && this.task.unread_task) {
        const taggedIds = [];
        this.task.tagged_users.forEach((item) => {
          taggedIds.push(item.user_id);
        });
        taggedIds.push(this.task.assignee_id);
        const taggedUsers = this.users.filter((item) =>
          taggedIds.includes(item.id)
        );
        if (!this.task.unread_task.length) {
          return taggedUsers.filter((item) => item.id != this.user.id);
        } else {
          const unreadUsersIds = [];
          this.task.unread_task.forEach((item) =>
            unreadUsersIds.push(item.user_id)
          );
          return taggedUsers.filter(
            (item) =>
              !unreadUsersIds.includes(item.id) && item.id != this.user.id
          );
        }
      }
      return [];
    },
    canEdit() {
      return (
        this.$route.params.id != undefined &&
        this.task &&
        this.task.user_id == this.user.id
      );
    },
    assigneeCanEdit() {
      return (
        this.$route.params.id != undefined &&
        !this.user.isPatient &&
        this.task &&
        this.task.assignee_id == this.user.id
      );
    },
    isNew() {
      return this.$route.params.id == undefined || this.noRedirect;
    },
  },
  methods: {
    ...mapActions({
      getPatientProviders: "patients/providers/getBasicProviders",
      getUser: "auth/getUser",
      deleteDrafts: "task/deleteDraft",
    }),
    scrollToReply: function (replyId) {
      const element = document.getElementById(`reply_message_${replyId}`);
      if (element) {
        const offset = 100;
        const elementRect = element.getBoundingClientRect();
        element.classList.add("selected-reply");
        const absoluteTop = elementRect.top + window.scrollY;
        window.scrollTo({
          top: absoluteTop - offset,
          behavior: "smooth",
        });
      }
    },
    updateTaskType() {
      this.task.type = (this.selectedTaskType || {}).value || -1;
    },
    checkUserForInit: function () {
      if (!this.user) {
        setTimeout(() => {
          this.checkUserForInit();
        }, 500);
      } else {
        this.initPage();
      }
    },
    initPage: async function () {
      if (this.user && this.user.isPatient) {
        this.getPatientProviders(this.user.patient_id);
      }
      let reply = false;
      let replyId = 0;
      if (this.$route.query && this.$route.query.reply) {
        reply = true;
        this.$router.replace("");
      }
      if (this.$route.query && this.$route.query.reply_id) {
        replyId = this.$route.query.reply_id;
        this.$router.replace("");
      }
      try {
        if (this.$route.query && this.$route.query.taskType) {
          this.task.type = this.$route.query.taskType;
          this.selectedTaskType = CONSTANT.TASK_TYPES.find(
            (type) => type.value == this.task.type
          );
        }
        let users = [];
        const usersRes = await this.$http.get("users?with_images=true");
        usersRes.data.data.forEach((u) => {
          u = {
            text: u.last + ", " + u.first,
            value: u.email || u.username,
            id: u.id,
            profile_picture: u.profile_picture,
            status: u.status == "ACTIVE" || u.status == "PENDING" ? 1 : 0,
          };
          users.push(u);
        });
        this.users = users.filter((u) => u.status);
        this.autocompleteItems = users;
        if (this.$route.params.id != undefined && !this.noRedirect) {
          const res = await this.$http.get(
            `${this.isDraft ? "drafts" : "tasks"}/${this.$route.params.id}`
          );
          this.task = res.data.data;
          this.task.priority = `${
            (
              this.priorityOptions.filter(
                (item) => item.label == this.task.priority
              )[0] || {}
            ).value
          }`;
          this.task.due_date = this.task.due_date
            ? `${this.task.due_date.split(" ")[0]}T00:00:00.000Z`
            : null;
          this.task.read_at = null;
          this.selectedTaskType = this.taskTypes.filter(
            (item) => item.value == this.task.type
          )[0];
          if (this.task.assignee_id) {
            this.tags = [
              users.find((user) => {
                if (user.id == this.task.assignee_id) {
                  return user.text;
                }
              }),
            ];
          }
          this.loading = false;
        } else {
          this.loading = false;
        }
        if (this.assignee) {
          const providers = this.users.filter((item) =>
            this.assignee.includes(item.id)
          );
          if (providers.length == 1) {
            this.tags = [providers[0]];
          }
        }
        if (reply) {
          this.replyToMessage();
        }
        if (replyId) {
          setTimeout(() => {
            this.scrollToReply(replyId);
          }, 0);
        }
        if (this.$route.query.userId) {
          this.shouldNavigateBack = true;
          this.task.assignee_id = this.$route.query.userId;
          this.tags = [
            users.find((user) => {
              if (user.id == this.task.assignee_id) {
                return user.text;
              }
            }),
          ];
        }
        this.uploadFromLS();
      } catch (error) {
        //eslint-disable-next-line
        this.loading = false;
        if (!error.accessDenied) {
          console.log(error);
        }
      }
    },
    saveTask: async function () {
      try {
        if (!this.task.type || this.task.type == -1) {
          return Swal.fire({
            title: "Validation Error",
            text: "Task Type is required!",
            icon: "error",
          });
        }

        this.saving = true;
        let data;
        this.task.alert = true;
        if (this.task.due_date == "") {
          delete this.task.due_date;
        }
        if (this.user && this.user.isPatient) {
          this.task.clientAccess = true;
        } else {
          this.task.clientAccess = this.task.clientAccess || false;
        }
        if (
          this.task.description &&
          this.task.description.indexOf("data-mention-id") > -1
        ) {
          const taggedUsers = this.task.description.split('data-mention-id="');
          const taggedUsersIds = [];
          taggedUsers.forEach((user, index) => {
            if (index > 0 && user.split('"')[0]) {
              taggedUsersIds.push(user.split('"')[0]);
            }
          });
          this.task.tagged_users = taggedUsersIds;
        } else {
          this.task.tagged_users = [];
        }

        this.task.descriptionText = window.$(this.task.description).text();

        if (this.task.descriptionText.length > 500) {
          this.task.descriptionText = `${this.task.descriptionText.substring(
            0,
            497
          )}...`;
        }

        if (!this.task.status) {
          this.task.status = "Created";
        }

        if (this.$route.params.id && !this.noRedirect && !this.isDraft) {
          data = await this.$http.put("tasks/" + this.task.id, {
            ...this.task,
            assignee_id: this.tags[0].id,
          });
        } else {
          data = await this.$http.post("tasks", {
            ...this.task,
            assignee_id: this.tags[0].id,
            draft_id: this.isDraft ? this.task.id : 0,
          });
        }
        this.saving = false;
        if (data) {
          this.$emit("saved");
          Swal.fire({
            title: "",
            text: "Task Added Successfully",
            icon: "success",
          });
          window.localStorage.removeItem("task_form");
          if (this.shouldNavigateBack) {
            this.$router.go(-1);
          } else if (!this.noRedirect) {
            this.$router.push("/tasks");
          }
        }
      } catch (err) {
        this.saving = false;
        Swal.fire({
          title: "Save Failed",
          text: err.data ? err.data.message : err,
          icon: "error",
        });
      }
    },
    saveTaskAsDraft: async function (event) {
      try {
        event.preventDefault();
        if (!this.task.name) {
          return Swal.fire({
            title: "Validation Error",
            text: "Subject is required!",
            icon: "error",
          });
        }
        this.saving = true;
        let data;
        if (this.task.due_date == "") {
          delete this.task.due_date;
        }
        if (this.user && this.user.isPatient) {
          this.task.clientAccess = true;
        } else {
          this.task.clientAccess = !!this.task.clientAccess;
        }

        if (this.$route.params.id && !this.noRedirect) {
          data = await this.$http.put("drafts/" + this.task.id, {
            ...this.task,
            assignee_id: (this.tags[0] || {}).id,
          });
        } else {
          data = await this.$http.post("drafts", {
            ...this.task,
            assignee_id: (this.tags[0] || {}).id,
          });
        }
        this.saving = false;
        if (data) {
          this.$emit("saved");
          Swal.fire({
            title: "",
            text: `Draft ${this.isDraft ? "Updated" : "Added"} Successfully`,
            icon: "success",
          });
          window.localStorage.removeItem("task_form");
          if (this.shouldNavigateBack) {
            this.$router.go(-1);
          } else if (!this.noRedirect) {
            this.$router.push("/tasks/drafts");
          }
        }
      } catch (err) {
        this.saving = false;
        Swal.fire({
          title: "Save Failed",
          text: err.data ? err.data.message : err,
          icon: "error",
        });
      }
    },
    saveValuesToLS: function () {
      try {
        window.localStorage.setItem(
          "task_form",
          JSON.stringify({
            task: this.task,
            editorConfig: this.editorConfig,
            tag: this.tag,
            tags: this.tags,
          })
        );
      } catch (err) {
        //eslint-disable-next-line
        console.log(err);
      }
    },
    uploadFromLS: function () {
      try {
        let taskForm = this.lSTaskForm;
        if (!isEmpty(taskForm)) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-black ml-4",
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          return swalWithBootstrapButtons
            .fire({
              title: "Did you lose your data?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, restore!",
              cancelButtonText: "No, cancel!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.task = {
                  ...this.task,
                  ...(taskForm.task || {}),
                };
                this.editorConfig = {
                  ...this.editorConfig,
                  ...(taskForm.editorConfig || {}),
                  plugins: richTextConfig.plugins,
                };
                this.tag = taskForm.tag || "";
                this.tags = taskForm.tags || [];
                this.selectedTaskType = this.taskTypes.filter(
                  (type) => type.value == this.task.type
                )[0];
                window.localStorage.removeItem("task_form");
                this.lSTaskForm = undefined;
              } else {
                window.localStorage.removeItem("task_form");
                this.lSTaskForm = undefined;
              }
            });
        }
      } catch (error) {
        //eslint-disable-next-line
        console.log(error);
      }
    },
    updateReply: async function (reply) {
      try {
        reply.editing = true;
        this.$forceUpdate();
        let data;
        const dataToSend = {
          id: reply.id,
          task_id: this.task.id,
          description: reply.description,
        };
        const replyTaggedUsers = reply.description.split('data-mention-id="');
        const replyTaggedUsersIds = [];
        replyTaggedUsers.forEach((user, index) => {
          if (index > 0 && user.split('"')[0]) {
            replyTaggedUsersIds.push(user.split('"')[0]);
          }
        });
        dataToSend.tagged_users = replyTaggedUsersIds;

        dataToSend.replyText = window.$(reply.description).text();

        if (dataToSend.replyText.length > 500) {
          dataToSend.replyText = `${dataToSend.replyText.substring(0, 497)}...`;
        }

        data = await this.$http.post(`tasks/${this.task.id}/reply`, dataToSend);
        reply.editing = false;
        this.$forceUpdate();
        if (data) {
          Swal.fire({
            title: "",
            text: "Reply Updated Successfully",
            icon: "success",
          });
          this.initPage();
        }
      } catch (err) {
        reply.editing = false;
        this.$forceUpdate();
        Swal.fire({
          title: "Reply Update Failed",
          text: err.data ? err.data.message : err,
          icon: "error",
        });
      }
    },
    createComment: async function (comment) {
      try {
        comment.editing = true;
        this.$forceUpdate();
        let data;
        const dataToSend = {
          task_id: comment.task_id,
          parent_id: comment.parent_id,
          description: comment.description,
        };
        const commentTaggedUsers =
          comment.description.split('data-mention-id="');
        const commentTaggedUsersIds = [];
        commentTaggedUsers.forEach((user, index) => {
          if (index > 0 && user.split('"')[0]) {
            commentTaggedUsersIds.push(user.split('"')[0]);
          }
        });
        dataToSend.tagged_users = commentTaggedUsersIds;

        dataToSend.replyText = window.$(comment.description).text();

        if (dataToSend.replyText.length > 500) {
          dataToSend.replyText = `${dataToSend.replyText.substring(0, 497)}...`;
        }
        if (comment.isNew) {
          data = await this.$http.put(
            `tasks/${this.task.id}/reply-comment`,
            dataToSend
          );
        } else {
          data = await this.$http.put(
            `tasks/${this.task.id}/reply-comment`,
            dataToSend
          );
        }
        comment.editing = false;
        this.$forceUpdate();
        if (data) {
          Swal.fire({
            title: "",
            text: comment.isNew
              ? "Comment Added Successfully"
              : "Comment Updated Successfully",
            icon: "success",
          });
          this.initPage();
        }
      } catch (err) {
        comment.editing = false;
        this.$forceUpdate();
        Swal.fire({
          title: comment.isNew
            ? "Failed To Add Comment"
            : "Comment Update Failed",
          text: err.data ? err.data.message : err,
          icon: "error",
        });
      }
    },
    showHideReply: async function (reply, isComment = false) {
      try {
        reply.hiding = true;
        this.$forceUpdate();
        let data;
        const dataToSend = {
          id: reply.id,
          hidden: !reply.hidden,
        };
        data = await this.$http.post(`tasks/${this.task.id}/reply`, dataToSend);
        reply.hiding = false;
        this.$forceUpdate();
        if (data) {
          Swal.fire({
            title: "",
            text: `${
              isComment ? "Comment" : "Reply"
            } visibility changed successfully`,
            icon: "success",
          });
          this.initPage();
        }
      } catch (err) {
        reply.hiding = false;
        this.$forceUpdate();
        Swal.fire({
          title: `Faild to change ${
            isComment ? "comment" : "reply"
          } visibility`,
          text: err.data ? err.data.message : err,
          icon: "error",
        });
      }
    },
    replyToMessage: function () {
      this.$modal.show("task-reply-modal");
    },
    AddComment: function (reply) {
      const newComment = {
        id: `new_comment_${reply.id}`,
        description: "",
        replyText: "",
        task_id: reply.task_id,
        parent_id: reply.id,
        tagged_users: [],
        user: {
          id: this.user.id,
          email: this.user.email,
          name: this.user.name,
          profile_picture: this.user.profile_picture,
        },
        isNew: true,
        editMode: true,
      };
      if (reply.comments.length) {
        if (!reply.comments[0].isNew) {
          reply.comments = [newComment, ...reply.comments];
        }
      } else {
        reply.comments = [newComment];
      }

      setTimeout(() => {
        this.scrollToReply(newComment.id);
      }, 100);
    },
    removeComment: function (reply) {
      reply.comments.shift();
    },
    replyDone: function () {
      this.loading = true;
      this.checkUserForInit();
    },
    openEditMode: function (reply) {
      reply.editMode = true;
      reply.oldDescription = reply.description;
      this.$forceUpdate();
    },
    closeEditMode: function (reply) {
      reply.editMode = false;
      reply.description = reply.oldDescription;
      this.$forceUpdate();
    },
    getFeedItems: function (queryText) {
      let usersToDisplay = this.users;
      if (this.user && this.user.isPatient) {
        const providers = this.patientProviders.data.map((provider) =>
          parseInt(provider.user_id)
        );
        usersToDisplay = this.users.filter((user) =>
          providers.includes(user.id)
        );
      }
      return richTextConfig.getFeedItems(
        queryText,
        Object.assign(usersToDisplay)
      );
    },
    isOldMessage: function (created_date) {
      const createdAt = new Date(created_date);
      createdAt.setHours(0, 0, 0, 0);
      const oldReleaseDate = new Date(2022, 4, 18);
      return createdAt.getTime() < oldReleaseDate.getTime();
    },
    showSeenBy: function () {
      if (!this.seenBy.length) {
        return;
      }
      this.$modal.show("seen-by-modal");
    },
    showHistory: function (reply = undefined) {
      if (reply) {
        reply.historyLoading = true;
        this.$forceUpdate();
      } else {
        this.taskHistoryLoading = true;
      }
      let url = reply
        ? `tasks/show-reply-history/${reply.id}`
        : `tasks/show-history/${this.task.id}`;
      this.$http
        .get(url)
        .then((res) => {
          if (reply) {
            reply.historyLoading = false;
            this.$forceUpdate();
          } else {
            this.taskHistoryLoading = false;
          }
          this.taskHistory = res.data.data;
          this.$modal.show("task-history-modal");
        })
        .catch((err) => {
          Swal.fire({
            title: "Error",
            text:
              (err.data ? err.data.message : err) || "Something went wrong...",
            icon: "error",
          });
          if (reply) {
            reply.historyLoading = false;
            this.$forceUpdate();
          } else {
            this.taskHistoryLoading = false;
          }
        });
    },
    togglePicker: function (task_id) {
      this.emojiPickerToggled =
        task_id == this.emojiPickerToggled ? null : task_id;
      this.replyEmojiPickerToggled = null;
    },
    toggleReplyPicker: function (reply_id) {
      this.replyEmojiPickerToggled =
        reply_id == this.replyEmojiPickerToggled ? null : reply_id;
      this.emojiPickerToggled = null;
    },
    clickAway: function () {
      this.emojiPickerToggled = null;
      this.replyEmojiPickerToggled = null;
    },
    selectEmoji: function () {
      this.emojiPickerToggled = null;
      this.replyEmojiPickerToggled = null;
    },
    prettyDate: function (date) {
      if (!date) {
        return "";
      }
      return helpers.prettyDate(date);
    },
    deleteDraft(id) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to delete this draft?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            vm.deleteDrafts(id).then((success) => {
              if (success) {
                vm.$router.push("/tasks/drafts");
              }
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
.create-task-wrapper {
  .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
  .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    min-height: 120px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .form-group .vue-tags-input .ti-input {
    border: 1px solid #d2ddec;
    border-radius: 36px;
    min-height: calc(1.5em + 1rem + 2px);
  }
  .col-form-label {
    text-align: right;
    @media (max-width: 1024px) {
      text-align: left;
    }
  }
  .form-control:not(.custom-select) {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
  }
  .form-control:disabled {
    opacity: 0.8;
    pointer-events: none;
  }
  .subject-task-input {
    .form-control:not(.custom-select) {
      box-shadow: none;
    }
  }
  .vue-tags-input.shadow-card {
    border-radius: 36px;
  }
}
#input-control-archived {
  width: 22.5px;
}
</style>

<style scoped lang="scss">
.small {
  font-size: 0.8125rem !important;
}
.seen-by {
  position: absolute;
  bottom: 0;
  right: 0;
}

.edit-history {
  position: absolute;
  bottom: 8px;
  left: 8px;
}
@media (max-width: 1024px) {
  .create-task-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
}

.timeline-task {
  text-align: left;

  .timeline-dashboard-container {
    position: relative;
    padding-left: 50px;
  }

  .timeline-bar-container {
    .timeline-subject {
      .timeline-user-name {
        background-color: #0000000f;
        border-radius: 99px;
        padding: 2px 8px;
        font-weight: normal;
      }

      .task-emoji {
        border-radius: 6px;
        border: 1px solid #d2ddec;
        padding: 2px 4px;
        display: inline-block;
      }
    }
  }

  .timeline-bar {
    width: 23px;
    min-width: 23px;
    background-repeat: no-repeat;
    background-position: bottom;
    position: absolute;
    top: 6px;
    bottom: 40px;
    left: 27px;
  }

  .image-info {
    position: absolute;
    left: -51.5px;
    top: -16px;
    width: 60px;
  }

  .image-border {
    width: 100%;
  }

  .avatar-task-img {
    position: absolute;
    top: 26px;
    left: 2.5px;
    width: 55px;
    min-width: 55px;
    height: 55px;
  }

  .timeline-content {
    position: relative;
    width: 100%;
    .title {
      font-weight: 500;
      font-size: 32px;
    }

    .item-content {
      margin-top: 32px;
    }

    .timeline-date-info {
      background-color: rgba(37, 37, 37, 0.4);
      color: #ffffff;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 12px 24px;

      .user-name {
        font-size: 18px;
        font-weight: 500;
      }

      .date-time {
        font-size: 16px;
        font-weight: normal;
        opacity: 0.72;
      }
    }
    .timeline-subject {
      background-color: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 32px;
      border-bottom-left-radius: 32px;
      padding: 12px 24px;
      margin-top: -4px;
    }
  }
}

.comments-chat-container {
  padding: 12px;
  background: var(--body-bg-color);
  border-radius: 36px;
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    padding: 8px 12px;
  }
}

.selected-reply {
  .timeline-date-info,
  .timeline-subject {
    animation: shadowBlink 1s 5;
    box-shadow: 0px 0px 13px 3px var(--theme-color);
  }
}

.hidden-message {
  .item-content {
    opacity: 0.7;
  }
}

@keyframes shadowBlink {
  0% {
    box-shadow: 0px 0px 13px 3px var(--theme-color);
  }
  50% {
    box-shadow: 0px 0px 13px 6px var(--theme-color);
  }
  100% {
    box-shadow: 0px 0px 13px 3px var(--theme-color);
  }
}
</style>
