<template>
  <nav
    class="navbar navbar-expand-lg mb-4"
    :class="scrolled ? 'shadow-nav' : ''"
  >
    <div class="container">
      <div class="container no-wrap p-0">
        <button
          v-if="user"
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <img class="site-logo" :src="companyLogo || companyLogoPlaceholder" />
        <router-link class="navbar-brand" to="/dashboard">{{
          companyName
        }}</router-link>

        <ul class="navbar-nav icons-navbar-nav search-task-nav-mobile">
          <li
            class="nav-item position-relative search-nav-item"
            v-if="user && !user.isPatient"
          >
            <label
              :data-state="searchState"
              for="search"
              class="jump-label"
              :title="searchState == 'open' ? '' : 'Jump to Anyone...'"
            >
              <input
                type="text"
                placeholder="Jump to Anyone..."
                @click="searchState = 'open'"
                v-model="searchKey"
                @blur="searchBlured"
              />
              <i
                :class="
                  searchState == 'open'
                    ? 'fa fa-times fa-lg'
                    : 'fa fa-search fa-lg'
                "
                aria-hidden="true"
              ></i>
              <ul class="search-result_users">
                <li
                  class="text-center p-3 h3 m-0"
                  v-if="!filteredUsers.length && searchKey"
                >
                  No users found.
                </li>
                <li
                  class="text-center p-3 h3 m-0"
                  v-if="!filteredUsers.length && !searchKey"
                >
                  No users to search for.
                </li>
                <li
                  v-for="userItem in filteredUsers"
                  :key="userItem.username"
                  @click="navigateToUser(userItem)"
                  class="pointer-cursor"
                >
                  <div
                    class="
                      d-flex
                      justify-content-between
                      jump-list-item
                      align-items-center
                    "
                  >
                    <div
                      class="jump-list-name"
                      :class="`${
                        userItem.status != 'INACTIVE' ? '' : 'text-danger'
                      }`"
                    >
                      {{ userItem.name }}
                    </div>
                    <div
                      class="jump-list-role text-bold text-primary"
                      :class="`${
                        userItem.status != 'INACTIVE'
                          ? 'text-primary'
                          : 'text-danger'
                      }`"
                    >
                      {{ getRoleName(parseInt(userItem.roleId)) }}
                    </div>
                    <div
                      class="delete-user-icon text-danger"
                      v-if="
                        user &&
                        user.isAdmin &&
                        user.email == 'eng.karam.eqhash@gmail.com'
                      "
                      @click.stop="deleteUser(userItem.user_id)"
                    >
                      <i class="fa fa-trash" />
                    </div>
                  </div>
                </li>
              </ul>
            </label>
          </li>
          <li class="nav-item nav-create-task-item mr-2">
            <div
              class="nav-create-task pointer-cursor"
              @click="navigateToProfile"
            >
              <div class="nav-item create-task-item create-task-modal">
                <i class="far fa-user fa-lg text-main" title="My Profile" />
              </div>
            </div>
          </li>
          <li class="nav-item nav-create-task-item">
            <div class="nav-create-task">
              <div class="nav-item create-task-item create-task-modal">
                <i
                  class="far fa-edit pointer-cursor fa-lg text-main"
                  title="Create Task"
                  @click="openCreateTaskModal"
                />
                <sidebar
                  :toggle="openModal"
                  @close="closeCreateTaskModal"
                  class="create-task-modal"
                >
                  <Create
                    :insideModal="true"
                    @saved="taskSaved"
                    :noRedirect="true"
                    :key="Math.random()"
                    v-if="openModal"
                  ></Create>
                </sidebar>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        v-if="user"
      >
        <admin-nav v-if="user.isAdmin || user.isManagingAdmin" />
        <provider-nav v-if="user.isProvider" />

        <patient-nav v-if="user.isPatient" />
        <!-- <alert-nav v-if="user" /> -->
        <!--TODO , decide when to show it  -->

        <referral-nav v-if="user.isReferer" />
      </div>
    </div>
    <theme-modal></theme-modal>
  </nav>
</template>
<script>
import { mapState } from "vuex";
import { default as AdminNav } from "./Navs/Admin";
import { default as ProviderNav } from "./Navs/Provider";
import { default as PatientNav } from "./Navs/Patient";
import { default as ReferralNav } from "./Navs/Referral";
import { default as Create } from "../views/tasks/Create";
import ThemeModal from "./ThemeModal.vue";

export default {
  components: {
    AdminNav,
    ProviderNav,
    PatientNav,
    ReferralNav,
    Create,
    ThemeModal,
  },
  data() {
    return {
      scrolled: false,
      openModal: false,
      searchState: "close",
      searchKey: "",
      companyLogoPlaceholder: require("@/assets/img/c3-logo.png"),
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      allUsers: (state) => state.security.usersWithRoles,
      companyName: (state) => (state.settings.companyProfile || {}).name || "",
      companyLogo: (state) => state.settings.companyLogo,
    }),
    filteredUsers: function () {
      if (!this.searchKey) {
        return this.allUsers || [];
      }
      return (this.allUsers || []).filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) > -1
      );
    },
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 50) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
    navigateToProfile: function () {
      if (this.user) {
        if (this.user.isAdmin || this.user.isManagingAdmin) {
          this.$router.push({ path: "/admin/profile" });
        } else if (this.user.isProvider) {
          this.navigateToProviderDetails();
        } else if (this.user.isPatient) {
          this.navigateToPatientDetails();
        }
      }
    },
    navigateToProviderDetails: function () {
      this.$router.push({ path: `/providers/${this.user.provider_id}` });
    },
    navigateToPatientDetails: function () {
      this.$router.push({ path: `/clients/${this.user.patient_id}` });
    },
    navigateToUser: function (userItem) {
      if (userItem.roleId == 1 || userItem.roleId == 5) {
        this.$router.push({ path: "/loading" });
        setTimeout(() => {
          this.$router.replace({ path: `/admin/users/${userItem.id}` });
        }, 500);
      } else if (userItem.roleId == 2) {
        this.$router.push({ path: "/loading" });
        setTimeout(() => {
          this.$router.replace({ path: `/providers/${userItem.id}` });
        }, 500);
      } else {
        this.$router.push({ path: "/loading" });
        setTimeout(() => {
          this.$router.replace({ path: `/clients/${userItem.id}` });
        }, 500);
      }
    },
    openCreateTaskModal: function () {
      this.openModal = true;
    },
    closeCreateTaskModal: function () {
      this.openModal = false;
      window.localStorage.removeItem("task_form");
    },
    taskSaved: function () {
      this.openModal = false;
      window.localStorage.removeItem("task_form");
    },
    getRoleName: function (roleId) {
      let roleName;
      switch (roleId) {
        case 1:
          roleName = "(Admin)";
          break;
        case 2:
          roleName = "(Provider)";
          break;
        case 5:
          roleName = "(Manager)";
          break;
        default:
          roleName = "(Client)";
          break;
      }
      return roleName;
    },
    deleteUser: function (id) {
      this.$store.dispatch("Profile/deleteUser", id);
    },
    searchBlured: function () {
      setTimeout(() => {
        this.searchState = "close";
        this.searchKey = "";
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.search-task-nav-mobile {
  display: none;
}
@media (max-width: 991.98px) {
  .no-wrap {
    flex-wrap: nowrap !important;
  }

  .search-task-nav-mobile {
    display: flex;
    flex-direction: row;
    margin: 0 !important;

    .nav-create-task {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .nav-create-task-item {
      margin-right: 0;
      width: 52px;
      height: 52px;
      text-align: center;
    }

    .search-nav-item {
      width: 52px;
      height: 52px;
    }

    .jump-label[data-state="close"] {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
    .jump-label {
      i:not(.fa-trash) {
        right: 15px;
        top: 25px;
      }
    }
  }
}
</style>
