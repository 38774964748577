<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8 mx-auto mt-4">
        <h1 v-if="provider != null">
          Add a Clinic to provider {{ provider.last + ", " + provider.first }}
        </h1>
      </div>
    </div>
    <div class="card theme-card mx-auto col-12 col-md-8 shadow-card">
      <div class="card-body">
        <alert v-if="isLoading" class="mb-0" />

        <div v-if="!clinics.length && !isLoading">
          No Available Clinics or All clinics have been assigned to the selected
          provider
        </div>
        <form @submit="addClinic" validate v-if="clinics.length && !isLoading">
          <div class="form-group">
            <label>Clinic</label>
            <select class="form-control" :required="true" v-model="clinic.id">
              <option v-for="item in clinics" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <save :saving="isSaving">Attach Clinic</save>
          <button
            v-if="src"
            class="btn btn-link ml-2"
            @click.prevent="navigateBack"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      provider: (state) => state.providers.provider,
      userSelectedClinics: (state) =>
        (
          (((state.providers || {}).provider || {}).user || {}).clinics || []
        ).map((item) => (item.clinicId = item.id)),
    }),
    src: function () {
      return this.$route.query.src;
    },
  },
  data() {
    return {
      isSaving: false,
      isLoading: true,
      clinic: {
        id: "",
        user_id: this.$route.params.id,
      },
      clinics: [],
    };
  },
  mounted() {
    let vm = this;

    this.getAllClinics().then(function (response) {
      if (response) {
        vm.clinics = response.filter(
          (item) => !vm.userSelectedClinics.includes(item.id)
        );
        vm.isLoading = false;
      }
    });

    if (this.provider == null) {
      this.$router.back();
    }
  },
  methods: {
    ...mapActions({
      getAllClinics: "clinics/get",
      attachClinic: "clinics/attachClinicToUser",
    }),
    addClinic: function (e) {
      let vm = this;
      e.preventDefault();
      this.isSaving = true;
      let data = {
        id: this.clinic.id,
        user: this.provider.user_id,
      };
      this.attachClinic(data).then(function (created) {
        vm.isSaving = false;
        if (created) {
          vm.$router.back();
        }
      });
    },
    navigateBack: function () {
      this.$router.go(-1);
    },
  },
};
</script>
