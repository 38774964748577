<template>
  <Create :isDraft="true"></Create>
</template>
<script>
import { default as Create } from "./Create";

export default {
  components: {
    Create,
  },
};
</script>
