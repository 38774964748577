<template>
  <div class="container">
    <div class="row">
      <div class="col-6 col-md-8">
        <h1>Clients</h1>
      </div>
      <div
        class="col text-right"
        v-if="
          user &&
          !user.isProvider &&
          $can({ key: 'clients', expectedPermission: 'create' })
        "
      >
        <router-link
          :to="{ name: 'patients.add' }"
          class="btn btn-outline-blue btn-sm-mobile py-2"
        >
          <icon type="plus" /> Add New Client
        </router-link>
      </div>
    </div>
    <list-patients />
  </div>
</template>

<script>
import { default as ListPatients } from "./ListPatients";
import { mapState } from "vuex";

export default {
  name: "Patients",
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  components: {
    ListPatients,
  },
};
</script>
