<template>
  <div>
    <h2>{{ referralName }} Clients</h2>
    <div class="overflow-auto table-container">
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Created At</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="referralSourceClient in referralSourceClients"
            :key="referralSourceClient.id"
          >
            <td>
              <b class="mobile-tablet-only mr-2">ID:</b
              >{{ referralSourceClient.id }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Name:</b>
              <router-link :to="`/clients/${referralSourceClient.id}`">
                {{ referralSourceClient.name }}
              </router-link>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Created At:</b
              >{{ referralSourceClient.created_at | date("YYYY-MM-DD") }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Email:</b>
              <a :href="`mailto:${referralSourceClient.email}`">{{
                referralSourceClient.email
              }}</a>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Phone:</b>
              {{ referralSourceClient.phone_number }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Status:</b>
              <span
                :class="`${
                  referralSourceClient.status == 'ACTIVE' ||
                  referralSourceClient.status == 'PROTECTED'
                    ? 'text-success'
                    : referralSourceClient.status == 'PENDING' ||
                      referralSourceClient.status == 'ON_HOLD'
                    ? 'text-warning'
                    : 'text-danger'
                }`"
              >
                {{
                  referralSourceClient.status == "PROTECTED"
                    ? "PROTECTED ACCOUNT"
                    : referralSourceClient.status == "ON_HOLD"
                    ? "ON HOLD"
                    : referralSourceClient.status
                }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div class="card-body pb-0" v-if="referralSourceClients.length == 0">
        <alert> No clients </alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      referralSourceClients: (state) =>
        state.referralSources.referralSourceClients.data,
      referralName: (state) => state.referralSources.referralSourceClients.name,
    }),
  },
};
</script>
