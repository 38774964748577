<template>
  <div class="container">
    <h1 class="text-center">Form Builder</h1>
    <h3 class="text-center text-main">How It Works</h3>
    <div class="accordion light-shadow radius-36" id="howWorks">
      <div class="card mb-0">
        <div class="card-header" id="headingOne">
          <h2 class="mb-0">
            <button
              class="btn btn-link text-uppercase text-left w-100"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Introduction
            </button>
          </h2>
        </div>
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#howWorks"
        >
          <div class="card-body">
            Form Builder is one of our most creative features that provides the
            capability to build dynamic forms and clinical notes in an easy way.
            <br />
            Once the forms are generated, They can be used for writing/filling
            clinical notes with the power of auto fill functionality supported
            by the Form Builder.
            <small class="text-muted"
              ><b>Check AutoFill section for more details</b></small
            >
          </div>
        </div>
      </div>
      <div class="card mb-0">
        <div class="card-header" id="headingTwo">
          <h2 class="mb-0">
            <button
              class="btn btn-link collapsed text-uppercase text-left w-100"
              type="button"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Building Forms
            </button>
          </h2>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#howWorks"
        >
          <div class="card-body">
            Building a form is very easy with the usage of Form Builder. Mainly,
            the form is a root element that can consist of one or more sections.
            Each section contains multiple fields per needs. To build a form
            successfully, we have to follow these steps:<br /><br />
            <ul class="steps-list">
              <li class="mb-4">
                Add Section: first step here is to add a section, the section
                can be normal or toggleable like accordions.<br />
                <img
                  src="@/assets/img/formBuilder/howItWorks1.png"
                /><br /><br />
                The generated section can have header and subheader that can be
                modified using the configuration attached to that section.<br />
                <img src="@/assets/img/formBuilder/howItWorks2.png" />
              </li>
              <li class="mb-4">
                Once the section is created, we can start adding
                controls(fields) inside it
                <br />
                <img src="@/assets/img/formBuilder/howItWorks3.png" />
              </li>
              <li class="mb-4">
                Each Control has its own configurations. you can use these
                conifurations to display label, sub-label, placeholder, default
                value, size and custom validations.<br />
                <br />
                <img src="@/assets/img/formBuilder/howItWorks4.png" />
                <br /><br />
                The most important part here is the name of that control, this
                name is used in auto-fill feature.
                <small class="text-muted"
                  ><b>Check AutoFill section for more details</b></small
                >
              </li>
              <li class="mb-4">
                Control Specific Configuration: This part is custom for
                checkboxes, dropdowns, and radio buttons where you can specifiy
                your options.
                <br />
                <img src="@/assets/img/formBuilder/howItWorks5.png" />
              </li>
              <li class="mb-4">
                Once done, you can save your form successfully.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card mb-0">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button
              class="btn btn-link collapsed text-uppercase text-left w-100"
              type="button"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Auto Fill
            </button>
          </h2>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#howWorks"
        >
          <div class="card-body">
            When you create the controls for your form, you have to be aware
            about the names for them.<br />
            These names are used in auto-fill when using the form to generate
            clinical notes.<br />
            With this feature, you don't have to fill the details manually.
            instead, use correct names for your controls and we will take care
            of the rest.<br />
            Here, we are supporting auto-fill feature for various details as
            below:
            <br /><br />
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colspan="2" class="text-center bg-secondary text-white">
                    Client Information
                  </th>
                </tr>
                <tr>
                  <td><code>patientFirstName</code></td>
                  <td>Client first name</td>
                </tr>
                <tr>
                  <td><code>patientLastName</code></td>
                  <td>Client last name</td>
                </tr>
                <tr>
                  <td><code>patientName</code></td>
                  <td>Client full name</td>
                </tr>
                <tr>
                  <td><code>patientEmail</code></td>
                  <td>Client email address</td>
                </tr>
                <tr>
                  <td><code>patientPhone</code></td>
                  <td>Client phone number</td>
                </tr>
                <tr>
                  <td><code>patientFundingSource</code></td>
                  <td>Client funding source</td>
                </tr>
                <tr>
                  <td><code>patientStatus</code></td>
                  <td>Client status</td>
                </tr>
                <tr>
                  <td><code>patientClaimNumber</code></td>
                  <td>Client claim number</td>
                </tr>
                <tr>
                  <td><code>patientPolicyNumber</code></td>
                  <td>Client policy number</td>
                </tr>
                <tr>
                  <td><code>patientInjuryDate</code></td>
                  <td>Date of injury</td>
                </tr>
                <tr>
                  <td><code>patientDiagnosis</code></td>
                  <td>Client diagnosis</td>
                </tr>
                <th colspan="2" class="text-center bg-secondary text-white">
                  Author Information
                </th>
                <tr>
                  <td><code>authorFirstName</code></td>
                  <td>Author first name</td>
                </tr>
                <tr>
                  <td><code>authorlastName</code></td>
                  <td>Author last name</td>
                </tr>
                <tr>
                  <td><code>authorName</code></td>
                  <td>Author full name</td>
                </tr>
                <tr>
                  <td><code>authorEmail</code></td>
                  <td>Author email address</td>
                </tr>
                <tr>
                  <td><code>authorStatus</code></td>
                  <td>Author status</td>
                </tr>
                <tr>
                  <th colspan="2" class="text-center bg-secondary text-white">
                    Session Information
                  </th>
                </tr>
                <tr>
                  <td><code>sessionStartDate</code></td>
                  <td>Session start date</td>
                </tr>
                <tr>
                  <td><code>sessionEndDate</code></td>
                  <td>Session end date</td>
                </tr>
                <tr>
                  <td><code>sessionStartTime</code></td>
                  <td>Session start time</td>
                </tr>
                <tr>
                  <td><code>sessionEndTime</code></td>
                  <td>Session end time</td>
                </tr>
                <tr>
                  <td><code>sessionStartHours</code></td>
                  <td>Session start time in hours</td>
                </tr>
                <tr>
                  <td><code>sessionStartMinutes</code></td>
                  <td>Session start time in minutes</td>
                </tr>
                <tr>
                  <td><code>sessionStartAMPM</code></td>
                  <td>Session start time ante meridiem</td>
                </tr>
                <tr>
                  <td><code>sessionEndHours</code></td>
                  <td>Session end time in hours</td>
                </tr>
                <tr>
                  <td><code>sessionEndMinutes</code></td>
                  <td>Session end time in minutes</td>
                </tr>
                <tr>
                  <td><code>sessionEndAMPM</code></td>
                  <td>Session end time ante meridiem</td>
                </tr>
                <tr>
                  <td><code>sessionReason</code></td>
                  <td>Session reason</td>
                </tr>
                <tr>
                  <td><code>sessionType</code></td>
                  <td>Session type</td>
                </tr>
                <tr>
                  <td><code>sessionStatus</code></td>
                  <td>Session status</td>
                </tr>
                <tr>
                  <td><code>sessionMemo</code></td>
                  <td>Session memo</td>
                </tr>
                <tr>
                  <th colspan="2" class="text-center bg-secondary text-white">
                    Service Information
                  </th>
                </tr>
                <tr>
                  <td><code>serviceName</code></td>
                  <td>Service name</td>
                </tr>
                <tr>
                  <td><code>serviceTaxable</code></td>
                  <td>Indicate whether service is taxable or not</td>
                </tr>
                <tr>
                  <td><code>serviceCatastrophic</code></td>
                  <td>Indicate whether service catastrophic or not</td>
                </tr>
                <tr>
                  <td><code>serviceProv</code></td>
                  <td>Service prov</td>
                </tr>
                <tr>
                  <td><code>serviceRate</code></td>
                  <td>Service rate</td>
                </tr>
                <tr>
                  <td><code>serviceUnit</code></td>
                  <td>Service unit</td>
                </tr>
                <tr>
                  <td><code>serviceDescription</code></td>
                  <td>Service description</td>
                </tr>
                <tr>
                  <th colspan="2" class="text-center bg-secondary text-white">
                    Questionnaire Information (Symptoms)
                  </th>
                </tr>
                <tr>
                  <td><code>bodyLocations</code></td>
                  <td>Location of the pain in the body</td>
                </tr>
                <tr>
                  <td><code>painTime</code></td>
                  <td>When does the pain occur</td>
                </tr>
                <tr>
                  <td><code>sleepHours</code></td>
                  <td>How many hours the client slept the previous night</td>
                </tr>
                <tr>
                  <td><code>anxietyReported</code></td>
                  <td>Anxiety Reported</td>
                </tr>
                <tr>
                  <td><code>clientPainRate</code></td>
                  <td>Rate pain from 0-10</td>
                </tr>
                <tr>
                  <td><code>noClientPain</code></td>
                  <td>No Answer</td>
                </tr>
                <tr>
                  <td><code>clientFatigueRate</code></td>
                  <td>Rate fatigue from 0-10 (-1 if no answer)</td>
                </tr>
                <tr>
                  <td><code>noClientFatigue</code></td>
                  <td>No Answer</td>
                </tr>
                <tr>
                  <td><code>clientMoodRate</code></td>
                  <td>Rate mood from 0-10</td>
                </tr>
                <tr>
                  <td><code>noClientMood</code></td>
                  <td>No Answer</td>
                </tr>
                <tr>
                  <td><code>clientHeadachesRate</code></td>
                  <td>Rate headaches from 0-10</td>
                </tr>
                <tr>
                  <td><code>noClientHeadaches</code></td>
                  <td>No Answer</td>
                </tr>
                <tr>
                  <td><code>activityCategory</code></td>
                  <td>Activity Category</td>
                </tr>
                <tr>
                  <td><code>providerPainRate</code></td>
                  <td>Rate pain from 0-10</td>
                </tr>
                <tr>
                  <td><code>providerFatigueRate</code></td>
                  <td>Rate fatigue from 0-10</td>
                </tr>
                <tr>
                  <td><code>providerMoodRate</code></td>
                  <td>Rate mood from 0-10</td>
                </tr>
                <tr>
                  <td><code>providerHeadachesRate</code></td>
                  <td>Rate headaches from 0-10</td>
                </tr>
                <tr v-for="question in GSO_questions" :key="question.key">
                  <td>
                    <code>{{ question.key }}</code>
                  </td>
                  <td>
                    {{ question.text }}
                    <br v-if="question.note" /><small
                      class="text-muted"
                      v-if="question.note"
                      >({{ question.note }})</small
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            All above fileds are grouped into a single form template called
            <b>Client Info</b>. you can check it
            <router-link :to="{ name: 'templates.add' }">here</router-link>
          </div>
        </div>
      </div>
      <div class="card mb-0">
        <div class="card-header" id="headingFour">
          <h2 class="mb-0">
            <button
              class="btn btn-link text-uppercase text-left w-100"
              type="button"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              API<span class="text-lowercase">s</span>
            </button>
          </h2>
        </div>

        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="headingFour"
          data-parent="#howWorks"
        >
          <div class="card-body">
            You can use dynamic dropdown values as below:
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>API</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><code>{domain}{API_LINK}</code></td>
                  <td>
                    <span class="text-danger"
                      >{domain} is a preserved word to be replaced with the
                      current domain</span
                    ><br />The API should return list of options directly or
                    under data object.<br />
                    You must specify the value and the text keys to make it work
                    properly.
                    <br /><code
                      >For example: {domain}locations-of-pain<br />
                      <pre>
RESPONSE:
        data: [
        {id: '1', name: 'location1'},
        {id: '2', name: 'location2'},
        ...
        ]
                    </pre
                      >
                    </code>
                    <img
                      src="@/assets/img/formBuilder/howItWorks6.png"
                      style="max-width: 800px"
                    />
                  </td>
                </tr>
                <tr>
                  <td><code>{domain}locations-of-pain</code></td>
                  <td>Get body locations used in symptoms tracker</td>
                </tr>
                <tr>
                  <td><code>{domain}activity-categories</code></td>
                  <td>Get activity categories used in symptoms tracker</td>
                </tr>
                <tr>
                  <td>
                    <code
                      >http://{getProvidersAPI}<br />OR<br />{domain}providers-basic-info</code
                    >
                  </td>
                  <td>Get all providers</td>
                </tr>
                <tr>
                  <td><code>http://{getProvidersAPIForPatient}</code></td>
                  <td>Get all providers attached to client</td>
                </tr>
                <tr>
                  <td>
                    <code
                      >http://{getManagersAPI}<br />OR<br />{domain}managers-basic-info</code
                    >
                  </td>
                  <td>Get all managers</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card mb-0">
        <div class="card-header" id="headingFive">
          <h2 class="mb-0">
            <button
              class="btn btn-link text-uppercase text-left w-100"
              type="button"
              data-toggle="collapse"
              data-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              Validation
            </button>
          </h2>
        </div>

        <div
          id="collapseFive"
          class="collapse"
          aria-labelledby="headingFive"
          data-parent="#howWorks"
        >
          <div class="card-body">
            Custom validation rules can be added to the fields with custom error
            messages.<br />
            The note will not be saved when the validation rules fail.<br />
            <span class="text-danger"
              >Inline error messages will be displayed when trying to save the
              note in case the form has validtion fails.</span
            >
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Validation Rule</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><code>required</code></td>
                  <td>
                    Mark the field as required (Field must have value (at least
                    length must be 1)
                    <br /><br />
                    <img
                      src="@/assets/img/formBuilder/howItWorks7.png"
                      class="mr-5"
                      style="max-width: 300px"
                    />
                    <img
                      src="@/assets/img/formBuilder/howItWorks8.png"
                      style="max-width: 300px"
                    />
                  </td>
                </tr>
                <tr>
                  <td><code>min</code></td>
                  <td>
                    Minimum length of the value. For number field, it will be
                    the value (not length)
                  </td>
                </tr>
                <tr>
                  <td><code>max</code></td>
                  <td>
                    Maximum length of the value. For number field, it will be
                    the value (not length)
                  </td>
                </tr>
                <tr>
                  <td>
                    <code>isEmail</code>
                  </td>
                  <td>Validate email address</td>
                </tr>
                <tr>
                  <td><code>regex</code></td>
                  <td>Validation the field by using your own Regex Rule</td>
                </tr>
                <tr>
                  <td><code>sameAs</code></td>
                  <td>Check if the field has same value with another field</td>
                </tr>
                <tr>
                  <td>
                    <code>customClosure</code>
                    <badge class="badge-warning font-weight-bold"
                      >Advanced</badge
                    >
                  </td>
                  <td>Invoke your own method to check your field</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card mb-0">
        <div class="card-header" id="headingSix">
          <h2 class="mb-0">
            <button
              class="btn btn-link text-uppercase text-left w-100"
              type="button"
              data-toggle="collapse"
              data-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Styling
            </button>
          </h2>
        </div>

        <div
          id="collapseSix"
          class="collapse"
          aria-labelledby="headingSix"
          data-parent="#howWorks"
        >
          <div class="card-body">Coming soon</div>
        </div>
      </div>

      <div class="card mb-0">
        <div class="card-header" id="headingSeven">
          <h2 class="mb-0">
            <button
              class="btn btn-link text-uppercase text-left w-100"
              type="button"
              data-toggle="collapse"
              data-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              Symptoms Tracker (Questionnaire)
            </button>
          </h2>
        </div>

        <div
          id="collapseSeven"
          class="collapse"
          aria-labelledby="headingSeven"
          data-parent="#howWorks"
        >
          <div class="card-body">
            The Form Builder can be used for creating symptoms forms.<br />
            It is important to know that:
            <ul>
              <li>
                The symptoms form values will be reflected into symptoms tracker
                area.
              </li>
              <li>
                This will happen only when creating clinical note for a specific
                session for the first time.
              </li>
              <li>
                Editing the clinical note will NOT be reflected on symptoms
                tracker area.
              </li>
              <li>
                Creating another clinical note for same session will NOT be
                reflected on symptoms tracker area.
              </li>
              <li>
                From above, we can say the symptoms form will be reflected into
                smyptoms trakcer area ONLY if no questionnaire is filled before
                for the selected session.
              </li>
              <li>
                In case you have to modify a clinical note that contains
                symptoms form, you have to make same modifications in symptoms
                tracker area manually. Otherwise, the values in the clinical
                note and symptoms tracker area will not match.
              </li>
              <li>
                Symptoms form can be used to auto fill the values as well in
                case the questionnaire is filled before for the selected
                session.
              </li>
              <li>
                You can get the benifit of having ready symptoms forms under
                controls dropdown:<br />
                <img
                  src="@/assets/img/formBuilder/howItWorks9.png"
                  style="max-width: 800px"
                />
              </li>
            </ul>

            <b
              >The fields names used in symptoms are preserved names that should
              be added when creating custom symptoms forms and are essential to
              make the form work without any issue.
              <small class="text-primary"
                >Check (Questionnaire Information) under AutoFill section for
                more details</small
              >
            </b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Badge from "../../../components/Badge.vue";
import CONSTANT from "../../../store/patients/constants";

export default {
  components: { Badge },
  data() {
    return {
      GSO_questions: CONSTANT.GSO_QUESTIONS,
    };
  },
  name: "guide",
};
</script>
<style scoped>
img {
  max-width: 100%;
}
.table {
  width: auto;
  min-width: 50%;
}
.card-body {
  overflow-x: auto;
}
</style>
