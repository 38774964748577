<template>
  <div class="container tasks-container">
    <h1 class="header-title noPrint mb-3">Tasks</h1>
    <div
      class="
        d-flex
        align-items-center
        flex-wrap
        justify-content-start
        tasks-filters-container
      "
    >
      <select-control
        v-if="user && (user.isAdmin || user.isManagingAdmin)"
        v-model="assigneeType"
        :options="assigneeTypes"
        @input="assigneeTypeChanged()"
        required
        :disabled="loading || !assigneeTypes.length"
        type="vertical"
        class="assingee-types mb-3 col-12 col-md-6 col-lg-5 col-xl-3"
        inputClass="light-shadow"
      ></select-control>
      <select-control
        v-model="taskType"
        :options="taskTypes"
        @input="filterIsDirty = true"
        type="vertical"
        class="
          assingee-types
          mb-3
          col-12 col-md-6 col-lg-5 col-xl-3
          pr-0 pr-lg-3
        "
        inputClass="light-shadow"
        :disabled="loading"
      ></select-control>
      <div
        class="
          d-flex
          pl-0
          pr-0 pr-lg-3
          align-items-center
          col-6 col-md-4 col-lg-auto
          mr-3 mr-md-0
          mb-3
          read-status-container
        "
      >
        <select-control
          v-model="resolvedStatus"
          :options="resolvedStatuses"
          type="vertical"
          @input="filterIsDirty = true"
          class="read-status col-12 pl-0 pl-lg-3"
          inputClass="light-shadow"
          :disabled="loading"
        ></select-control>
      </div>
      <div
        class="
          d-flex
          pl-0
          pr-3
          align-items-center
          col-6 col-md-4 col-lg-auto
          mr-3 mr-md-0
          mb-3
          read-status-container
        "
      >
        <select-control
          v-model="unreadStatus"
          :options="readStatuses"
          @input="filterIsDirty = true"
          type="vertical"
          class="read-status col-12 pr-0 pr-md-3"
          inputClass="light-shadow"
          :disabled="loading"
        ></select-control>
      </div>
      <div
        class="
          d-flex
          pl-0
          pr-3
          align-items-center
          col-6 col-md-4 col-lg-auto
          mr-3 mr-md-0
          mb-3
        "
        :class="`${
          user && user.isPatient
            ? 'patient-priority-status'
            : 'priority-status-container'
        }`"
      >
        <select-control
          v-model="priorityStatus"
          :options="priorityStatuses"
          :disabled="loading"
          @input="filterIsDirty = true"
          type="vertical"
          class="priority-status col-12 pl-0 pl-lg-3"
          inputClass="light-shadow"
        ></select-control>
      </div>
      <div class="mr-4 row ml-0 align-items-center mb-3">
        <label class="font-weight-bold mr-3 mb-0">Date Range</label>
        <div>
          <v-date-picker
            v-model="dateRange"
            mode="date"
            :masks="{ input: 'YYYY-MM-DD' }"
            is-range
            @input="filterIsDirty = true"
          >
            <template v-slot="{ inputValue, inputEvents, isDragging }">
              <div
                class="
                  d-flex
                  justify-content-start
                  align-items-center
                  form-group
                  mb-0
                "
              >
                <input
                  class="px-2 py-1 form-control light-shadow"
                  :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                  :value="inputValue.start"
                  v-on="inputEvents.start"
                  placeholder="Start Date"
                />
                <div class="mx-2">-</div>
                <input
                  class="px-2 py-1 form-control light-shadow"
                  :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                  :value="inputValue.end"
                  v-on="inputEvents.end"
                  placeholder="End Date"
                />
                <button
                  class="btn btn-sm text-main"
                  @click="clearDateRange()"
                  :style="{
                    opacity: !dateRange?.start || !dateRange?.end ? 0 : 1,
                  }"
                >
                  Clear
                </button>
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>

      <div class="d-flex flex-wrap mt-2 mb-3 justify-content-start">
        <div
          class="
            custom-control custom-checkbox
            d-flex
            align-items-center
            col-6 col-md-auto
            mr-md-4
          "
        >
          <input
            type="checkbox"
            class="custom-control-input"
            id="my-tasks"
            @change="
              (filterIsDirty = true), (taggedIn = false);
              assigneeType = '-1';
            "
            v-model="assignedToMe"
            :disabled="loading"
          />
          <label class="custom-control-label" for="my-tasks"
            >Assigned to me</label
          >
        </div>
        <div
          class="
            custom-control custom-checkbox
            d-flex
            align-items-center
            col-6 col-md-auto
            mr-md-4
          "
        >
          <input
            type="checkbox"
            class="custom-control-input"
            id="my-tagged-in"
            @change="
              (filterIsDirty = true), (assignedToMe = false);
              assigneeType = '-1';
            "
            v-model="taggedIn"
            :disabled="loading"
          />
          <label class="custom-control-label" for="my-tagged-in"
            >Tagged in</label
          >
        </div>

        <div
          class="
            custom-control custom-checkbox
            d-flex
            align-items-center
            col-6 col-md-auto
            mr-md-4
          "
        >
          <input
            type="checkbox"
            class="custom-control-input"
            id="important-tasks"
            @change="filterIsDirty = true"
            v-model="importantOnly"
            :disabled="loading"
          />
          <label class="custom-control-label" for="important-tasks"
            ><i
              class="fa-flag"
              :class="importantOnly ? 'fas text-danger' : 'far'"
            ></i>
            Flagged Only</label
          >
        </div>
        <div
          class="
            custom-control custom-checkbox
            d-flex
            align-items-center
            col-6 col-md-auto
          "
        >
          <input
            type="checkbox"
            class="custom-control-input"
            id="sent-tasks"
            @change="filterIsDirty = true"
            v-model="sentTasks"
            :disabled="loading"
          />
          <label class="custom-control-label" for="sent-tasks">
            Sent Tasks
          </label>
        </div>
        <div
          class="
            d-flex
            align-items-center
            justify-content-end
            mb-3
            mt-4 mt-sm-0
          "
          :class="[tasks.length ? '' : 'col pr-0']"
        ></div>
      </div>
    </div>
    <div
      class="
        d-flex
        mb-4
        m-2
        mx-sm-0
        align-items-end
        justify-content-end
        mt-4
        flex-column flex-sm-row
      "
    >
      <div class="mr-auto">
        <span class="mr-3 tasks-count-container desktop-tablet-only-force">
          <b v-if="!loading">
            <i class="fas fa-spin fa-circle-notch" v-if="busy"></i>
            {{ busy ? "" : tasks.length }}/{{ total }}</b
          >
          <i class="fas fa-spin fa-circle-notch" v-if="loading"></i>
          Task{{ loading || total != 1 ? "s" : "" }}
        </span>
        <button
          class="btn btn-dark position-relative pr-3 mr-2 mb-3 mb-sm-0"
          @click="getPaginatedTasks()"
        >
          <i class="fas fa-filter"></i> Apply Filters
          <div
            class="action-indicator btn btn-danger spinner-grow"
            v-if="filterIsDirty"
          ></div>
        </button>
        <button
          class="btn btn-outline-blue position-relative pr-3 mb-3 mb-sm-0"
          @click="markAllTasksAsRead()"
          :disabled="markLoading"
          v-if="numberOfUnreadTasks"
        >
          <i class="fas fa-check-double" v-if="!markLoading"></i>
          <i class="fas fa-spin fa-circle-notch" v-if="markLoading"></i> Mark
          All As Read
        </button>
      </div>
      <div class="sort-container d-flex flex-wrap">
        <JsonExcel
          :data="tasks"
          name="CRM-alerts"
          worksheet="CRM Alerts"
          :fields="excel_fields"
        >
          <button
            class="btn btn-deep-success"
            style="min-width: 100px"
            :disabled="loading"
          >
            <i class="far fa-file-excel fa-lg"></i> Save Excel
          </button>

          <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        </JsonExcel>
        <router-link
          to="/tasks/create"
          class="btn btn-outline-blue ml-3 mb-3 mx-md-3 mb-md-0"
        >
          <icon type="plus" /> Add New Task
        </router-link>
        <span
          class="
            mr-0
            ml-3 ml-sm-0
            mr-sm-3
            tasks-count-container
            mobile-only-force
          "
        >
          <b v-if="!loading">
            <i class="fas fa-spin fa-circle-notch" v-if="busy"></i>
            {{ busy ? "" : tasks.length }}/{{ total }}</b
          >
          <i class="fas fa-spin fa-circle-notch" v-if="loading"></i>
          Task{{ loading || total != 1 ? "s" : "" }}
        </span>
        <div class="ml-auto">
          <button
            v-if="!loading && tasks.length"
            class="btn btn-outline-link mr-1"
            @click="toggleReverse"
            :disabled="loading"
            :title="reverseTasksOrder ? 'Sort asc' : 'Sort desc'"
          >
            <i
              class="fa fa-lg"
              :class="
                reverseTasksOrder
                  ? 'fa-sort-amount-up-alt'
                  : 'fa-sort-amount-down'
              "
            ></i>
          </button>
          <button
            v-if="!loading && tasks.length"
            :class="`btn ${
              listDisplay == 'timeline'
                ? 'btn-header-blue'
                : 'btn-outline-header-blue'
            }`"
            style="border-bottom-right-radius: 0; border-top-right-radius: 0"
            @click="setDisplayType('timeline')"
          >
            <i class="fa-solid fa-timeline"></i>
          </button>
          <button
            v-if="!loading && tasks.length"
            :class="`btn ${
              listDisplay == 'list'
                ? 'btn-header-blue'
                : 'btn-outline-header-blue'
            }`"
            style="border-radius: 0"
            @click="setDisplayType('list')"
          >
            <i class="fas fa-list"></i>
          </button>
          <button
            v-if="!loading && tasks.length"
            :class="`btn ${
              listDisplay == 'table'
                ? 'btn-header-blue'
                : 'btn-outline-header-blue'
            }`"
            style="border-bottom-left-radius: 0; border-top-left-radius: 0"
            @click="setDisplayType('table')"
          >
            <i class="fas fa-th"></i>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="table-outer-container border-0 overflow-visible"
      :class="[
        this.listDisplay == 'table' ? 'nice-scroll' : '',
        this.listDisplay == 'timeline' && tasks.length
          ? ''
          : 'card light-shadow',
      ]"
    >
      <div class="p-3 no-tasks" v-if="tasks.length == 0">No Tasks Found.</div>
      <table
        v-show="tasks.length > 0 && listDisplay == 'table'"
        class="table table-sm table-striped mb-0"
      >
        <thead>
          <tr>
            <th style="border-top-left-radius: 36px">Subject</th>
            <th>Assignee</th>
            <th>Description</th>
            <th>Priority</th>
            <th>Date Created</th>
            <th>Due Date</th>
            <th>Type</th>
            <th class="text-center" v-if="user && !user.isPatient">Resolved</th>
            <th class="text-center">Important</th>
            <th style="border-top-right-radius: 36px"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="[taskSeen(task) ? '' : 'taskAlert']"
            class="position-relative"
            v-for="task in tasks"
            :key="task.id"
          >
            <td>
              <b class="mobile-tablet-only mr-2">Subject:</b>{{ task.name }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Assignee:</b
              >{{ task.assignee.last }}, {{ task.assignee.first }}
            </td>
            <td style="word-break: break-word">
              <b class="mobile-tablet-only mr-2">Description:</b>
              <div
                class="task-description-table-cell ck ck-content"
                v-html="
                  $options.filters.nl2br(
                    $options.filters.slash2hr(task.description)
                  )
                "
              ></div>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Priority:</b
              >{{ task.priority }}
            </td>

            <td>
              <b class="mobile-tablet-only mr-2">Date Created:</b
              >{{ prettyDate(task.created_at) }}
            </td>

            <td>
              <b class="mobile-tablet-only mr-2">Due Date:</b
              >{{ prettyDate(task.due_date) }}
            </td>
            <td class="type-select">
              <b class="mobile-tablet-only mr-2">Type:</b>
              <alert
                v-if="saving[task.id]"
                :emptySlot="true"
                class="text-center p-2 m-0"
              />
              <select-control
                v-if="!saving[task.id]"
                v-model="task.type"
                :options="taskTypes"
                :disabled="!canEdit(task)"
                labelClass="u-display-none"
                :wrapClass="`select-wrapper ${task.className}${
                  !canEdit(task) ? ' select-disabled' : ''
                }`"
                @input="updateTask(task)"
              ></select-control>
            </td>
            <td
              class="text-center resolve-status"
              v-if="user && !user.isPatient"
            >
              <b class="mobile-tablet-only mr-2">Resolved:</b>
              <alert
                v-if="archiving[task.id]"
                :emptySlot="true"
                class="text-center p-2 m-0"
              />
              <div
                class="custom-control custom-checkbox d-flex align-items-center"
                v-if="!archiving[task.id]"
              >
                <input
                  type="checkbox"
                  v-model="task.archived"
                  :disabled="!canEdit(task)"
                  class="custom-control-input"
                  :id="`archived-${task.id}`"
                  @change="updateTask(task, true)"
                />
                <label
                  class="custom-control-label"
                  :for="`archived-${task.id}`"
                  >{{ task.archived ? "Yes" : "No" }}</label
                >
              </div>
            </td>
            <td class="text-center">
              <b class="mobile-tablet-only mr-2">Important:</b>
              <i
                class="fa-lg fa-flag pointer-cursor table-important-flag"
                :class="taskImportant(task) ? 'fas text-danger' : 'far'"
                v-if="!task.togglingImportant"
                :title="
                  taskImportant(task)
                    ? 'Mark as Unimportant'
                    : 'Mark as important'
                "
                @click="
                  !task.togglingImportant && toggleTaskImportant($event, task)
                "
              ></i>
              <i
                class="fa-lg fas fa-spin fa-circle-notch table-important-flag"
                v-if="task.togglingImportant"
              ></i>
            </td>
            <td class="text-right desktop-only">
              <div class="dropdown">
                <i
                  class="fas fa-ellipsis-h text-info fa-2x"
                  aria-hidden="true"
                  type="button"
                  id="dropdownMoreActions"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div
                  class="dropdown-menu"
                  aria-labelledby="dropdownMoreActions"
                >
                  <a
                    class="dropdown-item"
                    href="#"
                    :disabled="task.gettingThreads"
                    @click.prevent="viewThreads(task)"
                    >View Threads</a
                  >
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="
                      changeReadStatus(task);
                      navigate(`/tasks/update/${task.id}`);
                    "
                    >View{{ canEdit(task) ? "/Edit" : "" }}</a
                  >
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="
                      changeReadStatus(task);
                      navigate(`/tasks/update/${task.id}?reply=true`);
                    "
                    >Reply</a
                  >

                  <a
                    class="dropdown-item"
                    href="#"
                    v-if="taskSeen(task)"
                    @click.prevent="markAsUnread(task)"
                  >
                    <span
                      v-if="task.unreading"
                      class="fa fa-spin fa-circle-notch mr-2"
                    ></span>
                    Mark as Unread</a
                  >
                </div>
              </div>
            </td>
            <td class="mobile-tablet-only">
              <hr />
              <div
                class="d-flex align-items-center justify-content-center mt-4"
              >
                <button
                  class="btn btn-outline-blue"
                  @click.prevent="
                    changeReadStatus(task);
                    navigate(`/tasks/update/${task.id}`);
                  "
                >
                  View{{ canEdit(task) ? "/Edit" : "" }}
                </button>
                <button
                  class="btn btn-theme ml-2"
                  @click.prevent="
                    changeReadStatus(task);
                    navigate(`/tasks/update/${task.id}?reply=true`);
                  "
                >
                  Reply
                </button>
                <button
                  class="btn btn-secondary ml-2"
                  v-if="taskSeen(task)"
                  @click.prevent="markAsUnread(task)"
                >
                  <span
                    v-if="task.unreading"
                    class="fa fa-spin fa-circle-notch mr-2"
                  ></span>
                  Mark as Unread
                </button>
              </div>
            </td>
            <EmojiReactions
              :task="task"
              :user="user"
              :emojiPickerToggled="emojiPickerToggled"
              @togglePicker="togglePicker(task.id)"
              @clickAway="clickAway"
              @selectEmoji="selectEmoji"
              :listDisplay="listDisplay == 'list'"
              wrapClass="emoji-reaction-container-index-wrapper-1"
            />
          </tr>
        </tbody>
      </table>
      <div v-show="tasks.length > 0 && listDisplay == 'list'">
        <div class="accordion task-accordion" id="tasksAccordion">
          <div
            v-for="(task, taskIndex) in tasks"
            :key="task.id"
            class="card light-shadow mb-0 overflow-visible"
            :class="[
              taskSeen(task) ? '' : 'taskAlert',
              `item-index_${
                taskIndex == 0 && tasks.length == 1
                  ? 'first item-index_last'
                  : taskIndex == 0
                  ? 'first'
                  : taskIndex == tasks.length - 1
                  ? 'last'
                  : taskIndex
              }`,
            ]"
          >
            <div
              class="card-header p-0"
              style="height: auto; min-height: 60px"
              :id="`headingTask_${task.id}`"
              @click="accordionClicked(task)"
            >
              <h2 class="mb-0">
                <button
                  class="btn btn-link text-left w-100 shadow-none"
                  type="button"
                  data-toggle="collapse"
                  :data-target="`#collapseTask_${task.id}`"
                  aria-expanded="false"
                  :aria-controls="`collapseTask_${task.id}`"
                >
                  <div
                    class="
                      d-flex
                      heading-task-content
                      text-dark
                      align-items-center
                      pr-4
                    "
                  >
                    <i
                      class="fa-flag mr-3 accordion-important-flag"
                      :class="taskImportant(task) ? 'fas text-danger' : 'far'"
                      v-if="!task.togglingImportant"
                      :title="
                        taskImportant(task)
                          ? 'Mark as Unimportant'
                          : 'Mark as important'
                      "
                      @click="
                        !task.togglingImportant &&
                          toggleTaskImportant($event, task)
                      "
                    ></i>
                    <i
                      class="
                        fas
                        fa-spin fa-circle-notch
                        accordion-important-flag
                        mr-3
                      "
                      v-if="task.togglingImportant"
                    ></i>
                    <img
                      class="avatar-task-img rounded-circle mt-5 mt-lg-0"
                      :src="
                        task.owner.profile_picture || profileImagePlaceholder
                      "
                    />
                    <p class="task-owner-name text-bold mb-0 ml-2">
                      {{ task.owner.last }}, {{ task.owner.first }}
                    </p>
                    <p
                      class="task-action-type mb-0 ml-1"
                      v-html="task.headerText"
                    ></p>
                    <div :class="`task-type-header ellipsis ${task.className}`">
                      {{ task.displayType }}
                    </div>
                    <i class="fas fa-angle-down rotate-icon text-dark"></i>
                  </div>
                </button>
              </h2>
            </div>
            <div
              :id="`collapseTask_${task.id}`"
              class="card-body-accordion collapse"
              :aria-labelledby="`headingTask_${task.id}`"
            >
              <div class="card-body pb-0">
                <div class="task-title text-bold text-center">
                  Subject: {{ task.name }}
                </div>
                <br />
                <div
                  class="card shadow-none p-2 ck ck-content"
                  v-html="
                    $options.filters.nl2br(
                      $options.filters.slash2hr(task.description)
                    )
                  "
                ></div>
                <div class="d-flex justify-content-between flex-wrap">
                  <button
                    class="btn btn-sm btn-0d62a8 mr-3 mb-2 col-12 col-md-auto"
                    :disabled="task.gettingThreads"
                    @click="viewThreads(task)"
                  >
                    <span v-if="!task.gettingThreads">View Threads</span>
                    <i
                      v-if="task.gettingThreads"
                      class="fas fa-spinner fa-spin fa-lg px-4"
                    ></i>
                  </button>
                  <div class="mb-2 text-right">
                    Assignee:
                    <b>{{ task.assignee.last }}, {{ task.assignee.first }}</b>
                  </div>
                </div>
                <div
                  class="
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-center
                    py-4
                    px-2
                    accordion-actions
                  "
                  style="background-color: #e6e0e069; border-radius: 4px"
                >
                  Type:
                  <div class="type-select mr-sm-5 ml-2">
                    <alert
                      v-if="saving[task.id]"
                      :emptySlot="true"
                      class="text-center p-2 m-0"
                    />
                    <select-control
                      v-if="!saving[task.id]"
                      v-model="task.type"
                      :options="taskTypes"
                      :disabled="!canEdit(task)"
                      labelClass="u-display-none"
                      :wrapClass="`select-wrapper ${task.className}${
                        !canEdit(task) ? ' select-disabled' : ''
                      }`"
                      @input="updateTask(task)"
                    ></select-control>
                  </div>
                  <div
                    class="mr-4 mr-sm-2 my-4 my-lg-0"
                    v-if="user && !user.isPatient"
                  >
                    Resolved
                  </div>
                  <div
                    class="text-center mr-5 my-4 my-lg-0"
                    v-if="user && !user.isPatient"
                  >
                    <alert
                      v-if="archiving[task.id]"
                      :emptySlot="true"
                      class="text-center p-2 m-0"
                    />
                    <div
                      class="
                        custom-control custom-checkbox
                        d-flex
                        align-items-center
                      "
                      v-if="!archiving[task.id]"
                    >
                      <input
                        type="checkbox"
                        v-model="task.archived"
                        :disabled="!canEdit(task)"
                        class="custom-control-input"
                        :id="`archived-${task.id}`"
                        @change="updateTask(task, true)"
                      />
                      <label
                        class="custom-control-label"
                        :for="`archived-${task.id}`"
                        >{{ task.archived ? "Yes" : "No" }}</label
                      >
                    </div>
                  </div>
                  <button
                    class="btn btn-black mr-3 col-6 col-sm-auto mt-3 mt-lg-0"
                    @click="
                      changeReadStatus(task);
                      navigate(`/tasks/update/${task.id}`);
                    "
                  >
                    View{{ canEdit(task) ? "/Edit" : "" }}
                  </button>
                  <button
                    class="btn btn-black mr-3 mt-3 mt-lg-0"
                    @click="
                      changeReadStatus(task);
                      navigate(`/tasks/update/${task.id}?reply=true`);
                    "
                  >
                    Reply
                  </button>
                  <button
                    v-if="taskSeen(task)"
                    class="btn btn-black mr-3 mt-3 mt-lg-0"
                    @click="markAsUnread(task)"
                  >
                    <span
                      v-if="task.unreading"
                      class="fa fa-spin fa-circle-notch mr-2"
                    ></span>
                    Mark as Unread
                  </button>
                </div>
                <div class="emojis-reaction-container py-2">
                  <EmojiReactions
                    :task="task"
                    :user="user"
                    :emojiPickerToggled="emojiPickerToggled"
                    @togglePicker="togglePicker(task.id)"
                    @clickAway="clickAway"
                    @selectEmoji="selectEmoji"
                    wrapClass="emoji-reaction-container-index-wrapper"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="tasks.length > 0 && listDisplay == 'timeline'"
        class="timeline-task"
      >
        <div class="timeline-dashboard-container d-flex">
          <div
            class="timeline-bar"
            :style="{ backgroundImage: `url(${timelineBarImg})` }"
          ></div>
          <div class="timeline-content">
            <h1 class="mb-0 pt-3">Timeline</h1>
            <div v-for="task in tasks" :key="task.id" class="position-relative">
              <div class="image-info">
                <img :src="timelineImgBorder" class="image-border" />
                <img
                  class="avatar-task-img rounded-circle"
                  :src="task.owner.profile_picture || profileImagePlaceholder"
                />
              </div>
              <div class="item-content">
                <div class="timeline-date-info">
                  <p class="user-name mb-0">Subject: {{ task.name }}</p>
                  <p class="date-time mb-0">
                    {{ prettyDate(task.updated_at) }}
                  </p>
                </div>
                <div
                  class="timeline-subject"
                  :class="[taskSeen(task) ? '' : 'taskAlert']"
                >
                  <div class="accordion task-accordion" id="timeTasksAccordion">
                    <div
                      class="card-header p-0 border-0"
                      style="height: auto; min-height: 60px"
                      :id="`timeHeadingTask_${task.id}`"
                      @click="accordionClicked(task)"
                    >
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link text-left w-100 shadow-none"
                          type="button"
                          data-toggle="collapse"
                          :data-target="`#timeCollapseTask_${task.id}`"
                          aria-expanded="false"
                          :aria-controls="`timeCollapseTask_${task.id}`"
                        >
                          <div
                            class="
                              d-flex
                              heading-task-content
                              text-dark
                              align-items-center
                              pr-4
                            "
                          >
                            <i
                              class="fa-flag mr-3 accordion-important-flag"
                              :class="
                                taskImportant(task) ? 'fas text-danger' : 'far'
                              "
                              v-if="!task.togglingImportant"
                              :title="
                                taskImportant(task)
                                  ? 'Mark as Unimportant'
                                  : 'Mark as important'
                              "
                              @click="
                                !task.togglingImportant &&
                                  toggleTaskImportant($event, task)
                              "
                            ></i>
                            <i
                              class="
                                fas
                                fa-spin fa-circle-notch
                                accordion-important-flag
                                mr-3
                              "
                              v-if="task.togglingImportant"
                            ></i>
                            <p class="task-owner-name text-bold mb-0 ml-2">
                              {{ task.owner.last }}, {{ task.owner.first }}
                            </p>
                            <p
                              class="task-action-type mb-0 ml-1"
                              v-html="task.headerText"
                            ></p>
                            <div
                              :class="`task-type-header ellipsis ${task.className}`"
                            >
                              {{ task.displayType }}
                            </div>
                            <i
                              class="fas fa-angle-down rotate-icon text-dark"
                            ></i>
                          </div>
                        </button>
                      </h2>
                    </div>
                    <div
                      :id="`timeCollapseTask_${task.id}`"
                      class="card-body-accordion collapse"
                      :aria-labelledby="`timeHeadingTask_${task.id}`"
                    >
                      <div class="card-body pb-0">
                        <div class="task-title text-bold text-center">
                          Subject: {{ task.name }}
                        </div>
                        <br />
                        <div
                          class="card shadow-none py-4 px-2 ck ck-content"
                          v-html="
                            $options.filters.nl2br(
                              $options.filters.slash2hr(task.description)
                            )
                          "
                        ></div>
                        <div class="d-flex justify-content-between flex-wrap">
                          <button
                            class="
                              btn btn-sm btn-0d62a8
                              mr-3
                              mb-2
                              col-12 col-md-auto
                            "
                            :disabled="task.gettingThreads"
                            @click="viewThreads(task)"
                          >
                            <span v-if="!task.gettingThreads"
                              >View Threads</span
                            >
                            <i
                              v-if="task.gettingThreads"
                              class="fas fa-spinner fa-spin fa-lg px-4"
                            ></i>
                          </button>
                          <div class="mb-2 text-right">
                            Assignee:
                            <b
                              >{{ task.assignee.last }},
                              {{ task.assignee.first }}</b
                            >
                          </div>
                        </div>
                        <div
                          class="
                            d-flex
                            flex-wrap
                            align-items-center
                            justify-content-center
                            py-4
                            px-2
                            accordion-actions
                          "
                          style="
                            background-color: #e6e0e069;
                            border-radius: 4px;
                          "
                        >
                          Type:
                          <div class="type-select mr-sm-5 ml-2">
                            <alert
                              v-if="saving[task.id]"
                              :emptySlot="true"
                              class="text-center px-4 py-2 m-0"
                            />
                            <select-control
                              v-if="!saving[task.id]"
                              v-model="task.type"
                              :options="taskTypes"
                              :disabled="!canEdit(task)"
                              labelClass="u-display-none"
                              :wrapClass="`select-wrapper ${task.className}${
                                !canEdit(task) ? ' select-disabled' : ''
                              }`"
                              @input="updateTask(task)"
                            ></select-control>
                          </div>
                          <div
                            class="mr-4 mr-sm-2 my-4 my-lg-0"
                            v-if="user && !user.isPatient"
                          >
                            Resolved
                          </div>
                          <div
                            class="text-center mr-5 my-4 my-lg-0"
                            v-if="user && !user.isPatient"
                          >
                            <alert
                              v-if="archiving[task.id]"
                              :emptySlot="true"
                              class="text-center p-2 m-0"
                            />
                            <div
                              class="
                                custom-control custom-checkbox
                                d-flex
                                align-items-center
                              "
                              v-if="!archiving[task.id]"
                            >
                              <input
                                type="checkbox"
                                v-model="task.archived"
                                :disabled="!canEdit(task)"
                                class="custom-control-input"
                                :id="`archived-${task.id}`"
                                @change="updateTask(task, true)"
                              />
                              <label
                                class="custom-control-label"
                                :for="`archived-${task.id}`"
                                >{{ task.archived ? "Yes" : "No" }}</label
                              >
                            </div>
                          </div>
                          <button
                            class="
                              btn btn-black
                              mr-3
                              col-6 col-sm-auto
                              mt-3 mt-lg-0
                            "
                            @click="
                              changeReadStatus(task);
                              navigate(`/tasks/update/${task.id}`);
                            "
                          >
                            View{{ canEdit(task) ? "/Edit" : "" }}
                          </button>
                          <button
                            class="btn btn-black mr-3 mt-3 mt-lg-0"
                            @click="
                              changeReadStatus(task);
                              navigate(`/tasks/update/${task.id}?reply=true`);
                            "
                          >
                            Reply
                          </button>
                          <button
                            v-if="taskSeen(task)"
                            class="btn btn-black mr-3 mt-3 mt-lg-0"
                            @click="markAsUnread(task)"
                          >
                            <span
                              v-if="task.unreading"
                              class="fa fa-spin fa-circle-notch mr-2"
                            ></span>
                            Mark as Unread
                          </button>
                        </div>
                        <div class="emojis-reaction-container py-2">
                          <EmojiReactions
                            :task="task"
                            :user="user"
                            :emojiPickerToggled="emojiPickerToggled"
                            @togglePicker="togglePicker(task.id)"
                            @clickAway="clickAway"
                            @selectEmoji="selectEmoji"
                            wrapClass="emoji-reaction-container-index-wrapper"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mugen-scroll :handler="loadMore" :should-handle="!busy && !loading">
        <alert
          v-if="busy"
          class="
            text-center
            p-2
            mb-0
            rounded-0
            border-0
            bg-gray
            alert alert-basic
            bg-secondary
            text-white
          "
          ><i class="fas fa-spin fa-circle-notch mr-2"></i>Loading more tasks
          (alerts)...</alert
        >
      </mugen-scroll>
    </div>
    <alert v-if="loading" class="light-shadow" />
    <TaskThreadsModal
      v-if="selectedTask"
      :task="selectedTask"
    ></TaskThreadsModal>
  </div>
</template>

<script>
import helpers from "@/utils/helpers";
import dateFormat from "@/utils/dateFormat";
import { mapState, mapActions } from "vuex";
import JsonExcel from "vue-json-excel";
import Swal from "sweetalert2";
import MugenScroll from "vue-mugen-scroll";
import EmojiReactions from "./EmojiReactions.vue";
import TaskThreadsModal from "./TaskThreadsModal.vue";
import CONSTANT from "./constants";

export default {
  name: "Tasks",
  components: {
    JsonExcel,
    MugenScroll,
    EmojiReactions,
    TaskThreadsModal,
  },
  data() {
    return {
      listDisplay: "timeline",
      reverseTasksOrder: false,
      filterIsDirty: false,
      markLoading: false,
      selectedTask: null,
      sentTasks: false,
      limit: 50,
      dateRange: null,
      busy: false,
      excel_fields: {
        Subject: "name",
        Owner: {
          field: "owner",
          callback: (value) => {
            return `${value.last}, ${value.first}`;
          },
        },
        Assignee: {
          field: "assignee",
          callback: (value) => {
            return `${value.last}, ${value.first}`;
          },
        },
        Description: "description",
        Priority: "priority",
        "Date Created": {
          field: "created_at",
          callback: (value) => {
            return this.prettyDate(value);
          },
        },
        "Due Date": {
          field: "due_date",
          callback: (value) => {
            return this.prettyDate(value);
          },
        },
        Type: "type",
      },
      assignedToMe: false,
      taggedIn: false,
      assigneeType: "-1",
      taskType: 0,
      taskTypes: [
        { value: 0, label: "Select Type" },
        ...CONSTANT.TASK_TYPES.sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
      ],
      unreadStatus: 1,
      importantOnly: false,
      priorityStatus: 0,
      resolvedStatus: 0,
      readStatuses: [
        { label: "Status: All", value: 0 },
        { label: "Unread Only", value: 1 },
        { label: "Read Only", value: 2 },
      ],
      resolvedStatuses: [
        { label: "Resolved: All", value: 0 },
        { label: "Resolved Only", value: 1 },
        { label: "Unresolved Only", value: 2 },
      ],
      priorityStatuses: [
        { label: "Priority: All", value: 0 },
        { label: "High", value: 3 },
        { label: "Medium", value: 2 },
        { label: "Low", value: 1 },
      ],
      saving: {},
      archiving: {},
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
      timelineBarImg: require("@/assets/img/timeline-long.svg"),
      timelineImgBorder: require("@/assets/img/timeline-profile-container.svg"),
      emojiPickerToggled: null,
    };
  },
  mounted() {
    this.getMyUnreadTasksCount();
    this.getPaginatedTasks();
    this.getUsers();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      users: (state) => state.security.usersInfo,
      originTasks: (state) => state.task.pagination.data,
      current: (state) => state.task.pagination.current,
      total: (state) => state.task.pagination.total || 0,
      loading: (state) => state.task.isLoading,
      numberOfUnreadTasks: (state) => state.task.numberOfUnreadTasks,
    }),
    tasks: function () {
      if (!this.originTasks || !this.originTasks.length) {
        return [];
      }
      const tasksToDisplay = this.originTasks;
      tasksToDisplay.forEach((task) => {
        if (!task.displayType) {
          task.displayType = this.getTypeName(task.type);
          task.className = this.getTaskColor(task.type);
          task.headerText = this.getHeaderText(task);
        }
      });
      return tasksToDisplay;
    },
    assigneeTypes: function () {
      if (this.users && this.users.length) {
        const systemUsers = [{ value: "-1", label: "Select Assignee" }];
        this.users.forEach((user) => {
          systemUsers.push({
            value: user.id,
            label: user.name,
          });
        });
        return systemUsers;
      }
      return [{ value: "-1", label: "Select Assignee" }];
    },
  },
  methods: {
    ...mapActions({
      getUsers: "security/getAllUsersInfo",
      markAsRead: "task/markAsRead",
      markTaskAsImportant: "task/markAsImportant",
      markTaskAsUnread: "task/markAsUnread",
      markAllAsRead: "task/markAllAsRead",
      decrementUnread: "task/decrementUnread",
      incrementUnread: "task/incrementUnread",
      getMyUnreadTasksCount: "task/getMyUnreadTasksCount",
      getTasks: "task/getTasks",
    }),
    async viewThreads(task) {
      this.selectedTask = null;
      task.gettingThreads = true;
      this.$forceUpdate();
      const res = await this.$http.get(`tasks/${task.id}`);
      if (res.data.data) {
        this.selectedTask = res.data.data;
        setTimeout(() => {
          this.$modal.show("taskThreadsModal");
        }, 100);
      } else {
        Swal.fire({
          title: "Error",
          text: "Something went wrong...",
          icon: "error",
        });
      }
      task.gettingThreads = false;
      this.$forceUpdate();
    },
    canEdit(task) {
      return (
        task.user_id == this.user.id ||
        (task.assignee_id == this.user.id && !this.user.isPatient)
      );
    },
    getHeaderText(task) {
      if (task.created_at != task.updated_at) {
        if (task.assignee_id == this.user.id) {
          return (
            'Updated a message/task you were assigned to at <span class="task-date text-secondary">' +
            this.prettyDate(task.updated_at) +
            "</span>"
          );
        } else {
          let isTagged = task.tagged_users.filter(
            (taged) => taged.user_id == this.user.id
          ).length;
          if (isTagged) {
            return (
              'Updated a message/task you were tagged in at <span class="task-date text-secondary">' +
              this.prettyDate(task.updated_at) +
              "</span>"
            );
          }
          if (this.sentTasks) {
            return task.name;
          }
          return (
            'Updated a message/task at <span class="task-date text-secondary">' +
            this.prettyDate(task.updated_at) +
            "</span>"
          );
        }
      } else if (task.assignee_id == this.user.id) {
        switch (`${task.type}`) {
          case "3":
            return (
              'Asked you for a call log <span class="task-date text-secondary">' +
              this.prettyDate(task.created_at) +
              "</span>"
            );
          case "4":
            return (
              'Sent you administrative note <span class="task-date text-secondary">' +
              this.prettyDate(task.created_at) +
              "</span>"
            );
          case "5":
            return (
              'Requested you for follow up <span class="task-date text-secondary">' +
              this.prettyDate(task.created_at) +
              "</span>"
            );
          case "6":
            return (
              'Requested a report from you <span class="task-date text-secondary">' +
              this.prettyDate(task.created_at) +
              "</span>"
            );
          case "7":
            return (
              'Sent you message <span class="task-date text-secondary">' +
              this.prettyDate(task.created_at) +
              "</span>"
            );
          case "8":
            return (
              'Requested appointment from you <span class="task-date text-secondary">' +
              this.prettyDate(task.created_at) +
              "</span>"
            );
          default:
            return `Sent you a message of type (${
              task.displayType
            }) at <span class="task-date text-secondary">${this.prettyDate(
              task.created_at
            )}</span>`;
        }
      } else {
        let isTagged = task.tagged_users.filter(
          (taged) => taged.user_id == this.user.id
        ).length;
        if (isTagged) {
          return (
            'Tagged you on a message/task <span class="task-date text-secondary">' +
            this.prettyDate(task.created_at) +
            "</span>"
          );
        } else {
          return task.name;
        }
      }
    },
    getTaskColor(type) {
      let className = "";
      switch (type) {
        case "1":
          className = "btn-secondary";
          break;
        case "2":
          className = "btn-info";
          break;
        case "3":
          className = "btn-secondary";
          break;
        case "4":
          className = "btn-deep-red";
          break;
        case "5":
          className = "btn-info";
          break;
        case "6":
          className = "btn-info";
          break;
        case "7":
          className = "btn-secondary";
          break;
        case "8":
          className = "btn-info";
          break;
        case "9":
          className = "btn-7ac98b";
          break;
        case "10":
          className = "btn-info";
          break;
        case "11":
          className = "btn-deep-red";
          break;
        case "12":
          className = "btn-deep-warning";
          break;
        case "13":
          className = "btn-warning";
          break;
        case "14":
          className = "btn-success";
          break;
        case "15":
          className = "btn-deep-success";
          break;
        case "16":
          className = "btn-danger";
          break;

        case "17":
          className = "btn-danger";
          break;
        case "18":
          className = "btn-deep-red";
          break;
        case "19":
          className = "btn-info";
          break;
        case "20":
          className = "btn-7ac98b";
          break;
        case "21":
          className = "btn-639db9";
          break;
        case "22":
          className = "btn-a84bc6";
          break;
        case "23":
          className = "btn-e27c1d";
          break;
        case "24":
          className = "btn-e0511d";
          break;
        case "25":
          className = "btn-dd1c59";
          break;
        case "26":
          className = "btn-a31cdd";
          break;
        case "27":
          className = "btn-0859d3";
          break;
        case "28":
          className = "btn-157477";
          break;
        case "29":
          className = "btn-1a3f3f";
          break;
        case "30":
          className = "btn-0d62a8";
          break;
        case "39":
          className = "btn-dark";
          break;
        case "40":
          className = "btn-deep-red";
          break;
        default:
          className = "btn-danger";
          break;
      }
      return className;
    },
    getTypeName(type) {
      return (this.taskTypes.filter((item) => item.value == type)[0] || {})
        .label;
    },
    setDisplayType(type) {
      this.listDisplay = type;
    },
    prettyDate: function (date) {
      if (!date) {
        return "";
      }
      return helpers.prettyDate(date);
    },
    clearDateRange: function () {
      this.dateRange = null;
      this.filterIsDirty = true;
    },
    assigneeTypeChanged: function () {
      this.filterIsDirty = true;
      if (parseInt(this.assigneeType) != this.user.id) {
        this.assignedToMe = false;
        this.taggedIn = false;
      }
    },
    updateTask: function (task, archiving) {
      if (archiving) {
        this.archiving = {
          ...this.archiving,
          [task.id]: true,
        };
      } else {
        this.saving = {
          ...this.saving,
          [task.id]: true,
        };
      }
      const dataToSend = Object.assign({}, task);
      const taggedUsers = [];
      dataToSend.tagged_users.forEach((taggedUser) => {
        taggedUsers.push(taggedUser.user_id);
      });
      dataToSend.tagged_users = taggedUsers;

      if (this.user && this.user.isPatient) {
        dataToSend.clientAccess = true;
      } else {
        dataToSend.clientAccess = task.clientAccess || false;
      }
      if (dataToSend.due_date == "") {
        delete dataToSend.due_date;
      }
      delete dataToSend.className;
      delete dataToSend.headerText;
      delete dataToSend.displayType;
      this.$http
        .put("tasks/" + task.id, dataToSend)
        .then(() => {
          if (archiving) {
            this.archiving = {
              ...this.archiving,
              [task.id]: false,
            };
          } else {
            this.saving = {
              ...this.saving,
              [task.id]: false,
            };
          }
          task.displayType = this.getTypeName(task.type);
          task.className = this.getTaskColor(task.type);
          this.changeReadStatus(task);
        })
        .catch((err) => {
          if (archiving) {
            this.archiving = {
              ...this.archiving,
              [task.id]: false,
            };
          } else {
            this.saving = {
              ...this.saving,
              [task.id]: false,
            };
          }
          if (!err.accessDenied) {
            Swal.fire({
              title: "Save Failed",
              text: err.data ? err.data.message : err,
              icon: "error",
            });
          }
        });
    },
    navigate: function (url) {
      this.$router.push({ path: url });
    },
    accordionClicked(task) {
      task.opened = !task.opened;
      if (task.opened) {
        this.changeReadStatus(task);
      }
    },
    changeReadStatus: function (task) {
      if (!this.taskSeen(task)) {
        this.markAsRead(task.id).then((success) => {
          if (success) {
            task.unread_task = task.unread_task.filter(
              (item) => item.user_id != this.user.id
            );
            this.decrementUnread();
          }
        });
      }
    },
    toggleTaskImportant: function (event, task) {
      event.stopPropagation();
      event.preventDefault();
      task.togglingImportant = true;
      this.$forceUpdate();
      const isImportant = this.taskImportant(task);
      this.markTaskAsImportant({ id: task.id, status: !isImportant }).then(
        (success) => {
          if (success) {
            if (isImportant) {
              task.important_users = task.important_users.filter(
                (item) => item.user_id != this.user.id
              );
            } else {
              task.important_users.push({
                user_id: this.user.id,
              });
            }
          }
          task.togglingImportant = false;
          this.$forceUpdate();
        }
      );
    },
    markAsUnread: function (task) {
      if (this.taskSeen(task) && !task.unreading) {
        task.unreading = true;
        this.$forceUpdate();
        this.markTaskAsUnread(task.id).then((success) => {
          task.unreading = false;
          this.$forceUpdate();
          if (success) {
            task.unread_task.push({
              user_id: this.user.id,
            });
            this.incrementUnread();
          }
        });
      }
    },
    markAllTasksAsRead: function () {
      this.markLoading = true;
      this.markAllAsRead().then((success) => {
        if (success) {
          Swal.fire({
            title: "",
            text: "Marked Successfully",
            icon: "success",
          });
          this.getPaginatedTasks();
        }
        this.markLoading = false;
      });
    },
    taskSeen: function (task) {
      const unreadUsersIds = [];
      task.unread_task.forEach((item) => unreadUsersIds.push(item.user_id));
      return !unreadUsersIds.includes(this.user.id);
    },
    taskImportant: function (task) {
      const importantUsersIds = [];
      (task.important_users || []).forEach((item) =>
        importantUsersIds.push(item.user_id)
      );
      return importantUsersIds.includes(this.user.id);
    },
    toggleReverse: function () {
      this.reverseTasksOrder = !this.reverseTasksOrder;
      this.getPaginatedTasks();
    },
    getPaginatedTasks: function (page) {
      this.filterIsDirty = false;
      const payload = {
        limit: this.limit,
        asc: this.reverseTasksOrder,
        read_status: this.unreadStatus,
        priority: this.priorityStatus,
        mine: this.assignedToMe,
        important: this.importantOnly,
        tagged_in: this.taggedIn,
        resolved: this.resolvedStatus,
        type: this.taskType,
        sent_tasks: this.sentTasks,
      };
      if (this.dateRange?.start && this.dateRange?.end) {
        const startDateTime = `${dateFormat(
          this.dateRange.start,
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;
        const endDateTime = `${dateFormat(
          this.dateRange.end,
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;

        payload.start_date = startDateTime;
        payload.end_date = endDateTime;
      }

      if (this.assigneeType != "-1") {
        payload.assigned_id = this.assigneeType;
      }

      if (page) {
        payload.page = page;
      }
      this.getTasks(payload).then(() => {
        this.busy = false;
      });
    },
    loadMore: function () {
      if (this.tasks.length < this.total) {
        this.busy = true;
        this.getPaginatedTasks(this.current + 1);
      }
    },
    togglePicker: function (task_id) {
      this.emojiPickerToggled =
        task_id == this.emojiPickerToggled ? null : task_id;
    },
    clickAway: function () {
      this.emojiPickerToggled = null;
    },
    selectEmoji: function () {
      this.emojiPickerToggled = null;
    },
  },
};
</script>
<style lang="scss">
.tasks-container {
  .task-accordion {
    .avatar-task-img {
      width: 35px;
      min-width: 35px;
      height: 35px;
    }
    .taskAlert {
      .card-header,
      .card-body-accordion {
        border-left: 4px inset var(--theme-color);
        border-bottom: 0;
      }

      &.item-index_first {
        .card-header,
        .card-body-accordion {
          border-top-left-radius: 28px;
        }
      }
      &.item-index_last {
        .card-header,
        .card-body-accordion {
          border-bottom-left-radius: 28px;
        }
      }
    }
    .rotate-icon {
      position: absolute;
      right: 0px;
      transition: all 0.3s ease-in-out;
    }
    .task-type-header {
      position: absolute;
      right: 24px;
      padding: 2px 12px;
      border-radius: 8px;
      pointer-events: none;
      font-size: 14px;
      max-width: 350px;
      @media (max-width: 1024px) {
        max-width: calc(100% - 48px);
      }
      @media (max-width: 767px) {
        position: static;
        max-width: 100%;
      }
    }
    button[aria-expanded="true"] {
      .rotate-icon {
        transform: rotate(180deg);
      }
    }
    .type-select .form-control {
      width: auto;
    }
  }
  .assingee-types {
    .form-group {
      margin-right: 12px;
      margin-bottom: 0;
      .col {
        padding-left: 0;
      }
    }
  }
  .type-select {
    .select-wrapper {
      margin: 0;
      border-radius: 4px;
    }
    .form-control {
      border: 0;
      background: transparent;
      color: white;
      padding: 0;
      height: 28px;
      width: 80px;
      overflow: hidden !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;

      option {
        color: black;
      }
    }
  }
  .type-select {
    .form-control:disabled,
    .select-disabled {
      opacity: 1;
      pointer-events: none;
      appearance: none;
    }
  }
  hr {
    margin-top: 4px;
    margin-bottom: 4px;

    & + br {
      display: none;
    }
  }
  .todo-list {
    padding: 0;
    margin: 0;

    .todo-list__label__description {
      padding-left: 8px;
    }
  }

  .read-status,
  .priority-status {
    .form-group {
      margin-bottom: 0;
    }
    .form-control {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  table {
    .taskAlert {
      background-color: #edb66920 !important;
      border-bottom: 4px solid #ffffff;
    }
  }

  .task-description-table-cell {
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-outline-header-blue {
    &,
    &:focus {
      color: #062157;
      border-color: #062157;
      background-color: white;
    }

    &:hover {
      color: #0e43ad;
      border-color: #0e43ad;
      background-color: white;
    }
  }

  .btn-header-blue {
    &,
    &:hover,
    &:focus {
      color: white;
      border-color: #222222;
      background-color: #222222;
    }
  }

  .accordion-important-flag,
  .table-important-flag {
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.3);
    }
  }

  .timeline-task {
    .taskAlert {
      border-left: 4px inset var(--theme-color);
    }

    .timeline-dashboard-container {
      position: relative;
      padding-left: 50px;
    }

    .timeline-bar-container {
      .timeline-subject {
        .timeline-user-name {
          background-color: #0000000f;
          border-radius: 99px;
          padding: 2px 8px;
          font-weight: normal;
        }

        .task-emoji {
          border-radius: 6px;
          border: 1px solid #d2ddec;
          padding: 2px 4px;
          display: inline-block;
        }
      }
    }

    .timeline-bar {
      width: 23px;
      min-width: 23px;
      background-repeat: no-repeat;
      background-position: bottom;
      position: absolute;
      top: 6px;
      bottom: 40px;
      left: 27px;
    }

    .image-info {
      position: absolute;
      left: -51.5px;
      top: -16px;
      width: 60px;
    }

    .image-border {
      width: 100%;
    }

    .avatar-task-img {
      position: absolute;
      top: 26px;
      left: 2.5px;
      width: 55px;
      min-width: 55px;
      height: 55px;
    }

    .timeline-content {
      position: relative;
      width: 100%;
      .title {
        font-weight: 500;
        font-size: 32px;
      }

      .item-content {
        margin-top: 32px;
      }

      .timeline-date-info {
        background-color: rgba(37, 37, 37, 0.4);
        color: #ffffff;
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        padding: 12px 24px;

        .user-name {
          font-size: 18px;
          font-weight: 500;
        }

        .date-time {
          font-size: 16px;
          font-weight: normal;
          opacity: 0.72;
        }
      }
      .timeline-subject {
        background-color: #ffffff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 32px;
        border-bottom-left-radius: 32px;
        padding: 12px 24px;
        margin-top: -4px;
      }
    }
  }

  @media (max-width: 1024px) {
    .heading-task-content {
      flex-direction: column;
    }
    .accordion-important-flag {
      position: absolute;
      left: 0;
      top: 4px;
      margin-right: 0 !important;
    }
    .no-tasks {
      margin-bottom: 1.5rem;
      border-color: #edf2f9;
      background-color: #ffffff;
      background-clip: border-box;
      border: 1px solid #b8c2d0;
      border-radius: 0.5rem;
    }

    .type-select {
      .form-control {
        max-width: calc(100vw - 150px);
      }
    }
    table,
    .type-select {
      display: flex;
    }
    .table-outer-container {
      box-shadow: none;
      background-color: transparent;
    }
    thead {
      display: none;
    }
    tbody {
      width: 100%;
    }
    tr {
      display: flex;
      flex-direction: column;
      background-color: white !important;
      margin-bottom: 24px;
      padding: 8px;
      box-shadow: rgb(0 0 0 / 17%) 0px 0px 10px;
      &:last-child {
        margin-bottom: 0 !important;
      }
      td {
        padding: 0 4px !important;
        margin-bottom: 8px;
        text-align: left !important;
        border: 0 !important;
        b {
          display: inline-block;
          padding: 4px 0;
        }

        &.buttons-td {
          display: flex;
          justify-content: center;
          text-align: center !important;
          margin-bottom: 8px;
          margin-top: 12px;
          padding-top: 12px;
          border-top: 1px solid #ccc !important;

          .btn-secondary {
            padding: 4px 8px;
            color: #fff !important;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .tasks-filters-container {
      .assingee-types {
        padding-left: 0 !important;
        padding-right: 0 !important;

        * {
          margin-left: 0 !important;
          margin-right: 0 !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
      .read-status-container,
      .priority-status-container,
      .excel-container {
        &,
        .read-status,
        .priority-status {
          padding-right: 0 !important;
          margin-right: 0 !important;
        }
      }
      .patient-priority-status {
        padding-right: 0 !important;
        padding-left: 12px !important;
      }
    }
  }
  .emojis-reaction-container {
    min-height: 45px;
  }

  @media (min-width: 1025px) {
    .emoji-reaction-container-index-wrapper-1 {
      position: absolute;
      bottom: 3px;
      left: 10px;
      .emojis {
        max-height: 32px;
        overflow: auto;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.overflow-visible {
  overflow: visible !important;
}
.tasks-count-container {
  display: inline-block;
  min-width: 100px;
}
.action-indicator {
  width: 10px;
  height: 10px;
  padding: 0;
  position: absolute;
  top: 0px;
  right: 1px;
}
.card {
  position: static;
}
.heading-task-content {
  position: relative;
}
table {
  tr {
    td {
      padding-bottom: 4.5rem;
    }
  }
}

@media (max-width: 767px) {
  .sort-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
  }
}
</style>
