<template>
  <div>
    <ul class="nav nav-tabs header-tabs noPrint profile-nav-container">
      <li
        v-for="link in displayedNavs"
        :key="link.to || link.action"
        :class="link.dropdown ? `nav-item dropdown` : `nav-item`"
      >
        <!-- Dropdown -->
        <a
          class="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-toggle="dropdown"
          v-if="link.dropdown"
        >
          <icon :type="link.icon" class="mr-1" />
          {{ link.label }}
        </a>
        <div class="dropdown-menu" v-if="link.dropdown">
          <router-link
            v-for="drop in link.dropdown"
            :key="drop.label"
            :to="getLink(drop.to)"
            class="dropdown-item"
            :class="name == drop.to ? `active` : ``"
          >
            <icon :type="drop.icon" class="mr-1" />
            {{ drop.label }}
          </router-link>
        </div>

        <!-- Single -->
        <router-link
          v-if="!link.dropdown && !link.action"
          :to="getLink(link.to)"
          class="nav-link"
          :class="
            name == link.to || (link.subLinks && link.subLinks.includes(name))
              ? `active`
              : ``
          "
        >
          <icon :type="link.icon" class="mr-1" />
          {{ link.label }}
        </router-link>
        <a
          v-if="!link.dropdown && link.action"
          href="/"
          @click="openCalendarModal"
          class="nav-link calendar-item-link"
        >
          <icon :type="link.icon" class="mr-1" />
          {{ link.label }}
        </a>
      </li>
    </ul>
    <sidebar
      :toggle="modalOpened"
      @close="closeCalendarModal"
      :title="`${providerData.last}, ${providerData.first} Events`"
      wrapClass="calendar-tray"
      class="calendar-modal"
    >
      <Calendar
        :key="Math.random()"
        :profileProviderUserId="providerData.user_id"
        @closeModal="modalOpened = false"
        v-if="modalOpened"
      ></Calendar>
    </sidebar>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Calendar from "../../appointment/calendar";
export default {
  name: "ProviderNavBar",
  components: {
    Calendar,
  },
  props: {
    itemsIdToDisplay: Array,
  },
  computed: {
    ...mapState({
      providerData: (state) => state.providers.provider,
      user: (state) => state.auth.user,
    }),
    id: function () {
      return this.$route.params.id;
    },
    name: function () {
      return this.$route.name;
    },
    displayedNavs: function () {
      let vm = this;
      return this.nav.filter(
        (item) =>
          (!this.itemsIdToDisplay || this.itemsIdToDisplay.includes(item.id)) &&
          (!item.providerOnly ||
            (vm.user && vm.user.isProvider && item.providerOnly))
      );
    },
  },
  data() {
    return {
      modalOpened: false,
      nav: [
        {
          id: "profile",
          label: "Profile",
          to: "providers.basic",
          icon: "home",
          subLinks: [
            "providers.basic",
            "providers.classes",
            "providers.clinics",
            "providers.address",
            "providers.phones",
            "providers.emails",
          ],
        },
        {
          id: "profession",
          label: "Profession",
          to: "providers.profession",
          icon: "user-md",
        },
        {
          id: "payroll",
          label: "Pay Details",
          to: "providers.payroll",
          icon: "money-check",
        },
        {
          id: "patients",
          label: "Clients",
          to: "providers.patients",
          icon: "user-injured",
        },
        {
          id: "contacts",
          label: "Contacts",
          to: "providers.contacts",
          icon: "file",
        },
        {
          id: "documents",
          label: "Documents",
          to: "providers.documents",
          icon: "folder-open",
        },
        {
          id: "timeline",
          label: "Provider Timeline",
          to: "providers.timeline",
          icon: "file",
        },
        {
          id: "shared-recordings",
          label: "Shared Recordings",
          to: "providers.shared-recordings",
          icon: "camera",
        },
        {
          id: "availability",
          label: "Availability",
          to: "providers.availability",
          icon: "user-clock",
        },
        {
          id: "events",
          label: "Events",
          action: "openCalendarModal",
          icon: "calendar",
        },
        {
          id: "integration",
          label: "Integration",
          to: "provider.c3.integration",
          icon: "anchor",
          providerOnly: true,
        },
      ],
    };
  },
  methods: {
    getLink(to) {
      return {
        name: to,
        params: {
          id: this.id,
        },
      };
    },
    openCalendarModal(event) {
      event.preventDefault();
      this.modalOpened = true;
    },
    closeCalendarModal() {
      this.modalOpened = false;
    },
  },
  watch: {
    $route(to, from) {
      if (to.name == from.name && !from.query.event && to.query.event) {
        this.$router.replace({ query: "" });
        setTimeout(() => {
          this.modalOpened = true;
        }, 0);
      }
    },
  },
  mounted() {
    if (this.$route.query.event) {
      this.$router.replace({ query: "" });
      setTimeout(() => {
        this.modalOpened = true;
      }, 0);
    }
  },
};
</script>
