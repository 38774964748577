<template>
  <div>
    <div class="row mx-2 my-0">
      <div
        class="
          col-12 col-md-10 col-lg-8
          offset-md-1 offset-lg-2
          card
          theme-card
          border-0
          py-5
          px-4
        "
      >
        <h1 class="mb-5">Add New Clinic</h1>
        <form @submit.prevent="saveClinic" validate>
          <input-control
            type="horizontal"
            v-model="clinic.name"
            labelClass="col-md-3 col-lg-3"
            required
            >Clinic Name</input-control
          >
          <save classes="save-button btn btn-theme" :saving="isSaving"
            >Save</save
          >
          <button
            v-if="src"
            class="btn btn-link ml-2"
            @click.prevent="navigateBack"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "CreateClinic",
  data() {
    return {
      clinic: {
        name: "",
      },
      isSaving: false,
    };
  },
  computed: {
    src: function () {
      return this.$route.query.src;
    },
  },
  methods: {
    ...mapActions({
      createClinic: "clinics/create",
    }),
    saveClinic: function () {
      this.isSaving = true;
      this.$forceUpdate();
      this.createClinic(this.clinic).then(() => {
        this.isSaving = false;
        this.$forceUpdate();
      });
    },
    navigateBack: function () {
      this.$router.go(-1);
    },
  },
};
</script>
