var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-expand-lg mb-4",class:_vm.scrolled ? 'shadow-nav' : ''},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"container no-wrap p-0"},[(_vm.user)?_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})]):_vm._e(),_c('img',{staticClass:"site-logo",attrs:{"src":_vm.companyLogo || _vm.companyLogoPlaceholder}}),_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/dashboard"}},[_vm._v(_vm._s(_vm.companyName))]),_c('ul',{staticClass:"navbar-nav icons-navbar-nav search-task-nav-mobile"},[(_vm.user && !_vm.user.isPatient)?_c('li',{staticClass:"nav-item position-relative search-nav-item"},[_c('label',{staticClass:"jump-label",attrs:{"data-state":_vm.searchState,"for":"search","title":_vm.searchState == 'open' ? '' : 'Jump to Anyone...'}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchKey),expression:"searchKey"}],attrs:{"type":"text","placeholder":"Jump to Anyone..."},domProps:{"value":(_vm.searchKey)},on:{"click":function($event){_vm.searchState = 'open'},"blur":_vm.searchBlured,"input":function($event){if($event.target.composing)return;_vm.searchKey=$event.target.value}}}),_c('i',{class:_vm.searchState == 'open'
                  ? 'fa fa-times fa-lg'
                  : 'fa fa-search fa-lg',attrs:{"aria-hidden":"true"}}),_c('ul',{staticClass:"search-result_users"},[(!_vm.filteredUsers.length && _vm.searchKey)?_c('li',{staticClass:"text-center p-3 h3 m-0"},[_vm._v(" No users found. ")]):_vm._e(),(!_vm.filteredUsers.length && !_vm.searchKey)?_c('li',{staticClass:"text-center p-3 h3 m-0"},[_vm._v(" No users to search for. ")]):_vm._e(),_vm._l((_vm.filteredUsers),function(userItem){return _c('li',{key:userItem.username,staticClass:"pointer-cursor",on:{"click":function($event){return _vm.navigateToUser(userItem)}}},[_c('div',{staticClass:"d-flex justify-content-between jump-list-item align-items-center"},[_c('div',{staticClass:"jump-list-name",class:`${
                      userItem.status != 'INACTIVE' ? '' : 'text-danger'
                    }`},[_vm._v(" "+_vm._s(userItem.name)+" ")]),_c('div',{staticClass:"jump-list-role text-bold text-primary",class:`${
                      userItem.status != 'INACTIVE'
                        ? 'text-primary'
                        : 'text-danger'
                    }`},[_vm._v(" "+_vm._s(_vm.getRoleName(parseInt(userItem.roleId)))+" ")]),(
                      _vm.user &&
                      _vm.user.isAdmin &&
                      _vm.user.email == 'eng.karam.eqhash@gmail.com'
                    )?_c('div',{staticClass:"delete-user-icon text-danger",on:{"click":function($event){$event.stopPropagation();return _vm.deleteUser(userItem.user_id)}}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e()])])})],2)])]):_vm._e(),_c('li',{staticClass:"nav-item nav-create-task-item mr-2"},[_c('div',{staticClass:"nav-create-task pointer-cursor",on:{"click":_vm.navigateToProfile}},[_vm._m(0)])]),_c('li',{staticClass:"nav-item nav-create-task-item"},[_c('div',{staticClass:"nav-create-task"},[_c('div',{staticClass:"nav-item create-task-item create-task-modal"},[_c('i',{staticClass:"far fa-edit pointer-cursor fa-lg text-main",attrs:{"title":"Create Task"},on:{"click":_vm.openCreateTaskModal}}),_c('sidebar',{staticClass:"create-task-modal",attrs:{"toggle":_vm.openModal},on:{"close":_vm.closeCreateTaskModal}},[(_vm.openModal)?_c('Create',{key:Math.random(),attrs:{"insideModal":true,"noRedirect":true},on:{"saved":_vm.taskSaved}}):_vm._e()],1)],1)])])])],1),(_vm.user)?_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[(_vm.user.isAdmin || _vm.user.isManagingAdmin)?_c('admin-nav'):_vm._e(),(_vm.user.isProvider)?_c('provider-nav'):_vm._e(),(_vm.user.isPatient)?_c('patient-nav'):_vm._e(),(_vm.user.isReferer)?_c('referral-nav'):_vm._e()],1):_vm._e()]),_c('theme-modal')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-item create-task-item create-task-modal"},[_c('i',{staticClass:"far fa-user fa-lg text-main",attrs:{"title":"My Profile"}})])
}]

export { render, staticRenderFns }