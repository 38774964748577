<template>
  <div class="row task-tabs">
    <div class="col-12">
      <nav
        class="navbar-expand nav nav-tabs header-tabs mb-4"
        style="position: relative; background: none"
      >
        <ul class="navbar-nav border-none mx-0">
          <li class="nav-item" v-for="link in nav" :key="link.to">
            <router-link
              :to="getLink(link.to)"
              class="nav-link"
              :class="$route.name == link.to ? `active` : ``"
            >
              <icon :type="link.icon" class="mr-2" />
              {{ link.label }}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-12">
      <router-view class="mt-2"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nav: [
        {
          label: "Tasks",
          to: "Tasks",
          icon: "file-alt",
        },
        {
          label: "Drafts",
          to: "drafts",
          icon: "file-signature",
        },
      ],
    };
  },
  methods: {
    getLink(to) {
      return {
        name: to,
        params: {
          id: this.$route.params.id,
        },
      };
    },
  },
};
</script>

<style lang="scss">
.task-tabs {
  .header-tabs {
    border-bottom: 1px solid var(--secondary-color);
  }
  .navbar-nav {
    .nav-link.active:before {
      border-left: 0;
    }
  }
}
</style>
