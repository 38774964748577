import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: [],
    profession: null,
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    create: function (context, data) {
      context.commit("SET_STATE", { key: "isSaving", value: true });
      return new Promise((resolve, reject) => {
        this.$http
          .post("professions", data)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Profession Created Successfully",
              icon: "success",
            });
            context.commit("SET_STATE", { key: "isSaving", value: false });
            resolve(true);
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isSaving", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || err.response.data).message,
                icon: "error",
              });
            }
            reject(err);
          });
      });
    },
    update: function (context, data) {
      context.commit("SET_STATE", { key: "isSaving", value: true });
      return new Promise((resolve, reject) => {
        this.$http
          .put(`professions/${data.id}`, data)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Profession Updated Successfully",
              icon: "success",
            });
            context.commit("SET_STATE", { key: "isSaving", value: false });
            resolve(true);
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isSaving", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || err.response.data).message,
                icon: "error",
              });
            }
            reject(err);
          });
      });
    },
    all: function (ctx) {
      let vm = this;
      return new Promise((resolve) => {
        ctx.commit("SET_STATE", { key: "isLoading", value: true });
        vm.$http
          .get("professions")
          .then((res) => {
            ctx.commit("SET_STATE", { key: "isLoading", value: false });
            ctx.commit("SET_STATE", { key: "data", value: res.data.data });
            resolve(res.data.data);
          })
          .catch((err) => {
            ctx.commit("SET_STATE", { key: "isLoading", value: false });
            //eslint-disable-next-line
            console.log(err);
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    getUserProfessions: function (ctx, user) {
      ctx.commit("SET_STATE", { key: "isLoading", value: true });
      this.$http
        .get(`users/${user}/professions`)
        .then((res) => {
          ctx.commit("SET_STATE", { key: "data", value: res.data.data });
          ctx.commit("SET_STATE", { key: "isLoading", value: false });
        })
        .catch((err) => {
          ctx.commit("SET_STATE", { key: "isLoading", value: false });
          if (!err.accessDenied) {
            Swal.fire("Error", err.statusText, "error");
          }
        });
    },
    attachProfessionToUser: function (ctx, data) {
      return new Promise((resolve, reject) => {
        ctx.commit("SET_STATE", { key: "isSaving", value: true });
        let url = `users/${data.user}/professions`;
        if (data.id) {
          url = `users/${data.user}/professions/${data.id}`;
          delete data["id"];
          this.$http
            .put(url, data)
            .then(() => {
              Swal.fire({
                title: "",
                text: "Profession Updated Successfully",
                icon: "success",
              });
              ctx.commit("SET_STATE", { key: "isSaving", value: false });
              resolve(true);
            })
            .catch((err) => {
              ctx.commit("SET_STATE", { key: "isSaving", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "",
                  text: (err.data || {}).message || "Something went wrong...",
                  icon: "error",
                });
              }
              reject(err);
            });
        } else {
          this.$http
            .post(url, data)
            .then(() => {
              Swal.fire({
                title: "",
                text: "Profession Attached To The User Successfully",
                icon: "success",
              });
              ctx.commit("SET_STATE", { key: "isSaving", value: false });
              resolve(true);
            })
            .catch((err) => {
              ctx.commit("SET_STATE", { key: "isSaving", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "",
                  text:
                    ((err.response || {}).data || {}).message ||
                    (err.data || {}).message ||
                    err.message ||
                    "",
                  icon: "error",
                });
              }
              reject(err);
            });
        }
      });
    },
    detachProfessionFromUser: function (_, data) {
      return new Promise((resolve) => {
        this.$http
          .delete(`users/${data.user_id}/professions/${data.id}`)
          .then(() => {
            Swal.fire({
              title: "Success",
              text: "Profession Deleted Successfully",
              icon: "success",
            });
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || err.response.data).message,
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    attachProfessionToProvider: function (ctx, data) {
      return new Promise((resolve, reject) => {
        ctx.commit("SET_STATE", { key: "isSaving", value: true });
        let url = `providers/${data.user}/professions`;
        if (data.id) {
          url = `providers/${data.user}/professions/${data.id}`;
          delete data["id"];
          this.$http
            .put(url, data)
            .then(() => {
              Swal.fire({
                title: "",
                text: "Profession Updated Successfully",
                icon: "success",
              });
              ctx.commit("SET_STATE", { key: "isSaving", value: false });
              resolve(true);
            })
            .catch((err) => {
              ctx.commit("SET_STATE", { key: "isSaving", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "",
                  text: (err.data || {}).message || "Something went wrong...",
                  icon: "error",
                });
              }
              reject(err);
            });
        } else {
          this.$http
            .post(url, data)
            .then(() => {
              Swal.fire({
                title: "",
                text: "Profession Attached To Provider Successfully",
                icon: "success",
              });
              ctx.commit("SET_STATE", { key: "isSaving", value: false });
              resolve(true);
            })
            .catch((err) => {
              ctx.commit("SET_STATE", { key: "isSaving", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "",
                  text: (err.data || {}).message || "Something went wrong...",
                  icon: "error",
                });
              }
              reject(err);
            });
        }
      });
    },
  },
};
