<template>
  <sidebar :toggle="openTray" @close="close(true)">
    <loader v-if="openTray && actionInProgress" class="event-loader"></loader>
    <form validate @submit.prevent="handleBook" v-if="openTray">
      <div class="align-items-start d-flex">
        <h2>Book New Appointment</h2>
        <i
          class="fas fa-lg fa-user-secret ml-5"
          v-if="isHidden && user && (user.isAdmin || user.isManagingAdmin)"
        ></i>
      </div>

      <nav>
        <div class="nav nav-tabs" id="nav-create-tab" role="tablist">
          <a
            class="nav-item nav-link active"
            id="nav-home-create-tab"
            data-toggle="tab"
            href="#nav-create-home"
            role="tab"
            aria-controls="nav-create-home"
            aria-selected="true"
            >Details</a
          >
          <a
            class="nav-item nav-link"
            id="nav-memo-create-tab"
            data-toggle="tab"
            href="#nav-create-memo"
            role="tab"
            aria-controls="nav-create-memo"
            aria-selected="false"
            >Memo & Reason</a
          >
          <a
            v-if="user && !user.isPatient && booking.primary && isBillable"
            class="nav-item nav-link"
            id="nav-profile-create-tab"
            data-toggle="tab"
            href="#nav-create-profile"
            role="tab"
            aria-controls="nav-create-profile"
            aria-selected="false"
            >Sub Services</a
          >
          <a
            class="nav-item nav-link"
            id="nav-expenses-create-tab"
            data-toggle="tab"
            href="#nav-create-expenses"
            v-if="isBillable"
            role="tab"
            aria-controls="nav-create-expenses"
            aria-selected="false"
            >Expenses</a
          >
        </div>
      </nav>
      <div class="tab-content" id="nav-create-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-create-home"
          role="tabpanel"
          aria-labelledby="nav-home-create-tab"
        >
          <div class="row">
            <div class="col-12 row m-0 mb-3 mb-sm-0">
              <label>Is this billable or non-billable?</label>
              <div class="custom-control custom-switch ml-4 ml-sm-5 mr-sm-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isBillable"
                  v-model="isBillable"
                  @change="billableStatusChanged()"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="isBillable"
                  >{{ isBillable ? "Billable" : "Non Billable" }}</label
                >
              </div>
            </div>
            <div
              class="col-12 row m-0 mb-3 mb-sm-0"
              v-if="user && (user.isAdmin || user.isManagingAdmin)"
            >
              <label class="mr-5 pr-5"
                >Is this hidden?
                <i
                  class="fa fa-info-circle ml-2 pointer-cursor"
                  v-tooltip.top="
                    'Once the session is marked as hidden, only admins and managers can see it.'
                  "
                ></i>
              </label>
              <div class="custom-control custom-switch ml-4 ml-sm-5 mr-sm-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isHidden"
                  v-model="isHidden"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="isHidden"
                  >{{ isHidden ? "Hidden" : "Visible" }}</label
                >
              </div>
            </div>
            <div class="col-12">
              <label>Appointment Time</label>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <datetime
                      v-model="clickedEventDetails_startStr"
                      placeholder="DateTime"
                      :week-start="7"
                      input-class="col-md-12 form-control"
                      input-style="font-size: 12px;"
                      type="datetime"
                    >
                    </datetime>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <datetime
                      v-model="clickedEventDetails_endStr"
                      placeholder="DateTime"
                      :week-start="7"
                      input-class="col-md-12 form-control"
                      input-style="font-size: 12px;"
                      type="datetime"
                    >
                    </datetime>
                    <div
                      class="text-right pr-2"
                      :style="`opacity: ${
                        selectedMainServiceOnCreate &&
                        selectedMainServiceOnCreate.unit !== 'FT' &&
                        selectedMainServiceOnCreate.unit !== 'FLAT'
                          ? '1'
                          : '0'
                      }`"
                    >
                      <span
                        class="h4 text-primary pointer-cursor"
                        @click="setTimeToZero()"
                        >Set main service to zero hrs</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="form-group"
                v-if="user && (user.isAdmin || user.isManagingAdmin)"
              >
                <label>Provider/Admin</label>
                <multiselect
                  v-model="select.provider"
                  class="app-select no-labels"
                  :options="user1Options"
                  :preserve-search="true"
                  :searchable="true"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :allow-empty="false"
                  :show-labels="false"
                  placeholder="Search for Provider/Admin"
                  label="name"
                  @input="updateSelectedUser1($event.id)"
                >
                  <template slot="option" slot-scope="props">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex flex-column">
                        <div class="font-weight-bold select-label">
                          {{ props.option.name }}
                        </div>
                        <div class="small email-container" style="opacity: 0.9">
                          ({{ props.option.email }})
                        </div>
                      </div>
                      <div class="d-flex">
                        <div
                          class="small font-weight-bold select-sublabel"
                          style="opacity: 0.9"
                        >
                          ({{ props.option.type }})
                        </div>
                        <div
                          class="text-danger small ml-3"
                          v-if="props.option.$isDisabled"
                        >
                          Inactive
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #noResult>
                    <div class="no-result">Oops! No users found.</div>
                  </template>
                </multiselect>
                <div
                  class="
                    px-1
                    mb-2
                    pt-2
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <a
                    v-if="!isBillable"
                    href="#"
                    class="h4 mb-0"
                    @click.prevent="
                      updateSelectedUser1(
                        `${user.id}_${
                          user.isAdmin || user.isManagingAdmin
                            ? 'true'
                            : 'false'
                        }`
                      )
                    "
                    >Assign to me</a
                  >
                  <a
                    href="#"
                    v-if="booking.provider"
                    class="h4 mb-0 ml-auto"
                    @click.prevent="openUserAvailabilityModal(booking.provider)"
                    >Availability</a
                  >
                </div>
              </div>
              <div class="form-group">
                <label>{{ isBillable ? "Client" : "User/Client" }}</label>

                <multiselect
                  v-model="select.patient"
                  class="app-select no-labels"
                  :options="user2Options"
                  :preserve-search="true"
                  :searchable="true"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :allow-empty="false"
                  :show-labels="false"
                  :placeholder="`Search for ${
                    isBillable ? 'Client' : 'User/Client'
                  }`"
                  label="name"
                  @input="updateSelectedUser2($event.id)"
                >
                  <template slot="option" slot-scope="props">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex flex-column">
                        <div class="font-weight-bold select-label">
                          {{ props.option.name }}
                        </div>
                        <div class="small email-container" style="opacity: 0.9">
                          ({{ props.option.email }})
                        </div>
                      </div>
                      <div class="d-flex">
                        <div
                          class="small font-weight-bold select-sublabel"
                          style="opacity: 0.9"
                        >
                          ({{ props.option.type }})
                        </div>
                        <div
                          class="text-danger small ml-3"
                          v-if="props.option.$isDisabled"
                        >
                          Inactive
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #noResult>
                    <div class="no-result">Oops! No users found.</div>
                  </template>
                </multiselect>

                <div
                  class="
                    px-1
                    mb-2
                    pt-2
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <a
                    v-if="!isBillable"
                    href="#"
                    class="h4 mb-0"
                    @click.prevent="updateSelectedUser2(user.id)"
                    >Assign to me</a
                  >
                  <a
                    href="#"
                    class="h4 mb-0 ml-auto"
                    v-if="booking.patient"
                    @click.prevent="openUserAvailabilityModal(booking.patient)"
                    >Availability</a
                  >
                </div>
              </div>
              <div class="form-group">
                <label>Service</label>
                <multiselect
                  v-model="select.primary"
                  class="app-select no-labels"
                  :options="serviceOptions"
                  :preserve-search="true"
                  :searchable="true"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :allow-empty="false"
                  :show-labels="false"
                  placeholder="Search for Service"
                  label="name"
                  @input="updateSelectedService($event.id)"
                >
                  <template slot="option" slot-scope="props">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="font-weight-bold select-label">
                        {{ props.option.name }}
                      </div>
                      <div
                        class="small font-weight-bold select-sublabel"
                        v-if="props.option.type"
                        style="opacity: 0.9"
                      >
                        ({{ props.option.type }})
                      </div>
                    </div>
                  </template>
                  <template #noResult>
                    <div class="no-result">Oops! No services found.</div>
                  </template>
                </multiselect>
                <div
                  class="w-100 text-right"
                  v-if="
                    user && !user.isPatient && booking.primary && isBillable
                  "
                >
                  <a
                    href="#nav-create-profile"
                    class="h4 mr-1"
                    @click.prevent="selectTab('#nav-profile-create-tab')"
                    >Add sub service</a
                  >
                </div>
              </div>

              <input-control
                control="number"
                v-model="booking.provider_rate"
                type="vertical"
                step="0.1"
                min="0"
                >Provider Rate</input-control
              >

              <div class="form-group">
                <label>Appointment Type</label>
                <select
                  required
                  v-model="booking.type"
                  class="form-control"
                  @change="showHideAutoVirtualMeeting($event.target.value)"
                >
                  <option
                    v-for="p in serviceTypes"
                    :key="p.value"
                    :value="p.value"
                  >
                    {{ p.label }}
                  </option>
                </select>
              </div>

              <div class="form-group" v-if="showAutoScheduleOption">
                <label>Auto-Schedule Virtual Meeting</label>
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    v-model="booking.auto_schedule_zoom"
                    id="customCheck1"
                  />
                  <label class="custom-control-label" for="customCheck1"
                    >Yes, schedule a virtual meeting</label
                  >
                </div>
              </div>
            </div>
            <div
              class="col-12 mb-2"
              v-if="
                user &&
                (user.isAdmin ||
                  user.isManagingAdmin ||
                  (currentProvider && currentProvider.can_verify_event))
              "
            >
              <input
                type="checkbox"
                id="verifyBookEvent"
                v-model="verifyBookEvent"
              />
              <label for="verifyBookEvent" class="pointer-cursor ml-2"
                >Verify This Event</label
              >
            </div>
            <div class="col-12 mb-2">
              <input type="checkbox" id="allowOverlap" v-model="allowOverlap" />
              <label
                for="allowOverlap"
                class="pointer-cursor ml-2 text-dark-red"
                >Allow Overlapping With Other Sessions</label
              >
            </div>
            <div class="col-12">
              <button class="btn btn-block btn-black" type="submit">
                Book
              </button>
            </div>
          </div>
        </div>
        <!-- end of first tab -->

        <div
          class="tab-pane fade"
          id="nav-create-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-create-tab"
        >
          <label class="serviceName"
            >{{
              this.booking.primary != null
                ? ""
                : "To add sub services, please select a Service first"
            }}
          </label>
          <div
            v-if="
              clickedEventDetails_services != null &&
              clickedEventDetails_services.length != 0
            "
          >
            <span style="color: #f00; font-size: 12px"
              >* Only the selected Sub Service will be submitted</span
            >

            <div
              class="subServiceItem col-md-12"
              v-for="subService in clickedEventDetails_services"
              :key="subService.id"
            >
              <div class="row mb-2 align-items-center">
                <input
                  class="form-control pointer-cursor checkbox-input"
                  type="checkbox"
                  :id="`${subService.name}_${subService.id}`"
                  v-model="subService.selected"
                  :disabled="subService.is_default == true"
                />
                <label
                  :for="`${subService.name}_${subService.id}`"
                  class="pointer-cursor ml-2 mb-0"
                  >Selected <span style="color: #f00">*</span></label
                >
              </div>

              <div
                class="subServiceItemContainer"
                :class="
                  subService.something == false ? 'disabledSubService1' : ''
                "
              >
                <h3 class="SubServiceTitle">{{ subService.name }}</h3>

                <div class="row mr-0">
                  <div class="col-md-4" v-if="user && !user.isProvider">
                    <input-control
                      type="vertical"
                      v-model="subService.rate"
                      control="number"
                      step="0.01"
                      disabled
                      >Rate</input-control
                    >
                  </div>

                  <div
                    class="mt-3 mt-md-0"
                    :class="`${
                      user && user.isProvider ? 'col-md-6' : 'col-md-4'
                    }`"
                  >
                    <input-control
                      type="vertical"
                      v-model="subService.qty"
                      :required="subService.selected"
                      :disabled="
                        subService.unit == 'FT' || subService.unit == 'FLAT'
                      "
                      step="0.01"
                      control="number"
                      >Quantity</input-control
                    >
                  </div>

                  <div
                    class="mt-3 mt-md-0"
                    :class="`${
                      user && user.isProvider ? 'col-md-6' : 'col-md-4'
                    }`"
                  >
                    <select-control
                      type="vertical"
                      v-model="subService.unit"
                      labelClass="mr-0"
                      disabled
                      :options="units"
                      >Unit</select-control
                    >
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <input-control
                      type="vertical"
                      rows="6"
                      v-model="subService.description"
                      maxLength="500"
                      control="textarea"
                      >Description
                    </input-control>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 text-center mb-5">
            <a
              class="btn btn-black"
              href="#nav-create-home"
              @click.prevent="selectTab('#nav-home-create-tab')"
              >Back To Details</a
            >
          </div>
        </div>
        <!-- end of sub services tab-->
        <div
          class="tab-pane fade"
          id="nav-create-memo"
          role="tabpanel"
          aria-labelledby="nav-memo-create-tab"
        >
          <div class="form-group" v-if="user && !user.isPatient">
            <label
              >Memo
              <i
                class="fa fa-info-circle ml-2 pointer-cursor"
                v-tooltip.top="'Seen in billing & payroll reports'"
              ></i
            ></label>
            <textarea
              class="form-control"
              v-model="booking.memo"
              rows="5"
            ></textarea>
          </div>
          <div class="form-group">
            <label>Reason - seen by client</label>
            <textarea
              class="form-control"
              v-model="booking.reason"
              rows="5"
            ></textarea>
          </div>
          <div class="w-100 text-center mb-5">
            <a
              class="btn btn-black"
              href="#nav-create-home"
              @click.prevent="selectTab('#nav-home-create-tab')"
              >Back To Details</a
            >
          </div>
        </div>
        <!-- end of memo and reason tab-->
        <div
          class="tab-pane fade"
          id="nav-create-expenses"
          role="tabpanel"
          aria-labelledby="nav-expenses-create-tab"
        >
          <div class="form-group">
            <input-control
              control="number"
              v-model="booking.expenses"
              type="vertical"
              step="0.01"
              >Expenses $</input-control
            >
            <input-control
              type="vertical"
              rows="6"
              v-model="booking.billing_details"
              control="textarea"
              >Billing Details
            </input-control>
          </div>
          <div class="w-100 text-center mb-5">
            <a
              class="btn btn-black"
              href="#nav-create-home"
              @click.prevent="selectTab('#nav-home-create-tab')"
              >Back To Details</a
            >
          </div>
        </div>
        <!-- end of expenses tab-->
      </div>
      <!-- end of tabs cotainers -->
    </form>
  </sidebar>
</template>
<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Datetime } from "vue-datetime";
import Multiselect from "vue-multiselect";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "create-calendar-session",
  components: {
    datetime: Datetime,
    Multiselect,
  },
  props: {
    profilePatientUserId: Number,
    profileProviderUserId: Number,
    users: Array,
    patients: Array,
    providers: Array,
    nonBillableServices: Array,
    currentProvider: Object,
    parentServicesTemp: Array,
    services: Array,
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      openTray: false,
      actionInProgress: false,
      isHidden: false,
      showAutoScheduleOption: false,
      isBillable: true,
      parentServices: [],
      booking: {
        start: null,
        end: null,
        provider: null,
        patient: null,
        primary: null,
        provider_rate: 0.0,
        type: "",
      },
      select: {
        provider: null,
        patient: null,
        primary: null,
      },
      clickedEventDetails_startStr: "",
      clickedEventDetails_endStr: "",
      clickedEventDetails_service: null,
      clickedEventDetails_services: [],
      serviceTypes: [
        { value: "VIRTUAL", label: "Virtual" },
        { value: "IN-PERSON", label: "In-Person" },
      ],
      units: [
        { value: "HR", label: "Hourly" },
        { value: "KM", label: "Kilometers" },
        { value: "FLAT", label: "Flat Rate" },
        { value: "FT", label: "Flat Rate" },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    selectedMainServiceOnCreate() {
      if (this.booking.primary) {
        return this.parentServices.filter(
          (serv) => serv.id == this.booking.primary
        )[0];
      }
      return {};
    },
    displayedPatients() {
      if (this.isBillable) {
        if (
          (this.booking.provider || "").toString().includes("_") &&
          `${this.booking.provider}`.split("_")[1] == "true"
        ) {
          return this.sortAlphabetically(this.patients);
        }
        const selectedProvider = this.providers.filter(
          (item) => item.user_id == `${this.booking.provider}`.split("_")[0]
        )[0];
        if (selectedProvider) {
          return this.sortAlphabetically(
            this.patients.filter((patient) =>
              (selectedProvider.patients || []).includes(patient.id)
            )
          );
        }
        return this.sortAlphabetically(this.patients);
      }
      return this.sortAlphabetically(this.users);
    },
    user1Options() {
      const displayedOptions = [];

      if (this.options.adminUsers.length) {
        const adminUsers = this.options.adminUsers.map((p) => ({
          id: `${p.id}_true`,
          name: `${p.last}, ${p.first}`,
          $isDisabled: p.status === "INACTIVE",
          type: "Admin",
          email: p.email,
        }));
        displayedOptions.push(...adminUsers);
      }

      if (this.options.providerUsers.length) {
        const providerUsers = this.options.providerUsers.map((p) => ({
          id: `${p.id}_false`,
          name: `${p.last}, ${p.first}`,
          disabled: p.status === "INACTIVE",
          type: "Provider",
          email: p.email,
        }));
        displayedOptions.push(...providerUsers);
      }
      return displayedOptions;
    },
    user2Options() {
      const displayedOptions = [];

      if (this.isBillable) {
        const patientUsers = this.displayedPatients.map((p) => ({
          id: p.user_id || p.id,
          name: p.name ? p.name : `${p.last}, ${p.first}`,
          disabled: p.status === "INACTIVE",
          type: "Client",
          email: p.email,
        }));
        displayedOptions.push(...patientUsers);
      } else {
        if (this.user && (this.user.isAdmin || this.user.isManagingAdmin)) {
          const adminUsers = this.options.adminUsers.map((p) => ({
            id: p.id,
            name: `${p.last}, ${p.first}`,
            $isDisabled: p.status === "INACTIVE",
            type: "Admin",
            email: p.email,
          }));
          displayedOptions.push(...adminUsers);
        }

        if (this.options.providerUsers.length) {
          const providerUsers = this.options.providerUsers.map((p) => ({
            id: p.id,
            name: `${p.last}, ${p.first}`,
            disabled: p.status === "INACTIVE",
            type: "Provider",
            email: p.email,
          }));
          displayedOptions.push(...providerUsers);
        }
        if (this.options.patientUsers.length) {
          const patientUsers = this.options.patientUsers.map((p) => ({
            id: p.user_id || p.id,
            name: `${p.last}, ${p.first}`,
            disabled: p.status === "INACTIVE",
            type: "Client",
            email: p.email,
          }));
          displayedOptions.push(...patientUsers);
        }
      }
      return displayedOptions;
    },
    serviceOptions() {
      const displayedOptions = this.parentServices.map((p) => ({
        id: p.id,
        name: p.name,
        type: p.catastrophic ? "CAT" : "",
      }));
      return displayedOptions;
    },
  },
  methods: {
    updateSelectedUser1(id) {
      this.select.provider = this.user1Options.filter(
        (user) => user.id == id
      )[0];
      this.booking.provider = id;
      this.showServicesBasedOnSelectedProvider(id);
      this.updateProviderRate(id, this.booking.primary);
    },
    updateSelectedUser2(id) {
      this.select.patient = this.user2Options.filter(
        (user) => user.id == id
      )[0];
      this.booking.patient = id;
      this.showServicesBasedOnSelectedProvider(this.booking.provider);
    },
    updateSelectedService(id) {
      this.select.primary = this.serviceOptions.filter(
        (service) => service.id == id
      )[0];
      this.booking.primary = id;
      this.getSubServicesByParentServiceId(id);
    },
    showHideAutoVirtualMeeting(eventType) {
      if (eventType == "VIRTUAL") {
        this.showAutoScheduleOption = true;
      } else {
        this.showAutoScheduleOption = false;
      }
    },
    sortAlphabetically(arrayInput) {
      const arraySrc = new Array(...arrayInput);
      const result = arraySrc.sort((a, b) => {
        if (`${a.last} ${a.first}` < `${b.last} ${b.first}`) {
          return -1;
        }
        if (`${a.last} ${a.first}` > `${b.last} ${b.first}`) {
          return 1;
        }
        return 0;
      });
      return result;
    },
    openUserAvailabilityModal: function (userId) {
      this.$emit("openUserAvailability", userId);
    },
    selectTab(id) {
      window.$(id).trigger("click");
    },
    close: function (noRefetch) {
      if (!noRefetch) {
        this.$emit("refetchCalendar");
      }
      this.openTray = false;
    },
    setTimeToZero: function () {
      this.clickedEventDetails_endStr = this.clickedEventDetails_startStr;
    },
    billableStatusChanged: function () {
      if (!this.isBillable) {
        this.booking.provider = null;
        this.select.provider = null;
        this.booking.patient = null;
        this.select.patient = null;
        this.booking.provider_rate = 0.0;
        if (this.user && this.user.isProvider) {
          this.booking.provider = this.user.id;
          this.select.provider = this.user1Options.filter(
            (user) => user.id == this.booking.provider
          )[0];
        }
        if (this.profilePatientUserId) {
          this.booking.patient = this.profilePatientUserId;
          this.select.patient = this.user2Options.filter(
            (user) => user.id == this.booking.patient
          )[0];

          this.booking.provider = `${this.user.id}_${
            this.user.isAdmin || this.user.isManagingAdmin ? "true" : "false"
          }`;
          this.select.provider = this.user1Options.filter(
            (user) => user.id == this.booking.provider
          )[0];
          this.showServicesBasedOnSelectedProvider(this.booking.provider);
          this.updateProviderRate(this.booking.provider, this.booking.primary);
        } else {
          this.showServicesBasedOnSelectedProvider(this.booking.provider);
          this.updateProviderRate(this.booking.provider, this.booking.primary);
        }
        this.showServicesBasedOnSelectedProvider(this.booking.provider);
      } else {
        this.booking.provider = null;
        this.select.provider = null;
        this.booking.patient = null;
        this.select.patient = null;
        if (this.user && this.user.isProvider) {
          this.booking.provider = this.user.id;
          this.select.provider = this.user1Options.filter(
            (user) => user.id == this.booking.provider
          )[0];
        }
        if (this.profilePatientUserId) {
          this.booking.patient = this.profilePatientUserId;
          this.select.patient = this.user2Options.filter(
            (user) => user.id == this.booking.patient
          )[0];
          this.booking.provider = `${this.user.id}_${
            this.user.isAdmin ? "true" : "false"
          }`;
          this.select.provider = this.user1Options.filter(
            (user) => user.id == this.booking.provider
          )[0];
          this.showServicesBasedOnSelectedProvider(this.booking.provider);
          this.updateProviderRate(this.booking.provider, this.booking.primary);
        } else {
          this.showServicesBasedOnSelectedProvider(this.booking.provider);
          this.updateProviderRate(this.booking.provider, this.booking.primary);
        }
        this.showServicesBasedOnSelectedProvider(this.booking.provider);
      }
    },
    showServicesBasedOnSelectedProvider(selectedUserObj) {
      let selectedProviderId = selectedUserObj;
      let isAdmin = false;
      if (selectedUserObj != null && selectedUserObj.toString().includes("_")) {
        selectedProviderId = selectedUserObj.split("_")[0];
        if (selectedUserObj.split("_")[1] == "true") {
          isAdmin = true;
        }
      }

      if (this.isBillable) {
        this.updateSelectedService(0);
      } else {
        this.updateSelectedService((this.mainNonBillableService || {}).id);
      }
      let vm = this;
      if (!vm.isBillable && vm.nonBillableServices.length) {
        vm.parentServices = vm.nonBillableServices;
        return;
      }

      let selectedUser = this.users.filter(
        (item) => item.id == selectedProviderId
      )[0];

      if (!isAdmin && !((selectedUser || {}).professions || []).length) {
        selectedUser = this.providers.filter(
          (item) => item.user_id == selectedProviderId
        )[0];
      }

      //show error message
      if (
        !selectedUser ||
        selectedUser.isAdmin ||
        selectedUser.isManagingAdmin
      ) {
        vm.parentServices = vm.parentServicesTemp.filter(
          (serv) => serv.status !== "INACTIVE"
        );
        if (this.booking.patient) {
          const selectedPatient = this.patients.filter(
            (patient) => patient.user_id == this.booking.patient
          )[0];
          if (selectedPatient && selectedPatient.services.length) {
            vm.parentServices = vm.parentServices.filter((serv) =>
              selectedPatient.services.includes(serv.id)
            );
          }
        }
        return;
      } else if (
        vm.isBillable &&
        (selectedUser.professions || []).length == 0
      ) {
        vm.parentServices = [];

        return Swal.fire({
          title: "Warning",
          html:
            this.user && this.user.isProvider
              ? "There are no services/professions attached to you yet. Please update you profession before proceeding."
              : "The selected healthcare provider/admin does not have a profession assigned to their profile. Please update their profession before proceeding.",
          icon: "warning",
        });
      }
      if (vm.isBillable) {
        // show all parent services that has the provider profession
        vm.parentServices = [];
        selectedUser.professions.forEach(function (profession) {
          vm.parentServicesTemp.forEach(function (item) {
            if (item.profession_id == profession.id) {
              vm.parentServices.push(item);
            } else if (
              vm.clickedEventDetails_service &&
              item.id == vm.clickedEventDetails_service?.pivot?.service_id
            ) {
              vm.parentServices.push({ ...item, status: "INACTIVE" });
            }
          });
        });
        if (this.booking.patient) {
          const selectedPatient = this.patients.filter(
            (patient) => patient.user_id == this.booking.patient
          )[0];
          if (selectedPatient && selectedPatient.services.length) {
            vm.parentServices = vm.parentServices.filter(
              (serv) =>
                selectedPatient.services.includes(serv.id) ||
                serv.id == this.clickedEventDetails_service?.pivot?.service_id
            );
          }
        }
        vm.parentServices = vm.parentServices.filter(
          (serv) => serv.status !== "INACTIVE"
        );
      }
    },
    getSubServicesByParentServiceId(parentServiceID) {
      this.updateProviderRate(this.booking.provider, parentServiceID);
      var eventSubServices = this.services.filter(function (item) {
        if (item.is_default) {
          item.selected = true;
          item.qty = 1;
          return item;
        }
        if (item.unit == "FT" || item.unit == "FLAT") {
          item.qty = 1;
        }

        if (item.parent_service_id == parentServiceID) {
          item.selected = false;
          return item;
        }
      });

      this.clickedEventDetails_services = JSON.parse(
        JSON.stringify(eventSubServices)
      );
      if (this.openTray) {
        this.clickedEventDetails_services =
          this.clickedEventDetails_services.filter(
            (sub) => sub.status !== "INACTIVE"
          );
      }
    },
    updateProviderRate: function (providerId_Type, serviceId) {
      let isAdmin = false;
      let providerId = providerId_Type;
      if (providerId_Type != null) {
        const providerSplitValue = `${providerId_Type}`.split("_");

        if (providerSplitValue != null) {
          providerId = `${providerId_Type}`.split("_")[0];
          if (`${providerId_Type}`.split("_")[1] == "true") {
            isAdmin = true;
          }
        }
      }

      if (providerId && serviceId) {
        const selectedService = this.services.filter(
          (service) => service.id == serviceId
        )[0];

        let selectedProvider = this.users.filter(
          (user) => user.id == providerId
        )[0];

        if (!isAdmin && !((selectedProvider || {}).professions || []).length) {
          selectedProvider = this.providers.filter(
            (item) => item.user_id == providerId
          )[0];
        }
        if (!selectedProvider) {
          return;
        }
        const providerProfession = (selectedProvider.professions || []).filter(
          (profession) => profession.id == selectedService.profession_id
        )[0];
        if (providerProfession) {
          this.booking.provider_rate = providerProfession.rate;
        }
      }
    },
    initBooking: function (arg) {
      if (this.user && this.user.isProvider && !this.patients.length) {
        return Swal.fire({
          title: "Warning",
          text: "Can't create a session without having at least 1 client assigned to you.",
          icon: "warning",
        });
      }
      if (this.user && !this.user.isPatient) {
        this.booking.start = arg.start;
        this.booking.end = arg.end;
        const difference =
          dayjs(this.booking.end).diff(dayjs(this.booking.start)) /
          (1000 * 60 * 60 * 24);
        if (difference == 1) {
          this.clickedEventDetails_startStr = dayjs(this.booking.start)
            .hour(9)
            .format();
          this.clickedEventDetails_endStr = dayjs(this.booking.start)
            .hour(10)
            .format();
        } else {
          this.clickedEventDetails_startStr = dayjs(
            this.booking.start
          ).format();
          this.clickedEventDetails_endStr = dayjs(this.booking.end).format();
        }
        this.updateSelectedService(null);
        this.isBillable = true;

        if (this.user.isProvider || this.profileProviderUserId) {
          this.booking.provider = this.profileProviderUserId || this.user.id;
          this.select.provider = this.user1Options.filter(
            (user) =>
              user.id == `${this.booking.provider}_false` ||
              user.id == this.booking.provider
          )[0];
          this.showServicesBasedOnSelectedProvider(this.user.id + "_false");
        }
        if (this.profilePatientUserId) {
          this.booking.patient = this.profilePatientUserId;
          this.select.patient = this.user2Options.filter(
            (user) => user.id == this.booking.patient
          )[0];

          this.booking.provider = `${this.user.id}_${
            this.user.isAdmin || this.user.isManagingAdmin ? "true" : "false"
          }`;
          this.select.provider = this.user1Options.filter(
            (user) => user.id == this.booking.provider
          )[0];
          this.showServicesBasedOnSelectedProvider(this.booking.provider);
          this.updateProviderRate(this.booking.provider, this.booking.primary);
        } else {
          this.showServicesBasedOnSelectedProvider(this.booking.provider);
        }
        this.getSubServicesByParentServiceId(this.booking.primary);
        this.verifyBookEvent = false;
        this.allowOverlap = false;
        this.openTray = true;
      }
    },
    canHaveClinicalNotes: function () {
      return this.isBillable;
    },
    approveNewEvent: function (id) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      if (this.canHaveClinicalNotes() || this.user.isProvider) {
        let text = "You haven't created a clinical note for this event yet!";
        if (this.user.isProvider) {
          text =
            "Once verified, you will not be able to undo this action without the assistance of the file administrator";
          if (this.canHaveClinicalNotes()) {
            text =
              "You haven't created a clinical note for this event yet!<br><br>Once verified, you will not be able to undo this action without the assistance of the file administrator";
          }
        }
        return swalWithBootstrapButtons
          .fire({
            title: "Are you sure you want to verify?",
            html: `<p class="text-left">${text}</p>`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, verify!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              vm.sendApproveEventRequest(id);
            } else if (id) {
              this.close();
            }
          });
      } else {
        vm.sendApproveEventRequest(id);
      }
    },
    handleBook: function () {
      if (!this.booking.provider) {
        Swal.fire({
          title: "Error",
          text: `Provider/Admin field is required!`,
          icon: "error",
        });
        return Promise.resolve(false);
      }
      if (this.isBillable && !this.booking.patient) {
        Swal.fire({
          title: "Error",
          text: `Client field is required!`,
          icon: "error",
        });
        return Promise.resolve(false);
      }
      if (!this.booking.primary) {
        Swal.fire({
          title: "Error",
          text: `Service field is required!`,
          icon: "error",
        });
        return Promise.resolve(false);
      }
      const canadaOffset = dayjs
        .tz(new Date(this.clickedEventDetails_startStr), "America/Toronto")
        .utcOffset();
      const sessionTimeDiff = dayjs(this.clickedEventDetails_endStr).diff(
        this.clickedEventDetails_startStr,
        "s"
      );
      if (sessionTimeDiff < 0) {
        this.selectTab("#nav-home-create-tab");
        Swal.fire({
          title: "Error",
          text: `Session end time is earlier than start time!`,
          icon: "error",
        });
        return Promise.resolve(false);
      } else if (
        sessionTimeDiff == 0 &&
        (this.selectedMainServiceOnCreate.unit == "FT" ||
          this.selectedMainServiceOnCreate.unit == "FLAT")
      ) {
        this.selectTab("#nav-home-tab");
        Swal.fire({
          title: "Error",
          text: `Session end time is equal to start time!`,
          icon: "error",
        });
        return Promise.resolve(false);
      }
      let data = {
        // We need to store unified date in server with GTM+0
        // Then we have to use dayjs to format the time to match browser time zone.
        start_time: dayjs(this.clickedEventDetails_startStr)
          .add(dayjs(this.clickedEventDetails_startStr).utcOffset(), "minute")
          .add(canadaOffset, "minute")
          .utc()
          .format(),
        end_time: dayjs(this.clickedEventDetails_endStr)
          .add(dayjs(this.clickedEventDetails_endStr).utcOffset(), "minute")
          .add(canadaOffset, "minute")
          .utc()
          .format(),
        user_2: this.booking.patient,
        // If provider then set user Id
        user_1: this.user.isProvider
          ? this.user.id
          : parseInt(`${this.booking.provider}`.split("_")[0]),
        memo: this.booking.memo,
        type: this.booking.type,
        primary: this.booking.primary,
        reason: this.booking.reason,
        expenses: this.isBillable ? this.booking.expenses : 0,
        billing_details: this.isBillable ? this.booking.billing_details : "",
        provider_rate: this.booking.provider_rate,
        auto_schedule_zoom: this.booking.auto_schedule_zoom,
        virtual_url: "To Create", // Show popup on checkbox is checked.TODO
        billable: this.isBillable,
        allow_overlap: this.allowOverlap,
        hidden: this.isHidden,
      };

      data.services = this.clickedEventDetails_services.filter(
        (item) => item.selected == true
      );

      let hasEmptyQuantity = false;
      let hasNegativeQuantity = false;
      data.services.forEach((service) => {
        if (!service.qty) {
          hasEmptyQuantity = true;
        } else if (service.qty < 0) {
          hasNegativeQuantity = true;
        }
      });
      if (hasEmptyQuantity || hasNegativeQuantity) {
        this.selectTab("#nav-profile-create-tab");
        return Swal.fire({
          title: "Error",
          text: hasEmptyQuantity
            ? "Quantity in selected sub services is required!"
            : "Quantity in selected sub services can't be negative!",
          icon: "error",
        });
      }
      if (data.expenses && data.expenses < 0) {
        this.selectTab("#nav-expenses-create-tab");
        Swal.fire({
          title: "Error",
          text: "Expenses can't be negative!",
          icon: "error",
        });
        return Promise.resolve(false);
      }

      if (sessionTimeDiff == 0) {
        const vm = this;
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black ml-4",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You have set the main service to 0 hours, are you sure you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, sure!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              return vm.handleCreateBookWithRateCheck(data);
            }
          });
      } else {
        return this.handleCreateBookWithRateCheck(data);
      }
    },
    handleCreateBookWithRateCheck: function (data) {
      if (
        data.billable &&
        (!data.provider_rate || parseFloat(data.provider_rate) == 0)
      ) {
        const vm = this;
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black ml-4",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "Provider Rate is zero or empty, are you sure you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, sure!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              vm.actionInProgress = true;
              vm.$http
                .post("appointments", data)
                .then(
                  function (res) {
                    vm.actionInProgress = false;
                    if (res && vm.verifyBookEvent) {
                      vm.approveNewEvent(res.data.data.id);
                    } else if (res) {
                      this.$emit("refetchCalendar");
                      vm.close();
                    }
                  }.bind(vm)
                )
                .catch((err) => {
                  vm.actionInProgress = false;
                  if (!err.accessDenied) {
                    Swal.fire({
                      title: "Issue While Creating the Appointment",
                      text:
                        ((err.response || {}).data || {}).message ||
                        (err.data || {}).message ||
                        "Something went wrong...",
                      icon: "error",
                    });
                  }
                });
            }
          });
      } else {
        this.actionInProgress = true;
        this.$http
          .post("appointments", data)
          .then(
            function (res) {
              this.actionInProgress = false;
              if (res && this.verifyBookEvent) {
                this.approveNewEvent(res.data.data.id);
              } else if (res) {
                this.$emit("refetchCalendar");
                this.close();
              }
            }.bind(this)
          )
          .catch((err) => {
            this.actionInProgress = false;
            if (!err.accessDenied) {
              Swal.fire({
                title: "Issue While Creating the Appointment",
                text:
                  ((err.response || {}).data || {}).message ||
                  (err.data || {}).message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
          });
      }
    },
    sendApproveEventRequest: function (id) {
      this.actionInProgress = true;
      this.$http
        .put(`appointments/${id}/verify`)
        .then(
          function (res) {
            this.actionInProgress = false;
            if (res) {
              Swal.fire({
                title: "Appointment Verified",
                text: "Appointment Verified Successfully",
                icon: "success",
              });
              this.close();
            }
          }.bind(this)
        )
        .catch((err) => {
          this.false = true;
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text: (err.data || {}).message || "Something went wrong...",
              icon: "error",
            });
          }
          this.openUpdateTray = false;
        });
    },
  },
};
</script>

<style lang="scss">
.checkbox-input {
  box-shadow: 0 0 0 1px #060c1285;
  transform: scale(0.45);
  width: 40px;
  margin-top: -5px;
}

.loader {
  &.event-loader {
    width: 500px;
    right: 0;
    top: 0;
  }
}
</style>
