<template>
  <div>
    <button
      class="btn btn-outline-blue mb-3"
      @click="addNewClinic"
      v-if="$can({ key: 'clinics', expectedPermission: 'edit' })"
    >
      <icon type="plus" /> Add Clinic
    </button>

    <alert v-if="isLoading" />
    <alert
      :hideLoader="true"
      class="my-4"
      v-if="!isLoading && provider != null && clinics.length == 0"
    >
      No Clinics Attached</alert
    >
    <div
      class="card table-container"
      v-if="provider != null && provider.user != null && clinics.length > 0"
    >
      <table class="table table-striped mb-0">
        <thead>
          <tr>
            <th>Name</th>
            <th
              v-if="
                user &&
                (user.isAdmin || user.isManagingAdmin) &&
                $can({ key: 'clinics', expectedPermission: 'edit' })
              "
            >
              detach
            </th>
            <th class="desktop-only"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="clinic in clinics" :key="clinic.id">
            <td>{{ clinic.name }}</td>

            <td v-if="$can({ key: 'clinics', expectedPermission: 'edit' })">
              <span class="ml-2 text-danger pointer-cursor"
                ><i
                  v-if="!isSaving"
                  class="fas fa-unlink"
                  @click="detachSelectedClinic($event, clinic.id)"
                ></i>
                <i class="fa fa-spin fa-circle-notch" v-if="isSaving"></i>
              </span>
            </td>
            <td class="desktop-only"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "provider-clinics",
  data() {
    return {
      providerId: this.$route.params.id,
      isLoading: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      provider: (state) => state.providers.provider,
      clinics: (state) => state.providers.provider.user.clinics || [],
    }),
  },

  methods: {
    ...mapActions({
      detach: "clinics/detachClinicFromUser",
      getProviderData: "providers/get",
    }),
    addNewClinic: function () {
      this.$router.push({
        name: "providers.clinics.create",
        params: { id: this.providerId },
        query: { src: "clinics" },
      });
    },

    detachSelectedClinic: function ($event, id) {
      $event.preventDefault();
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, detach it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            vm.isSaving = true;
            vm.detach({ id: id, user: vm.provider.user_id }).then((detach) => {
              vm.isSaving = false;
              if (detach) {
                this.getProviderData({ id: vm.providerId });
              } else {
                swalWithBootstrapButtons.fire(
                  "Error!",
                  "Something went wrong...",
                  "error"
                );
              }
            });
          }
        });
    },
  },
};
</script>
