<template>
  <div class="calendar-filters noPrint">
    <div
      class="customCalendarFilter"
      v-if="
        !profileProviderUserId && !profilePatientUserId && !profileAdminUserId
      "
    >
      <div class="row noPrint">
        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
          v-if="user && !user.isPatient"
        >
          <multiselect
            v-model="filter.classification"
            class="app-select"
            :options="options.classes"
            :preserve-search="true"
            :searchable="true"
            :close-on-select="true"
            :clear-on-select="true"
            :allow-empty="true"
            :show-labels="true"
            track-by="id"
            placeholder="Seach for Class"
            label="label"
            @input="applyFilter()"
          >
            <template slot="option" slot-scope="props">
              <div class="d-flex align-items-center justify-content-start">
                <div class="font-weight-bold select-label">
                  {{ props.option.label }}
                </div>
              </div>
            </template>
            <template #noResult>
              <div class="no-result">Oops! No classes found.</div>
            </template>
          </multiselect>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
          <multiselect
            v-if="user && !user.isPatient"
            v-model="filter.Patient"
            class="app-select"
            :options="patientFilterOptions"
            :preserve-search="true"
            :searchable="true"
            :close-on-select="true"
            :clear-on-select="true"
            :allow-empty="true"
            :show-labels="true"
            track-by="id"
            placeholder="Search for User/Client"
            label="name"
            @input="applyFilter()"
          >
            <template slot="option" slot-scope="props">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex flex-column">
                  <div class="font-weight-bold select-label">
                    {{ props.option.name }}
                  </div>
                  <div class="small email-container" style="opacity: 0.9">
                    ({{ props.option.email }})
                  </div>
                </div>
                <div class="d-flex">
                  <div
                    class="small font-weight-bold select-sublabel"
                    style="opacity: 0.9"
                  >
                    ({{ props.option.type }})
                  </div>
                </div>
              </div>
            </template>
            <template #noResult>
              <div class="no-result">Oops! No users found.</div>
            </template>
          </multiselect>

          <multiselect
            v-if="user && user.isPatient"
            v-model="filter.Provider"
            class="app-select"
            :options="patientProvidersOptions"
            :preserve-search="true"
            :searchable="true"
            :close-on-select="true"
            :clear-on-select="true"
            :allow-empty="true"
            :show-labels="true"
            track-by="id"
            placeholder="Search for Provider"
            label="name"
            @input="applyFilter()"
          >
            <template slot="option" slot-scope="props">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex flex-column">
                  <div class="font-weight-bold select-label">
                    {{ props.option.name }}
                  </div>
                  <div class="small email-container" style="opacity: 0.9">
                    ({{ props.option.email }})
                  </div>
                </div>
              </div>
            </template>
            <template #noResult>
              <div class="no-result">Oops! No providers found.</div>
            </template>
          </multiselect>
        </div>

        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
          v-if="user && (user.isAdmin || user.isManagingAdmin)"
        >
          <multiselect
            v-if="user && !user.isPatient"
            v-model="filter.Provider"
            class="app-select"
            :options="providerFilterOptions"
            :preserve-search="true"
            :searchable="true"
            :close-on-select="true"
            :clear-on-select="true"
            :allow-empty="true"
            :show-labels="true"
            track-by="id"
            placeholder="Search for Provider/Admin"
            label="name"
            @input="applyFilter()"
          >
            <template slot="option" slot-scope="props">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex flex-column">
                  <div class="font-weight-bold select-label">
                    {{ props.option.name }}
                  </div>
                  <div class="small email-container" style="opacity: 0.9">
                    ({{ props.option.email }})
                  </div>
                </div>
                <div class="d-flex">
                  <div
                    class="small font-weight-bold select-sublabel"
                    style="opacity: 0.9"
                  >
                    ({{ props.option.type }})
                  </div>
                </div>
              </div>
            </template>
            <template #noResult>
              <div class="no-result">Oops! No users found.</div>
            </template>
          </multiselect>
        </div>

        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
          v-if="user && !user.isPatient"
        >
          <select
            v-model="filter.billableStatus"
            class="form-control light-shadow"
            @change="applyFilter()"
          >
            <option value="" selected>Filter by Billable Status</option>

            <option v-for="p in billableStatuses" :key="p.id" :value="p.id">
              {{ p.label }}
            </option>
          </select>
        </div>
        <button
          v-if="user && (user.isAdmin || user.isManagingAdmin)"
          class="btn btn-theme ml-3 mb-3"
          @click="openBulkSessionsModal()"
        >
          Verify/Unverify Appointments
        </button>
        <button
          v-if="user && (user.isAdmin || user.isManagingAdmin)"
          class="btn btn-1a3f3f ml-3 mb-3"
          @click="openUsersAvailability()"
        >
          Users Availability
        </button>
      </div>
    </div>
    <div
      class="customCalendarFilter d-flex flex-wrap mt-4 align-items-center"
      v-if="
        user &&
        (profileAdminUserId ||
          (profilePatientUserId && !user.isPatient) ||
          (profileProviderUserId && (user.isAdmin || user.isManagingAdmin)))
      "
    >
      <div
        class="custom-control custom-checkbox form-group mr-3"
        v-if="
          user &&
          ((profilePatientUserId && !user.isPatient) ||
            (profileProviderUserId && (user.isAdmin || user.isManagingAdmin)))
        "
      >
        <input
          type="checkbox"
          class="custom-control-input"
          id="calendar_assignedToMe"
          v-model="filter.assignedToMe"
          @change="(filter.Provider = null), applyFilter()"
        />
        <label class="custom-control-label" for="calendar_assignedToMe"
          >Assigned to me ({{ user.name }}) only</label
        >
      </div>
      <button
        v-if="user && (user.isAdmin || user.isManagingAdmin)"
        class="btn btn-theme mb-4"
        @click="openBulkSessionsModal()"
      >
        Verify/Unverify Appointments
      </button>
      <button
        v-if="user && (user.isAdmin || user.isManagingAdmin)"
        class="btn btn-1a3f3f ml-3 mb-4"
        @click="
          openUserAvailability(
            profileAdminUserId || profileProviderUserId || profilePatientUserId
          )
        "
      >
        {{
          getUserName(
            profileAdminUserId || profileProviderUserId || profilePatientUserId
          )
        }}'s Availability
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "calendar-filters",
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    profileProviderUserId: {
      type: Number,
      default: 0,
    },
    profilePatientUserId: {
      type: Number,
      default: 0,
    },
    profileAdminUserId: {
      type: Number,
      default: 0,
    },
    users: Array,
  },
  data() {
    return {
      filter: {
        AppStatus: "",
        billableStatus: "",
        classification: null,
        Patient: null,
        Provider: null,
        assignedToMe: false,
      },
      billableStatuses: [
        { label: "Billable", id: "billable" },
        { label: "Non Billable", id: "non-billable" },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      userInfo: (state) => state.auth.userInfo,
    }),
    patientFilterOptions() {
      const displayedOptions = [];
      if (
        this.user &&
        (this.user.isAdmin || this.user.isManagingAdmin) &&
        this.options.adminUsers.length
      ) {
        const adminUsers = this.options.adminUsers.map((p) => ({
          id: p.user_id || p.id,
          name: `${p.last}, ${p.first}`,
          type: "Admin",
          email: p.email,
        }));
        displayedOptions.push(...adminUsers);
      }

      if (
        this.user &&
        !this.user.isProvider &&
        this.options.providerUsers.length
      ) {
        const providerUsers = this.options.providerUsers.map((p) => ({
          id: p.id,
          name: `${p.last}, ${p.first}`,
          type: "Provider",
          email: p.email,
        }));
        displayedOptions.push(...providerUsers);
      }
      if (this.options.patientUsers.length) {
        const patientUsers = this.options.patientUsers.map((p) => ({
          id: p.user_id || p.id,
          name: `${p.last}, ${p.first}`,
          type: "Client",
          email: p.email,
        }));
        displayedOptions.push(...patientUsers);
      }
      return displayedOptions;
    },
    providerFilterOptions() {
      const displayedOptions = [];
      if (
        this.user &&
        (this.user.isAdmin || this.user.isManagingAdmin) &&
        this.options.adminUsers.length
      ) {
        const adminUsers = this.options.adminUsers.map((p) => ({
          id: p.id,
          name: `${p.last}, ${p.first}`,
          type: "Admin",
          email: p.email,
        }));
        displayedOptions.push(...adminUsers);
      }
      if (this.options.providerUsers.length) {
        const providerUsers = this.options.providerUsers.map((p) => ({
          id: p.id,
          name: `${p.last}, ${p.first}`,
          type: "Provider",
          email: p.email,
        }));
        displayedOptions.push(...providerUsers);
      }
      return displayedOptions;
    },
    patientProvidersOptions() {
      const providerUsers = this.options.providerUsers.map((p) => ({
        id: p.user_id || p.id,
        name: `${p.last}, ${p.first}`,
        type: "Provider",
        email: p.email,
      }));
      return providerUsers;
    },
  },
  methods: {
    applyFilter: function () {
      const filterIds = {
        AppStatus: this.filter.AppStatus,
        billableStatus: this.filter.billableStatus,
        classification: (this.filter.classification || {}).id || "",
        Patient: (this.filter.Patient || {}).id || "",
        Provider: (this.filter.Provider || {}).id || "",
        assignedToMe: this.filter.assignedToMe,
      };
      this.$emit("filterChanged", filterIds);
    },
    openUsersAvailability: function () {
      window.open("/admin/users-availability", "_blank");
    },
    openUserAvailability: function (id) {
      this.$emit("openUserAvailability", id);
    },
    openBulkSessionsModal: function () {
      this.$emit("openBulkSessionsModal");
    },
    getUserName(id) {
      const user = this.users.filter((user) => user.id == id)[0];
      if (user) {
        return `${user.last}, ${user.first}`;
      }
      return "";
    },
  },
};
</script>
