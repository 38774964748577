<template>
  <div>
    <div class="row mx-2 my-0" v-if="isLoading">
      <div
        class="
          col-12 col-md-10 col-lg-8
          offset-md-1 offset-lg-2
          card
          light-shadow
          border-0
          p-0
        "
      >
        <alert class="m-0" />
      </div>
    </div>

    <div v-else class="row mx-2 my-0">
      <div
        class="
          col-12 col-md-10 col-lg-8
          offset-md-1 offset-lg-2
          card
          theme-card
          border-lg-0
          py-5
          px-4
        "
      >
        <h1 class="mb-5">Edit Clinic</h1>
        <form @submit.prevent="saveClinic" validate>
          <input-control
            type="horizontal"
            v-model="clinic.name"
            labelClass="col-12 col-md-3 col-lg-3"
            required
            >Clinic Name</input-control
          >

          <save classes="save-button btn btn-theme" :saving="saving">Save</save>
          <button
            v-if="src"
            class="btn btn-link ml-2"
            @click.prevent="navigateBack"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "EditClinic",
  data() {
    return {
      isLoading: true,
      saving: false,
      clinic: {
        name: "",
      },
    };
  },
  mounted() {
    let vm = this;
    this.getClinic(this.$route.params.id).then(function (resonse) {
      vm.clinic = resonse;
      vm.isLoading = false;
    });
  },
  computed: {
    src: function () {
      return this.$route.query.src;
    },
  },
  methods: {
    ...mapActions({
      getClinic: "clinics/get",
      updateClinic: "clinics/update",
    }),
    saveClinic: function () {
      this.saving = true;
      this.$forceUpdate();
      this.updateClinic(this.clinic).then(() => {
        this.saving = false;
        this.$forceUpdate();
      });
    },
    navigateBack: function () {
      this.$router.go(-1);
    },
  },
};
</script>
