<template>
  <div class="providers-list-container">
    <div
      class="
        card
        theme-card
        overflow-auto
        mobile-tablet-no-card
        provider-list-container
      "
    >
      <div :class="onlyMe ? '' : 'card-body px-2 px-sm-4'">
        <div class="d-flex justify-content-start flex-wrap">
          <div
            class="
              d-flex
              flex-md-row flex-column
              justify-content-end
              align-items-center
              col-12 col-sm-6 col-xl-4
              form-group
              mobile-column
            "
            v-if="!onlyMe && !connect & !user.isPatient"
          >
            <label class="col-form-label col-auto p-0 pr-3 m-0"> Class: </label>
            <multiselect
              v-model="selectedClasses"
              :options="providerClassifications"
              :disabled="providers == null || !pageIsReady || loading"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :searchable="true"
              placeholder="All Classes"
              Value="value"
              label="label"
              track-by="value"
              class="app-select"
              :preselect-first="false"
              @input="isClassesMultiSelectDirty = true"
              @open="isClassesMultiSelectOpen = true"
              @close="
                (isClassesMultiSelectOpen = false),
                  isClassesMultiSelectDirty && getFilteredProviders()
              "
              @remove="!isClassesMultiSelectOpen && getFilteredProviders()"
            >
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  class="multiselect__single text-primary"
                  v-if="values.length > 1 &amp;&amp; !isOpen"
                >
                  {{ values.length }} Classes Selected
                </span>
              </template>
              <template #noResult>
                <div class="no-result">Oops! No classes found.</div>
              </template>
            </multiselect>
          </div>
          <div
            class="
              d-flex
              flex-md-row flex-column
              justify-content-end
              align-items-center
              col-12 col-sm-6 col-xl-4
              form-group
              mobile-column
            "
            v-if="!onlyMe && !connect & (user.isAdmin || user.isManagingAdmin)"
          >
            <label class="col-form-label col-auto p-0 pr-3 m-0">
              Clinic:
            </label>
            <multiselect
              v-model="selectedClinics"
              :options="clinics"
              :disabled="providers == null || !pageIsReady || loading"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :searchable="true"
              placeholder="All Clinics"
              Value="id"
              label="name"
              track-by="id"
              class="app-select"
              :preselect-first="false"
              @input="isClinicsMultiSelectDirty = true"
              @open="isClinicsMultiSelectOpen = true"
              @close="
                (isClinicsMultiSelectOpen = false),
                  isClinicsMultiSelectDirty && getFilteredProviders()
              "
              @remove="!isClinicsMultiSelectOpen && getFilteredProviders()"
            >
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  class="multiselect__single text-primary"
                  v-if="values.length > 1 &amp;&amp; !isOpen"
                >
                  {{ values.length }} Clinics Selected
                </span>
              </template>
              <template #noResult>
                <div class="no-result">Oops! No clinics found.</div>
              </template>
            </multiselect>
          </div>
          <div
            class="
              d-flex
              flex-row
              align-items-center
              col-12 col-sm-6 col-xl-4
              form-group
              mobile-column
            "
            v-if="(user.isAdmin || user.isManagingAdmin) && !onlyMe && !connect"
          >
            <!-- <select-control
              v-model="selectedAdminManager"
              :options="adminsManagers"
              :disabled="providers == null || !pageIsReady || loading"
              @input="(assignedToMe = false), getFilteredProviders()"
              labelClass="col-4 col-sm-auto m-font-size-15"
              wrapClass="align-items-center"
              class="w-100"
              >Administrator:</select-control
            > -->
            <label class="col-form-label col-auto p-0 pr-3 m-0">
              Administrator:
            </label>
            <multiselect
              v-model="selectedAdminManager"
              class="app-select"
              :options="adminsManagers"
              :searchable="true"
              :close-on-select="true"
              :clear-on-select="true"
              :allow-empty="true"
              :show-labels="true"
              placeholder="Search for Administrator"
              label="label"
              track-by="value"
              @input="(assignedToMe = false), getFilteredProviders()"
              :disabled="providers == null || !pageIsReady || loading"
            >
              <template slot="option" slot-scope="props">
                <div class="d-flex align-items-center justify-content-start">
                  <div class="select-label">
                    {{ props.option.label }}
                  </div>
                </div>
              </template>
              <template #noResult>
                <div class="no-result">Oops! No users found.</div>
              </template>
            </multiselect>
          </div>
          <div
            class="
              d-flex
              flex-row
              align-items-center
              col-12 col-sm-6 col-xl-4
              form-group
              mobile-column
            "
            v-if="!onlyMe && !connect & !user.isPatient"
          >
            <!-- <select-control
              v-model="selectedProfession"
              :options="providerProfessions"
              :disabled="providers == null || !pageIsReady || loading"
              @input="getFilteredProviders()"
              labelClass="col-4 col-sm-auto m-font-size-15"
              wrapClass="align-items-center"
              class="w-100"
              >Profession:</select-control
            > -->
            <label class="col-form-label col-auto p-0 pr-3 m-0">
              Profession:
            </label>
            <multiselect
              v-model="selectedProfession"
              class="app-select"
              :options="providerProfessions"
              :searchable="true"
              :close-on-select="true"
              :clear-on-select="true"
              :allow-empty="true"
              :show-labels="true"
              placeholder="Search for Profession"
              label="label"
              track-by="value"
              @input="getFilteredProviders()"
              :disabled="providers == null || !pageIsReady || loading"
            >
              <template slot="option" slot-scope="props">
                <div class="d-flex align-items-center justify-content-start">
                  <div class="select-label">
                    {{ props.option.label }}
                  </div>
                </div>
              </template>
              <template #noResult>
                <div class="no-result">Oops! No professions found.</div>
              </template>
            </multiselect>
          </div>
          <div
            class="
              d-flex
              flex-row
              align-items-center
              col-12 col-sm-6 col-xl-4
              form-group
              mobile-column
            "
            v-if="!onlyMe && !connect & !user.isPatient"
          >
            <label class="col-form-label col-auto p-0 pr-3 m-0">
              Status:
            </label>
            <select-control
              v-model="selectedStatus"
              :options="statusOptions"
              :disabled="providers == null || !pageIsReady || loading"
              labelClass="d-none"
              wrapClass="align-items-center mb-0"
              class="w-100"
              @input="getFilteredProviders()"
            ></select-control>
          </div>
          <div
            v-if="user.isManagingAdmin && !onlyMe && !connect"
            class="
              custom-control custom-checkbox
              d-flex
              align-items-center
              justify-content-end
              form-group
              mx-3
            "
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="my-tasks"
              :disabled="providers == null || !pageIsReady || loading"
              :checked="assignedToMe"
              @input="
                ((selectedAdminManager = null), (assignedToMe = !assignedToMe)),
                  getFilteredProviders()
              "
            />
            <label class="custom-control-label" for="my-tasks"
              >Assigned To Me</label
            >
          </div>
        </div>
        <div class="form-group mb-0 px-3" v-if="!onlyMe">
          <input
            type="search"
            class="form-control pr-5"
            @keydown.enter="SearchProviderText"
            v-model.trim="searchContent"
            placeholder="Search by Email Address, First or Last Name"
          />
          <span>
            <i
              class="fa fa-search search-icon link-black"
              @click="SearchProviderText"
            ></i>
            <i
              v-if="searchContent"
              class="fas fa-times cancel-icon"
              @click="(searchContent = ''), SearchProviderText()"
            ></i>
          </span>
        </div>
        <div
          v-if="!maxItems"
          class="d-flex align-items-center mr-auto text-dark col-12 mt-4"
        >
          <span>
            <b v-if="!loading">
              <i class="fas fa-spin fa-circle-notch" v-if="busy"></i>
              {{ busy ? "" : providers.length }}/{{ total }}</b
            >
            <i class="fas fa-spin fa-circle-notch" v-if="loading"></i>
            Provider{{ loading || total != 1 ? "s" : "" }}
          </span>
        </div>
      </div>
      <div
        class="px-2 px-sm-4 white-bg"
        v-if="providers == null || loading || !pageIsReady"
      >
        <alert class="main-alert mt-3" />
      </div>

      <div
        class="overflow-auto p-1 p-lg-0 nice-scroll"
        :ref="connect ? 'wrap' : ''"
        v-if="providers != null && providers.length && !loading && pageIsReady"
      >
        <table
          class="table table-striped mb-0 responsive-mobile-tablet-table"
          v-if="user && providers.length"
        >
          <thead>
            <tr>
              <th v-if="!onlyMe">ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>

              <th v-if="!onlyMe">Hired On</th>
              <th v-if="!hideDetails && !onlyMe">Exit On</th>
              <th>Status</th>
              <th v-if="!onlyMe"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="p in providers" :key="p.id">
              <td v-if="!onlyMe">
                <b class="mobile-tablet-only mr-2">ID:</b>{{ p.user_id }}
              </td>
              <td>
                <div
                  class="
                    d-inline-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <b class="mobile-tablet-only mr-2">Name:</b>
                  <div class="position-relative">
                    <img
                      class="user-avatar-img mr-2 mr-lg-3 rounded-circle"
                      :src="p.profile_picture || profileImagePlaceholder"
                    />
                    <div
                      class="user-status"
                      :class="{
                        online: isOnline(p.user_id),
                        offline: !isOnline(p.user_id),
                      }"
                    />
                  </div>
                  <router-link :to="`/providers/${p.id}`">
                    {{ p.last }}, {{ p.first }}
                  </router-link>
                </div>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Email:</b
                ><a :href="`mailto:${p.email}`">{{ p.email }}</a>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Phone:</b
                >{{ p.phone_number }}
              </td>
              <td v-if="!onlyMe">
                <b class="mobile-tablet-only mr-2">Hired On:</b
                >{{ formattedDate(p.hired_at) }}
              </td>
              <td v-if="!hideDetails && !onlyMe">
                <b class="mobile-tablet-only mr-2">Exit On:</b
                >{{ formattedDate(p.exited_at) }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Status:</b>
                <span
                  :class="`${
                    p.status == 'ACTIVE'
                      ? 'text-success'
                      : p.status == 'PENDING'
                      ? 'text-warning'
                      : 'text-danger'
                  }`"
                >
                  {{ p.status }}
                </span>
              </td>
              <td v-if="!hideDetails && !onlyMe" class="text-center td-actions">
                <router-link :to="`/providers/${p.id}`" class="link-black"
                  ><b class="mobile-tablet-only mr-2">View Profile</b
                  ><i class="fa fa-user actionIcons"></i>
                </router-link>
              </td>
              <td v-if="hideDetails" class="td-actions">
                <button
                  class="btn btn-sm btn-black"
                  @click="handleConnect(p)"
                  :disabled="p.connected || p.connecting"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    v-if="p.connecting"
                  ></span>
                  <span
                    class="d-flex justify-content-center align-items-center"
                    v-else
                  >
                    <icon
                      v-if="!p.connected"
                      type="check-circle"
                      class="mr-2"
                    />
                    <slot>Assign</slot>
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <mugen-scroll
          :handler="loadMore"
          :should-handle="!busy && !loading && !maxItems"
          scroll-container="wrap"
        >
          <alert
            v-if="busy"
            class="
              text-center
              p-2
              mb-0
              mobile-tablet-loadmore mobile-tablet-shadow-card
            "
            ><i class="fas fa-spin fa-circle-notch mr-2"></i>Loading more
            providers...</alert
          >
        </mugen-scroll>
      </div>

      <div
        class="card-body py-0 px-3 px-sm-4"
        v-if="providers != null && !providers.length && !loading && pageIsReady"
      >
        <alert
          :hideLoader="true"
          :class="onlyMe ? 'm-0 main-alert' : 'mb-4 mt-3 main-alert'"
        >
          No Providers,
          <router-link
            v-if="$can({ key: 'providers', expectedPermission: 'create' })"
            :to="{ name: 'providers.add' }"
            >Add a new provider here</router-link
          >
        </alert>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import * as dayjs from "dayjs";
import MugenScroll from "vue-mugen-scroll";
import Multiselect from "vue-multiselect";

export default {
  name: "ListProviders",
  props: ["hideDetails", "connect", "onlyMe", "maxItems"],
  components: { MugenScroll, Multiselect },
  data() {
    return {
      searchContent: "",
      latestSearch: "",
      assignedToMe: false,
      selectedAdminManager: null,
      selectedStatus: 0,
      selectedProfession: null,
      selectedClasses: [],
      selectedClinics: [],
      providerProfessions: [],
      pageIsReady: false,
      busy: false,
      limit: 50,
      adminsManagers: [{ value: -2, label: "Not Assigned" }],
      statusOptions: [
        { value: -1, label: "All" },
        { value: 0, label: "Active" },
        { value: 1, label: "Inactive" },
        { value: 2, label: "Pending" },
      ],
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
      isClassesMultiSelectOpen: false,
      isClassesMultiSelectDirty: false,
      isClinicsMultiSelectOpen: false,
      isClinicsMultiSelectDirty: false,
    };
  },
  computed: {
    ...mapState({
      providers: (state) => state.providers.providerUsers.pagination.data,
      current: (state) => state.providers.providerUsers.pagination.current,
      total: (state) => state.providers.providerUsers.pagination.total,
      loading: (state) => state.providers.providerUsers.loading,
      user: (state) => state.auth.user,
      providerClassifications: (state) =>
        state.classifications.providerClassifications,
      onlineUsers: (state) => state.auth.onlineUsers,
      clinics: (state) => state.clinics.data,
    }),
    me: function () {
      return this.$route.query.me;
    },
  },
  async mounted() {
    this.setPaginationLoading(true);
    if (this.me) {
      this.assignedToMe = true;
    }
    await this.getProviderClassifications();
    this.getProfessions().then((professions) => {
      if (professions) {
        this.providerProfessions = [
          ...this.providerProfessions,
          ...professions.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }),
        ];
      }
    });
    if (this.user.isAdmin || this.user.isManagingAdmin) {
      this.getAdminsManagers();
      this.getClinics();
    }
    this.getFilteredProviders();
  },
  methods: {
    ...mapActions({
      getUser: "auth/getUser",
      getProviders: "providers/getBasicProviders",
      getClinics: "clinics/get",
      setPaginationLoading: "providers/setLoading",
      getProviderClassifications: "classifications/getProviderClassifications",
      getProfessions: "professions/all",
    }),
    formattedDate: function (dateToformat) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat).format("YYYY-MM-DD");
      } else return "";
    },
    handleConnect: function (provider) {
      if (this.$props.connect) {
        provider.connecting = true;
        this.$forceUpdate();
        this.$props.connect(provider).then(() => {
          provider.connecting = false;
          this.$forceUpdate();
        });
      }
    },
    getAdminsManagers: function () {
      try {
        let users = [];
        this.$http.get("admins-managers-info").then((usersRes) => {
          usersRes.data.forEach((u) => {
            u = { value: u.id, label: u.name };
            users.push(u);
          });
          users.unshift({ value: -2, label: "Not Assigned" });
          this.adminsManagers = users;
          if (this.me) {
            setTimeout(() => {
              this.selectedAdminManager = this.adminsManagers.filter(
                (user) => user.value == this.user.id
              )[0];
              this.assignedToMe = true;
            }, 0);
          }
          this.pageIsReady = true;
        });
      } catch (err) {
        //eslint-disable-next-line
        console.log(err);
        this.pageIsReady = true;
      }
    },
    getFilteredProviders: function (page) {
      this.isClassesMultiSelectDirty = false;
      this.isClinicsMultiSelectDirty = false;
      const payload = {
        administratorId:
          this.assignedToMe || this.onlyMe
            ? this.user.id
            : (this.selectedAdminManager || {}).value || -1,
        professionId: (this.selectedProfession || {}).value || -1,
        status: this.selectedStatus,
        limit: this.maxItems ? this.maxItems : this.limit,
      };
      if (this.selectedClasses.length > 0) {
        payload.classIds = `[${this.selectedClasses
          .map((item) => item.value)
          .join(",")}]`;
      }
      if (this.selectedClinics.length > 0) {
        payload.clinicIds = `[${this.selectedClinics
          .map((item) => item.id)
          .join(",")}]`;
      }
      if (page) {
        payload.page = page;
      }
      if (this.searchContent) {
        payload.username = this.searchContent;
      }
      this.getProviders(payload).then(() => {
        this.busy = false;
      });
    },
    loadMore() {
      if (this.providers.length < this.total) {
        this.busy = true;
        this.getFilteredProviders(this.current + 1);
      }
    },
    SearchProviderText: function () {
      if (this.latestSearch == this.searchContent) {
        return;
      }
      this.latestSearch = this.searchContent;
      this.getFilteredProviders();
    },
    isOnline: function (id) {
      return (this.onlineUsers || []).includes(id);
    },
  },
};
</script>
<style scoped lang="scss">
.count-alert {
  border: 0;
  background-color: transparent;
  display: inline-block;
}
.cancel-icon,
.search-icon {
  z-index: 10;
  position: absolute;
  right: 52px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #dc143c;
  cursor: pointer;
}
.search-icon {
  right: 27px;
}
.form-group {
  position: relative;
}

.user-avatar-img {
  width: 45px;
  min-width: 45px;
  height: 45px;
}

@media (max-width: 1024px) {
  .responsive-mobile-tablet-table {
    margin-top: 4px;
  }
  .card-body {
    background-color: white;
    border-radius: 36px;
  }
}

.user-status {
  position: absolute;
  right: 10px;
  bottom: 1px;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  border: 1px solid #edf2f9;
  &.online {
    background-color: #4caf50;
  }
  &.offline {
    background-color: #9ca6af;
  }
  @media (max-width: 768px) {
    right: 4px;
  }
}
</style>

<style lang="scss">
.DashboardItem {
  .main-alert {
    border: 0;
    margin: 0;
  }
  .provider-list-container {
    box-shadow: none !important;
  }
}
.providers-list-container {
  .multiselect--disabled .multiselect__select {
    background-color: transparent;
  }
  .multiselect__tags {
    height: calc(1.5em + 1rem + 2px);
    border: 1px solid #d2ddec;
  }
  .multiselect__select:before {
    border-color: #12263f transparent transparent;
  }
  .multiselect--disabled {
    background-color: transparent;
  }
  .combobox {
    width: 66.67% !important;
  }
}
</style>
