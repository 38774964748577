import Swal from "sweetalert2";
import router from "../router";

export default {
  namespaced: true,
  state: {
    data: [],
    clinic: null,
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_CLINICS: (state, classification) => {
      state.classification = classification;
    },
  },

  actions: {
    create: function (context, data) {
      context.commit("SET_STATE", { key: "isSaving", value: true });
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .post("clinics", data)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Clinic Created Successfully",
              icon: "success",
            });
            context.commit("SET_STATE", { key: "isSaving", value: false });
            router.push({ name: "clinics" });
            return resolve();
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            context.commit("SET_STATE", { key: "isSaving", value: false });
            return resolve();
          });
      });
    },
    update: function (context, data) {
      context.commit("SET_STATE", { key: "isSaving", value: true });
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put(`clinics/${data.id}/`, data)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Clinic Updated Successfully",
              icon: "success",
            });
            context.commit("SET_STATE", { key: "isSaving", value: false });
            router.push({ name: "clinics" });
            return resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            context.commit("SET_STATE", { key: "isSaving", value: false });
            return resolve();
          });
      });
    },
    get: function (context, id) {
      let vm = this;
      context.commit("SET_STATE", { key: "isLoading", value: true });
      return new Promise(function (resolve) {
        if (typeof id == "undefined") {
          vm.$http
            .get("clinics")
            .then((res) => {
              if (res.status == 200) {
                context.commit("SET_STATE", {
                  key: "data",
                  value: res.data.data,
                });
              }
              context.commit("SET_STATE", { key: "isLoading", value: false });
              resolve(res.data.data);
            })
            .catch((err) => {
              context.commit("SET_STATE", { key: "isLoading", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              resolve();
            });
        } else {
          vm.$http
            .get(`clinics/${id}`)
            .then((res) => {
              context.commit("SET_STATE", {
                key: "clinic",
                value: res.data.data,
              });
              context.commit("SET_STATE", { key: "isLoading", value: false });
              resolve(res.data.data);
            })
            .catch((err) => {
              context.commit("SET_STATE", { key: "isLoading", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              resolve();
            });
        }
      });
    },
    delete: function (ctx, id) {
      return new Promise((resolve, reject) => {
        ctx.commit("SET_STATE", { key: "isSaving", value: true });
        this.$http
          .delete(`clinics/${id}`)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Clinic Deleted Successfully",
              icon: "success",
            });
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            reject(err);
          });
      });
    },
    attachClinicToUser: function (ctx, data) {
      return new Promise((resolve, reject) => {
        ctx.commit("SET_STATE", { key: "isSaving", value: true });
        this.$http
          .post(`clinics/${data.id}/attach-user`, { user: data.user })
          .then(() => {
            Swal.fire({
              title: "",
              text: "Clinic Attached To User Successfully",
              icon: "success",
            });
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: err,
                icon: "error",
              });
            }
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            reject(err);
          });
      });
    },
    detachClinicFromUser: function (ctx, data) {
      return new Promise((resolve, reject) => {
        ctx.commit("SET_STATE", { key: "isSaving", value: true });
        this.$http
          .post(`clinics/${data.id}/detach-user`, data)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Clinic Detached From User Successfully",
              icon: "success",
            });
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            ctx.commit("SET_STATE", { key: "isSaving", value: false });
            reject(err);
          });
      });
    },
  },
};
