<template>
  <div class="clients-list-container">
    <div
      class="card theme-card shadow-card mobile-tablet-no-card overflow-auto"
    >
      <div :class="onlyMe ? '' : 'card-body px-2 px-sm-4'">
        <div class="d-flex flex-wrap">
          <div
            class="
              d-flex
              flex-row
              align-items-center
              col-12 col-sm-6 col-xl-4
              form-group
              mobile-column
            "
            v-if="!onlyMe"
          >
            <label class="col-form-label col-auto p-0 pr-3 m-0"> Class: </label>
            <multiselect
              v-model="selectedClasses"
              :options="clientClassifications"
              :disabled="patients == null || !pageIsReady || loading"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :searchable="true"
              placeholder="All Classes"
              Value="value"
              label="label"
              track-by="value"
              class="app-select"
              :preselect-first="false"
              @input="isClassesMultiSelectDirty = true"
              @open="isClassesMultiSelectOpen = true"
              @close="
                (isClassesMultiSelectOpen = false),
                  isClassesMultiSelectDirty && getFilteredPatients()
              "
              @remove="!isClassesMultiSelectOpen && getFilteredPatients()"
            >
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  class="multiselect__single text-primary"
                  v-if="values.length > 1 &amp;&amp; !isOpen"
                >
                  {{ values.length }} Classes Selected
                </span>
              </template>
              <template #noResult>
                <div class="no-result">Oops! No classes found.</div>
              </template>
            </multiselect>
          </div>
          <div
            class="
              d-flex
              flex-row
              align-items-center
              col-12 col-sm-6 col-xl-4
              form-group
              mobile-column
            "
            v-if="!onlyMe && (user.isAdmin || user.isManagingAdmin)"
          >
            <label class="col-form-label col-auto p-0 pr-3 m-0">
              Clinic:
            </label>
            <multiselect
              v-model="selectedClinics"
              :options="clinics"
              :disabled="patients == null || !pageIsReady || loading"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :searchable="true"
              placeholder="All Clinics"
              Value="id"
              label="name"
              track-by="id"
              class="app-select"
              :preselect-first="false"
              @input="isClinicsMultiSelectDirty = true"
              @open="isClinicsMultiSelectOpen = true"
              @close="
                (isClinicsMultiSelectOpen = false),
                  isClinicsMultiSelectDirty && getFilteredPatients()
              "
              @remove="!isClinicsMultiSelectOpen && getFilteredPatients()"
            >
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  class="multiselect__single text-primary"
                  v-if="values.length > 1 &amp;&amp; !isOpen"
                >
                  {{ values.length }} Clinics Selected
                </span>
              </template>
              <template #noResult>
                <div class="no-result">Oops! No clinics found.</div>
              </template>
            </multiselect>
          </div>
          <div
            class="
              d-flex
              flex-row
              align-items-center
              col-12 col-sm-6 col-xl-4
              form-group
              mobile-column
            "
            v-if="!onlyMe"
          >
            <label class="col-form-label col-auto p-0 pr-3 m-0">
              Referral Source/Funder:
            </label>
            <multiselect
              v-model="selectedReferral"
              class="app-select"
              :options="referrals"
              :searchable="true"
              :close-on-select="true"
              :clear-on-select="true"
              :allow-empty="true"
              :show-labels="true"
              placeholder="Search for Referral"
              label="label"
              track-by="value"
              @input="getFilteredPatients()"
              :disabled="patients == null || !pageIsReady || loading"
            >
              <template slot="option" slot-scope="props">
                <div class="d-flex align-items-center justify-content-start">
                  <div class="select-label">
                    {{ props.option.label }}
                  </div>
                </div>
              </template>
              <template #noResult>
                <div class="no-result">Oops! No users found.</div>
              </template>
            </multiselect>
          </div>
          <div
            class="
              d-flex
              flex-row
              align-items-center
              col-12 col-sm-6 col-xl-4
              form-group
              mobile-column
            "
            v-if="(user.isAdmin || user.isManagingAdmin) && !onlyMe"
          >
            <label class="col-form-label col-auto p-0 pr-3 m-0">
              Administrator:
            </label>
            <multiselect
              v-model="selectedAdminManager"
              class="app-select"
              :options="adminsManagers"
              :searchable="true"
              :close-on-select="true"
              :clear-on-select="true"
              :allow-empty="true"
              :show-labels="true"
              placeholder="Search for Administrator"
              label="label"
              track-by="value"
              @input="(assignedToMe = false), getFilteredPatients()"
              :disabled="patients == null || !pageIsReady || loading"
            >
              <template slot="option" slot-scope="props">
                <div class="d-flex align-items-center justify-content-start">
                  <div class="select-label">
                    {{ props.option.label }}
                  </div>
                </div>
              </template>
              <template #noResult>
                <div class="no-result">Oops! No users found.</div>
              </template>
            </multiselect>
          </div>
          <div
            class="
              d-flex
              flex-row
              align-items-center
              col-12 col-sm-6 col-xl-4
              form-group
              mobile-column
            "
            v-if="(user.isAdmin || user.isManagingAdmin) && !onlyMe"
          >
            <label class="col-form-label col-auto p-0 pr-3 m-0">
              Provider:
            </label>
            <multiselect
              v-model="selectedProvider"
              class="app-select"
              :options="providersList"
              :searchable="true"
              :close-on-select="true"
              :clear-on-select="true"
              :allow-empty="true"
              :show-labels="true"
              placeholder="Search for Provider"
              label="label"
              track-by="value"
              @input="getFilteredPatients()"
              :disabled="patients == null || !pageIsReady || loading"
            >
              <template slot="option" slot-scope="props">
                <div class="d-flex align-items-center justify-content-start">
                  <div class="select-label">
                    {{ props.option.label }}
                  </div>
                </div>
              </template>
              <template #noResult>
                <div class="no-result">Oops! No users found.</div>
              </template>
            </multiselect>
          </div>
          <div
            class="
              d-flex
              flex-row
              align-items-center
              col-12 col-sm-6 col-xl-4
              form-group
              mobile-column
            "
            v-if="!onlyMe"
          >
            <label class="col-form-label col-auto p-0 pr-3 m-0">
              Status:
            </label>
            <select-control
              v-model="selectedStatus"
              :options="statusOptions"
              labelClass="d-none"
              wrapClass="align-items-center mb-0"
              @input="getFilteredPatients()"
              :disabled="patients == null || !pageIsReady || loading"
            ></select-control>
          </div>
          <div
            v-if="user.isManagingAdmin && !onlyMe"
            class="
              custom-control custom-checkbox
              d-flex
              align-items-center
              form-group
              mx-3
              col-md-auto col-12
            "
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="my-tasks"
              :checked="assignedToMe"
              :disabled="patients == null || !pageIsReady || loading"
              @input="
                ((selectedAdminManager = null), (assignedToMe = !assignedToMe)),
                  getFilteredPatients()
              "
            />
            <label class="custom-control-label" for="my-tasks"
              >Assigned To Me</label
            >
          </div>
        </div>
        <div class="form-group mb-0 px-3" v-if="!onlyMe">
          <input
            type="search"
            class="form-control pr-5"
            @keydown.enter="SearchPatientText"
            v-model.trim="searchContent"
            placeholder="Search by Email Address, First or Last Name"
          />
          <span>
            <i
              class="fa fa-search search-icon link-black"
              @click="SearchPatientText"
            ></i>
            <i
              v-if="searchContent"
              class="fas fa-times cancel-icon"
              @click="(searchContent = ''), SearchPatientText()"
            ></i>
          </span>
        </div>
        <div
          v-if="!maxItems"
          class="d-flex align-items-center mr-auto text-dark col-12 mt-4"
        >
          <span>
            <b v-if="!loading">
              <i class="fas fa-spin fa-circle-notch" v-if="busy"></i>
              {{ busy ? "" : patients.length }}/{{ total }}</b
            >
            <i class="fas fa-spin fa-circle-notch" v-if="loading"></i>
            Client{{ loading || total != 1 ? "s" : "" }}
          </span>
        </div>
      </div>
      <div
        class="px-2 px-sm-4 white-bg"
        v-if="patients == null || loading || !pageIsReady"
      >
        <alert class="shadow-card mt-3" />
      </div>
      <div
        class="table-container radius-0 p-1 p-lg-0 nice-scroll"
        :ref="connect ? 'wrap' : ''"
        v-if="patients != null && patients.length && !loading && pageIsReady"
      >
        <table class="table table-striped mb-0 responsive-mobile-tablet-table">
          <thead>
            <tr>
              <th v-if="!onlyMe">ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th v-if="!onlyMe">Created Date</th>
              <th v-if="!hideDetails && !onlyMe">Referred By</th>
              <th v-if="!hideDetails && !onlyMe" style="min-width: 118px">
                Date Of Injury
              </th>
              <th v-if="!hideDetails && !onlyMe">Claim Number</th>
              <th>Status</th>
              <th class="text-center" v-if="!onlyMe">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="p in patients" :key="p.id">
              <td v-if="!onlyMe">
                <b class="mobile-tablet-only mr-2">ID:</b>{{ p.user_id }}
              </td>
              <td>
                <div
                  class="
                    d-inline-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <b class="mobile-tablet-only mr-2">Name:</b>
                  <div class="position-relative">
                    <img
                      class="user-avatar-img mr-2 mr-lg-3 rounded-circle"
                      :src="p.profile_picture || profileImagePlaceholder"
                    />
                    <div
                      class="user-status"
                      :class="{
                        online: isOnline(p.user_id),
                        offline: !isOnline(p.user_id),
                      }"
                    />
                  </div>
                  <router-link :to="`/clients/${p.id}`">
                    {{ p.last }}, {{ p.first }}
                  </router-link>
                </div>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Email:</b
                ><a :href="`mailto:${p.email}`">{{ p.email }}</a>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Phone:</b
                >{{ p.phone_number }}
              </td>
              <td v-if="!onlyMe">
                <b class="mobile-tablet-only mr-2">Created Date:</b>
                {{ formattedDate(p.created_at) }}
              </td>
              <td v-if="!hideDetails && !onlyMe">
                <b class="mobile-tablet-only mr-2">Referred By:</b>
                <div class="referral-container">
                  <div
                    v-for="referral in p.referred_by"
                    :key="referral.id"
                    class="referral-name mobile-tablet-only-force"
                  >
                    {{ referral.name }}
                  </div>

                  <div
                    v-for="referral in getFirstXReferral(p.referred_by, 2)"
                    :key="`desktop_${referral.id}`"
                    class="referral-name desktop-only-force"
                  >
                    {{ referral.name }}
                  </div>
                  <button
                    class="
                      btn btn-link btn-sm
                      no-shadow-btn
                      font-weight-bold
                      desktop-only-force
                    "
                    v-if="p.referred_by.length - 2 > 0"
                    @click="showReferrals(p)"
                  >
                    +{{ p.referred_by.length - 2 }} more
                  </button>
                </div>
              </td>
              <td v-if="!hideDetails && !onlyMe">
                <b class="mobile-tablet-only mr-2">Date Of Injury:</b>
                {{ formattedDate(p.injured_at) }}
              </td>
              <td
                v-if="!hideDetails && !onlyMe"
                style="word-break: break-all; min-width: 118px"
              >
                <b class="mobile-tablet-only mr-2">Claim Number:</b>
                {{ p.claim_number != null ? p.claim_number : "" }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Status:</b>
                <span
                  :class="`${
                    p.status == 'ACTIVE' || p.status == 'PROTECTED'
                      ? 'text-success'
                      : p.status == 'PENDING' || p.status == 'ON_HOLD'
                      ? 'text-warning'
                      : 'text-danger'
                  }`"
                >
                  {{
                    p.status == "PROTECTED"
                      ? "PROTECTED ACCOUNT"
                      : p.status == "ON_HOLD"
                      ? "ON HOLD"
                      : p.status
                  }}
                </span>
              </td>
              <td class="text-center td-actions" v-if="!hideDetails && !onlyMe">
                <router-link :to="`/clients/${p.id}`" class="link-black"
                  ><b class="mobile-tablet-only mr-2">View Profile</b>
                  <i class="fa fa-user actionIcons"></i>
                </router-link>
                <span
                  class="ml-2 text-danger pointer-cursor"
                  v-if="user && !user.isProvider && hideDetails"
                  ><span
                    class="spinner-border spinner-border-sm"
                    v-if="p.disconnecting"
                  ></span>
                  <i
                    class="fas fa-unlink"
                    @click="disconnectPatient(p)"
                    v-else
                  ></i
                ></span>
              </td>
              <td v-if="hideDetails" class="td-actions">
                <button
                  class="btn btn-sm btn-black"
                  @click="handleConnect(p)"
                  :disabled="p.connected || p.connecting"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    v-if="p.connecting"
                  ></span>
                  <span
                    class="d-flex justify-content-center align-items-center"
                    v-else
                  >
                    <icon
                      v-if="!p.connected"
                      type="check-circle"
                      class="mr-2"
                    />
                    <slot>Assign</slot>
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <mugen-scroll
          :handler="loadMore"
          :should-handle="!busy && !loading && !maxItems"
          scroll-container="wrap"
        >
          <alert
            v-if="busy"
            class="
              text-center
              p-2
              mb-0
              mobile-tablet-loadmore mobile-tablet-shadow-card
            "
            ><i class="fas fa-spin fa-circle-notch mr-2"></i>Loading more
            clients...</alert
          >
        </mugen-scroll>
      </div>
      <div
        class="card-body py-0 px-3 px-sm-4"
        v-if="patients != null && !patients.length && !loading && pageIsReady"
      >
        <alert :hideLoader="true" :class="onlyMe ? 'm-0' : 'mb-4 mt-3'">
          No Clients
          <span v-if="user && (user.isAdmin || user.isManagingAdmin)">, </span>
          <router-link
            :to="{ name: 'patients.add' }"
            v-if="
              user &&
              (user.isAdmin || user.isManagingAdmin) &&
              $can({ key: 'clients', expectedPermission: 'create' })
            "
            >Add a new client here</router-link
          >
        </alert>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import * as dayjs from "dayjs";
import Swal from "sweetalert2";
import MugenScroll from "vue-mugen-scroll";
import Multiselect from "vue-multiselect";

export default {
  name: "Listpatients",
  props: ["hideDetails", "connect", "onlyMe", "maxItems"],
  components: { MugenScroll, Multiselect },
  data() {
    return {
      searchContent: "",
      latestSearch: "",
      assignedToMe: false,
      selectedAdminManager: null,
      selectedProvider: null,
      selectedStatus: 0,
      selectedClasses: [],
      selectedClinics: [],
      selectedReferral: null,
      referrals: [],
      pageIsReady: false,
      busy: false,
      limit: 50,
      statusOptions: [
        { value: -1, label: "All" },
        { value: 0, label: "Active" },
        { value: 4, label: "Protected Account" },
        { value: 2, label: "Pending" },
        { value: 3, label: "On Hold" },
        { value: 1, label: "Inactive" },
      ],
      adminsManagers: [{ value: -2, label: "Not Assigned" }],
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
      isClassesMultiSelectOpen: false,
      isClassesMultiSelectDirty: false,
      isClinicsMultiSelectOpen: false,
      isClinicsMultiSelectDirty: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user || {},
      patients: (state) => state.patients.patientUsers.pagination.data,
      current: (state) => state.patients.patientUsers.pagination.current,
      total: (state) => state.patients.patientUsers.pagination.total,
      loading: (state) => state.patients.patientUsers.loading,
      clientClassifications: (state) =>
        state.classifications.clientClassifications,
      onlineUsers: (state) => state.auth.onlineUsers,
      users: (state) => state.security.usersWithRoles,
      clinics: (state) => state.clinics.data,
    }),
    me: function () {
      return this.$route.query.me;
    },
    providersList: function () {
      const defaultList = [{ value: -2, label: "Not Assigned" }];
      if (!this.pageIsReady) {
        return defaultList;
      }

      if (this.users && this.users.length) {
        const parsedList = this.users
          .filter((user) => user.roleId === 2)
          .map((user) => {
            return {
              value: user.user_id,
              label: user.name,
            };
          });
        return [...defaultList, ...parsedList];
      } else {
        return [];
      }
    },
  },
  async mounted() {
    this.setPaginationLoading(true);
    if (this.me) {
      this.assignedToMe = true;
    }
    await this.getClientClassifications();
    if (this.user.isAdmin || this.user.isManagingAdmin) {
      this.getClinics();
    }
    this.getFilteredPatients();
    this.getBasicReferralSources().then((items) => {
      items = items.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      this.referrals = [
        // { value: -1, label: "Select Referral Source" },
        ...items,
      ];
    });
    try {
      let users = [];
      this.$http.get("admins-managers-info").then((usersRes) => {
        usersRes.data.forEach((u) => {
          u = { value: u.id, label: u.name };
          users.push(u);
        });
        users.unshift({ value: -2, label: "Not Assigned" });
        this.adminsManagers = users;
        if (this.me) {
          setTimeout(() => {
            this.selectedAdminManager = this.adminsManagers.filter(
              (user) => user.value == this.user.id
            )[0];
          }, 0);
        }
        this.pageIsReady = true;
      });
    } catch (err) {
      //eslint-disable-next-line
      console.log(err);
      this.pageIsReady = true;
    }
  },
  methods: {
    ...mapActions({
      disconnect: "providers/patients/disconnect",
      getClientClassifications: "classifications/getClientClassifications",
      getPatients: "patients/getBasicPatients",
      setPaginationLoading: "patients/setLoading",
      getBasicReferralSources: "referralSources/getBasicReferralSources",
      getClinics: "clinics/get",
    }),
    getFirstXReferral: function (array, length) {
      return array.slice(0, length); // Returns the first item of the array
    },
    showReferrals: function (patient) {
      let content = "";
      patient.referred_by.forEach((referral) => {
        content += `<div class="referral-name col-5">
                    ${referral.name}
                  </div>`;
      });

      Swal.fire({
        html: `<div class="mt-5 swal-referral-content" style="margin-bottom: -24px;">
          <h3>${patient.name} Referrals</h3>
          <div class="referral-container d-flex align-items-baseline justify-content-between flex-wrap border rounded p-3 mb-3">
          ${content}
          </div>
          <hr>
          </div>`,
        showCancelButton: true,
        cancelButtonText: "Close",
        cancelButtonColor: "#6e84a3",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "px-0",
        },
      });
    },
    formattedDate: function (dateToformat) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat).format("YYYY-MM-DD");
      } else return "";
    },
    handleConnect: function (patient) {
      if (this.$props.connect) {
        patient.connecting = true;
        this.$forceUpdate();
        this.$props.connect(patient).then(() => {
          patient.connecting = false;
          this.$forceUpdate();
        });
      }
    },
    disconnectPatient: function (patient) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You are going to unassign this client",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, unassign",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            const data = {
              providerId: this.user.id,
              patientId: patient.id,
            };
            patient.disconnecting = true;
            this.$forceUpdate();
            this.disconnect(data).then((disconnected) => {
              patient.disconnecting = false;
              this.$forceUpdate();
              if (disconnected) {
                this.$store.dispatch("patients/get");
              }
            });
          }
        });
    },
    getFilteredPatients: function (page) {
      this.isClassesMultiSelectDirty = false;
      const payload = {
        administratorId:
          this.assignedToMe || this.onlyMe
            ? this.user.id
            : (this.selectedAdminManager || {}).value || -1,
        providerId: (this.selectedProvider || {}).value || -1,
        status: this.selectedStatus,
        limit: this.maxItems ? this.maxItems : this.limit,
        referralId: (this.selectedReferral || {}).value || -1,
      };
      if (this.selectedClasses.length > 0) {
        payload.classIds = `[${this.selectedClasses
          .map((item) => item.value)
          .join(",")}]`;
      }
      if (this.selectedClinics.length > 0) {
        payload.clinicIds = `[${this.selectedClinics
          .map((item) => item.id)
          .join(",")}]`;
      }
      if (page) {
        payload.page = page;
      }
      if (this.searchContent) {
        payload.username = this.searchContent;
      }
      this.getPatients(payload).then(() => {
        this.busy = false;
      });
    },
    loadMore() {
      if (this.patients.length < this.total) {
        this.busy = true;
        this.getFilteredPatients(this.current + 1);
      }
    },
    SearchPatientText: function () {
      if (this.latestSearch == this.searchContent) {
        return;
      }
      this.latestSearch = this.searchContent;
      this.getFilteredPatients();
    },
    isOnline: function (id) {
      return (this.onlineUsers || []).includes(id);
    },
  },
};
</script>
<style scoped lang="scss">
.user-avatar-img {
  width: 45px;
  min-width: 45px;
  height: 45px;
}
.referral-container {
  display: -ms-inline-grid;
  display: -moz-inline-grid;
  display: inline-grid;
  @media (max-width: 1024px) {
    display: inline-block;
  }

  .referral-name {
    background-color: #222222;
    color: #fff;
    border-radius: 8px;
    display: inline-block;
    padding: 3px 12px;
    margin: 3px;
    font-size: 0.85em;
  }
}
.no-shadow-btn {
  box-shadow: none !important;
}
.count-alert {
  border: 0;
  background-color: transparent;
  display: inline-block;
}
.col-12 > div {
  width: 100%;
}
.cancel-icon,
.search-icon {
  z-index: 10;
  position: absolute;
  right: 52px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #dc143c;
  cursor: pointer;
}
.search-icon {
  right: 27px;
}
.form-group {
  position: relative;
}
@media (max-width: 1024px) {
  .responsive-mobile-tablet-table {
    margin-top: 4px;
  }
  .card-body {
    background-color: white;
    border-radius: 36px;
  }
}
.user-status {
  position: absolute;
  right: 10px;
  bottom: 1px;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  border: 1px solid #edf2f9;
  &.online {
    background-color: #4caf50;
  }
  &.offline {
    background-color: #9ca6af;
  }
  @media (max-width: 768px) {
    right: 4px;
  }
}
</style>

<style lang="scss">
.swal-referral-content {
  .referral-container {
    .referral-name {
      background-color: #222222;
      color: #fff;
      border-radius: 8px;
      display: inline-block;
      padding: 3px 12px;
      margin: 3px;
      font-size: 0.85em;
    }
  }
}

.clients-list-container {
  .multiselect--disabled .multiselect__select {
    background-color: transparent;
  }
  .multiselect__tags {
    height: calc(1.5em + 1rem + 2px);
    border: 1px solid #d2ddec;
  }
  .multiselect__select:before {
    border-color: #12263f transparent transparent;
  }
  .multiselect--disabled {
    background-color: transparent;
  }
  .combobox {
    width: 66.67% !important;
  }
}
</style>
