<template>
  <div
    class="container forms-template-container"
    v-if="user && !user.isPatient"
  >
    <div class="row page-header">
      <div class="col">
        <h1>Form Templates</h1>
      </div>
      <div class="col text-right">
        <router-link
          :to="{ name: 'templates.guide' }"
          class="btn btn-black mb-3 mr-3"
        >
          <icon type="info-circle" /> How It Works?
        </router-link>
        <router-link :to="{ name: 'templates.add' }" class="btn btn-theme mb-3">
          <icon type="plus" /> Add New Template
        </router-link>
      </div>
    </div>
    <div>
      <alert v-if="isLoading" class="light-shadow" />
    </div>
    <div
      class="
        card
        overflow-auto
        theme-card
        mobile-tablet-no-card
        border-lg-0
        table-container
      "
      v-if="!isLoading && templates.length > 0"
    >
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Category</th>
            <th>Permission</th>
            <th>Type</th>
            <th>Created By</th>
            <th>Updated By</th>
            <th class="text-center" style="min-width: 105px">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="t in templates" :key="t.id">
            <td><b class="mobile-tablet-only mr-2">Title:</b>{{ t.title }}</td>
            <td>
              <b class="mobile-tablet-only mr-2">Category:</b
              >{{ categoryOptions[t.category] }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Permission:</b
              >{{ permissionOptions[t.role_id] }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Type:</b
              >{{ t.type | capitalize }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Created By:</b>
              {{
                t.created_by && t.created_by.full_name
                  ? t.created_by.full_name
                  : ""
              }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Updated By:</b>
              {{
                t.updated_by && t.updated_by.full_name
                  ? t.updated_by.full_name
                  : ""
              }}
            </td>
            <td class="text-center td-actions">
              <router-link
                :to="`/templates/${t.id}`"
                class="mr-3 btn btn-black btn-sm mt-1"
                v-if="user && (user.isAdmin || user.isManagingAdmin)"
                ><icon type="edit" />
              </router-link>
              <router-link
                :to="`/templates/${t.id}?preview=true`"
                class="mr-3 btn btn-theme btn-sm mt-1"
                ><icon type="search" />
              </router-link>
              <a
                href="/"
                v-if="user && (user.isAdmin || user.isManagingAdmin)"
                :class="`mr-3 mt-1 btn btn-${
                  t.hidden ? 'warning' : 'deep-success'
                } btn-sm`"
                :title="t.hidden ? 'Can\'t be used (hidden)' : 'Shown'"
                @click.prevent="showHideTemplate(t)"
              >
                <icon
                  v-if="!t.updating"
                  :type="t.hidden ? 'eye-slash' : 'eye'"
                />
                <i class="fas fa-spin fa-circle-notch" v-if="t.updating"></i>
              </a>
              <a
                href="/"
                v-if="user && (user.isAdmin || user.isManagingAdmin)"
                class="actionsSeperator btn btn-danger btn-sm mt-1"
                @click.prevent="deleteSelectedTemplate(t)"
                ><icon type="trash" v-if="!t.deleting" />
                <i class="fas fa-spin fa-circle-notch" v-if="t.deleting"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <alert v-if="!isLoading && templates.length == 0" class="m-4 light-shadow"
      >No Templates Created</alert
    >
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "templates",
  data() {
    return {
      categoryOptions: {},
      permissionOptions: {
        1: "All",
        2: "Admins",
      },
    };
  },
  computed: {
    ...mapState({
      templates: (state) => state.templates.templates,
      isLoading: (state) => state.templates.isLoading,
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    this.getTemplateCategories().then((res) => {
      this.categoryOptions = {};
      res.forEach((item) => {
        this.categoryOptions[item.id] = item.name;
      });
    });
    this.getTemplates();
  },
  methods: {
    ...mapActions({
      getTemplates: "templates/getTemplates",
      getTemplateCategories: "templates/getTemplateCategories",
      deleteTemplate: "templates/deleteTemplate",
      updateTemplateStatus: "templates/updateTemplateStatus",
    }),
    deleteSelectedTemplate: function (template) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            template.deleting = true;
            vm.$forceUpdate();
            vm.deleteTemplate(template.id).then((deleted) => {
              template.deleting = false;
              vm.$forceUpdate();
              if (deleted) {
                this.getTemplates();
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Form has been deleted.",
                  "success"
                );
              }
            });
          }
        });
    },
    showHideTemplate: function (template) {
      if (template.updating) {
        return;
      }
      template.updating = true;
      this.$forceUpdate();
      this.updateTemplateStatus({
        id: template.id,
        hidden: !template.hidden,
      }).then((success) => {
        if (success) {
          template.hidden = !template.hidden;
        }
        template.updating = false;
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.forms-template-container {
  @media (max-width: 1024px) {
    .page-header {
      display: block !important;
    }
  }
}
</style>
