<template>
  <div>
    <div class="card-body">
      <div class="DashboardSection">
        <div class="row m-0 col-12">
          <div
            id="Summary"
            v-if="user && user.isManagingAdmin"
            class="col-12 col-lg-7 dashboard-item-container my-0"
          >
            <div class="row m-0 justify-content-between dashboard-item-header">
              <div class="row mx-0 col-12 p-0 justify-content-between">
                <div class="dashboard-item-title">Summary</div>
              </div>
            </div>
            <div class="flex-wrap row m-0">
              <div class="col-12 col-sm-6 col-md-12 col-xl-4 mt-4">
                <div
                  class="
                    card-container
                    btn-theme
                    text-main
                    font-weight-normal
                    px-3
                  "
                >
                  <div class="d-flex align-items-center mb-4">
                    <img
                      class="card-icon mr-3 mb-0 large"
                      src="@/assets/img/dashboard/revenue-by-provider-black.svg"
                    />
                    <p class="text-capitalize title">Assigned Providers</p>
                  </div>
                  <div
                    class="text-center"
                    v-if="numberOfAssignedProviders.loading"
                  >
                    <i class="fas fa-2x fa-spin fa-circle-notch mr-5"></i>
                  </div>
                  <p v-else class="value text-center large">
                    {{ numberOfAssignedProviders.data }}
                    <br />
                    <span
                      class="btn btn-sm text-main btn-link"
                      @click="openCardInfo('providers', 'Assigned Providers')"
                      >View details</span
                    >
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-12 col-xl-4 mt-4">
                <div class="card-container bg-table text-white px-3">
                  <div class="d-flex align-items-center mb-4">
                    <img
                      class="card-icon mr-3 mb-0 large"
                      src="@/assets/img/dashboard/revenue-by-patient-active.svg"
                    />
                    <p class="text-capitalize title">Assigned Clients</p>
                  </div>
                  <div
                    class="text-center"
                    v-if="numberOfAssignedClients.loading"
                  >
                    <i class="fas fa-lg fa-spin fa-circle-notch mr-2"></i>
                  </div>
                  <p v-else class="value text-center large">
                    {{ numberOfAssignedClients.data }}
                    <br />
                    <span
                      class="btn btn-sm text-white btn-link"
                      @click="openCardInfo('patients', 'Assigned Clients')"
                      >View details</span
                    >
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-12 col-xl-4 mt-4">
                <div class="card-container bg-white text-main bg-img-card px-3">
                  <div class="d-flex align-items-center mb-4">
                    <img
                      class="card-icon mr-3 mb-0 large"
                      src="@/assets/img/dashboard/sessions-done-black.svg"
                    />
                    <p class="text-capitalize title">Sessions completed</p>
                  </div>
                  <div class="text-center" v-if="gettingTotalRevenue">
                    <i class="fas fa-2x fa-spin fa-circle-notch mr-5"></i>
                  </div>
                  <p v-else class="value text-center large">
                    {{ totalRevenue.sessions_done }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-lg-7 dashboard-item-container my-0"
            v-if="
              user &&
              user.isAdmin &&
              $can({ key: 'billingReport', expectedPermission: 'view' })
            "
          >
            <div class="row m-0 justify-content-between dashboard-item-header">
              <div class="row mx-0 col-12 p-0 justify-content-between">
                <div class="dashboard-item-title">
                  Monthly Revenue
                  <sup class="dashboard-selected-time">{{
                    selectedMonthYear.annualRevenue
                  }}</sup>
                </div>
                <div>
                  <select-control
                    v-model="selectedMonthYear.annualRevenue"
                    :options="lastYears"
                    :key="selectedMonthYear.annualRevenue"
                    labelClass="d-none"
                    wrapClass="mb-0"
                    :disabled="chartLoading.annualRevenue"
                    @input="getAnnualRevenue(true)"
                  ></select-control>
                </div>
              </div>
              <div
                class="row mx-0 mb-3 annual-revenue-all"
                :id="noChartData.annualRevenue ? 'annualRevenue' : ''"
              >
                <div class="dashboard-widget-info text-center mr-3 mt-3">
                  <p class="mb-0 widget-title text-center">
                    {{ selectedMonthYear.annualRevenue }} Total Revenue
                  </p>
                  <p class="mt-1 mb-0 widget-value">
                    $
                    <b
                      style="font-size: 18px"
                      v-if="
                        totalAllRevenue[`${selectedMonthYear.annualRevenue}`] !=
                        undefined
                      "
                      >{{
                        `${+parseFloat(
                          totalAllRevenue[
                            `${selectedMonthYear.annualRevenue}`
                          ] || 0
                        ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                      }}</b
                    >
                    <b
                      v-if="
                        totalAllRevenue[`${selectedMonthYear.annualRevenue}`] ==
                        undefined
                      "
                      class="pl-3"
                    >
                      <i class="fas fa-spin fa-circle-notch"></i>
                    </b>
                  </p>
                </div>
                <div class="dashboard-widget-info text-center mt-3">
                  <p class="mb-0 widget-title text-center">
                    {{ selectedMonthYear.annualRevenue - 1 }} Total Revenue
                  </p>
                  <p class="mt-1 mb-0 widget-value">
                    $
                    <b
                      style="font-size: 18px"
                      v-if="
                        totalAllRevenue[
                          `${selectedMonthYear.annualRevenue - 1}`
                        ] != undefined
                      "
                      >{{
                        `${+parseFloat(
                          totalAllRevenue[
                            `${selectedMonthYear.annualRevenue - 1}`
                          ] || 0
                        ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                      }}</b
                    >
                    <b
                      v-if="
                        totalAllRevenue[
                          `${selectedMonthYear.annualRevenue - 1}`
                        ] == undefined
                      "
                      class="pl-3"
                    >
                      <i class="fas fa-spin fa-circle-notch"></i>
                    </b>
                  </p>
                </div>
              </div>
            </div>
            <alert v-if="chartLoading.annualRevenue" />
            <alert
              v-if="noChartData.annualRevenue && !chartLoading.annualRevenue"
              :hideLoader="true"
              >No data to show</alert
            >
            <div id="annualRevenue" v-show="!noChartData.annualRevenue"></div>
          </div>
          <div class="col-12 col-lg-5 mt-4 mt-lg-0">
            <timeline-bar
              :userId="userId"
              :maxItems="user && user.isAdmin ? 3 : 2"
            />
          </div>

          <dashboard-appointments class="DashboardItem" :maxItems="100" />

          <div
            class="
              col-12
              dashboard-item-container dashboard-main-container
              d-flex
              p-0
            "
            v-if="user && user.isAdmin"
          >
            <div
              class="
                col-3
                menu-container
                py-4
                desktop-only-force
                d-flex
                flex-column
                justify-content-between
              "
            >
              <div>
                <div class="row m-0 dashboard-item-header">
                  <div class="dashboard-item-title">Revenue By</div>
                </div>
                <div class="chart-menu">
                  <div
                    class="
                      chart-menu-item
                      pointer-cursor
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    :class="{ selected: menuItem.name === selectedRevenueName }"
                    v-for="menuItem in revenueMenuItems"
                    :key="menuItem.name"
                    @click="updateSelectedRevenueChart(menuItem.name)"
                  >
                    <div class="title-img">
                      <img class="menu-icon mr-3" :src="menuItem.icon" />
                      <span class="title">{{ menuItem.title }}</span>
                    </div>
                    <span class="total" v-if="menuItem.total">{{
                      menuItem.total
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                class="
                  chart-layout
                  justify-content-between
                  d-flex
                  align-items-center
                  mt-5
                  px-0
                  pt-5
                  flex-wrap
                "
              >
                <span class="label pr-4 mb-1 font-weight-bold"
                  >Chart Layout</span
                >
                <div class="control radius-99 d-inline-flex">
                  <button
                    class="btn radius-99 text-main"
                    :class="{
                      selected: selectedRevenueDirection == 'horizontal',
                    }"
                    @click="updateSelectedRevenueLayout('horizontal')"
                    :disabled="chartLoading[selectedRevenueName]"
                  >
                    Horizontal
                  </button>
                  <button
                    class="btn radius-99 text-main right-btn"
                    :class="{
                      selected: selectedRevenueDirection == 'vertical',
                    }"
                    @click="updateSelectedRevenueLayout('vertical')"
                    :disabled="chartLoading[selectedRevenueName]"
                  >
                    Vertical
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-9 py-4 charts-outer-container">
              <div
                class="row m-0 dashboard-item-header small-display flex-column"
              >
                <div class="dashboard-item-title">Revenue</div>
                <select-control
                  v-model="selectedRevenueName"
                  :options="revenueChartOptions"
                  labelClass="d-none"
                  inputClass="font-weight-bold"
                  @input="updateChartRender()"
                  wrapClass="m-0 w-100 p-0"
                ></select-control>
              </div>
              <div
                v-show="selectedRevenueName == 'revenueByPatient'"
                v-if="
                  user &&
                  user.isAdmin &&
                  $can({ key: 'billingReport', expectedPermission: 'view' })
                "
              >
                <div
                  class="row m-0 justify-content-between dashboard-item-header"
                >
                  <div class="dashboard-item-title">
                    Revenue by Client
                    <sup class="dashboard-selected-time">{{
                      selectedMonthYear.revenueByPatient
                    }}</sup>
                    <badge
                      v-if="!chartLoading.revenueByPatient"
                      class="ml-3 badge-theme"
                      >{{ this.activeClientsCount }} Client{{
                        this.activeClientsCount != 1 ? "s" : ""
                      }}</badge
                    >
                  </div>
                  <div class="d-flex mt-2 mb-2 mb-lg-5">
                    <select-control
                      v-model="selectedMonthYear.revenueByPatient"
                      :options="lastYears"
                      :key="selectedMonthYear.revenueByPatient"
                      labelClass="d-none"
                      :disabled="chartLoading.revenueByPatient"
                      @input="getRevenueByPatient(true)"
                      wrapClass="mb-0"
                    ></select-control>
                  </div>
                </div>
                <alert v-if="chartLoading.revenueByPatient" />
                <alert
                  v-if="
                    noChartData.revenueByPatient &&
                    !chartLoading.revenueByPatient
                  "
                  :hideLoader="true"
                  >No data to show</alert
                >
                <div class="chart-container">
                  <div
                    id="revenueByPatient"
                    v-show="!noChartData.revenueByPatient"
                  ></div>
                </div>
              </div>
              <div
                v-show="selectedRevenueName == 'revenueByProvider'"
                v-if="
                  user &&
                  user.isAdmin &&
                  $can({ key: 'billingReport', expectedPermission: 'view' })
                "
              >
                <div
                  class="row m-0 justify-content-between dashboard-item-header"
                >
                  <div class="dashboard-item-title">
                    Revenue by Provider
                    <sup class="dashboard-selected-time">{{
                      selectedMonthYear.revenueByProvider
                    }}</sup>
                    <badge
                      v-if="!chartLoading.revenueByProvider"
                      class="ml-3 badge-theme"
                      >{{ this.activeProvidersCount }} Provider{{
                        this.activeProvidersCount != 1 ? "s" : ""
                      }}</badge
                    >
                    <div class="h4 ml-1 mt-1">
                      Admins and Managers are included.
                    </div>
                  </div>

                  <div class="d-flex mt-2 mb-2 mb-lg-5">
                    <select-control
                      v-model="selectedMonthYear.revenueByProvider"
                      :options="lastYears"
                      :key="selectedMonthYear.revenueByProvider"
                      labelClass="d-none"
                      :disabled="chartLoading.revenueByProvider"
                      @input="getRevenueByProvider(true)"
                      wrapClass="mb-0"
                    ></select-control>
                  </div>
                </div>
                <alert v-if="chartLoading.revenueByProvider" />
                <alert
                  v-if="
                    noChartData.revenueByProvider &&
                    !chartLoading.revenueByProvider
                  "
                  :hideLoader="true"
                  >No data to show</alert
                >
                <div class="chart-container">
                  <div
                    id="revenueByProvider"
                    v-show="!noChartData.revenueByProvider"
                  ></div>
                </div>
              </div>
              <div
                v-show="selectedRevenueName == 'revenueByService'"
                v-if="
                  user &&
                  user.isAdmin &&
                  $can({ key: 'billingReport', expectedPermission: 'view' })
                "
              >
                <div
                  class="row m-0 justify-content-between dashboard-item-header"
                >
                  <div class="dashboard-item-title">
                    Revenue by Service
                    <sup class="dashboard-selected-time">{{
                      selectedMonthYear.revenueByService
                    }}</sup>
                  </div>
                  <div class="d-flex mt-2 mb-2 mb-lg-5">
                    <select-control
                      v-model="selectedMonthYear.revenueByService"
                      :options="lastYears"
                      :key="selectedMonthYear.revenueByService"
                      labelClass="d-none"
                      :disabled="chartLoading.revenueByService"
                      @input="getRevenueByService(true)"
                      wrapClass="mb-0"
                    ></select-control>
                    <button
                      class="btn btn-sm btn-theme pb-0 mr-2 ml-3 radius-99"
                      :disabled="chartLoading.revenueByService"
                      @click="
                        !chartLoading.revenueByService &&
                          showHideRevenueSubServices()
                      "
                    >
                      {{ showRevenueSubServices ? "Hide" : "Show" }} sub
                      services
                      <i
                        :class="`ml-2 fas ${
                          showRevenueSubServices ? 'fa-list-alt' : 'fa-list-alt'
                        }`"
                      ></i>
                    </button>
                  </div>
                </div>
                <alert v-if="chartLoading.revenueByService" />
                <alert
                  v-if="
                    noChartData.revenueByService &&
                    !chartLoading.revenueByService
                  "
                  :hideLoader="true"
                  >No data to show</alert
                >
                <div class="chart-container">
                  <div
                    id="revenueByService"
                    v-show="!noChartData.revenueByService"
                  ></div>
                </div>
              </div>
              <div
                v-show="selectedRevenueName == 'revenueByReferralSource'"
                v-if="user && user.isAdmin"
              >
                <div
                  class="row m-0 justify-content-between dashboard-item-header"
                >
                  <div class="dashboard-item-title">
                    Revenue by Referral Source
                    <sup class="dashboard-selected-time">{{
                      selectedMonthYear.revenueByReferralSource
                    }}</sup>
                  </div>
                  <div class="d-flex mt-2 mb-2 mb-lg-5">
                    <select-control
                      v-model="selectedMonthYear.revenueByReferralSource"
                      :options="lastYears"
                      :key="selectedMonthYear.revenueByReferralSource"
                      labelClass="d-none"
                      :disabled="chartLoading.revenueByReferralSource"
                      @input="getRevenueByReferralSource(true)"
                      wrapClass="mb-0"
                    ></select-control>
                  </div>
                </div>
                <alert v-if="chartLoading.revenueByReferralSource" />
                <alert
                  v-if="
                    noChartData.revenueByReferralSource &&
                    !chartLoading.revenueByReferralSource
                  "
                  :hideLoader="true"
                  >No data to show</alert
                >
                <div class="chart-container">
                  <div
                    id="revenueByReferralSource"
                    v-show="!noChartData.revenueByReferralSource"
                  ></div>
                </div>
              </div>

              <div
                v-show="selectedRevenueName == 'revenueByProfession'"
                v-if="user && user.isAdmin"
              >
                <div
                  class="row m-0 justify-content-between dashboard-item-header"
                >
                  <div class="dashboard-item-title">
                    Revenue by Profession
                    <sup class="dashboard-selected-time">{{
                      selectedMonthYear.revenueByProfession
                    }}</sup>
                  </div>
                  <div class="d-flex mt-2 mb-2 mb-lg-5">
                    <select-control
                      v-model="selectedMonthYear.revenueByProfession"
                      :options="lastYears"
                      :key="selectedMonthYear.revenueByProfession"
                      labelClass="d-none"
                      :disabled="chartLoading.revenueByProfession"
                      @input="getRevenueByProfession(true)"
                      wrapClass="mb-0"
                    ></select-control>
                  </div>
                </div>
                <alert v-if="chartLoading.revenueByProfession" />
                <alert
                  v-if="
                    noChartData.revenueByProfession &&
                    !chartLoading.revenueByProfession
                  "
                  :hideLoader="true"
                  >No data to show</alert
                >
                <div class="chart-container">
                  <div
                    id="revenueByProfession"
                    v-show="!noChartData.revenueByProfession"
                  ></div>
                </div>
              </div>

              <div
                class="
                  chart-layout
                  col-12
                  justify-content-between
                  d-flex
                  align-items-center
                  px-0
                  flex-wrap
                  small-display
                  mt-3
                "
              >
                <span class="label pr-4 mb-1 font-weight-bold"
                  >Chart Layout</span
                >
                <div class="control radius-99 d-inline-flex">
                  <button
                    class="btn radius-99 text-main"
                    :class="{
                      selected: selectedRevenueDirection == 'horizontal',
                    }"
                    @click="updateSelectedRevenueLayout('horizontal')"
                    :disabled="chartLoading[selectedRevenueName]"
                  >
                    Horizontal
                  </button>
                  <button
                    class="btn radius-99 text-main right-btn"
                    :class="{
                      selected: selectedRevenueDirection == 'vertical',
                    }"
                    @click="updateSelectedRevenueLayout('vertical')"
                    :disabled="chartLoading[selectedRevenueName]"
                  >
                    Vertical
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              col-12
              dashboard-item-container dashboard-main-container
              d-flex
              p-0
            "
          >
            <div
              class="
                col-3
                menu-container
                py-4
                desktop-only-force
                d-flex
                flex-column
                justify-content-between
              "
            >
              <div>
                <div class="row m-0 dashboard-item-header">
                  <div class="dashboard-item-title">Budget</div>
                </div>
                <div class="chart-menu">
                  <div
                    class="
                      chart-menu-item
                      pointer-cursor
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    :class="{ selected: menuItem.name === selectedBudgetName }"
                    v-for="menuItem in budgetMenuItems"
                    :key="menuItem.name"
                    @click="updateSelectedBudgetChart(menuItem.name)"
                  >
                    <div class="title-img">
                      <img class="menu-icon mr-3" :src="menuItem.icon" />
                      <span class="title">{{ menuItem.title }}</span>
                    </div>
                    <span class="total" v-if="menuItem.total">{{
                      menuItem.total
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                class="
                  chart-layout
                  justify-content-between
                  d-flex
                  align-items-center
                  mt-5
                  px-0
                  pt-5
                  flex-wrap
                "
              >
                <span class="label pr-4 mb-1 font-weight-bold"
                  >Chart Layout</span
                >
                <div class="control radius-99 d-inline-flex">
                  <button
                    class="btn radius-99 text-main"
                    :class="{
                      selected: selectedBudgetDirection == 'horizontal',
                    }"
                    @click="updateSelectedBudgetLayout('horizontal')"
                    :disabled="
                      chartLoading[selectedBudgetName] ||
                      selectedBudgetName == 'budgetStatus'
                    "
                  >
                    Horizontal
                  </button>
                  <button
                    class="btn radius-99 text-main right-btn"
                    :class="{
                      selected: selectedBudgetDirection == 'vertical',
                    }"
                    @click="updateSelectedBudgetLayout('vertical')"
                    :disabled="
                      chartLoading[selectedBudgetName] ||
                      selectedBudgetName == 'budgetStatus'
                    "
                  >
                    Vertical
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-9 py-4 charts-outer-container">
              <div
                class="row m-0 dashboard-item-header small-display flex-column"
              >
                <div class="dashboard-item-title">Budget</div>
                <select-control
                  v-model="selectedBudgetName"
                  :options="BudgetChartOptions"
                  labelClass="d-none"
                  inputClass="font-weight-bold"
                  @input="updateBudgetChartRender()"
                  wrapClass="m-0 w-100 p-0"
                ></select-control>
              </div>

              <div
                v-show="selectedBudgetName == 'maxHoursByClient'"
                v-if="
                  user &&
                  (user.isAdmin || user.isManagingAdmin) &&
                  $can({ key: 'maxHoursReport', expectedPermission: 'view' })
                "
              >
                <div
                  class="row m-0 justify-content-between dashboard-item-header"
                >
                  <div class="dashboard-item-title">
                    Monthly Budget in Hours by Client
                  </div>
                  <div class="d-flex mt-2 mb-2 mb-lg-5">
                    <select-control
                      v-model="selectedMonthYear.maxHoursByClient"
                      :options="lastMonths"
                      :key="selectedMonthYear.maxHoursByClient"
                      labelClass="d-none"
                      :disabled="chartLoading.maxHoursByClient"
                      @input="getMaxhoursByClient(true)"
                      wrapClass="mb-0"
                    ></select-control>
                  </div>
                </div>
                <alert v-if="chartLoading.maxHoursByClient" />
                <alert
                  v-if="
                    noChartData.maxHoursByClient &&
                    !chartLoading.maxHoursByClient
                  "
                  :hideLoader="true"
                  >No data to show</alert
                >
                <div class="chart-container">
                  <div
                    id="maxHoursByClient"
                    v-show="!noChartData.maxHoursByClient"
                  ></div>
                </div>
              </div>
              <div
                v-show="selectedBudgetName == 'budgetByClient'"
                v-if="
                  user &&
                  (user.isAdmin || user.isManagingAdmin) &&
                  $can({ key: 'maxHoursReport', expectedPermission: 'view' })
                "
              >
                <div
                  class="row m-0 justify-content-between dashboard-item-header"
                >
                  <div class="dashboard-item-title">
                    Monthly Budget in $ by Client
                    <i
                      class="fa fa-xs fa-info-circle pointer-cursor"
                      v-tooltip.top="
                        `The green bars on the chart represent the monthly amount spent
                  for the selected month and the red lines represent the total
                  budget. <br/> You can see the remaining balance and the spending
                  percentage by hovering over the bars.`
                      "
                    ></i>
                  </div>
                  <div class="d-flex mt-2 mb-2 mb-lg-5">
                    <select-control
                      v-model="selectedMonthYear.budgetByClient"
                      :options="lastMonths"
                      :key="selectedMonthYear.budgetByClient"
                      labelClass="d-none"
                      :disabled="chartLoading.budgetByClient"
                      @input="getBudgetByClient(true)"
                      wrapClass="mb-0"
                    ></select-control>
                  </div>
                </div>
                <alert v-if="chartLoading.budgetByClient" />
                <alert
                  v-if="
                    noChartData.budgetByClient && !chartLoading.budgetByClient
                  "
                  :hideLoader="true"
                  >No data to show</alert
                >
                <div class="chart-container">
                  <div
                    id="budgetByClient"
                    v-show="!noChartData.budgetByClient"
                  ></div>
                </div>
              </div>

              <div
                v-show="selectedBudgetName == 'budgetStatus'"
                v-if="
                  user &&
                  (user.isAdmin || user.isManagingAdmin) &&
                  $can({ key: 'maxHoursReport', expectedPermission: 'view' })
                "
              >
                <div
                  class="row m-0 justify-content-between dashboard-item-header"
                >
                  <div class="dashboard-item-title">Budget Status</div>
                </div>
                <div
                  class="chart-container"
                  style="min-height: 408px; height: auto"
                >
                  <budget-update id="#budget-updates" />
                </div>
              </div>

              <div
                class="
                  chart-layout
                  col-12
                  justify-content-between
                  d-flex
                  align-items-center
                  px-0
                  flex-wrap
                  small-display
                  mt-3
                "
              >
                <span class="label pr-4 mb-1 font-weight-bold"
                  >Chart Layout</span
                >
                <div class="control radius-99 d-inline-flex">
                  <button
                    class="btn radius-99 text-main kaka"
                    :class="{
                      selected: selectedBudgetDirection == 'horizontal',
                    }"
                    @click="updateSelectedBudgetLayout('horizontal')"
                    :disabled="
                      chartLoading[selectedBudgetName] ||
                      selectedBudgetName == 'budgetStatus'
                    "
                  >
                    Horizontal
                  </button>
                  <button
                    class="btn radius-99 text-main right-btn"
                    :class="{
                      selected: selectedBudgetDirection == 'vertical',
                    }"
                    @click="updateSelectedBudgetLayout('vertical')"
                    :disabled="
                      chartLoading[selectedBudgetName] ||
                      selectedBudgetName == 'budgetStatus'
                    "
                  >
                    Vertical
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 dashboard-item-container pb-0 mt-0" id="Locations">
            <div
              class="
                row
                m-0
                justify-content-between
                dashboard-item-header
                pb-3
                align-items-center
              "
            >
              <div class="dashboard-item-title pb-md-1">User locations</div>
              <div
                class="see-all pointer-cursor btn see-all-btn"
                @click="navigateToLocations"
              >
                See Full Map <i class="fas fa-chevron-right"></i>
              </div>
            </div>
            <UserLocations
              class="DashboardItem mapItem overflow-hidden"
              src="dashboard"
            />
          </div>
        </div>
      </div>
    </div>
    <modal
      class="card-info-modal themed-modal"
      name="card-info-modal"
      transition="pop-out"
      :height="'auto'"
      :width="'650px'"
    >
      <div class="position-relative modal-body">
        <div
          class="
            vm--top-right-slot
            d-flex
            align-items-center
            justify-content-between
            mb-2
          "
          style="z-index: 1"
        >
          <h2 class="mb-0 ml-3 text-main">{{ learnMoreInfo.title }}</h2>
          <button class="btn p-2 border-0" @click="closeCardInfoModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="body-content modal-body-container">
          <Patients
            v-if="learnMoreInfo.name == 'patients'"
            :maxItems="99999"
            :propAdminId="user.id"
            class="DashboardItem pl-2 pr-2 pl-lg-0 pr-lg-0"
          />

          <Providers
            :onlyMe="true"
            :maxItems="99999"
            class="DashboardItem pl-2 pr-2 pl-lg-0 pr-lg-0"
            v-if="learnMoreInfo.name == 'providers'"
          />
        </div>
      </div>
    </modal>
    <button
      class="btn btn-black m-5"
      v-if="user && user.email == 'eng.karam.eqhash@gmail.com'"
      @click="oneTimeFix()"
    >
      One Time Fix
    </button>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";
import Patients from "./Patients";
import Providers from "../providers/ListProviders.vue";
import dateFormat from "@/utils/dateFormat";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CONSTANT from "./constants";
import { mapState } from "vuex";
import BudgetUpdate from "./BudgetUpdate.vue";
import DashboardAppointments from "../../components/dashboardAppointments.vue";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "Index",
  props: ["userId"],
  components: {
    Patients,
    Providers,
    BudgetUpdate,
    DashboardAppointments,
  },
  mounted() {
    this.setYearsMonths();
    if (this.user.isManagingAdmin) {
      this.getTotalRevenue();
    }
    this.getAnnualRevenue();
    if (this.user.isAdmin) {
      this.getRevenueByReferralSource();
      this.getRevenueByProfession();
      this.getRevenueByProvider();
      this.getRevenueByService();
      this.getRevenueByPatient();
    } else {
      this.getNumberOfAssignedClients();
      this.getNumberOfAssignedProviders();
    }
    this.getMaxhoursByClient();
    this.getBudgetByClient();
  },
  beforeDestroy() {
    this.chartRef.revenueByPatient && this.chartRef.revenueByPatient.destroy();
    this.chartRef.revenueByProvider &&
      this.chartRef.revenueByProvider.destroy();
    this.chartRef.revenueByService && this.chartRef.revenueByService.destroy();
    this.chartRef.maxHoursByClient && this.chartRef.maxHoursByClient.destroy();
    this.chartRef.budgetByClient && this.chartRef.budgetByClient.destroy();
    this.chartRef.annualRevenue && this.chartRef.annualRevenue.destroy();
    this.chartRef.revenueByReferralSource &&
      this.chartRef.revenueByReferralSource.destroy();
    this.chartRef.revenueByProfession &&
      this.chartRef.revenueByProfession.destroy();
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      totalRevenue: {},
      totalAllRevenue: {},
      gettingTotalRevenue: false,
      learnMoreInfo: {
        name: "",
        title: "",
      },
      numberOfAssignedClients: {
        loading: false,
        data: "",
      },
      numberOfAssignedProviders: {
        loading: false,
        data: "",
      },
      chartLoading: {
        annualRevenue: true,
        revenueByProvider: true,
        revenueByService: true,
        revenueByPatient: true,
        revenueByReferralSource: true,
        revenueByProfession: true,
        maxHoursByClient: true,
        budgetByClient: true,
      },
      revenueChartOptions: [
        {
          label: "Client",
          value: "revenueByPatient",
        },
        {
          label: "Provider",
          value: "revenueByProvider",
        },
        {
          label: "Service",
          value: "revenueByService",
        },
        {
          label: "Profession",
          value: "revenueByProfession",
        },
        {
          label: "Referral Source",
          value: "revenueByReferralSource",
        },
      ],
      BudgetChartOptions: [
        {
          label: "In Hours By Client",
          value: "maxHoursByClient",
        },
        {
          label: "In $ By Client",
          value: "budgetByClient",
        },
        {
          label: "Status",
          value: "budgetStatus",
        },
      ],
      chartRef: {
        revenueByPatient: null,
        revenueByProvider: null,
        revenueByService: null,
        revenueByReferralSource: null,
        revenueByProfession: null,
        maxHoursByClient: null,
        budgetByClient: null,
        annualRevenue: null,
      },
      selectedRevenueName: "revenueByPatient",
      selectedRevenueDirection: "vertical",
      selectedBudgetName: "maxHoursByClient",
      selectedBudgetDirection: "vertical",
      lastYears: [],
      lastMonths: [],
      selectedMonthYear: {
        annualRevenue: new Date().getFullYear(),
        revenueByPatient: new Date().getFullYear(),
        revenueByProvider: new Date().getFullYear(),
        revenueByService: new Date().getFullYear(),
        revenueByReferralSource: new Date().getFullYear(),
        revenueByProfession: new Date().getFullYear(),
        maxHoursByClient: `${new Date().getFullYear()}__${new Date().getMonth()}`,
        budgetByClient: `${new Date().getFullYear()}__${new Date().getMonth()}`,
      },
      noChartData: {
        annualRevenue: false,
        revenueByProvider: false,
        revenueByService: false,
        revenueByPatient: false,
        revenueByReferralSource: false,
        revenueByProfession: false,
        maxHoursByClient: false,
        budgetByClient: false,
      },
      revenueByProvider: CONSTANT.barChart,
      revenueByProviderHorizontal: CONSTANT.barChartHorizontal,
      revenueByService: CONSTANT.barChart2,
      revenueByServiceHorizontal: CONSTANT.barChartHorizontal2,
      revenueByPatient: CONSTANT.barChart3,
      revenueByPatientHorizontal: CONSTANT.barChartHorizontal3,
      revenueByReferralSource: CONSTANT.referralSourceBarChart,
      revenueByReferralSourceHorizontal:
        CONSTANT.referralSourceBarChartHorizontal,
      revenueByProfession: CONSTANT.barChart5,
      revenueByProfessionHorizontal: CONSTANT.barChartHorizontal5,
      annualRevenue: CONSTANT.areaChart,
      maxHoursByClient: CONSTANT.maxBarChart,
      maxHoursByClientHorizontal: CONSTANT.maxBarChartHorizontal,
      budgetByClient: CONSTANT.budgetBarChart,
      budgetByClientHorizontal: CONSTANT.budgetBarChartHorizontal,
      showRevenueSubServices: false,
      isBudgetUpdatesVisible: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      allUsers: (state) => state.security.usersWithRoles,
      totalAssignedProviders: (state) =>
        state.providers.providerUsers.pagination.total,
      assignedProvidersloading: (state) =>
        state.providers.providerUsers.loading,
    }),
    activeProvidersCount: function () {
      if (this.allUsers && this.allUsers.length) {
        return this.allUsers.filter(
          (user) => [2, 5, 1].includes(user.roleId) && user.status == "ACTIVE"
        ).length;
      }
      return 0;
    },
    activeClientsCount: function () {
      if (this.allUsers && this.allUsers.length) {
        return this.allUsers.filter(
          (user) =>
            user.roleId == 3 &&
            (user.status == "ACTIVE" || user.status == "PROTECTED")
        ).length;
      }
      return 0;
    },
    revenueMenuItems: function () {
      return [
        {
          name: "revenueByPatient",
          title: "Client",
          icon: require("@/assets/img/icons/revenue-client.svg"),
          total: `${this.activeClientsCount} Client${
            this.activeClientsCount != 1 ? "s" : ""
          }`,
        },
        {
          name: "revenueByProvider",
          title: "Provider",
          icon: require("@/assets/img/icons/revenue-provider.svg"),
          total: `${this.activeProvidersCount} Provider${
            this.activeProvidersCount != 1 ? "s" : ""
          }`,
        },
        {
          name: "revenueByService",
          title: "Service",
          icon: require("@/assets/img/icons/revenue-service.svg"),
        },
        {
          name: "revenueByProfession",
          title: "Profession",
          icon: require("@/assets/img/icons/revenue-profession.svg"),
        },
        {
          name: "revenueByReferralSource",
          title: "Referral Source",
          icon: require("@/assets/img/icons/revenue-referral.svg"),
        },
      ];
    },
    budgetMenuItems: function () {
      return [
        {
          name: "maxHoursByClient",
          title: "In Hours By Client",
          icon: require("@/assets/img/icons/hours-client.svg"),
        },
        {
          name: "budgetByClient",
          title: "In $ By Client",
          icon: require("@/assets/img/icons/money-client.svg"),
        },
        {
          name: "budgetStatus",
          title: "Status",
          icon: require("@/assets/img/icons/money-client.svg"),
        },
      ];
    },
  },
  methods: {
    oneTimeFix: async function () {
      const res = await this.$http.get("forms-one-time-fix");
      console.log(res);
    },
    setYearsMonths: function () {
      const lastYears = [];
      for (let i = 0; i <= 50; i++) {
        lastYears.push({
          label: this.currentYear - i,
          value: this.currentYear - i,
        });
      }
      this.lastYears = lastYears;

      let year = this.currentYear;
      let month = new Date().getMonth();
      let lastMonths = [];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      for (let i = 0; i <= 200; i++) {
        lastMonths.push({
          label: `${months[month]} ${year}`,
          value: `${year}__${month--}`,
        });
        if (month < 0) {
          month = 11;
          year--;
        }
      }
      this.lastMonths = lastMonths;
    },
    getTotalRevenue: async function () {
      try {
        this.gettingTotalRevenue = true;
        const res = await this.$http.get("dashboard/totalRevenue");
        this.gettingTotalRevenue = false;
        this.totalRevenue = res.data.data;
      } catch (err) {
        this.gettingTotalRevenue = false;
        this.totalRevenue = {};
      }
    },
    getAnnualRevenue: async function (yearChanged) {
      try {
        if (this.chartRef.annualRevenue && yearChanged) {
          this.chartLoading.annualRevenue = true;
          this.chartRef.annualRevenue.destroy();
        }

        const datesInterval = [];
        let lastMonth = dayjs().month() + 1;
        let currentYear = this.selectedMonthYear.annualRevenue;
        if (this.selectedMonthYear.annualRevenue != this.currentYear) {
          lastMonth = 12;
        }
        for (let i = 1; i <= lastMonth; i++) {
          const startOfMonth = `${dateFormat(
            dayjs(`${currentYear}-${i}-02`).startOf("month"),
            "YYYY-MM-DD"
          )}T00:00:00.000000Z`;
          const endOfMonth = `${dateFormat(
            dayjs(`${currentYear}-${i}-02`).endOf("month"),
            "YYYY-MM-DD"
          )}T23:59:59.000000Z`;
          const canadaOffsetStart = dayjs
            .tz(new Date(startOfMonth), "America/Toronto")
            .utcOffset();
          const canadaOffsetEnd = dayjs
            .tz(new Date(endOfMonth), "America/Toronto")
            .utcOffset();

          const startDateTime = dayjs(startOfMonth)
            .add(canadaOffsetStart, "minute")
            .utc()
            .format();

          const endDateTime = dayjs(endOfMonth)
            .add(canadaOffsetEnd, "minute")
            .utc()
            .format();

          datesInterval.push({
            start: startDateTime,
            end: endDateTime,
          });
        }

        this.noChartData.annualRevenue = false;
        if (this.totalAllRevenue[`${currentYear - 1}`] == undefined) {
          this.getTotalAllRevenue(currentYear - 1);
        }

        const res = await this.$http.get(
          `dashboard/totalRevenuePerMonth?datesInterval=${encodeURIComponent(
            JSON.stringify(datesInterval)
          )}`
        );
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        let hasData = false;
        let totalAnnual = 0;

        this.annualRevenue.series[0].data = res.data.map((val, index) => {
          if (val) {
            hasData = true;
            totalAnnual += val;
          }
          return {
            x: months[index],
            y: parseFloat(val || "0").toFixed(2),
          };
        });
        this.noChartData.annualRevenue = !hasData;
        this.totalAllRevenue[`${this.selectedMonthYear.annualRevenue}`] =
          totalAnnual;
        if (document.querySelector("#annualRevenue")) {
          this.chartRef.annualRevenue = new ApexCharts(
            document.querySelector("#annualRevenue"),
            this.annualRevenue
          );
          this.chartLoading.annualRevenue = false;
          this.chartRef.annualRevenue.render();
        }
      } catch (err) {
        this.noChartData.annualRevenue = true;
        if (document.querySelector("#annualRevenue")) {
          this.annualRevenue.series[0].data = [];
          this.chartRef.annualRevenue = new ApexCharts(
            document.querySelector("#annualRevenue"),
            this.annualRevenue
          );
          this.chartLoading.annualRevenue = false;
          this.chartRef.annualRevenue.render();
        }
      }
    },
    getTotalAllRevenue: function (prevYear) {
      const startOfYear = `${dateFormat(
        dayjs(`${prevYear}-01-02`).startOf("month"),
        "YYYY-MM-DD"
      )}T00:00:00.000000Z`;
      const endOfYear = `${dateFormat(
        dayjs(`${prevYear}-12-02`).endOf("month"),
        "YYYY-MM-DD"
      )}T23:59:59.000000Z`;
      const canadaOffsetStart = dayjs
        .tz(new Date(startOfYear), "America/Toronto")
        .utcOffset();
      const canadaOffsetEnd = dayjs
        .tz(new Date(endOfYear), "America/Toronto")
        .utcOffset();

      const startDateTime = dayjs(startOfYear)
        .add(canadaOffsetStart, "minute")
        .utc()
        .format();

      const endDateTime = dayjs(endOfYear)
        .add(canadaOffsetEnd, "minute")
        .utc()
        .format();

      this.$http
        .get(
          `dashboard/totalAllRevenue?start=${startDateTime}&end=${endDateTime}`
        )
        .then((res) => {
          this.totalAllRevenue[`${prevYear}`] =
            typeof res.data === "number" && isFinite(res.data) ? res.data : 0;
        })
        .catch(() => {});
    },
    getRevenueByProvider: async function (yearChanged) {
      try {
        if (this.chartRef.revenueByProvider && yearChanged) {
          this.chartLoading.revenueByProvider = true;
          this.chartRef.revenueByProvider.destroy();
        }
        const currentYear = this.selectedMonthYear.revenueByProvider;
        const firstYearDay = `${dateFormat(
          dayjs(`${currentYear}-01-02`).startOf("month"),
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;
        const lastYearDay = `${dateFormat(
          dayjs(`${currentYear}-12-02`).endOf("month"),
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;
        const canadaOffsetStart = dayjs
          .tz(new Date(firstYearDay), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(lastYearDay), "America/Toronto")
          .utcOffset();

        const startDateTime = dayjs(firstYearDay)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        const endDateTime = dayjs(lastYearDay)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        this.noChartData.revenueByProvider = false;

        const res = await this.$http.get(
          `dashboard/revenueByProvider?start=${startDateTime}&end=${endDateTime}`
        );
        const data = res.data.data;

        if (Array.isArray(data) && !data.length) {
          this.noChartData.revenueByProvider = true;
        }
        const horizontalSeries = [];
        const horizontalCategories = [];

        let seriesData = Object.keys(data)
          .sort(
            (a, b) => parseFloat(data[b] || "0") - parseFloat(data[a] || "0")
          )
          .map((key) => {
            horizontalSeries.push(parseFloat(data[key] || "0").toFixed(2));
            horizontalCategories.push(key);
            let firstName = key.split(", ")[1].split(" (ID_")[0].split(" ");
            let firstLetters = "";
            for (let i = 0; i < firstName.length; i++) {
              firstLetters = `${firstLetters}${firstName[i][0]}`;
            }
            let secondName = key.split(", ")[0].split(" ");
            let secondLetters = "";
            for (let i = 0; i < secondName.length; i++) {
              secondLetters = `${secondLetters}${secondName[i][0]}`;
            }
            const sureName = `${firstLetters}${secondLetters}`.toUpperCase();
            return {
              x: sureName,
              y: parseFloat(data[key] || "0").toFixed(2),
              fullName: key,
            };
          });

        this.revenueByProvider.series[0].data =
          this.handleDuplicateXChart(seriesData);

        this.revenueByProviderHorizontal.chart.height =
          Object.keys(data).length < 4 ? 200 : Object.keys(data).length * 50;
        this.revenueByProviderHorizontal.series[0].data = horizontalSeries;
        this.revenueByProviderHorizontal.xaxis.categories =
          horizontalCategories;

        this.revenueByProvider.xaxis.max =
          this.revenueByProvider.xaxis.originMax >
          this.revenueByProvider.series[0].data.length
            ? this.revenueByProvider.series[0].data.length
            : this.revenueByProvider.xaxis.originMax;

        if (this.selectedRevenueName == "revenueByProvider") {
          this.updateChartRender();
        }
      } catch (err) {
        this.noChartData.revenueByProvider = true;
        if (document.querySelector("#revenueByProvider")) {
          this.revenueByProvider.series[0].data = [];
          this.chartRef.revenueByProvider = new ApexCharts(
            document.querySelector("#revenueByProvider"),
            this.revenueByProvider
          );
          this.chartLoading.revenueByProvider = false;
          this.chartRef.revenueByProvider.render();
        }
      }
    },
    getRevenueByService: async function (yearChanged) {
      try {
        if (document.querySelector(`#revenueByService`) && yearChanged) {
          this.chartLoading.revenueByService = true;
        }
        const currentYear = this.selectedMonthYear.revenueByService;
        const firstYearDay = `${dateFormat(
          dayjs(`${currentYear}-01-02`).startOf("month"),
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;
        const lastYearDay = `${dateFormat(
          dayjs(`${currentYear}-12-02`).endOf("month"),
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;
        const canadaOffsetStart = dayjs
          .tz(new Date(firstYearDay), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(lastYearDay), "America/Toronto")
          .utcOffset();

        const startDateTime = dayjs(firstYearDay)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        const endDateTime = dayjs(lastYearDay)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        if (this.chartRef.revenueByService) {
          this.chartRef.revenueByService.destroy();
        }
        if (this.chartRef.revenueByServiceHorizontal) {
          this.chartRef.revenueByServiceHorizontal.destroy();
        }

        let url = `dashboard/revenueByService?start=${startDateTime}&end=${endDateTime}`;
        if (this.showRevenueSubServices) {
          url = `${url}&include_sub=true`;
        }

        this.noChartData.revenueByService = false;

        const res = await this.$http.get(url);
        const data = res.data.data;
        const horizontalSeries = [];
        data
          .filter(
            (item) =>
              item.name.toLowerCase() != "non-billable service" &&
              item.code != "NON-BILLABLE"
          )
          .sort(
            (a, b) => parseFloat(b.total || "0") - parseFloat(a.total || "0")
          )
          .forEach((item) => {
            horizontalSeries.push({
              x: item.dashboard_name || item.name,
              y: parseFloat(item.total || "0").toFixed(2),
            });
            if (item.subServices) {
              item.subServices = item.subServices.filter((item) =>
                parseFloat(item.total)
              );
              item.subServices.forEach((sub) => {
                horizontalSeries.push({
                  x: sub.dashboard_name || sub.name,
                  y: parseFloat(sub.total || "0").toFixed(2),
                  fillColor: "#16AF70",
                  strokeColor: "#16AF70",
                });
              });
            }
          });

        if (!horizontalSeries.length) {
          this.noChartData.revenueByService = true;
        }

        this.revenueByServiceHorizontal.chart.height =
          horizontalSeries.length < 4 ? 200 : horizontalSeries.length * 50;
        this.revenueByService.series[0].data =
          this.revenueByServiceHorizontal.series[0].data =
            this.handleDuplicateXChart(horizontalSeries);
        this.revenueByService.xaxis.max =
          this.revenueByService.xaxis.originMax >
          this.revenueByService.series[0].data.length
            ? this.revenueByService.series[0].data.length
            : this.revenueByService.xaxis.originMax;

        if (this.selectedRevenueName == "revenueByService") {
          this.updateChartRender();
        }
      } catch (err) {
        this.noChartData.revenueByService = true;
        if (document.querySelector("#revenueByService")) {
          this.revenueByService.series[0].data = [];
          this.chartRef.revenueByService = new ApexCharts(
            document.querySelector("#revenueByService"),
            this.revenueByService
          );
          this.chartLoading.revenueByService = false;
          this.chartRef.revenueByService.render();
        }
      }
    },
    showHideRevenueSubServices: function () {
      this.showRevenueSubServices = !this.showRevenueSubServices;
      this.chartLoading.revenueByService = true;
      this.getRevenueByService();
    },
    getRevenueByPatient: async function (yearChanged) {
      try {
        if (this.chartRef.revenueByPatient && yearChanged) {
          this.chartLoading.revenueByPatient = true;
          this.chartRef.revenueByPatient.destroy();
        }
        const currentYear = this.selectedMonthYear.revenueByPatient;
        const firstYearDay = `${dateFormat(
          dayjs(`${currentYear}-01-02`).startOf("month"),
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;
        const lastYearDay = `${dateFormat(
          dayjs(`${currentYear}-12-02`).endOf("month"),
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;
        const canadaOffsetStart = dayjs
          .tz(new Date(firstYearDay), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(lastYearDay), "America/Toronto")
          .utcOffset();

        const startDateTime = dayjs(firstYearDay)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        const endDateTime = dayjs(lastYearDay)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        this.noChartData.revenueByPatient = false;

        const res = await this.$http.get(
          `dashboard/revenuePerPatient?start=${startDateTime}&end=${endDateTime}`
        );
        const data = res.data.data;
        if (Array.isArray(data) && !data.length) {
          this.noChartData.revenueByPatient = true;
        }
        const horizontalSeries = [];
        const horizontalCategories = [];
        const seriesData = Object.keys(data)
          .sort(
            (a, b) => parseFloat(data[b] || "0") - parseFloat(data[a] || "0")
          )
          .map((key) => {
            horizontalSeries.push(parseFloat(data[key] || "0").toFixed(2));
            horizontalCategories.push(key);
            let firstName = key.split(", ")[1].split(" (ID_")[0].split(" ");
            let firstLetters = "";
            for (let i = 0; i < firstName.length; i++) {
              firstLetters = `${firstLetters}${firstName[i][0]}`;
            }
            let secondName = key.split(", ")[0].split(" ");
            let secondLetters = "";
            for (let i = 0; i < secondName.length; i++) {
              secondLetters = `${secondLetters}${secondName[i][0]}`;
            }
            const sureName = `${firstLetters}${secondLetters}`.toUpperCase();
            return {
              x: sureName,
              y: parseFloat(data[key] || "0").toFixed(2),
              fullName: key,
            };
          });

        this.revenueByPatient.series[0].data =
          this.handleDuplicateXChart(seriesData);

        this.revenueByPatientHorizontal.chart.height =
          Object.keys(data).length < 4 ? 200 : Object.keys(data).length * 50;
        this.revenueByPatientHorizontal.series[0].data = horizontalSeries;
        this.revenueByPatientHorizontal.xaxis.categories = horizontalCategories;
        this.revenueByPatient.xaxis.max =
          this.revenueByPatient.xaxis.originMax >
          this.revenueByPatient.series[0].data.length
            ? this.revenueByPatient.series[0].data.length
            : this.revenueByPatient.xaxis.originMax;

        if (this.selectedRevenueName == "revenueByPatient") {
          this.updateChartRender();
        }
      } catch (err) {
        this.noChartData.revenueByPatient = true;
        if (document.querySelector("#revenueByPatient")) {
          this.revenueByPatient.series[0].data = [];
          this.chartRef.revenueByPatient = new ApexCharts(
            document.querySelector("#revenueByPatient"),
            this.revenueByPatient
          );
          this.chartLoading.revenueByPatient = false;
          this.chartRef.revenueByPatient.render();
        }
      }
    },
    handleDuplicateXChart: function (data, splitSlash = false) {
      const dist = JSON.parse(JSON.stringify(data));
      const xValues = {};
      dist.forEach((item) => {
        if (!splitSlash) {
          if (Object.keys(xValues).includes(item.x)) {
            xValues[item.x]++;
            item.x = `${item.x} (${xValues[item.x]})`;
          } else {
            xValues[item.x] = 0;
          }
        } else {
          if (Object.keys(xValues).includes(item.x)) {
            xValues[item.x]++;
            item.x = `${item.x.split("/")[0]}/${item.x.split("/")[1]} (${
              xValues[item.x]
            })`;
          } else {
            xValues[item.x] = 0;
          }
        }
      });
      return dist;
    },
    getMaxhoursByClient: async function (monthChanged) {
      try {
        if (this.chartRef.maxHoursByClient && monthChanged) {
          this.chartLoading.maxHoursByClient = true;
          this.chartRef.maxHoursByClient.destroy();
        }
        const selectedYear =
          this.selectedMonthYear.maxHoursByClient.split("__")[0];
        const selectedMonth =
          parseInt(this.selectedMonthYear.maxHoursByClient.split("__")[1]) + 1;

        const startOfMonth = `${selectedYear}-${
          selectedMonth > 9 ? selectedMonth : "0" + selectedMonth
        }-01T00:00:00.000000Z`;
        const endOfMonth = `${dateFormat(
          dayjs(
            `${selectedYear}-${
              selectedMonth > 9 ? selectedMonth : "0" + selectedMonth
            }-01`
          ).endOf("month"),
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;
        const canadaOffsetStart = dayjs
          .tz(new Date(startOfMonth), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(endOfMonth), "America/Toronto")
          .utcOffset();

        const startDateTime = dayjs(startOfMonth)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        const endDateTime = dayjs(endOfMonth)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        this.noChartData.maxHoursByClient = false;
        const res = await this.$http.get(
          "dashboard/budgetPerClientService" +
            `?start=${startDateTime}&end=${endDateTime}`
        );
        const data = res.data;

        if (Array.isArray(data) && !data.length) {
          this.noChartData.maxHoursByClient = true;
        }
        this.maxHoursByClientHorizontal.chart.height =
          data.length < 4 ? 200 : data.length * 50;

        const seriesData = data.map((item) => {
          const zValue = `${item.maxHourValue}H ${item.period_type}`;
          let firstName = item.client_name.split(", ")[1].split(" ");
          let firstLetters = "";
          for (let i = 0; i < firstName.length; i++) {
            firstLetters = `${firstLetters}${firstName[i][0]}`;
          }
          let secondName = item.client_name.split(", ")[0].split(" ");
          let secondLetters = "";
          for (let i = 0; i < secondName.length; i++) {
            secondLetters = `${secondLetters}${secondName[i][0]}`;
          }
          const sureName = `${firstLetters}${secondLetters}`.toUpperCase();
          return {
            x: `${sureName}/${item.serviceName}`,
            fullName: `${item.client_name} (ID_${item.user_id})/${item.serviceName}`,
            y: parseFloat(item.thisMonthAppointmentsHrsTotal || "0").toFixed(2),
            z: `(${zValue.trim()})`,
            goals: [
              {
                name: "Budget in Hours:",
                value: parseFloat(item.totalMaxHours),
                strokeColor: "#d7263d",
              },
            ],
          };
        });

        this.maxHoursByClient.series[0].data = this.handleDuplicateXChart(
          seriesData,
          true
        );
        const horizontalSeriesData = JSON.parse(JSON.stringify(seriesData));
        horizontalSeriesData.forEach((item) => {
          item.x = item.fullName;
        });
        this.maxHoursByClientHorizontal.series[0].data =
          this.handleDuplicateXChart(horizontalSeriesData);

        this.maxHoursByClient.xaxis.max =
          this.maxHoursByClient.xaxis.originMax >
          this.maxHoursByClient.series[0].data.length
            ? this.maxHoursByClient.series[0].data.length
            : this.maxHoursByClient.xaxis.originMax;

        if (this.selectedBudgetName == "maxHoursByClient") {
          this.updateBudgetChartRender();
        }
      } catch (err) {
        this.noChartData.maxHoursByClient = true;
        if (document.querySelector("#maxHoursByClient")) {
          this.maxHoursByClient.series[0].data = [];
          this.chartRef.maxHoursByClient = new ApexCharts(
            document.querySelector("#maxHoursByClient"),
            this.maxHoursByClient
          );
          this.chartLoading.maxHoursByClient = false;
          this.chartRef.maxHoursByClient.render();
        }
      }
    },
    getRevenueByReferralSource: function (yearChanged) {
      if (this.chartRef.revenueByReferralSource && yearChanged) {
        this.chartLoading.revenueByReferralSource = true;
        this.chartRef.revenueByReferralSource.destroy();
      }
      const currentYear = this.selectedMonthYear.revenueByReferralSource;
      const firstYearDay = `${dateFormat(
        dayjs(`${currentYear}-01-02`).startOf("month"),
        "YYYY-MM-DD"
      )}T00:00:00.000000Z`;
      const lastYearDay = `${dateFormat(
        dayjs(`${currentYear}-12-02`).endOf("month"),
        "YYYY-MM-DD"
      )}T23:59:59.000000Z`;
      const canadaOffsetStart = dayjs
        .tz(new Date(firstYearDay), "America/Toronto")
        .utcOffset();
      const canadaOffsetEnd = dayjs
        .tz(new Date(lastYearDay), "America/Toronto")
        .utcOffset();

      const startDateTime = dayjs(firstYearDay)
        .add(canadaOffsetStart, "minute")
        .utc()
        .format();

      const endDateTime = dayjs(lastYearDay)
        .add(canadaOffsetEnd, "minute")
        .utc()
        .format();

      this.noChartData.revenueByReferralSource = false;
      this.$http
        .get(
          "dashboard/revenue-by-referral-source" +
            `?start=${startDateTime}&end=${endDateTime}`
        )
        .then((res) => {
          const data = res.data.data;

          if (Array.isArray(data) && !data.length) {
            this.noChartData.revenueByReferralSource = true;
          }
          const horizontalCategories = [];

          let seriesData = data
            .sort(
              (a, b) =>
                parseFloat(b.revenue || "0") - parseFloat(a.revenue || "0")
            )
            .map((item) => {
              horizontalCategories.push(item.name);
              let firstName = item.name.split(", ")[1].split(" ");
              let firstLetters = "";
              for (let i = 0; i < firstName.length; i++) {
                firstLetters = `${firstLetters}${firstName[i][0]}`;
              }
              let secondName = item.name.split(", ")[0].split(" ");
              let secondLetters = "";
              for (let i = 0; i < secondName.length; i++) {
                secondLetters = `${secondLetters}${secondName[i][0]}`;
              }
              const sureName = `${firstLetters}${secondLetters}`.toUpperCase();
              return {
                x: sureName,
                y: parseFloat(item.revenue || "0").toFixed(2),
                u: parseFloat(item.revenuePercentage || "0").toFixed(3),
                fullName: item.name,
              };
            });

          seriesData = this.handleDuplicateXChart(seriesData);

          this.revenueByReferralSourceHorizontal.chart.height =
            data.length < 4 ? 200 : data.length * 50;
          this.revenueByReferralSource.series[0].data = seriesData;
          this.revenueByReferralSourceHorizontal.series[0].data = seriesData;
          this.revenueByReferralSourceHorizontal.xaxis.categories =
            horizontalCategories;

          this.revenueByReferralSource.xaxis.max =
            this.revenueByReferralSource.xaxis.originMax >
            this.revenueByReferralSource.series[0].data.length
              ? this.revenueByReferralSource.series[0].data.length
              : this.revenueByReferralSource.xaxis.originMax;

          if (this.selectedRevenueName == "revenueByReferralSource") {
            this.updateChartRender();
          }
        })
        .catch(() => {
          this.noChartData.revenueByReferralSource = true;
          if (document.querySelector("#revenueByReferralSource")) {
            this.revenueByReferralSource.series[0].data = [];
            this.chartRef.revenueByReferralSource = new ApexCharts(
              document.querySelector("#revenueByReferralSource"),
              this.revenueByReferralSource
            );
            this.chartLoading.revenueByReferralSource = false;
            this.chartRef.revenueByReferralSource.render();
          }
        });
    },
    getRevenueByProfession: async function (yearChanged) {
      try {
        if (this.chartRef.revenueByProfession && yearChanged) {
          this.chartLoading.revenueByProfession = true;
          this.chartRef.revenueByProfession.destroy();
        }
        const currentYear = this.selectedMonthYear.revenueByProfession;
        const firstYearDay = `${dateFormat(
          dayjs(`${currentYear}-01-02`).startOf("month"),
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;
        const lastYearDay = `${dateFormat(
          dayjs(`${currentYear}-12-02`).endOf("month"),
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;
        const canadaOffsetStart = dayjs
          .tz(new Date(firstYearDay), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(lastYearDay), "America/Toronto")
          .utcOffset();

        const startDateTime = dayjs(firstYearDay)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        const endDateTime = dayjs(lastYearDay)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        this.noChartData.revenueByProfession = false;

        const res = await this.$http.get(
          `dashboard/revenueByProfession?start=${startDateTime}&end=${endDateTime}`
        );
        const data = res.data.data;

        if (Array.isArray(data) && !data.length) {
          this.noChartData.revenueByProfession = true;
        }
        const horizontalSeries = [];
        const horizontalCategories = [];

        const seriesData = Object.keys(data)
          .sort(
            (a, b) => parseFloat(data[b] || "0") - parseFloat(data[a] || "0")
          )
          .map((key) => {
            horizontalSeries.push(parseFloat(data[key] || "0").toFixed(2));
            horizontalCategories.push(key);
            return {
              x: key,
              y: parseFloat(data[key] || "0").toFixed(2),
            };
          });

        this.revenueByProfession.series[0].data =
          this.handleDuplicateXChart(seriesData);

        this.revenueByProfessionHorizontal.chart.height =
          Object.keys(data).length < 4 ? 200 : Object.keys(data).length * 50;
        this.revenueByProfessionHorizontal.series[0].data = horizontalSeries;
        this.revenueByProfessionHorizontal.xaxis.categories =
          horizontalCategories;

        this.revenueByProfession.xaxis.max =
          this.revenueByProfession.xaxis.originMax >
          this.revenueByProfession.series[0].data.length
            ? this.revenueByProfession.series[0].data.length
            : this.revenueByProfession.xaxis.originMax;

        if (this.selectedRevenueName == "revenueByProfession") {
          this.updateChartRender();
        }
      } catch (err) {
        this.noChartData.revenueByProfession = true;
        if (document.querySelector("#revenueByProfession")) {
          this.revenueByProfession.series[0].data = [];
          this.chartRef.revenueByProfession = new ApexCharts(
            document.querySelector("#revenueByProfession"),
            this.revenueByProfession
          );
          this.chartLoading.revenueByProfession = false;
          this.chartRef.revenueByProfession.render();
        }
      }
    },
    getBudgetByClient: async function (monthChanged) {
      try {
        if (this.chartRef.budgetByClient && monthChanged) {
          this.chartLoading.budgetByClient = true;
          this.chartRef.budgetByClient.destroy();
        }
        const selectedYear =
          this.selectedMonthYear.budgetByClient.split("__")[0];
        const selectedMonth =
          parseInt(this.selectedMonthYear.budgetByClient.split("__")[1]) + 1;

        const startOfMonth = `${selectedYear}-${
          selectedMonth > 9 ? selectedMonth : "0" + selectedMonth
        }-01T00:00:00.000000Z`;
        const endOfMonth = `${dateFormat(
          dayjs(
            `${selectedYear}-${
              selectedMonth > 9 ? selectedMonth : "0" + selectedMonth
            }-01`
          ).endOf("month"),
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;
        const canadaOffsetStart = dayjs
          .tz(new Date(startOfMonth), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(endOfMonth), "America/Toronto")
          .utcOffset();

        const startDateTime = dayjs(startOfMonth)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        const endDateTime = dayjs(endOfMonth)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        this.noChartData.budgetByClient = false;
        const res = await this.$http.get(
          "dashboard/budgetPerClientService" +
            `?start=${startDateTime}&end=${endDateTime}`
        );
        let data = res.data;
        data = data.filter((item) => item.budget != null);

        if (Array.isArray(data) && !data.length) {
          this.noChartData.budgetByClient = true;
        }
        this.budgetByClientHorizontal.chart.height =
          data.length < 4 ? 200 : data.length * 50;
        let seriesData = data.map((item) => {
          const zValue = `$${parseFloat(item.defined_budget).toFixed(2)} ${
            item.period_type
          }`;

          let firstName = item.client_name.split(", ")[1].split(" ");
          let firstLetters = "";
          for (let i = 0; i < firstName.length; i++) {
            firstLetters = `${firstLetters}${firstName[i][0]}`;
          }
          let secondName = item.client_name.split(", ")[0].split(" ");
          let secondLetters = "";
          for (let i = 0; i < secondName.length; i++) {
            secondLetters = `${secondLetters}${secondName[i][0]}`;
          }
          const sureName = `${firstLetters}${secondLetters}`.toUpperCase();
          return {
            x: `${sureName}/${item.serviceName}`,
            fullName: `${item.client_name} (ID_${item.user_id})/${item.serviceName}`,
            y: parseFloat(item.thisMonthAppointmentsBudgetTotal || "0").toFixed(
              2
            ),
            z: `(${zValue.trim()})`,
            remainingBalance: parseFloat(
              item.totalBudget - item.spending_amount
            ).toFixed(2),
            spendingAmount: parseFloat(item.spending_amount).toFixed(2),
            goals: [
              {
                name: "Total budget in $:",
                value: parseFloat(item.totalBudget),
                strokeColor: "#d7263d",
              },
            ],
          };
        });

        seriesData = this.handleDuplicateXChart(seriesData, true);

        const horizontalSeriesData = JSON.parse(JSON.stringify(seriesData));
        horizontalSeriesData.forEach((item) => {
          item.x = item.fullName;
        });
        this.budgetByClientHorizontal.series[0].data =
          this.handleDuplicateXChart(horizontalSeriesData);
        this.budgetByClient.series[0].data = seriesData;

        this.budgetByClient.xaxis.max =
          this.budgetByClient.xaxis.originMax >
          this.budgetByClient.series[0].data.length
            ? this.budgetByClient.series[0].data.length
            : this.budgetByClient.xaxis.originMax;

        if (this.selectedBudgetName == "budgetByClient") {
          this.updateBudgetChartRender();
        }
      } catch (err) {
        this.noChartData.budgetByClient = true;
        if (document.querySelector("#budgetByClient")) {
          this.budgetByClient.series[0].data = [];
          this.chartRef.budgetByClient = new ApexCharts(
            document.querySelector("#budgetByClient"),
            this.budgetByClient
          );
          this.chartLoading.budgetByClient = false;
          this.chartRef.budgetByClient.render();
        }
      }
    },
    changeChartType: function (chartSelector, isBudget = false) {
      if (this.chartRef[chartSelector]) {
        this.chartLoading[chartSelector] = true;
        this.chartRef[chartSelector].destroy();
      }

      const direction = isBudget
        ? this.selectedBudgetDirection
        : this.selectedRevenueDirection;

      if (document.querySelector(`#${chartSelector}`)) {
        setTimeout(() => {
          if (document.querySelector(`#${chartSelector}`)) {
            if (direction == "vertical") {
              this.chartRef[chartSelector] = new ApexCharts(
                document.querySelector(`#${chartSelector}`),
                this[`${chartSelector}`]
              );
            } else {
              this.chartRef[chartSelector] = new ApexCharts(
                document.querySelector(`#${chartSelector}`),
                this[`${chartSelector}Horizontal`]
              );
            }

            this.chartRef[chartSelector].render();
          }
          this.chartLoading[chartSelector] = false;
        }, 100);
      }
    },
    navigateToClients: function () {
      this.$router.push({ path: "/clients?me=true" });
    },
    navigateToProviders: function () {
      this.$router.push({ path: "/providers?me=true" });
    },
    navigateToLocations: function () {
      this.$router.push({ path: "/admin/locations" });
    },
    toggleBudgetUpdates: function () {
      this.isBudgetUpdatesVisible = !this.isBudgetUpdatesVisible;
      this.$nextTick(() => {
        if (this.isBudgetUpdatesVisible) {
          const clientRect = document
            .getElementById("#budget-updates")
            .getBoundingClientRect();
          window.scrollTo({
            top: clientRect.top + window.scrollY - 150,
            behavior: "smooth",
          });
        }
      });
    },
    updateSelectedRevenueChart: function (chartName) {
      this.selectedRevenueName = chartName;
      this.updateChartRender();
    },
    updateSelectedRevenueLayout: function (layout = "vertical") {
      this.selectedRevenueDirection = layout;
      this.updateChartRender();
    },
    updateChartRender: function () {
      this.changeChartType(this.selectedRevenueName);
    },
    updateBudgetChartRender: function () {
      this.changeChartType(this.selectedBudgetName, true);
    },
    updateSelectedBudgetChart: function (chartName) {
      this.selectedBudgetName = chartName;
      this.changeChartType(this.selectedBudgetName, true);
    },
    updateSelectedBudgetLayout: function (layout = "vertical") {
      this.selectedBudgetDirection = layout;
      this.changeChartType(this.selectedBudgetName, true);
    },
    getNumberOfAssignedClients: async function () {
      try {
        this.numberOfAssignedClients.loading = true;
        const res = await this.$http.get(
          "dashboard/number-of-assigned-clients"
        );
        if (res.data?.data || res.data?.data == 0) {
          this.numberOfAssignedClients.data = res.data.data;
        }
        this.numberOfAssignedClients.loading = false;
      } catch (err) {
        this.numberOfAssignedClients.data = "";
        this.numberOfAssignedClients.loading = false;
      }
    },
    getNumberOfAssignedProviders: async function () {
      try {
        this.numberOfAssignedProviders.loading = true;
        const res = await this.$http.get(
          `dashboard/number-of-assigned-providers?administratorId=${this.user.id}`
        );
        if (res.data?.data || res.data?.data == 0) {
          this.numberOfAssignedProviders.data = res.data.data;
        }
        this.numberOfAssignedProviders.loading = false;
      } catch (err) {
        this.numberOfAssignedProviders.data = "";
        this.numberOfAssignedProviders.loading = false;
      }
    },
    openCardInfo: function (name, title) {
      this.learnMoreInfo.name = name;
      this.learnMoreInfo.title = title;
      this.$modal.show("card-info-modal");
    },
    closeCardInfoModal: function () {
      this.$modal.hide("card-info-modal");
    },
  },
};
</script>
<style lang="scss">
.small-display {
  @media (min-width: 1023px) {
    display: none !important;
  }

  .select-outer-container {
    width: 100%;
    margin-top: 8px;

    .col {
      padding: 0;
    }
  }
}
.charts-outer-container {
  .alert {
    margin-bottom: -45px;
  }
}
@media (max-width: 1023px) {
  .charts-outer-container {
    .small-display {
      .dashboard-item-title {
        display: block;
      }
    }
    .dashboard-item-title {
      display: none;
    }
  }
}
.dashboard-item-header {
  select,
  .see-all-btn {
    padding-bottom: 0;
    border-radius: 99px;
    height: 42px;
    background-color: rgba(255, 255, 255, 0.8);
  }
  select {
    padding-top: 0;
    font-size: 16px;
  }
}

.dashboard-selected-time {
  font-size: 18px;
  font-weight: normal;
}
</style>
<style lang="scss" scoped>
.mb-20 {
  margin-bottom: 20px;
}

.bg-img-card {
  background-image: var(--notes-img-path);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto 40%;
}
.chart-container {
  width: 100%;
  height: 365px;
  overflow-x: hidden;
  overflow-y: auto;
}
.dashboard-main-container {
  padding: 0 16px !important;
  margin-bottom: 20px;
}
.menu-container {
  border-right: 1px solid var(--secondary-color);
  padding-right: 24px;
  padding-left: 8px;
}
.chart-menu {
  margin-top: 16px;
  .chart-menu-item {
    color: var(--main-text-color);
    opacity: 0.52;
    margin-bottom: 4px;
    border-radius: 16px;
    padding: 12px;
    margin-bottom: 4px;

    .title {
      font-size: 18px;
    }
    .menu-icon {
      margin-bottom: 4px;
    }

    &.selected {
      opacity: 1;
      background-color: #ffffff;

      .title {
        font-weight: 500;
      }
      .total {
        opacity: 0.52;
      }
    }
  }
}
.chart-layout {
  .label {
    font-size: 16px;
  }
  .control {
    background-color: var(--secondary-color);
    padding: 2px;

    .selected {
      background-color: #ffffff;
      font-weight: bold;
    }

    .right-btn {
      margin-left: -8px;
    }
  }
}
.annual-revenue-all {
  @media (max-width: 767px) {
    justify-content: space-between;
  }
}

.dashboard-widget-info {
  border-radius: 28px;
  padding: 16px 20px;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
