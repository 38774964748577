<template>
  <div class="container" v-if="user">
    <div class="row justify-content-between">
      <div class="col-auto">
        <h1>Providers</h1>
      </div>
      <div
        class="col-auto text-right"
        v-if="
          user &&
          (user.isAdmin || user.isManagingAdmin) &&
          $can({ key: 'providers', expectedPermission: 'create' })
        "
      >
        <router-link
          :to="{ name: 'providers.add' }"
          class="btn btn-outline-blue py-2 btn-sm-mobile"
        >
          <icon type="plus" /> Add New Provider
        </router-link>
      </div>
    </div>
    <list-providers />
  </div>
</template>

<script>
import { default as ListProviders } from "./ListProviders";
import { mapState } from "vuex";

export default {
  name: "Providers",
  computed: {
    ...mapState(["providers"]),
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  components: {
    ListProviders,
  },
};
</script>
