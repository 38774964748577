<template>
  <div class="row">
    <div class="col-12 col-md-3 col-lg-2" v-if="user && !user.isPatient">
      <nav
        class="
          navbar navbar-vertical
          fixed-left
          navbar-expand-md navbar-light
          page-inner-nav-container
          nice-scroll
          z-100
        "
        style="position: relative; border-width: 0; background: none"
      >
        <ul class="navbar-nav border-none">
          <li
            class="nav-item"
            :class="{
              'u-display-none': link.providerHide && (!user || user.isProvider),
            }"
            v-for="link in nav"
            :key="link.to || link.action"
          >
            <router-link
              v-if="
                (!link.providerHide || (user && !user.isProvider)) &&
                !link.action
              "
              :to="getLink(link.to)"
              class="nav-link"
              :class="$route.name == link.to ? `active` : ``"
            >
              <icon :type="link.icon" class="mr-2" />
              {{ link.label }}
            </router-link>
            <a
              v-if="link.action"
              href="/"
              @click="openCalendarModal"
              class="nav-link"
            >
              <icon :type="link.icon" class="mr-2" />
              {{ link.label }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-12 col-md-8 col-lg-9 card theme-card h-100">
      <router-view class="mt-4"></router-view>
    </div>
    <sidebar
      :toggle="modalOpened"
      @close="closeCalendarModal"
      :title="`${providerData.last}, ${providerData.first} Events`"
      wrapClass="calendar-tray"
      class="calendar-modal"
    >
      <Calendar
        :key="Math.random()"
        :profileProviderUserId="providerData.user_id"
        @closeModal="modalOpened = false"
        v-if="modalOpened"
      ></Calendar>
    </sidebar>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Calendar from "../appointment/calendar";

export default {
  components: {
    Calendar,
  },
  data() {
    return {
      modalOpened: false,
      nav: [
        {
          label: "General",
          to: "providers.basic",
          icon: "home",
        },
        {
          label: "Clinics",
          to: "providers.clinics",
          icon: "clinic-medical",
          providerHide: true,
        },
        {
          label: "Classes",
          to: "providers.classes",
          icon: "tags",
        },
        {
          label: "Address",
          to: "providers.address",
          icon: "map-signs",
        },
        {
          label: "Phones",
          to: "providers.phones",
          icon: "phone",
        },
        {
          label: "Emails",
          to: "providers.emails",
          icon: "at",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      providerData: (state) => state.providers.provider,
    }),
  },
  methods: {
    getLink(to) {
      return {
        name: to,
        params: {
          id: this.$route.params.id,
        },
      };
    },
    openCalendarModal(event) {
      event.preventDefault();
      this.modalOpened = true;
    },
    closeCalendarModal() {
      this.modalOpened = false;
    },
  },
};
</script>
