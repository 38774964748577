import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: [],
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    get: function (ctx, id) {
      let vm = this;
      return new Promise(function (resolve) {
        ctx.commit("SET_STATE", {
          key: "isLoading",
          value: true,
        });
        if (id) {
          vm.$http.get(`patients/${id}/providers`).then((res) => {
            ctx.commit("SET_STATE", {
              key: "data",
              value: res.data.data,
            });
            ctx.commit("SET_STATE", {
              key: "isLoading",
              value: false,
            });
            resolve(res.data.data);
          });
        } else {
          resolve();
        }
      });
    },
    getBasicProviders: function (ctx, id) {
      let vm = this;
      return new Promise(function (resolve) {
        ctx.commit("SET_STATE", {
          key: "isLoading",
          value: true,
        });
        if (id) {
          vm.$http.get(`patients/${id}/basic-providers`).then((res) => {
            ctx.commit("SET_STATE", {
              key: "data",
              value: res.data.data,
            });
            ctx.commit("SET_STATE", {
              key: "isLoading",
              value: false,
            });
            resolve(res.data.data);
          });
        } else {
          resolve();
        }
      });
    },
    getClientProviders: function (ctx, id) {
      let vm = this;
      return new Promise(function (resolve) {
        ctx.commit("SET_STATE", {
          key: "isLoading",
          value: true,
        });
        if (id) {
          vm.$http.get(`patients/${id}/patient-providers`).then((res) => {
            ctx.commit("SET_STATE", {
              key: "data",
              value: res.data.data,
            });
            ctx.commit("SET_STATE", {
              key: "isLoading",
              value: false,
            });
            resolve(res.data.data);
          });
        } else {
          resolve();
        }
      });
    },
    connect: function (ctx, payload) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .post(`patients/${payload.patientId}/attach-provider`, {
            provider: payload.provider.id,
          })
          .then(
            (res) => {
              if (res.status == 200) {
                ctx.commit("SET_STATE", {
                  key: "data",
                  value: [...ctx.state.data, payload.provider],
                });

                Swal.fire({
                  title: "Assigned",
                  text: res.data.message,
                  icon: "success",
                });
                return resolve(true);
              }
              return resolve();
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            }
          );
      });
    },
  },
};
