var render = function render(){var _vm=this,_c=_vm._self._c;return _c('sidebar',{attrs:{"toggle":_vm.openUpdateTray},on:{"close":function($event){return _vm.closeUpdateTray(true)}}},[(_vm.openUpdateTray && _vm.actionInProgress)?_c('loader',{staticClass:"event-loader"}):_vm._e(),(_vm.openUpdateTray && !_vm.gettingApt)?_c('form',{ref:"form",attrs:{"validate":""},on:{"submit":function($event){$event.preventDefault();return _vm.UpdateBook()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"mr-2 mb-3"},[(
            _vm.clickedEventDetails_meetingUrl != null &&
            !_vm.clickedEventDetails_deleted &&
            _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
          )?_c('button',{staticClass:"btn btn-secondary active",on:{"click":function($event){$event.preventDefault();return _vm.joinMeeting()}}},[_vm._v(" Join Virtual Meeting ")]):_vm._e()]),(
          _vm.user &&
          !_vm.user.isPatient &&
          !_vm.clickedEventDetails_deleted &&
          _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
        )?_c('div',{staticClass:"mr-2"},[_c('button',{staticClass:"btn btn-theme mb-3",attrs:{"type":"submit","disabled":_vm.clickedEventDetails_status == 'Verified'}},[_vm._v(" Save Changes ")])]):_vm._e(),(
          (_vm.clickedEventDetails_status == 'Ready for Review' ||
            _vm.clickedEventDetails_status == 'PENDING') &&
          !_vm.clickedEventDetails_deleted &&
          _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
        )?_c('div',[(
            _vm.user &&
            (_vm.user.isAdmin ||
              _vm.user.isManagingAdmin ||
              (_vm.currentProvider && _vm.currentProvider.can_verify_event))
          )?_c('a',{staticClass:"btn btn-info mb-2",on:{"click":function($event){$event.preventDefault();return _vm.approveEvent.apply(null, arguments)}}},[(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm"}):_vm._e(),_vm._v(" Verify ")]):_vm._e()]):_vm._e(),(
          _vm.clickedEventDetails_status == 'Verified' &&
          !_vm.clickedEventDetails_deleted &&
          _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
        )?_c('div',[(_vm.user && (_vm.user.isAdmin || _vm.user.isManagingAdmin))?_c('a',{staticClass:"btn btn-warning mb-2",on:{"click":function($event){$event.preventDefault();return _vm.unapproveEvent.apply(null, arguments)}}},[_vm._v(" Unverify ")]):_vm._e()]):_vm._e(),(
          !_vm.clickedEventDetails_deleted &&
          !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) &&
          _vm.clickedEventDetails_type != null
        )?_c('h2',{staticClass:"col-12 mt-2 mb-0 pl-2",class:`${
          _vm.clickedEventDetails_status != null
            ? _vm.clickedEventDetails_status == 'PENDING'
              ? 'text-warning'
              : _vm.clickedEventDetails_status == 'Verified'
              ? 'text-success'
              : _vm.clickedEventDetails_status
            : 'text-warning'
        }`},[_vm._v(" "+_vm._s(_vm.clickedEventDetails_status != null ? _vm.clickedEventDetails_status == "PENDING" ? "Pending" : _vm.clickedEventDetails_status == "Verified" ? "Verified" : _vm.clickedEventDetails_status : "Pending")+" ")]):_vm._e(),(
          _vm.user &&
          (_vm.user.isAdmin ||
            _vm.user.isManagingAdmin ||
            (_vm.user.isProvider && _vm.clickedEventDetails_status != 'Verified'))
        )?_c('div',[(
            !_vm.clickedEventDetails_deleted &&
            _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
          )?_c('button',{staticClass:"btn btn-danger ml-2 mr-2 mb-3",on:{"click":function($event){$event.preventDefault();return _vm.deleteEvent.apply(null, arguments)}}},[_vm._v(" Delete ")]):_vm._e(),(_vm.clickedEventDetails_hidden)?_c('i',{staticClass:"fas fa-xl fa-user-secret ml-4"}):_vm._e(),(_vm.clickedEventDetails_deleted)?_c('h1',{staticClass:"text-danger font-weight-bold mb-0",staticStyle:{"letter-spacing":"2px"}},[_vm._v(" REMOVED ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"row"}),_c('div',{staticClass:"row"},[(
          _vm.clickedEventDetails_patient != null &&
          _vm.clickedEventDetails_type != null
        )?_c('h3',{staticClass:"col-md-12 mt-4"},[_vm._v(" "+_vm._s(_vm.clickedEventDetails_type)+" Appointment with "+_vm._s(_vm.clickedEventDetails_patient.last)+", "+_vm._s(_vm.clickedEventDetails_patient.first)+" ")]):_vm._e()]),(
        !_vm.clickedEventDetails_deleted &&
        _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
      )?_c('div',{staticClass:"row"},[(_vm.clickedEventDetails_type != null)?_c('h2',{staticClass:"col-md-6 mt-4",class:`${
          _vm.clickedEventDetails_status != null
            ? _vm.clickedEventDetails_status == 'PENDING'
              ? 'text-warning'
              : _vm.clickedEventDetails_status == 'Verified'
              ? 'text-success'
              : _vm.clickedEventDetails_status
            : 'text-warning'
        }`},[_vm._v(" "+_vm._s(_vm.clickedEventDetails_status != null ? _vm.clickedEventDetails_status == "PENDING" ? "Pending" : _vm.clickedEventDetails_status == "Verified" ? "Verified" : _vm.clickedEventDetails_status : "Pending")+" ")]):_vm._e(),(_vm.user && !_vm.user.isPatient)?_c('div',{staticClass:"col-md-6 text-right align-items-center d-flex flex-column justify-content-end"},[(_vm.canHaveClinicalNotes())?_c('button',{staticClass:"btn btn-lg btn-black",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.createNote.apply(null, arguments)}}},[_vm._v(" Add Clinical Note ")]):_vm._e(),(_vm.canHaveClinicalNotes())?_c('a',{staticClass:"h4 mb-0 mt-3 text-underline",attrs:{"href":"/"},on:{"click":function($event){$event.preventDefault();return _vm.navigateToClinicalNotes()}}},[_c('u',[_vm._v("Show Client Notes")])]):_vm._e()]):_vm._e()]):_vm._e(),(
        !_vm.user.isPatient &&
        !_vm.clickedEventDetails_deleted &&
        _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
      )?_c('div',{staticClass:"row mt-2 mx-0 align-items-center"},[_c('button',{ref:"externalCalendarButton",staticClass:"btn btn-sm btn-warning font-weight-bold dropdown-toggle",attrs:{"type":"button","id":"calendar-button"},on:{"click":function($event){$event.preventDefault();return _vm.addToExternalCalendar.apply(null, arguments)}}},[_c('i',{staticClass:"far fa-calendar-plus mr-2 mt-2"}),_vm._v(" Add To External Calendar ")]),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
          `Make sure your calendar account's timezone matches the current device's timezone (${_vm.currentTimeZone}) to avoid time difference issues.`
        ),expression:"\n          `Make sure your calendar account's timezone matches the current device's timezone (${currentTimeZone}) to avoid time difference issues.`\n        ",modifiers:{"top":true}}],staticClass:"fa fa-info-circle ml-2 pointer-cursor"})]):_vm._e(),(_vm.user && (_vm.user.isAdmin || _vm.user.isManagingAdmin))?_c('div',{staticClass:"row mt-3"},[(
          _vm.clickedEventDetails_status != 'Verified' &&
          _vm.user &&
          (_vm.user.isAdmin || _vm.user.isManagingAdmin)
        )?_c('div',{staticClass:"col-12 row m-0 mb-3 mb-sm-0"},[_c('label',{staticClass:"mr-5"},[_vm._v("Is this hidden? "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
              'once the session marked as hidden then only admins and managers can see it'
            ),expression:"\n              'once the session marked as hidden then only admins and managers can see it'\n            ",modifiers:{"top":true}}],staticClass:"fa fa-info-circle ml-2 pointer-cursor"})]),_c('div',{staticClass:"custom-control custom-switch ml-4 ml-sm-5 mr-sm-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.clickedEventDetails_hidden),expression:"clickedEventDetails_hidden"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"isHidden","disabled":_vm.clickedEventDetails_deleted},domProps:{"checked":Array.isArray(_vm.clickedEventDetails_hidden)?_vm._i(_vm.clickedEventDetails_hidden,null)>-1:(_vm.clickedEventDetails_hidden)},on:{"change":function($event){var $$a=_vm.clickedEventDetails_hidden,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.clickedEventDetails_hidden=$$a.concat([$$v]))}else{$$i>-1&&(_vm.clickedEventDetails_hidden=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.clickedEventDetails_hidden=$$c}}}}),_c('label',{staticClass:"custom-control-label pointer-cursor",attrs:{"for":"isHidden"}},[_vm._v(_vm._s(_vm.clickedEventDetails_hidden ? "Hidden" : "Visible"))])])]):_vm._e()]):_vm._e(),_c('nav',[_c('div',{staticClass:"nav nav-tabs",attrs:{"id":"nav-tab","role":"tablist"}},[_c('a',{staticClass:"nav-item nav-link active",attrs:{"id":"nav-home-tab","data-toggle":"tab","href":"#nav-home","role":"tab","aria-controls":"nav-home","aria-selected":"true"}},[_vm._v("Details")]),_c('a',{staticClass:"nav-item nav-link",attrs:{"id":"nav-memo-tab","data-toggle":"tab","href":"#nav-memo","role":"tab","aria-controls":"nav-memo","aria-selected":"false"}},[_vm._v("Memo & Reason")]),(
            _vm.user &&
            !_vm.user.isPatient &&
            _vm.clickedEventDetails_service.id &&
            _vm.isBillable
          )?_c('a',{staticClass:"nav-item nav-link",attrs:{"id":"nav-profile-tab","data-toggle":"tab","href":"#nav-profile","role":"tab","aria-controls":"nav-profile","aria-selected":"false"}},[_vm._v("Sub Services")]):_vm._e(),(_vm.user && !_vm.user.isPatient && _vm.isBillable)?_c('a',{staticClass:"nav-item nav-link",attrs:{"id":"nav-expenses-tab","data-toggle":"tab","href":"#nav-expenses","role":"tab","aria-controls":"nav-expenses","aria-selected":"false"}},[_vm._v("Expenses")]):_vm._e()])]),_c('div',{staticClass:"tab-content",attrs:{"id":"nav-tabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"nav-home","role":"tabpanel","aria-labelledby":"nav-home-tab"}},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-12"},[_vm._v("Appointment Time")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('datetime',{attrs:{"disabled":_vm.clickedEventDetails_status == 'Verified' ||
                  _vm.clickedEventDetails_deleted ||
                  !_vm.canEnable(_vm.clickedEventDetails_provider_user_id),"placeholder":"DateTime","week-start":7,"input-class":"col-md-12 form-control","input-style":"font-size: 12px;","type":"datetime"},on:{"input":_vm.setChangesMade},model:{value:(_vm.clickedEventDetails_startStr),callback:function ($$v) {_vm.clickedEventDetails_startStr=$$v},expression:"clickedEventDetails_startStr"}})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('datetime',{attrs:{"disabled":_vm.clickedEventDetails_status == 'Verified' ||
                  _vm.clickedEventDetails_deleted ||
                  !_vm.canEnable(_vm.clickedEventDetails_provider_user_id),"placeholder":"DateTime","week-start":7,"input-class":"col-md-12 form-control","input-style":"font-size: 12px;","type":"datetime"},on:{"input":_vm.setChangesMade},model:{value:(_vm.clickedEventDetails_endStr),callback:function ($$v) {_vm.clickedEventDetails_endStr=$$v},expression:"clickedEventDetails_endStr"}}),_c('div',{staticClass:"text-right pr-2",style:(`opacity: ${
                  _vm.clickedEventDetails_mainService &&
                  _vm.clickedEventDetails_mainService.unit !== 'FT' &&
                  _vm.clickedEventDetails_mainService.unit !== 'FLAT'
                    ? '1'
                    : '0'
                }`)},[(
                    _vm.clickedEventDetails_status != 'Verified' &&
                    !_vm.clickedEventDetails_deleted &&
                    _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
                  )?_c('span',{staticClass:"h4 text-primary pointer-cursor",on:{"click":function($event){$event.preventDefault();return _vm.setTimeToZero()}}},[_vm._v("Set main service to zero hrs")]):_vm._e()])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.clickedEventDetails_provider != null)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Provider/Admin")]),_c('multiselect',{staticClass:"app-select no-labels",attrs:{"options":_vm.user1Options,"preserve-search":true,"searchable":true,"close-on-select":true,"clear-on-select":true,"allow-empty":false,"show-labels":false,"placeholder":"Search for Provider/Admin","label":"name","disabled":(_vm.user && (_vm.user.isPatient || _vm.user.isProvider)) ||
                  _vm.clickedEventDetails_status == 'Verified' ||
                  _vm.clickedEventDetails_deleted ||
                  !_vm.canEnable(_vm.clickedEventDetails_provider_user_id)},on:{"input":function($event){return _vm.updateSelectedUser1($event.id)}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"font-weight-bold select-label"},[_vm._v(" "+_vm._s(props.option.name)+" ")]),_c('div',{staticClass:"small email-container",staticStyle:{"opacity":"0.9"}},[_vm._v(" ("+_vm._s(props.option.email)+") ")])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"small font-weight-bold select-sublabel",staticStyle:{"opacity":"0.9"}},[_vm._v(" ("+_vm._s(props.option.type)+") ")]),(props.option.$isDisabled)?_c('div',{staticClass:"text-danger small ml-3"},[_vm._v(" Inactive ")]):_vm._e()])])]}},{key:"noResult",fn:function(){return [_c('div',{staticClass:"no-result"},[_vm._v("Oops! No users found.")])]},proxy:true}],null,false,2571097866),model:{value:(_vm.select.provider),callback:function ($$v) {_vm.$set(_vm.select, "provider", $$v)},expression:"select.provider"}}),_c('div',{staticClass:"px-1 mb-2 pt-2 d-flex justify-content-between align-items-center"},[(
                    _vm.user &&
                    (_vm.user.isAdmin || _vm.user.isManagingAdmin) &&
                    !_vm.isBillable &&
                    _vm.clickedEventDetails_status != 'Verified' &&
                    !_vm.clickedEventDetails_deleted &&
                    _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
                  )?_c('a',{staticClass:"h4 mb-0",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.updateSelectedUser1(_vm.user.id)}}},[_vm._v("Assign to me")]):_vm._e(),(_vm.clickedEventDetails_provider_user_id)?_c('a',{staticClass:"h4 mb-0 ml-auto",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openUserAvailabilityModal(
                      _vm.clickedEventDetails_provider_user_id
                    )}}},[_vm._v("Availability")]):_vm._e()])],1):_vm._e(),(
                _vm.clickedEventDetails_patient != null &&
                _vm.user &&
                (_vm.user.isProvider || _vm.user.isAdmin || _vm.user.isManagingAdmin)
              )?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.isBillable ? "Client" : "User/Client"))]),_c('multiselect',{staticClass:"app-select no-labels",attrs:{"options":_vm.user2Options,"preserve-search":true,"searchable":true,"close-on-select":true,"clear-on-select":true,"allow-empty":false,"show-labels":false,"placeholder":`Search for ${
                  _vm.isBillable ? 'Client' : 'User/Client'
                }`,"label":"name","disabled":_vm.clickedEventDetails_status == 'Verified' ||
                  _vm.clickedEventDetails_deleted ||
                  !_vm.canEnable(_vm.clickedEventDetails_provider_user_id)},on:{"input":function($event){return _vm.updateSelectedUser2($event.id)}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"font-weight-bold select-label"},[_vm._v(" "+_vm._s(props.option.name)+" ")]),_c('div',{staticClass:"small email-container",staticStyle:{"opacity":"0.9"}},[_vm._v(" ("+_vm._s(props.option.email)+") ")])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"small font-weight-bold select-sublabel",staticStyle:{"opacity":"0.9"}},[_vm._v(" ("+_vm._s(props.option.type)+") ")]),(props.option.$isDisabled)?_c('div',{staticClass:"text-danger small ml-3"},[_vm._v(" Inactive ")]):_vm._e()])])]}},{key:"noResult",fn:function(){return [_c('div',{staticClass:"no-result"},[_vm._v("Oops! No users found.")])]},proxy:true}],null,false,2571097866),model:{value:(_vm.select.patient),callback:function ($$v) {_vm.$set(_vm.select, "patient", $$v)},expression:"select.patient"}}),_c('div',{staticClass:"px-1 mb-2 pt-2 d-flex justify-content-between align-items-center"},[(
                    !_vm.isBillable &&
                    _vm.clickedEventDetails_status != 'Verified' &&
                    !_vm.clickedEventDetails_deleted &&
                    _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
                  )?_c('a',{staticClass:"h4 ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.updateSelectedUser2(_vm.user.id), _vm.setChangesMade()}}},[_vm._v("Assign to me")]):_vm._e(),(_vm.clickedEventDetails_patient_user_id)?_c('a',{staticClass:"h4 mb-0 ml-auto",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openUserAvailabilityModal(
                      _vm.clickedEventDetails_patient_user_id
                    )}}},[_vm._v("Availability")]):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Service")]),_c('multiselect',{staticClass:"app-select no-labels",attrs:{"options":_vm.serviceOptions,"preserve-search":true,"searchable":true,"close-on-select":true,"clear-on-select":true,"allow-empty":false,"show-labels":false,"placeholder":"Search for Service","label":"name","disabled":_vm.clickedEventDetails_deleted ||
                  !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) ||
                  !(
                    (_vm.clickedEventDetails_status == 'Ready for Review' ||
                      _vm.clickedEventDetails_status == 'PENDING') &&
                    _vm.user &&
                    (_vm.user.isAdmin ||
                      _vm.user.isManagingAdmin ||
                      (_vm.currentProvider && _vm.currentProvider.can_verify_event))
                  )},on:{"input":function($event){return _vm.updateSelectedService($event.id)}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"font-weight-bold select-label"},[_vm._v(" "+_vm._s(props.option.name)+" ")]),(props.option.type)?_c('div',{staticClass:"small font-weight-bold select-sublabel",staticStyle:{"opacity":"0.9"}},[_vm._v(" ("+_vm._s(props.option.type)+") ")]):_vm._e()])]}},{key:"noResult",fn:function(){return [_c('div',{staticClass:"no-result"},[_vm._v("Oops! No services found.")])]},proxy:true}],null,false,422855059),model:{value:(_vm.select.primary),callback:function ($$v) {_vm.$set(_vm.select, "primary", $$v)},expression:"select.primary"}}),(
                  _vm.user &&
                  !_vm.user.isPatient &&
                  _vm.clickedEventDetails_service.id &&
                  _vm.isBillable &&
                  !_vm.clickedEventDetails_deleted &&
                  _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
                )?_c('div',{staticClass:"w-100 text-right"},[_c('a',{staticClass:"h4 mr-1",attrs:{"href":"#nav-profile"},on:{"click":function($event){$event.preventDefault();return _vm.selectTab('#nav-profile-tab')}}},[_vm._v("Add sub service")])]):_vm._e()],1),(
                _vm.canEnable(_vm.clickedEventDetails_provider_user_id) &&
                _vm.user &&
                !_vm.user.isPatient
              )?_c('input-control',{attrs:{"control":"number","disabled":_vm.clickedEventDetails_status == 'Verified' ||
                _vm.clickedEventDetails_deleted,"type":"vertical","step":"0.1","min":"0"},on:{"input":_vm.setChangesMade},model:{value:(_vm.clickedEventDetails_providerRate),callback:function ($$v) {_vm.clickedEventDetails_providerRate=$$v},expression:"clickedEventDetails_providerRate"}},[_vm._v("Provider Rate")]):_vm._e(),(
                _vm.clickedEventDetails_provider != null &&
                _vm.user &&
                !_vm.user.isProvider
              )?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Appointment Type")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.clickedEventDetails_type),expression:"clickedEventDetails_type"}],staticClass:"form-control",attrs:{"disabled":(_vm.user && _vm.user.isPatient) ||
                  _vm.clickedEventDetails_status == 'Verified' ||
                  _vm.clickedEventDetails_deleted ||
                  !_vm.canEnable(_vm.clickedEventDetails_provider_user_id),"required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.clickedEventDetails_type=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.setChangesMade]}},_vm._l((_vm.serviceTypes),function(p){return _c('option',{key:p.value,domProps:{"value":p.value}},[_vm._v(" "+_vm._s(p.label)+" ")])}),0)]):_vm._e()],1),(
              _vm.clickedEventDetails_status !== 'Verified' &&
              !_vm.clickedEventDetails_deleted &&
              _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
            )?_c('div',{staticClass:"col-12 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.allowOverlap),expression:"allowOverlap"}],attrs:{"type":"checkbox","id":"allowOverlap"},domProps:{"checked":Array.isArray(_vm.allowOverlap)?_vm._i(_vm.allowOverlap,null)>-1:(_vm.allowOverlap)},on:{"change":[function($event){var $$a=_vm.allowOverlap,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.allowOverlap=$$a.concat([$$v]))}else{$$i>-1&&(_vm.allowOverlap=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.allowOverlap=$$c}},_vm.setChangesMade]}}),_c('label',{staticClass:"pointer-cursor ml-2 text-dark-red",attrs:{"for":"allowOverlap"}},[_vm._v("Allow Overlapping With Other Sessions")])]):_vm._e()])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"nav-profile","role":"tabpanel","aria-labelledby":"nav-profile-tab"}},[_c('label',{staticClass:"serviceName"},[_vm._v(_vm._s(_vm.clickedEventDetails_mainService != null ? _vm.clickedEventDetails_mainService.name : "")+" ")]),(
            _vm.clickedEventDetails_services != null &&
            _vm.clickedEventDetails_services.length != 0
          )?_c('div',[_c('span',{staticStyle:{"color":"#f00","font-size":"12px"}},[_vm._v("* Only the selected Sub Service will be submitted")]),_vm._l((_vm.clickedEventDetails_services),function(subService){return _c('div',{key:subService.id,staticClass:"subServiceItem col-md-12"},[_c('div',{staticClass:"row mb-2 align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(subService.selected),expression:"subService.selected"}],staticClass:"form-control pointer-cursor checkbox-input",attrs:{"type":"checkbox","id":`${subService.name}_${subService.id}`,"disabled":_vm.clickedEventDetails_deleted ||
                  !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) ||
                  subService.is_default == true ||
                  _vm.clickedEventDetails_status == 'Verified' ||
                  (subService.status == 'INACTIVE' && !subService.selected)},domProps:{"checked":Array.isArray(subService.selected)?_vm._i(subService.selected,null)>-1:(subService.selected)},on:{"change":[function($event){var $$a=subService.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(subService, "selected", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(subService, "selected", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(subService, "selected", $$c)}},_vm.setChangesMade]}}),_c('label',{staticClass:"pointer-cursor ml-2 mb-0",attrs:{"for":`${subService.name}_${subService.id}`}},[_vm._v("Selected "),_c('span',{staticStyle:{"color":"#f00"}},[_vm._v("*")])]),(subService.status == 'INACTIVE' && !subService.selected)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                  'The sub service has no longer been active and can\'t be used anymore'
                ),expression:"\n                  'The sub service has no longer been active and can\\'t be used anymore'\n                ",modifiers:{"top":true}}],staticClass:"font-weight-bold text-danger float-right"},[_vm._v("INACTIVE")]):_vm._e()]),_c('div',{staticClass:"subServiceItemContainer",class:subService.something == false ? 'disabledSubService1' : ''},[_c('h3',{staticClass:"SubServiceTitle"},[_vm._v(_vm._s(subService.name))]),_c('div',{staticClass:"row"},[(subService.pivot && _vm.user && !_vm.user.isProvider)?_c('div',{staticClass:"col-md-4"},[_c('input-control',{attrs:{"disabled":_vm.clickedEventDetails_deleted ||
                      !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) ||
                      (_vm.user && _vm.user.isProvider) ||
                      _vm.clickedEventDetails_status == 'Verified' ||
                      (subService.status == 'INACTIVE' &&
                        !subService.selected) ||
                      true,"type":"vertical","control":"number","step":"0.01"},on:{"change":_vm.setChangesMade,"input":_vm.setChangesMade},model:{value:(subService.pivot.rate),callback:function ($$v) {_vm.$set(subService.pivot, "rate", $$v)},expression:"subService.pivot.rate"}},[_vm._v("Rate")])],1):_vm._e(),(!subService.pivot && _vm.user && !_vm.user.isProvider)?_c('div',{staticClass:"col-md-4"},[_c('input-control',{attrs:{"type":"vertical","disabled":_vm.clickedEventDetails_deleted ||
                      !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) ||
                      (_vm.user && _vm.user.isProvider) ||
                      _vm.clickedEventDetails_status == 'Verified' ||
                      (subService.status == 'INACTIVE' &&
                        !subService.selected) ||
                      true,"control":"number","step":"0.01"},on:{"change":_vm.setChangesMade,"input":_vm.setChangesMade},model:{value:(subService.rate),callback:function ($$v) {_vm.$set(subService, "rate", $$v)},expression:"subService.rate"}},[_vm._v("Rate")])],1):_vm._e(),(subService.pivot)?_c('div',{staticClass:"mt-3 mt-md-0",class:`${
                    _vm.user && _vm.user.isProvider ? 'col-md-6' : 'col-md-4'
                  }`},[_c('input-control',{attrs:{"type":"vertical","disabled":_vm.clickedEventDetails_deleted ||
                      !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) ||
                      _vm.clickedEventDetails_status == 'Verified' ||
                      (subService.status == 'INACTIVE' &&
                        !subService.selected) ||
                      subService.unit == 'FT' ||
                      subService.unit == 'FLAT',"required":subService.selected,"control":"number","step":"0.01"},on:{"change":_vm.setChangesMade,"input":_vm.setChangesMade},model:{value:(subService.pivot.quantity),callback:function ($$v) {_vm.$set(subService.pivot, "quantity", $$v)},expression:"subService.pivot.quantity"}},[_vm._v("Quantity")])],1):_c('div',{staticClass:"mt-3 mt-md-0",class:`${
                    _vm.user && _vm.user.isProvider ? 'col-md-6' : 'col-md-4'
                  }`},[_c('input-control',{attrs:{"type":"vertical","required":subService.selected,"disabled":_vm.clickedEventDetails_deleted ||
                      !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) ||
                      _vm.clickedEventDetails_status == 'Verified' ||
                      (subService.status == 'INACTIVE' &&
                        !subService.selected) ||
                      subService.unit == 'FT' ||
                      subService.unit == 'FLAT',"control":"number","step":"0.01"},on:{"change":_vm.setChangesMade,"input":_vm.setChangesMade},model:{value:(subService.qty),callback:function ($$v) {_vm.$set(subService, "qty", $$v)},expression:"subService.qty"}},[_vm._v("Quantity")])],1),(subService.pivot)?_c('div',{staticClass:"mt-3 mt-md-0",class:`${
                    _vm.user && _vm.user.isProvider ? 'col-md-6' : 'col-md-4'
                  }`},[_c('select-control',{attrs:{"type":"vertical","labelClass":"mr-0","disabled":"","options":_vm.units},on:{"change":_vm.setChangesMade,"input":_vm.setChangesMade},model:{value:(subService.pivot.unit),callback:function ($$v) {_vm.$set(subService.pivot, "unit", $$v)},expression:"subService.pivot.unit"}},[_vm._v("Unit")])],1):_c('div',{staticClass:"mt-3 mt-md-0",class:`${
                    _vm.user && _vm.user.isProvider ? 'col-md-6' : 'col-md-4'
                  }`},[_c('select-control',{attrs:{"type":"vertical","disabled":"","labelClass":"mr-0","options":_vm.units},on:{"change":_vm.setChangesMade,"input":_vm.setChangesMade},model:{value:(subService.unit),callback:function ($$v) {_vm.$set(subService, "unit", $$v)},expression:"subService.unit"}},[_vm._v("Unit")])],1)]),(subService.pivot)?_c('div',{staticClass:"row mt-3"},[(false)?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-12"},[_vm._v("Taxable")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(subService.pivot.taxable),expression:"subService.pivot.taxable"}],attrs:{"type":"radio","id":"yes","value":"1","disabled":_vm.clickedEventDetails_deleted ||
                      !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) ||
                      _vm.clickedEventDetails_status == 'Verified' ||
                      (subService.status == 'INACTIVE' &&
                        !subService.selected)},domProps:{"checked":_vm._q(subService.pivot.taxable,"1")},on:{"change":[function($event){return _vm.$set(subService.pivot, "taxable", "1")},_vm.setChangesMade],"input":_vm.setChangesMade}}),_c('label',{attrs:{"for":"yes"}},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(subService.pivot.taxable),expression:"subService.pivot.taxable"}],attrs:{"type":"radio","id":"no","value":"0","disabled":_vm.clickedEventDetails_deleted ||
                      !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) ||
                      _vm.clickedEventDetails_status == 'Verified' ||
                      (subService.status == 'INACTIVE' &&
                        !subService.selected)},domProps:{"checked":_vm._q(subService.pivot.taxable,"0")},on:{"change":[function($event){return _vm.$set(subService.pivot, "taxable", "0")},_vm.setChangesMade],"input":_vm.setChangesMade}}),_c('label',{attrs:{"for":"no"}},[_vm._v("No")])]):_vm._e()]):_c('div',{staticClass:"row mt-3"},[(false)?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-12"},[_vm._v("Taxable")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(subService.taxable),expression:"subService.taxable"}],attrs:{"type":"radio","id":"yes","value":"true","disabled":_vm.clickedEventDetails_deleted ||
                      !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) ||
                      _vm.clickedEventDetails_status == 'Verified' ||
                      (subService.status == 'INACTIVE' &&
                        !subService.selected)},domProps:{"checked":_vm._q(subService.taxable,"true")},on:{"change":[function($event){return _vm.$set(subService, "taxable", "true")},_vm.setChangesMade],"input":_vm.setChangesMade}}),_c('label',{attrs:{"for":"yes"}},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(subService.taxable),expression:"subService.taxable"}],attrs:{"type":"radio","id":"no","value":"false","disabled":_vm.clickedEventDetails_deleted ||
                      !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) ||
                      _vm.clickedEventDetails_status == 'Verified' ||
                      (subService.status == 'INACTIVE' &&
                        !subService.selected)},domProps:{"checked":_vm._q(subService.taxable,"false")},on:{"change":[function($event){return _vm.$set(subService, "taxable", "false")},_vm.setChangesMade],"input":_vm.setChangesMade}}),_c('label',{attrs:{"for":"no"}},[_vm._v("No")])]):_vm._e()]),(subService.pivot)?_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-12"},[_c('input-control',{attrs:{"type":"vertical","rows":"6","disabled":_vm.clickedEventDetails_deleted ||
                      !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) ||
                      _vm.clickedEventDetails_status == 'Verified' ||
                      (subService.status == 'INACTIVE' &&
                        !subService.selected),"maxLength":"500","control":"textarea"},on:{"change":_vm.setChangesMade,"input":_vm.setChangesMade},model:{value:(subService.pivot.description),callback:function ($$v) {_vm.$set(subService.pivot, "description", $$v)},expression:"subService.pivot.description"}},[_vm._v("Description ")])],1)]):_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-12"},[_c('input-control',{attrs:{"type":"vertical","rows":"6","disabled":_vm.clickedEventDetails_deleted ||
                      !_vm.canEnable(_vm.clickedEventDetails_provider_user_id) ||
                      _vm.clickedEventDetails_status == 'Verified' ||
                      (subService.status == 'INACTIVE' &&
                        !subService.selected),"maxLength":"500","control":"textarea"},on:{"change":_vm.setChangesMade,"input":_vm.setChangesMade},model:{value:(subService.description),callback:function ($$v) {_vm.$set(subService, "description", $$v)},expression:"subService.description"}},[_vm._v("Description ")])],1)])])])})],2):_vm._e(),_c('div',{staticClass:"w-100 text-center mb-5"},[_c('a',{staticClass:"btn btn-black",attrs:{"href":"#nav-home"},on:{"click":function($event){$event.preventDefault();return _vm.selectTab('#nav-home-tab')}}},[_vm._v("Back To Details")])])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"nav-memo","role":"tabpanel","aria-labelledby":"nav-memo-tab"}},[(_vm.user && !_vm.user.isPatient)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Memo "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Seen in billing & payroll reports'),expression:"'Seen in billing & payroll reports'",modifiers:{"top":true}}],staticClass:"fa fa-info-circle ml-2 pointer-cursor"})]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.clickedEventDetails_memo),expression:"clickedEventDetails_memo"}],staticClass:"form-control",attrs:{"disabled":_vm.clickedEventDetails_status == 'Verified' ||
              _vm.clickedEventDetails_deleted ||
              !_vm.canEnable(_vm.clickedEventDetails_provider_user_id),"rows":"5"},domProps:{"value":(_vm.clickedEventDetails_memo)},on:{"change":_vm.setChangesMade,"input":function($event){if($event.target.composing)return;_vm.clickedEventDetails_memo=$event.target.value}}})]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Reason"+_vm._s(_vm.user && !_vm.user.isPatient ? " - seen by client" : ""))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.clickedEventDetails_reason),expression:"clickedEventDetails_reason"}],staticClass:"form-control",attrs:{"disabled":(_vm.user && _vm.user.isPatient) ||
              _vm.clickedEventDetails_deleted ||
              _vm.clickedEventDetails_status == 'Verified' ||
              !_vm.canEnable(_vm.clickedEventDetails_provider_user_id),"rows":"5"},domProps:{"value":(_vm.clickedEventDetails_reason)},on:{"change":_vm.setChangesMade,"input":function($event){if($event.target.composing)return;_vm.clickedEventDetails_reason=$event.target.value}}})]),_c('div',{staticClass:"w-100 text-center mb-5"},[_c('a',{staticClass:"btn btn-black",attrs:{"href":"#nav-home"},on:{"click":function($event){$event.preventDefault();return _vm.selectTab('#nav-home-tab')}}},[_vm._v("Back To Details")])])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"nav-expenses","role":"tabpanel","aria-labelledby":"nav-expenses-tab"}},[_c('div',{staticClass:"form-group"},[_c('input-control',{attrs:{"control":"number","disabled":_vm.clickedEventDetails_status == 'Verified' ||
              _vm.clickedEventDetails_deleted ||
              !_vm.canEnable(_vm.clickedEventDetails_provider_user_id),"type":"vertical","step":"0.01","min":"0"},on:{"input":_vm.setChangesMade},model:{value:(_vm.clickedEventDetails_expenses),callback:function ($$v) {_vm.clickedEventDetails_expenses=$$v},expression:"clickedEventDetails_expenses"}},[_vm._v("Expenses $")]),_c('input-control',{attrs:{"type":"vertical","rows":"6","disabled":_vm.clickedEventDetails_status == 'Verified' ||
              _vm.clickedEventDetails_deleted ||
              !_vm.canEnable(_vm.clickedEventDetails_provider_user_id),"control":"textarea"},on:{"change":_vm.setChangesMade,"input":_vm.setChangesMade},model:{value:(_vm.clickedEventDetails_billing_details),callback:function ($$v) {_vm.clickedEventDetails_billing_details=$$v},expression:"clickedEventDetails_billing_details"}},[_vm._v("Billing Details ")])],1),_c('div',{staticClass:"w-100 text-center mb-5"},[_c('a',{staticClass:"btn btn-black",attrs:{"href":"#nav-home"},on:{"click":function($event){$event.preventDefault();return _vm.selectTab('#nav-home-tab')}}},[_vm._v("Back To Details")])])])]),(_vm.user && !_vm.user.isPatient)?_c('div',{staticClass:"col p-0 mb-4"},[(
          _vm.clickedEventDetails_createdBy &&
          _vm.clickedEventDetails_createdAt &&
          _vm.clickedEventDetails_createdBy != '-' &&
          _vm.clickedEventDetails_createdAt != '-'
        )?_c('div',{staticClass:"mb-2"},[_vm._v(" Created by "),_c('b',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.clickedEventDetails_createdBy))]),_vm._v(" at "),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.clickedEventDetails_createdAt))])]):_vm._e(),(
          _vm.clickedEventDetails_verifiedBy &&
          _vm.clickedEventDetails_verifiedAt &&
          _vm.clickedEventDetails_verifiedBy != '-' &&
          _vm.clickedEventDetails_verifiedAt != '-'
        )?_c('div',[_vm._v(" "+_vm._s(_vm.clickedEventDetails_status == "Verified" ? "Verified" : "Unverified")+" by "),_c('b',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.clickedEventDetails_verifiedBy))]),_vm._v(" at "),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.clickedEventDetails_verifiedAt))])]):_vm._e(),(_vm.clickedEventDetails_deleted)?_c('div',{staticClass:"mt-2"},[_vm._v(" Removed by "),_c('b',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.clickedEventDetails_deletedBy))]),_vm._v(" at "),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.clickedEventDetails_deletedAt))])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"row m-0 d-flex justify-content-center"},[(
          _vm.user &&
          !_vm.user.isPatient &&
          !_vm.clickedEventDetails_deleted &&
          _vm.canEnable(_vm.clickedEventDetails_provider_user_id)
        )?_c('div',[(_vm.clickedEventDetails_status != 'Verified')?_c('button',{staticClass:"btn btn-theme",attrs:{"type":"submit"}},[_vm._v(" Save Changes ")]):_vm._e()]):_vm._e(),(
          !_vm.clickedEventDetails_deleted &&
          _vm.canEnable(_vm.clickedEventDetails_provider_user_id) &&
          (_vm.clickedEventDetails_status == 'Ready for Review' ||
            _vm.clickedEventDetails_status == 'PENDING')
        )?_c('div',[(
            _vm.user &&
            (_vm.user.isAdmin ||
              _vm.user.isManagingAdmin ||
              (_vm.currentProvider && _vm.currentProvider.can_verify_event))
          )?_c('a',{staticClass:"btn btn-info mb-2 ml-3",on:{"click":function($event){$event.preventDefault();return _vm.approveEvent.apply(null, arguments)}}},[(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm"}):_vm._e(),_vm._v(" Verify ")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.gettingApt)?_c('div',{staticClass:"pt-5"},[_c('alert',{staticClass:"m-4"})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }