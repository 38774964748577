<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Add Provider</h1>
      </div>
    </div>
    <div class="card theme-card shadow-card">
      <div class="card-body">
        <form @submit.prevent="submit">
          <input-control
            v-model="providers.first"
            required
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            >First Name</input-control
          >
          <input-control
            v-model="providers.last"
            required
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            >Last Name</input-control
          >
          <input-control
            v-model="providers.email"
            required
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            control="email"
            >Email</input-control
          >
          <input-control
            v-model="providers.password"
            required
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            control="password"
            >Password</input-control
          >
          <input-control
            v-model="providers.phone_number"
            required
            :cleave="cleave.phone"
            labelClass="col-md-3 col-lg-2"
            type="horizontal"
            >Phone Number</input-control
          >

          <div class="form-group row">
            <label
              class="administrator-id-label col-md-3 col-lg-2 col-form-label"
              for="administrator_id"
              >Provider Administrators</label
            >
            <div class="col">
              <vue-tags-input
                id="administrator_ids"
                name="administrator_ids"
                class="form-control"
                v-model="tag"
                :tags="tags"
                :autocomplete-items="filteredItems"
                :add-only-from-autocomplete="true"
                :autocomplete-min-length="0"
                placeholder=""
                @tags-changed="(newTags) => (adminTags = newTags)"
              />
            </div>
          </div>

          <input-control
            v-model="providers.sin"
            required
            type="horizontal"
            :cleave="cleave.sin"
            labelClass="col-md-3 col-lg-2"
            >S.I.N</input-control
          >
          <input-control
            v-model="providers.hstNumber"
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            >HST Number</input-control
          >
          <input-control
            v-model="providers.hcai_registration_id"
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            >HCAI Registration ID</input-control
          >
          <div class="form-group row">
            <label class="col-md-3 col-lg-2" for="input-control-birth_date"
              >Birth Date</label
            >
            <div class="col">
              <datetime
                id="input-control-birth_date"
                v-model="providers.birth_date"
                placeholder=""
                :week-start="7"
                zone="UTC"
                format="yyyy-MM-dd"
                input-class="form-control"
              ></datetime>
            </div>
          </div>
          <input-control
            type="horizontal"
            v-model="providers.bio"
            labelClass="col-md-3 col-lg-2"
            control="textarea"
            >Bio</input-control
          >
          <div class="form-group row">
            <label class="col-md-3 col-lg-2" for="input-control-hiredAt"
              >Hired On</label
            >
            <div class="col">
              <datetime
                id="input-control-hiredAt"
                v-model="providers.hired_at"
                placeholder=""
                :week-start="7"
                zone="UTC"
                format="yyyy-MM-dd"
                input-class="form-control"
              ></datetime>
            </div>
          </div>

          <select-control
            v-model="providers.status"
            :options="statuses"
            labelClass="col-12 col-md-3 col-lg-2"
            required
            >Status</select-control
          >

          <div class="form-group row">
            <label
              for="input-control-can-verify"
              class="
                col-auto col-lg-2 col-form-label
                pointer-cursor
                d-flex
                align-items-center
              "
              >Ability To Verify Events</label
            >
            <div class="col-auto can-verify">
              <input
                type="checkbox"
                id="input-control-can-verify"
                v-model="providers.can_verify_event"
                class="form-control ml-1 pointer-cursor"
              />
            </div>
          </div>

          <div class="form-group row align-items-center" v-if="false">
            <label class="col-md-3 col-lg-2 mb-0"
              >Receive Notifications Via</label
            >
            <div class="d-flex col mr-2">
              <div class="custom-control custom-switch mr-5">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sendingSMS"
                  v-model="providers.enable_sending_sms"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="sendingSMS"
                  >SMS</label
                >
              </div>
              <div class="custom-control custom-switch ml-5">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sendingEmail"
                  v-model="providers.enable_sending_email"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="sendingEmail"
                  >Email</label
                >
              </div>
            </div>
          </div>

          <button
            type="submit"
            class="mt-3 btn btn-theme save-button"
            :disabled="isSaving"
          >
            <span
              ><i class="mr-2 fas fa-check-circle fa-fw" v-if="!isSaving"></i>
              <i class="mr-2 fa fa-spin fa-circle-notch" v-else></i>Create</span
            >
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import Swal from "sweetalert2";
import VueTagsInput from "@johmun/vue-tags-input";
import { mapActions } from "vuex";
import * as dayjs from "dayjs";

export default {
  name: "ProviderAdd",
  components: {
    datetime: Datetime,
    VueTagsInput,
  },
  computed: {
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) != -1;
      });
    },
  },
  data() {
    return {
      isSaving: false,
      statuses: [
        { value: "ACTIVE", label: "Active" },
        { value: "PENDING", label: "Pending" },
        { value: "INACTIVE", label: "Inactive" },
      ],
      providers: {
        enable_sending_sms: false,
        enable_sending_email: false,
        can_verify_event: true,
      },
      tags: [],
      adminTags: [],
      tag: "",
      autocompleteItems: [],
      genderOptions: [
        { value: "0", label: "Other" },
        { value: "1", label: "Male" },
        { value: "2", label: "Female" },
      ],
      cleave: {
        phone: { blocks: [20], numericOnly: true },
        sin: { blocks: [3, 3, 3], delimiter: " ", numericOnly: true },
      },
    };
  },
  beforeMount() {
    const date = new Date(this.providers.hired_at);
    date.setDate(date.getDate() + 1);
    this.providers.hired_at = dayjs(date).format("YYYY-MM-DD");
  },
  async mounted() {
    try {
      let users = [];
      const usersRes = await this.$http.get("admins-managers-info");
      usersRes.data.forEach((u) => {
        u = { text: u.name, value: u.id, id: u.id };
        users.push(u);
      });
      this.users = users;
      this.autocompleteItems = users;
    } catch (err) {
      //eslint-disable-next-line
      console.log(err);
    }
  },
  methods: {
    submit() {
      this.addProvider();
    },
    ...mapActions({
      getAllUsers: "security/getUsersWithRoles",
    }),
    addProvider: function () {
      let data = this.providers;
      const emailDotIndex = data.email.lastIndexOf(".");
      if (emailDotIndex <= data.email.indexOf("@") + 1) {
        return Swal.fire({
          title: "Email Validation",
          text: "Invalid Email address",
          icon: "error",
        });
      }

      data.sin = data.sin.replaceAll(" ", "");
      if (data.sin.length && data.sin.length != 9 && data.sin !== "*****") {
        return Swal.fire({
          title: "S.I.N Validation",
          text: "S.I.N should be 9 digits",
          icon: "error",
        });
      }
      data.username = data.email;
      if (!data.can_verify_event) {
        data.can_verify_event = false;
      }
      const userIds = [];
      this.adminTags.forEach((el) => {
        userIds.push(el.id);
      });
      data.administrator_ids = userIds;
      this.isSaving = true;
      this.$forceUpdate();
      this.$store
        .dispatch("providers/create", data)
        .then(() => {
          this.isSaving = false;
          this.$forceUpdate();
          this.getAllUsers();
        })
        .catch(() => {
          this.isSaving = false;
          this.$forceUpdate();
        });
    },
  },
};
</script>
<style scoped>
.can-verify input {
  min-width: 25px;
  width: 25px;
}
</style>
<style lang="scss">
#administrator_ids {
  min-height: 4px;
  padding: 0;
  .ti-input {
    border: 0;
    .ti-tags {
      li {
        height: 32px;
        margin-top: 0;
      }
    }
  }
  .ti-new-tag-input-wrapper input {
    height: 100%;
  }
}
</style>
