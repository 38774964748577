<template>
  <div class="container user-locations">
    <h1 v-if="!src">Locations</h1>
    <GoogleMap
      ref="gmap"
      v-if="!loading"
      :key="markers.length"
      v-show="showMap"
      :markers="markers"
      :defaultZoom="defaultZoom"
      :icons="mapIcons"
      :currentLocation="currentLocation"
      :showMapLegend="user && (user.isAdmin || user.isManagingAdmin)"
    ></GoogleMap>
    <alert class="mt-3 light-shadow" v-if="loading" />
  </div>
</template>
<script>
import Vue from "vue";
import GoogleMap from "@/components/GoogleMap.vue";
import PatientMarkerModal from "../views/admin/locations/PatientMarkerModal";
import ProviderMarkerModal from "../views/admin/locations/ProviderMarkerModal";
import AdminMarkerModal from "../views/admin/locations/AdminMarkerModal";
import { mapState, mapActions } from "vuex";

export default {
  name: "UserLocations",
  props: {
    src: String,
  },
  data() {
    return {
      showMap: true,
      loading: true,
      defaultZoom: 12,
      currentLocation: null,
      mapIcons: [
        {
          name: "Client",
          iconColor: "green",
        },
        {
          name: "Provider",
          iconColor: "red",
        },
        {
          name: "Admin",
          iconColor: "blue",
        },
      ],
      markers: [],
    };
  },
  mounted() {
    this.loading = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    }
    this.getUserInfo().then(() => {
      if (this.user && this.user.isProvider) {
        this.getProviderPatients({
          id: this.user.provider_id,
          address: true,
          noStore: true,
        }).then((res) => {
          const patientsMarkers = [];
          res.forEach((patienet) => {
            patienet.addresses.forEach((address) => {
              if (!address.isDeleting) {
                patientsMarkers.push({
                  lat: parseFloat(address.lat),
                  lng: parseFloat(address.lng),
                  data: {
                    patientName: patienet.name,
                    patientId: `${patienet.id}`,
                    address: {
                      street: address.street,
                      city: address.city,
                      state: address.state,
                      country: address.country,
                      phone: patienet.phone_number,
                    },
                  },
                });
              }
            });
          });
          patientsMarkers.forEach((marker) => {
            marker.content = this.renderPatientMarkerModal(marker);
            marker.markerColor = "green";
          });
          this.markers = [...this.markers, ...patientsMarkers];
          this.loading = false;
        });
      } else if (
        this.user &&
        (this.user.isAdmin || this.user.isManagingAdmin)
      ) {
        this.getPatients({ address: true, status: 0, noStore: true }).then(
          (patients) => {
            const patientsMarkers = [];
            patients.forEach((patienet) => {
              patienet.addresses.forEach((address) => {
                if (!address.isDeleting) {
                  patientsMarkers.push({
                    lat: parseFloat(address.lat),
                    lng: parseFloat(address.lng),
                    data: {
                      patientName: patienet.name,
                      patientId: `${patienet.id}`,
                      address: {
                        street: address.street,
                        city: address.city,
                        state: address.state,
                        country: address.country,
                        phone: patienet.phone_number,
                      },
                      assignedProvider: {
                        providerName: `${this.userInfo.last}, ${this.userInfo.first}`,
                        providerId: `${this.userInfo.id}`,
                      },
                    },
                  });
                }
              });
            });
            patientsMarkers.forEach((marker) => {
              marker.content = this.renderPatientMarkerModal(marker);
              marker.markerColor = "green";
            });
            this.markers = [...this.markers, ...patientsMarkers];
          }
        );

        this.getProviders({ address: true, status: 0, noStore: true }).then(
          (providers) => {
            const providersMarkers = [];
            providers.forEach((provider) => {
              provider.addresses.forEach((address) => {
                if (!address.isDeleting) {
                  providersMarkers.push({
                    lat: parseFloat(address.lat),
                    lng: parseFloat(address.lng),
                    data: {
                      providerName: `${provider.last}, ${provider.first}`,
                      providerId: `${provider.id}`,
                      address: {
                        street: address.street,
                        city: address.city,
                        state: address.state,
                        country: address.country,
                        phone: provider.phone_number,
                      },
                    },
                  });
                }
              });
            });
            providersMarkers.forEach((marker) => {
              marker.content = this.renderProviderMarkerModal(marker);
              marker.markerColor = "red";
            });
            this.markers = [...this.markers, ...providersMarkers];
          }
        );
        this.$http.get("admins-managers-addresses").then((usersRes) => {
          if (usersRes && usersRes.data && usersRes.data.data) {
            const adminsMarkers = [];
            usersRes.data.data.forEach((admin) => {
              admin.addresses.forEach((address) => {
                if (!address.isDeleting) {
                  adminsMarkers.push({
                    lat: parseFloat(address.lat),
                    lng: parseFloat(address.lng),
                    data: {
                      adminName: `${admin.last}, ${admin.first}`,
                      adminId: `${admin.id}`,
                      address: {
                        street: address.street,
                        city: address.city,
                        state: address.state,
                        country: address.country,
                      },
                    },
                  });
                }
              });
            });
            adminsMarkers.forEach((marker) => {
              marker.content = this.renderAdminMarkerModal(marker);
              marker.markerColor = "blue";
            });
            this.markers = [...this.markers, ...adminsMarkers];
            this.loading = false;
          }
        });
      }
    });
  },
  methods: {
    ...mapActions({
      getUserInfo: "auth/getUserInfo",
      getProviderPatients: "providers/patients/getBasicPatients",
      getProviders: "providers/getBasicProviders",
      getPatients: "patients/getBasicPatients",
    }),
    renderPatientMarkerModal(marker) {
      var ComponentClass = Vue.extend(PatientMarkerModal);
      var instance = new ComponentClass({
        propsData: {
          data: marker.data,
        },
      });
      instance.$mount();
      return instance.$el;
    },
    renderProviderMarkerModal(marker) {
      var ComponentClass = Vue.extend(ProviderMarkerModal);
      var instance = new ComponentClass({
        propsData: {
          data: marker.data,
        },
      });
      instance.$mount();
      return instance.$el;
    },
    renderAdminMarkerModal(marker) {
      var ComponentClass = Vue.extend(AdminMarkerModal);
      var instance = new ComponentClass({
        propsData: {
          data: marker.data,
        },
      });
      instance.$mount();
      return instance.$el;
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      userInfo: (state) => state.auth.userInfo,
      providerPatients: (state) => state.providers.patients,
    }),
  },
  components: {
    GoogleMap,
  },
};
</script>

<style lang="scss">
.user-locations {
  @media (max-width: 767px) {
    padding: 0;
  }
  .map {
    height: 90vh !important;
  }
}
</style>
