import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: [],
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    get: function (ctx, id) {
      const vm = this;
      return new Promise(function (resolve) {
        ctx.commit("SET_STATE", {
          key: "isLoading",
          value: true,
        });
        if (id) {
          vm.$http.get(`providers/${id}/patients`).then((res) => {
            ctx.commit("SET_STATE", {
              key: "data",
              value: res.data.data,
            });
            ctx.commit("SET_STATE", {
              key: "isLoading",
              value: false,
            });
            resolve(res.data.data);
          });
        }
      });
    },
    getBasicPatients: function (ctx, payload) {
      const vm = this;
      return new Promise(function (resolve) {
        ctx.commit("SET_STATE", {
          key: "isLoading",
          value: true,
        });
        if (payload && payload.id) {
          vm.$http
            .get(
              `providers/${payload.id}/basic-patients${
                payload.address ? "?Address=1" : ""
              }`
            )
            .then((res) => {
              if (!payload.noStore) {
                ctx.commit("SET_STATE", {
                  key: "data",
                  value: res.data.data,
                });
              }
              ctx.commit("SET_STATE", {
                key: "isLoading",
                value: false,
              });
              resolve(res.data.data);
            });
        }
      });
    },
    connect: function (ctx, payload) {
      const vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .post(`providers/${payload.providerId}/attach-patient`, {
            patient: payload.patient.id,
          })
          .then(
            (res) => {
              if (res.status == 200) {
                ctx.commit("SET_STATE", {
                  key: "data",
                  value: [...ctx.state.data, payload.patient],
                });
                Swal.fire({
                  title: "Assigned",
                  text: res.data.message,
                  icon: "success",
                });
                return resolve(true);
              }
              return resolve();
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            }
          );
      });
    },
    disconnect: function (_, payload) {
      const vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .post(`providers/${payload.providerId}/disconnect-patient`, {
            patient: payload.patientId,
          })
          .then(
            (res) => {
              if (res.status == 200) {
                Swal.fire({
                  title: "Unassigned",
                  text: res.data.message,
                  icon: "success",
                });
                resolve(true);
              }
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err.data.error.message.error || err.data.message,
                  icon: "error",
                });
                resolve(false);
              }
            }
          );
      });
    },
  },
};
