<template>
  <div>
    <calendar-filters
      :options="{
        classes,
        adminUsers,
        providerUsers,
        patientUsers,
      }"
      :users="users"
      :profileAdminUserId="profileAdminUserId"
      :profileProviderUserId="profileProviderUserId"
      :profilePatientUserId="profilePatientUserId"
      @filterChanged="filtersChanged"
      @openUserAvailability="openUserAvailability"
      @openBulkSessionsModal="openBulkSessionsModal"
    ></calendar-filters>
    <p class="mobile-tablet-only text-danger noPrint">
      Hint: Long press to create a session
    </p>
    <calendar-print
      :profileAdminUserId="profileAdminUserId"
      :eventsToPrint="eventsToPrint"
      :selectedProvider="selectedProvider"
      :selectedPatient="selectedPatient"
      :patientData="patientData"
      :companyProfile="companyProfile"
      :calendarTitle="calendarTitle"
    ></calendar-print>
    <div class="calendar-wrapper noPrint">
      <button
        type="button"
        class="btn btn-black noPrint print-btn"
        @click="print"
      >
        <i class="fas fa-print mr-2"></i> Print
      </button>
      <div class="total-hours-info" v-if="user && !user.isPatient">
        <table>
          <tr>
            <td class="text-right pr-2">Total Billable:</td>
            <td class="text-right font-weight-bold">
              <i v-if="loading" class="fas fa-spin fa-circle-notch"></i
              >{{ loading ? "" : totalBillable }}
            </td>
          </tr>
          <tr>
            <td class="text-right pr-2">Total Non Billable:</td>
            <td class="text-right font-weight-bold">
              <i v-if="loading" class="fas fa-spin fa-circle-notch"></i
              >{{ loading ? "" : totalNonBillable }}
            </td>
          </tr>
        </table>
        <i
          class="ml-4 fas fa-lg pointer-cursor noPrint"
          style="width: 30px"
          :class="`${hideDeleted ? 'fa-eye-slash' : 'fa-eye'}`"
          @click="toggleDeletedStatus()"
          :title="
            hideDeleted
              ? 'Removed sessions are hidden'
              : 'Removed sessions are visible'
          "
        ></i>
      </div>
      <full-calendar ref="fullCalendar" :options="calendar" />
      <loader v-if="loading || gettingUsers"></loader>
    </div>
    <edit-calendar-session
      ref="editSessionModal"
      @refetchCalendar="refetchCalendar"
      @openUserAvailability="openUserAvailability"
      :currentProvider="currentProvider"
      :profilePatientUserId="profilePatientUserId"
      :nonBillableServices="nonBillableServices"
      :parentServicesTemp="parentServicesTemp"
      :services="services"
      :providers="providers"
      :patients="patients"
      :users="users"
      :options="{
        adminUsers,
        providerUsers,
        patientUsers,
      }"
    ></edit-calendar-session>
    <create-calendar-session
      ref="createSessionModal"
      @refetchCalendar="refetchCalendar"
      @closeModal="closeModal"
      @openUserAvailability="openUserAvailability"
      :currentProvider="currentProvider"
      :nonBillableServices="nonBillableServices"
      :parentServicesTemp="parentServicesTemp"
      :services="services"
      :providers="providers"
      :patients="patients"
      :users="users"
      :profilePatientUserId="profilePatientUserId"
      :profileProviderUserId="profileProviderUserId"
      :options="{
        adminUsers,
        providerUsers,
        patientUsers,
      }"
    ></create-calendar-session>
    <calendar-clone-modal
      @refetchCalendar="refetchCalendar"
    ></calendar-clone-modal>
    <calendar-bulk-modal
      :options="{
        classes,
        services: parentServicesTemp,
        adminUsers,
        providerUsers,
        patientUsers,
      }"
      :profileAdminUserId="profileAdminUserId"
      :profileProviderUserId="profileProviderUserId"
      :profilePatientUserId="profilePatientUserId"
      :initialFilter="filter"
      @refetchCalendar="refetchCalendar"
      ref="bulkModal"
    ></calendar-bulk-modal>
    <calendar-availability-modal
      ref="availabilityModal"
    ></calendar-availability-modal>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import { mapActions, mapState } from "vuex";
import dateFormat from "@/utils/dateFormat";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { CALENDAR_CONFIG } from "./constants";
import CalendarFilters from "./components/filters.vue";
import CalendarCloneModal from "./components/clone.vue";
import CalendarBulkModal from "./components/bulk.vue";
import CalendarAvailabilityModal from "./components/availability.vue";
import CalendarPrint from "./components/print.vue";
import EditCalendarSession from "./components/Edit.vue";
import CreateCalendarSession from "./components/Create.vue";
import { orderBy } from "lodash";

let calendarApi = null;
export default {
  components: {
    FullCalendar,
    CalendarFilters,
    CalendarCloneModal,
    CalendarBulkModal,
    CalendarAvailabilityModal,
    CalendarPrint,
    EditCalendarSession,
    CreateCalendarSession,
  },
  props: [
    "profileProviderUserId",
    "profilePatientUserId",
    "profileAdminUserId",
  ],
  data() {
    return {
      loading: false,
      gettingUsers: false,
      selectedProvider: null,
      selectedPatient: null,
      currentProvider: null,
      mainNonBillableService: null,
      nonBillableServices: [],
      sentAppointmentRequests: [],
      parentServicesTemp: [],
      services: [{}],
      eventsToPrint: [],
      totalBillable: 0,
      totalNonBillable: 0,
      filter: {
        AppStatus: "",
        billableStatus: "",
        classification: "",
        Patient: "",
        Provider: "",
        assignedToMe: false,
      },
      events: [],
      hideDeleted: true,
      calendar: {
        ...CALENDAR_CONFIG(this),
        eventClick: this.handleEventClick,
      },
    };
  },
  created() {
    this.calendar.events = this.getAppointments;
    window.updateHiddenValue = this.updateHiddenValue.bind(this);
    if (
      this.profileProviderUserId ||
      this.profilePatientUserId ||
      this.profileAdminUserId
    ) {
      this.getCompanyProfile();
    }
  },
  mounted() {
    if (this.paramAdminUserId) {
      this.filter.Provider = this.paramAdminUserId;
      this.$router.replace({ query: {} });
    }
    if (this.user && !this.user.isPatient) {
      this.getCalendarPatients({ noLoading: !!this.profilePatientUserId });
    }
    if (this.user && this.user.isPatient) {
      this.getPatientProviders(this.user.patient_id);
    } else {
      this.getClassification();
      this.getCalendarProviders({
        noLoading: !!this.profileProviderUserId,
      }).then((providers) => {
        if (
          providers &&
          providers.length &&
          this.user &&
          this.user.isProvider
        ) {
          this.currentProvider = providers.filter(
            (provider) => provider.user_id == this.user.id
          )[0];
        }
      });
    }
    this.gettingUsers = true;
    this.getCalendarUsers().then(() => {
      this.gettingUsers = false;
      this.$forceUpdate();
    });
    calendarApi = this.$refs.fullCalendar.getApi();
    calendarApi.on("select", this.handleSelect);
    this.$http
      .get("services")
      .then(
        function (res) {
          this.parentServices = res.data.data.filter(
            (item) =>
              item.parent_service_id == null &&
              item.is_default != true &&
              item.unit != "KM"
          );
          this.parentServicesTemp = this.parentServices;

          this.nonBillableServices = this.parentServices.filter(
            (service) => !service.profession_id
          );
          this.mainNonBillableService = this.parentServices.filter(
            (service) =>
              !service.profession_id && service.name == "Non-billable Service"
          )[0];
          if (!this.mainNonBillableService) {
            const service = {
              catastrophic: false,
              description: "Non billable service",
              is_default: false,
              name: "Non-billable Service",
              code: "NON-BILLABLE",
              prov: "ON",
              rate: 0,
              taxable: true,
              unit: "HR",
              status: "ACTIVE",
            };
            this.createService(service).then((result) => {
              this.nonBillableServices = [...this.nonBillableServices, result];
            });
          }
          this.services = orderBy(res.data.data, ["is_default"], ["desc"]);
        }.bind(this)
      )
      .catch((err) => {
        if (!err.accessDenied) {
          Swal.fire({
            title: "Error",
            text: (err.data || {}).message || "Something went wrong...",
            icon: "error",
          });
        }
        this.$refs.editSessionModal.closeUpdateTray();
      });
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      patients: (s) => s.patients.calendarPatients,
      providers: (s) => s.providers.calendarProviders,
      users: (state) => state.security.calendarUsers,
      userInfo: (state) => state.auth.userInfo,
      patientProviders: (state) => state.patients.providers,
      companyProfile: (state) => state.settings.companyProfile,
      patientData: (state) => state.patients.patient,
      classifications: (state) => state.classifications.data,
    }),
    calendarTitle() {
      if (this.events && calendarApi) {
        return calendarApi.currentData.viewTitle || "";
      }
      return "";
    },
    adminUsers() {
      return this.sortAlphabetically(
        this.users.filter((user) => user.isAdmin || user.isManagingAdmin)
      );
    },
    providerUsers() {
      if ((this.user || {}).isPatient) {
        const providersIds = (this.patientProviders.data || []).map((item) =>
          parseInt(item.user_id)
        );
        return this.sortAlphabetically(
          this.users.filter(
            (user) => user.isProvider && providersIds.includes(user.id)
          )
        );
      }
      return this.sortAlphabetically(
        this.users.filter((user) => user.isProvider)
      );
    },
    patientUsers() {
      if ((this.user || {}).isProvider) {
        return this.sortAlphabetically(this.patients);
      } else if ((this.user || {}).isPatient) {
        return [this.user];
      }
      return this.sortAlphabetically(
        this.users.filter((user) => user.isPatient)
      );
    },
    classes() {
      if (this.classifications && this.classifications.length) {
        return this.classifications.map((item) => {
          return { label: item.subject, id: item.id };
        });
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      getCalendarProviders: "providers/getCalendarProviders",
      getCalendarPatients: "patients/getCalendarPatients",
      getUserInfo: "auth/getUserInfo",
      getCalendarUsers: "security/getCalendarUsers",
      createService: "services/create",
      getPatientProviders: "patients/providers/get",
      getCompanyProfile: "settings/getCompanyProfile",
      getClassification: "classifications/get",
    }),
    dateFormat: dateFormat,
    openUserAvailability: function (userId) {
      this.$refs.availabilityModal.openUserAvailabilityModal(userId);
    },
    openBulkSessionsModal: function (userId) {
      this.$refs.bulkModal.openBulkSessionsModal(userId);
    },
    refetchCalendar: function () {
      calendarApi.refetchEvents();
    },
    toggleDeletedStatus: function () {
      this.hideDeleted = !this.hideDeleted;
      this.refetchCalendar();
    },
    handleSelect: function (arg) {
      this.$refs.createSessionModal.initBooking(arg);
    },
    getAppointments: function (info, successCb) {
      this.loading = true;
      if (!this.userInfo) {
        this.getUserInfo()
          .then(() => {
            this.sendAppointmentRequest(info, successCb);
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.sendAppointmentRequest(info, successCb);
      }
    },
    sendAppointmentRequest: function (info, successCb) {
      if (this.sentAppointmentRequests.length) {
        while (this.sentAppointmentRequests.length) {
          const requestToAbort = this.sentAppointmentRequests.pop();
          requestToAbort.abort();
        }
      }
      const abort_controller = new AbortController();
      this.events = [];
      let requestUrl = `users/${
        this.profilePatientUserId ||
        this.profileAdminUserId ||
        this.profileProviderUserId ||
        this.user.id
      }/appointments`;
      if (this.user && this.userInfo) {
        const startDateTime = `${dateFormat(
          info.start,
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;
        const endDateTime = `${dateFormat(
          dayjs(info.end).subtract(1, "day"),
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;

        const canadaOffsetStart = dayjs
          .tz(new Date(startDateTime), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(endDateTime), "America/Toronto")
          .utcOffset();
        const payloadStart = dayjs(startDateTime)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();
        const payloadEnd = dayjs(endDateTime)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        let query = `?start=${payloadStart}&end=${payloadEnd}&trashed=${!this
          .hideDeleted}`;

        if (this.filter.classification) {
          query = `${query}&class_id=${this.filter.classification}`;
        }
        if (this.filter.Patient) {
          query = `${query}&client_id=${this.filter.Patient}`;
        } else if (this.profilePatientUserId) {
          query = `${query}&client_id=${this.profilePatientUserId}`;
        }
        if (this.filter.Provider || this.filter.assignedToMe) {
          query = `${query}&provider_id=${
            this.filter.Provider || this.user.id
          }`;
        }
        if (this.filter.billableStatus) {
          query = `${query}&billable_status=${this.filter.billableStatus}`;
        }

        let profileSrc = false;
        if (
          this.profileAdminUserId ||
          this.profileProviderUserId ||
          this.profilePatientUserId
        ) {
          profileSrc = true;
        }

        if (profileSrc) {
          query = `${query}&profile_src=${profileSrc}`;
        }

        this.currentRequest = this.$http
          .get(requestUrl + query, {
            signal: abort_controller.signal,
          })
          .then(
            function (res) {
              let vm = this;
              vm.events = [];
              this.loading = false;
              if (res) {
                res.data.data.map((d) => {
                  try {
                    var insideThis = this;
                    if (insideThis.user != null && insideThis.user.isPatient) {
                      d.isloggedinPatient = true;
                    }
                    d.title = d.patient_name;
                  } catch (e) {
                    d.title = "";
                  }

                  if (d.status == "Ready for Review") {
                    d.backgroundColor = "#009688";
                  } else if (d.status == "PENDING") {
                    d.backgroundColor = "#9d6aa6";
                  } else if (d.status == "Verified") {
                    d.backgroundColor = "darkgreen";
                  }
                  d.start = dayjs(d.start_time)
                    .add(-1 * dayjs(d.start_time).utcOffset(), "minute")
                    .format();
                  d.end = dayjs(d.end_time)
                    .add(-1 * dayjs(d.end_time).utcOffset(), "minute")
                    .format();

                  let hours = 0;
                  try {
                    const date1 = dayjs(d.end_time);
                    hours = date1.diff(d.start_time, "hour", true);
                  } catch (err) {
                    console.log(err);
                  }

                  const minutes = (hours - parseInt(hours)) * 60;
                  d.hours = parseInt(hours);
                  d.minutes = minutes.toFixed(0);
                  vm.events = [...vm.events, d];
                });
                successCb(vm.events);
              }
              vm.applyFilter();
            }.bind(this)
          )
          .catch((err) => {
            if (abort_controller.signal.aborted) {
              return;
            }
            if (!err.accessDenied) {
              console.log(err);
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            this.loading = false;
            this.$refs.editSessionModal.closeUpdateTray();
          });
        this.sentAppointmentRequests.push(abort_controller);
      } else {
        this.loading = false;
      }
    },
    filtersChanged: function (filterData) {
      this.filter = filterData;
      this.applyServerFilter();
    },
    sortAlphabetically(arrayInput) {
      const arraySrc = new Array(...arrayInput);
      const result = arraySrc.sort((a, b) => {
        if (`${a.last} ${a.first}` < `${b.last} ${b.first}`) {
          return -1;
        }
        if (`${a.last} ${a.first}` > `${b.last} ${b.first}`) {
          return 1;
        }
        return 0;
      });
      return result;
    },
    updateHiddenValue: function (eventId) {
      this.eventToHide = this.events.filter(
        (eventItem) => eventItem.id == eventId
      )[0];
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          text: `Are you sure you want to ${
            !this.eventToHide.hidden
              ? "hide this session? so that only admins and managers can see it?"
              : "unhide this session? so all can see it?"
          }`,
          icon: "warning",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: `Yes, ${
            !this.eventToHide.hidden ? "hide" : "unhide"
          } it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .put("appointments/" + eventId + "/update-hidden", {
                hidden: !this.eventToHide.hidden,
              })
              .then(
                function (res) {
                  if (res) {
                    Swal.fire({
                      title: "Success",
                      text: `Session ${
                        !this.eventToHide.hidden ? "hidden" : "unhidden"
                      } successfully`,
                      icon: "success",
                    });
                    this.eventToHide.hidden = !this.eventToHide.hidden;
                    calendarApi.refetchEvents();
                  }
                }.bind(this)
              )
              .catch((err) => {
                if (!err.accessDenied) {
                  Swal.fire({
                    title: "Error",
                    text:
                      ((err.response || {}).data || {}).message ||
                      (err.data || {}).message ||
                      "Something went wrong...",
                    icon: "error",
                  });
                }
              });
          }
        });
    },
    applyServerFilter: function () {
      if (this.profilePatientUserId) {
        this.filter.Patient = this.profilePatientUserId;
      } else if (this.profileAdminUserId) {
        this.filter.Provider = this.profileAdminUserId;
      }
      if (this.filter.assignedToMe) {
        this.filter.Provider = this.user.id;
      }
      calendarApi.refetchEvents();
    },
    applyFilter: function () {
      let vm = this;
      const eventsToPrint = [];
      let totalBillable = 0;
      let totalNonBillable = 0;
      calendarApi.batchRendering(function () {
        let events = calendarApi.getEvents();
        for (let i = 0; i < events.length; i++) {
          let event = events[i];
          event.calendarRef = vm;
          if (event.extendedProps.status == "Verified") {
            totalBillable += event.extendedProps.sub_services_quantity;
            if (event.extendedProps.main_service_billable) {
              totalBillable += event.extendedProps.main_service_quantity;
            } else {
              totalNonBillable += event.extendedProps.main_service_quantity;
            }
          }
          eventsToPrint.push({
            ...event.extendedProps,
            start: event.start,
            id: event.id,
          });
        }

        const billableHours = parseInt(totalBillable);
        const billableMinutes = ((totalBillable - billableHours) * 60).toFixed(
          0
        );
        vm.totalBillable = `${billableHours}h${
          parseInt(billableMinutes) ? ", " + billableMinutes + "m" : ""
        }`;

        const nonBillableHours = parseInt(totalNonBillable);
        const nonBillableMinutes = (
          (totalNonBillable - nonBillableHours) *
          60
        ).toFixed(0);
        vm.totalNonBillable = `${nonBillableHours}h${
          parseInt(nonBillableMinutes) ? ", " + nonBillableMinutes + "m" : ""
        }`;

        vm.eventsToPrint = eventsToPrint;
        if (!events.length) {
          if (vm.profileProviderUserId) {
            vm.selectedProvider = vm.users.filter(
              (user) => user.id == vm.profileProviderUserId
            )[0];
          }
          if (vm.profilePatientUserId) {
            vm.selectedPatient = vm.users.filter(
              (user) => user.id == vm.profilePatientUserId
            )[0];
          }
        }
      });
    },
    print: function () {
      window.print();
    },
    handleEventClick: function (clickInfo) {
      this.$refs.editSessionModal.initEvent(clickInfo);
    },
    closeModal: function () {
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss">
.table {
  thead {
    th.large-font {
      font-size: 20px !important;
    }
  }
}

.fc-daygrid-day {
  border: 0 !important;
  border-left: 1px solid var(--secondary-color) !important;
}

.fc .fc-cell-shaded,
.fc .fc-day-disabled,
.fc .fc-view-harness-active > .fc-view {
  background-color: transparent !important;
}

.calendar-wrapper {
  position: relative;
  background-color: inherit;

  .fc-view {
    background-color: white;
  }
  .fc-scrollgrid-section-liquid {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
      0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
      0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  }
  .verified-card {
    .verified-mark {
      background-color: white;
      z-index: 1000;
      color: green;
      padding: 0;
      margin: 0;
      border-radius: 50%;
    }
    &.non-billable-card {
      .fc-daygrid-event-dot {
        border-color: darkblue !important;
      }
      .verified-mark {
        color: darkblue;
      }
    }
  }
  .pending-card {
    .fc-daygrid-event-dot {
      border-color: purple !important;
    }
  }
  .non-billable-card {
    .fc-daygrid-event-dot {
      border-color: darkred !important;
    }
  }
  .fc-scrollgrid-section-header {
    overflow: hidden;
    border: none;
    background: transparent;

    .fc-scroller {
      overflow: hidden !important;
    }
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    &::-webkit-scrollbar {
      width: 0 !important;
      background: transparent !important;
      height: 0 !important;
    }
  }
  table {
    border: none;
  }

  .fc-toolbar-title {
    font-weight: 900;
    font-size: 45px;
    color: var(--main-text-color);
  }

  .fc-scroller-harness {
    overflow: hidden;

    .fc-col-header {
      overflow: hidden;
      height: 44px;
      border: none;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      tr {
        background-color: rgba(37, 37, 37, 0.4);
      }
      th {
        text-align: left;
        border: none;
        padding-right: 12px;
        vertical-align: middle;
        margin: 0px;
        font-size: 20px;
        color: #ffffff;
      }
    }
  }

  .btn-primary {
    font-weight: 600;
    &::first-letter {
      text-transform: uppercase;
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active {
          color: var(--theme-color);
          background-color: var(--main-text-color);
          border: 2px solid var(--secondary-color);
          border-radius: 8px;
        }
      }
      &:not(.disabled).active {
        color: var(--btn-text-color);
        background-color: var(--theme-color);
        border: 2px solid var(--secondary-color);
        border-radius: 8px;
      }
    }
  }

  .print-btn {
    position: absolute;
    left: calc(50% - 200px);
    margin-top: 5px;
  }

  .total-hours-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 266px;
    font-size: 18px;
    color: var(--main-text-color);
  }
  th {
    .fc-day {
      border-left: 1px;
      border-right: 1px;
      text-align: right;
      padding-right: 12px;
    }
  }
  .fc-toolbar-chunk {
    .btn-group {
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .fc-next-button,
    .fc-today-button,
    .fc-prevYear-button,
    .fc-nextYear-button,
    .fc-prev-button {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }
  .btn-primary {
    background-color: #f0f5ffd6;
    border-radius: 10px;
    color: var(--main-text-color);
    border: 2px;
    border-color: #f0f5ff;
  }
  .loader {
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: flex-start;
    padding-top: 180px;
  }
}

.fc {
  .fc-popover {
    max-height: 400px;
    overflow: overlay;
    z-index: 9;
  }
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-left: 5%;
  margin-right: 5%;
}

.month-event-container {
  display: inline-block;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.deleted-session {
  background-position: center;

  .event-time-container,
  .event-user-info {
    opacity: 0.7;
  }
}
.month-event-container {
  width: 100%;
  border-top-right-radius: 99px;
  border-bottom-right-radius: 99px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  font-weight: bold;
  background-color: white;
  color: var(--main-text-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-shadow: 0 0 4px #ffffff;
  transition: 0.3s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
  .secret-session {
    position: absolute;
    top: -6px;
    left: -4px;
  }

  .verified-mark {
    color: darkgreen;
    position: absolute;
    right: -4px;
    top: -2px;
    z-index: 9999;
    background: white;
  }

  .event-service {
    padding-left: 4px;
    padding-right: 18px;
    padding-top: 0;
    word-break: break-word;
    text-align: center;
    font-size: 15px;
  }
  .event-time-container {
    flex-wrap: wrap;
    align-items: center;
    padding-right: 36px;
    font-size: 14px;
    font-weight: normal;
    max-width: 100%;
    .event-start-end-time {
      color: var(--main-text-color);
    }
  }
  .event-time-diff {
    color: var(--main-text-color);
    font-weight: bold;
    display: inline-block;
    margin-left: 5px;
    font-size: 14px;

    &.empty {
      color: transparent !important;
      text-shadow: none;
      display: none;
    }
  }
  .event-user-info {
    padding-left: 4px;
    font-size: 14px;
    line-height: 1.2;

    &.provider-info {
      padding-right: 12px;
    }

    &.patient-info {
      padding-bottom: 10px;
      padding-right: 16px;
      width: 90%;
    }
  }
  .fc-daygrid-event-dot {
    margin-left: 16px;
    box-shadow: 0 0 6px 0 #ffffff;
  }
}
.provider-name,
.client-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.right-secret-session {
  position: absolute;
  top: 4px;
  right: 1px;
}

.polit-container {
  .calendar-polit {
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-top: 16px;
    margin-left: 8px;
  }
}

.fc-daygrid-day-bottom {
  margin-top: 16px !important;
}

.fc-more-link {
  display: block;
  padding: 4px 8px;
  background-color: white;
  margin-bottom: 8px;
  color: var(--main-text-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
  font-size: 16px;
  width: 90%;
  border-radius: 99px;
  word-break: break-all;
  transition: all 0.3s ease-in-out;

  &:hover {
    filter: brightness(0.9);
  }
}
@media (max-width: 574px) {
  .calendar-wrapper {
    .loader {
      top: 103px;
      height: calc(100% - 103px);
      align-items: center;
      padding-top: 0;
    }
    .print-btn {
      right: 4px;
      left: initial;
      top: 30px;
    }
    .total-hours-info {
      right: 4px;
      top: 146px;
    }
    .fc-direction-ltr {
      overflow-x: auto;
      padding-left: 4px;

      > .fc-header-toolbar {
        margin-bottom: 70px;
      }

      .fc-toolbar-chunk {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .btn-group {
          margin-bottom: 16px;
        }
      }
      .fc-view-harness {
        min-width: 900px;
      }
    }
    .fc-scrollgrid-section-liquid {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
        0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
        0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
    }
  }
}

@media (max-width: 1244px) {
  .calendar-wrapper {
    .total-hours-info {
      right: 20px;
    }
  }
}

.fc-popover-body {
  .fc-daygrid-event-harness {
    margin-top: 16px;
  }
}

.fc-daygrid-event-harness-abs {
  opacity: 0.9;
}

.fc-h-event {
  border: 0 !important;
}

.fc-media-screen .fc-timegrid-event-harness,
.fc-daygrid-event {
  cursor: pointer;
  background-color: transparent !important;
}
@media print {
  @page {
    size: 330mm 400mm;
  }
  .calendar-wrapper {
    margin-top: 230px;
  }

  .fc-toolbar-chunk .btn-group,
  .fc-toolbar-chunk .fc-today-button,
  .fc-toolbar-chunk .btn {
    display: none;
  }
}
.calendar-clone-icon {
  color: var(--main-text-color);
  position: absolute;
  right: 6px;
  &:hover,
  &:focus {
    transition: all 0.2s ease-in-out;
    transform: scale(1.2);
  }
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start,
.fc .fc-non-business {
  border: 0;
}
.fc-daygrid-dot-event:hover,
.fc-daygrid-dot-event.fc-event-mirror {
  background-color: rgba(220, 220, 220, 0.1);
}

@media (max-width: 1244px) {
  .fc-header-toolbar {
    display: block !important;

    .btn-group {
      margin-bottom: 12px;
    }
  }
  .fc-toolbar-title {
    font-size: 32px !important;
    margin-bottom: 4px !important;
  }
  .print-btn {
    left: auto;
  }
}
</style>
