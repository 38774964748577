import emails from "./providers/emails";
import address from "./providers/address";
import phones from "./providers/phones";
import notes from "./providers/notes";
import bio from "./providers/bio";
import professions from "./providers/professions";
import patients from "./providers/patients";
import contacts from "./providers/contacts";
import router from "../router";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export default {
  namespaced: true,
  state: {
    data: [],
    allProviders: [],
    calendarProviders: [],
    provider: null,
    isLoading: false,
    isSaving: false,
    tempData: [],
    tempAllProviders: [],
    invoiceProviders: [],
    providerUsers: {
      loading: false,
      pagination: {
        current: null,
        total: null,
        data: [],
      },
    },
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_PROVIDER_USER_LOADING: (state, value) => {
      state.providerUsers.loading = value;
    },
    SET_PAGINATION: (state, value) => {
      state.providerUsers.pagination = value;
    },
  },
  actions: {
    create: function (context, data) {
      let vm = this;
      context.commit("SET_STATE", { key: "isSaving", value: true });
      return new Promise((resolve, reject) => {
        vm.$http
          .post("providers", data)
          .then((res) => {
            let provider = res.data.data;
            context.commit("SET_STATE", { key: "isSaving", value: false });

            Swal.fire({
              title: "Provider Created Successfully",
              text: "Redirecting to Provider profile",
              icon: "success",
            });

            router.push({
              name: "providers.basic",
              params: {
                id: provider.id,
              },
            });
            resolve();
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isSaving", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
            }
            reject(err);
          });
      });
    },
    update: function (context, data) {
      let vm = this;
      return new Promise(function (resolve) {
        if (data.provider) {
          context.commit("SET_STATE", { key: "isSaving", value: true });
          vm.$http
            .put("providers/" + data.provider, data)
            .then((res) => {
              if (res) {
                context.commit("SET_STATE", { key: "isSaving", value: false });
                Swal.fire({
                  title: "",
                  text: "Provider Data Updated Successfully",
                  icon: "success",
                });
              }
              resolve();
            })
            .catch((err) => {
              context.commit("SET_STATE", { key: "isSaving", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            });
        } else {
          resolve();
        }
      });
    },
    updatePhoto: function (context, data) {
      context.commit("SET_STATE", { key: "isSaving", value: true });
      let formData = new FormData();
      formData.append("profile_picture", data.profile_picture);
      this.$http
        .post(`providers/${context.state.provider.id}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res) {
            Swal.fire({
              title: "Image Updated",
              text: "Image has been updated successfully",
              icon: "success",
            });

            context.commit("SET_STATE", { key: "isSaving", value: false });
          }
        })
        .catch((err) => {
          context.commit("SET_STATE", { key: "isSaving", value: false });
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text:
                (err.data || err.response.data || {}).message ||
                "Something went wrong...",
              icon: "error",
            });
          }
        });
    },
    showConfidentialData: function (context, requestData) {
      let vm = this;
      this.state.sinSecured = true;
      let id = context.state.provider.id;
      return new Promise(function (resolve, reject) {
        vm.$http
          .post(`providers/${id}/reveal-attribute`, requestData)
          .then((reveralResponse) => {
            if (reveralResponse.status == 200) {
              resolve(reveralResponse.data);
            }
            reject(reveralResponse.data);
          })
          .catch((err) => {
            console.log(err);
            if (!err.accessDenied) {
              reject(err.data.error || err.data);
            } else {
              reject(err);
            }
          });
      });
    },
    getAllProviders: function (context, payload) {
      let { id, local, size, next } = payload || {};
      let noStore = false;
      if (id === true) {
        noStore = true;
        id = undefined;
      }

      if (context.state.allProviders.length && local) {
        return new Promise(function (resolve) {
          resolve(context.state.data);
        });
      }

      let vm = this;
      if (!noStore) {
        context.commit("SET_STATE", { key: "isLoading", value: true });
      }
      return new Promise(function (resolve) {
        if (typeof id == "undefined") {
          vm.$http
            .get(
              `basic-providers?Active=1` +
                `${size ? "&size=" + size : ""}` +
                `${next ? "&exclude=" + next : ""}`
            )
            .then((res) => {
              if (res.status == 200) {
                if (!noStore) {
                  let dataToSave = res.data.data;
                  if (next) {
                    dataToSave = [...context.state.allProviders, ...dataToSave];
                  }
                  context.commit("SET_STATE", {
                    key: "allProviders",
                    value: dataToSave,
                  });
                  dataToSave = res.data.data;
                  if (next) {
                    dataToSave = [
                      ...context.state.tempAllProviders,
                      ...dataToSave,
                    ];
                  }
                  context.commit("SET_STATE", {
                    key: "tempAllProviders",
                    value: dataToSave,
                  });
                }
              }
              if (!noStore) {
                context.commit("SET_STATE", { key: "isLoading", value: false });
              }
              resolve(size ? res.data : res.data.data);
            })
            .catch((err) => {
              if (!noStore) {
                context.commit("SET_STATE", { key: "isLoading", value: false });
              }
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            });
        } else {
          vm.$http
            .get(`providers/${id}?Active=1`)
            .then((res) => {
              if (!noStore) {
                context.commit("SET_STATE", {
                  key: "provider",
                  value: res.data.data,
                });
                context.commit("SET_STATE", { key: "isLoading", value: false });
              }
              resolve(res.data.data);
            })
            .catch((err) => {
              if (!noStore) {
                context.commit("SET_STATE", { key: "isLoading", value: false });
              }
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            });
        }
      });
    },
    get: function (context, payload) {
      let { id, local, noLoading, sin } = payload || {};
      let noStore = false;
      if (id === true) {
        noStore = true;
        id = undefined;
      }

      if (local && context.state.data.length) {
        return new Promise(function (resolve) {
          resolve(context.state.data);
        });
      }

      let vm = this;
      if (!noStore && !noLoading) {
        context.commit("SET_STATE", { key: "isLoading", value: true });
      }
      return new Promise(function (resolve) {
        if (typeof id == "undefined") {
          vm.$http
            .get("providers")
            .then((res) => {
              if (res.status == 200) {
                if (!noStore) {
                  context.commit("SET_STATE", {
                    key: "data",
                    value: res.data.data,
                  });
                  context.commit("SET_STATE", {
                    key: "tempData",
                    value: res.data.data,
                  });
                }
              }
              if (!noStore && !noLoading) {
                context.commit("SET_STATE", { key: "isLoading", value: false });
              }
              resolve(res.data.data);
            })
            .catch((err) => {
              if (!noStore) {
                context.commit("SET_STATE", { key: "isLoading", value: false });
              }
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            });
        } else {
          vm.$http
            .get(`providers/${id}`)
            .then((res) => {
              if (sin) {
                res.data.data.sin = sin;
              }
              if (res.data.data.hired_at) {
                res.data.data.hired_at = dayjs(
                  res.data.data.hired_at.split(" ")[0]
                )
                  .add(
                    dayjs(new Date(res.data.data.hired_at)).utcOffset(),
                    "minute"
                  )
                  .utc()
                  .format();
              }
              if (res.data.data.exited_at) {
                res.data.data.exited_at = dayjs(
                  res.data.data.exited_at.split(" ")[0]
                )
                  .add(
                    dayjs(new Date(res.data.data.exited_at)).utcOffset(),
                    "minute"
                  )
                  .utc()
                  .format();
              }
              if (res.data.data.user && res.data.data.user.birth_date) {
                res.data.data.user.birth_date = dayjs(
                  res.data.data.user.birth_date.split("T")[0]
                )
                  .add(
                    dayjs(new Date(res.data.data.user.birth_date)).utcOffset(),
                    "minute"
                  )
                  .utc()
                  .format();
              }
              if (!noStore) {
                context.commit("SET_STATE", {
                  key: "provider",
                  value: res.data.data,
                });
                context.commit("SET_STATE", { key: "isLoading", value: false });
              }
              resolve(res.data.data);
            })
            .catch((err) => {
              if (!noStore) {
                context.commit("SET_STATE", { key: "isLoading", value: false });
              }
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            });
        }
      });
    },
    getInvoiceProviders: function (context) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get("invoice-providers")
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "invoiceProviders",
                value: res.data.data,
              });
            }
            resolve(res.data.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    getCalendarProviders: function (context, payload) {
      let vm = this;
      if (!payload || !payload.noLoading) {
        context.commit("SET_STATE", { key: "isLoading", value: true });
      }
      return new Promise(function (resolve) {
        vm.$http
          .get("calendar-providers")
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "calendarProviders",
                value: res.data.data,
              });
            }
            context.commit("SET_STATE", { key: "isLoading", value: false });
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    setLoading: function ({ commit }, value) {
      commit("SET_PROVIDER_USER_LOADING", value);
    },
    getBasicProviders: function ({ commit, state }, payload = {}) {
      let url = `basic-providers?`;
      if (payload.status || payload.status == 0) {
        url = `${url}status=${payload.status}`;
      }
      if (payload.limit) {
        url = `${url}&limit=${payload.limit}`;
      }
      if (payload.page) {
        url = `${url}&page=${payload.page}`;
      }
      if (payload.administratorId) {
        url = `${url}&administratorId=${payload.administratorId}`;
      }
      if (payload.classIds) {
        url = `${url}&classIds=${payload.classIds}`;
      }
      if (payload.clinicIds) {
        url = `${url}&clinicIds=${payload.clinicIds}`;
      }
      if (payload.professionId) {
        url = `${url}&professionId=${payload.professionId}`;
      }
      if (payload.address) {
        url = `${url}&Address=${payload.address}`;
      }
      if (payload.username) {
        url = `${url}&username=${payload.username}`;
      }
      if (!payload.page) {
        commit("SET_PROVIDER_USER_LOADING", true);
      }
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              if (!payload.noStore) {
                if (payload && payload.page) {
                  commit("SET_PAGINATION", {
                    total: res.data.meta.total,
                    current: res.data.meta.current_page,
                    data: [
                      ...state.providerUsers.pagination.data,
                      ...res.data.data,
                    ],
                  });
                } else if (payload && payload.limit) {
                  commit("SET_PAGINATION", {
                    total: res.data.meta.total,
                    current: res.data.meta.current_page,
                    data: res.data.data,
                  });
                } else {
                  commit("SET_PAGINATION", {
                    total: 0,
                    current: 0,
                    data: res.data.data,
                  });
                }
              }
              resolve(res.data.data);
            }
            commit("SET_PROVIDER_USER_LOADING", false);
          })
          .catch((err) => {
            commit("SET_PROVIDER_USER_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    getProvidersUsersIds: function (context, payload) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(
            `providers-users-id?Active=1${
              payload && payload.address ? "&Address=1" : ""
            }`
          )
          .then((res) => {
            resolve(res.data.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    importProviders: function (_, data) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .post("providers/import-providers", data)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              resolve(err.data || err);
            }
          });
      });
    },
  },
  modules: {
    emails,
    phones,
    notes,
    address,
    professions,
    patients,
    contacts,
    bio,
  },
};
