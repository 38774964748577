export default [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      title: "Dashboard",
      guest: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/dashboard/Index.vue"),
    meta: {
      auth: true,
      title: "Dashboard",
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("../views/appointment/calendarMiddleware.vue"),
    meta: {
      auth: true,
      title: "Calendar",
    },
  },
  {
    path: "/old-calendar",
    name: "old-calendar",
    component: () => import("../views/appointment/oldCalendarMiddleware.vue"),
    meta: {
      auth: true,
      title: "Old Calendar",
    },
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: () => import("../views/tasks/Tabs.vue"),
    meta: {
      auth: true,
      title: "Tasks",
    },
    children: [
      {
        path: "",
        name: "Tasks",
        component: () => import("../views/tasks/Index.vue"),
      },
      {
        path: "drafts",
        name: "drafts",
        component: () => import("../views/tasks/Drafts.vue"),
      },
    ],
  },
  {
    path: "/tasks/create",
    name: "tasks.add",
    component: () => import("../views/tasks/Create.vue"),
    meta: {
      auth: true,
      title: "Create Tasks",
    },
  },
  {
    path: "/tasks/update/:id",
    name: "tasks.update",
    component: () => import("../views/tasks/Create.vue"),
    meta: {
      auth: true,
      title: "Update Tasks",
    },
  },
  {
    path: "/tasks/drafts/:id",
    name: "drafts.update",
    component: () => import("../views/tasks/DraftUpdate.vue"),
    meta: {
      auth: true,
      title: "Update Draft",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/Login.vue"),
    meta: {
      title: "Login",
      guest: true,
    },
  },
  {
    path: "/reset-password",
    name: "ForgotPasswordForm",
    component: () => import("../views/auth/ForgotPassword.vue"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/reset-password/:token",
    name: "ResetPasswordForm",
    component: () => import("../views/auth/ResetPassword.vue"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/rooms",
    name: "rooms",
    component: () => import("../views/meetingRooms/Index.vue"),
    meta: {
      auth: true,
      title: "Meeting Rooms",
    },
  },
  {
    path: "/rooms/recordings",
    name: "rooms.all-recordings",
    component: () => import("../views/meetingRooms/RoomsRecordings.vue"),
    meta: {
      auth: true,
      title: "Rooms Recordings",
    },
  },
  {
    path: "/rooms/create",
    name: "rooms.create",
    component: () => import("../views/meetingRooms/Create.vue"),
    meta: {
      auth: true,
      title: "Create Meeting Room",
    },
  },
  {
    path: "/rooms/:id/recordings",
    name: "rooms.recordings",
    component: () => import("../views/meetingRooms/Recordings.vue"),
    meta: {
      auth: true,
      title: "Recordings",
    },
  },
  {
    path: "/rooms/:slug",
    name: "room",
    component: () => import("../views/public/Room.vue"),
    meta: {
      public: true,
      title: "Meeting Room",
    },
  },
  {
    path: "/clinical-notes/:token",
    name: "clinical-note",
    component: () => import("../views/public/ClinicalNote.vue"),
    meta: {
      public: true,
      title: "Clinical Note",
    },
  },
  {
    path: "/invoices/:token",
    name: "invoice",
    component: () => import("../views/public/Invoice.vue"),
    meta: {
      public: true,
      title: "Invoice",
    },
  },
  {
    path: "/okaya-return",
    name: "okaya-return",
    component: () => import("../views/public/OkayaReturn.vue"),
    meta: {
      public: true,
      title: "Okaya",
    },
  },
];
