<template>
  <div>
    <button
      class="btn btn-outline-blue mb-3"
      @click="addProfession"
      v-if="
        !propUserId &&
        user &&
        (user.isAdmin || user.isManagingAdmin) &&
        $can({ key: 'providers', expectedPermission: 'edit' })
      "
    >
      <icon type="plus" /> Add Profession
    </button>
    <div>
      <alert v-if="professions.isLoading" class="light-shadow" />
    </div>
    <div>
      <alert
        v-if="!professions.isLoading && professions.data.length == 0"
        class="light-shadow"
      >
        No Professions Attached
      </alert>
    </div>
    <div
      class="card mobile-tablet-no-card table-container border border-lg-0"
      :class="!propUserId && 'shadow-card'"
      v-if="professions.data.length > 0 && !professions.isLoading"
    >
      <table class="table mb-0 table-striped responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>College #</th>
            <th>Rate</th>
            <th>Practitioner</th>
            <th>Regulated</th>
            <th>Created</th>
            <th
              v-if="
                !propUserId &&
                user &&
                (user.isAdmin || user.isManagingAdmin) &&
                $can({ key: 'providers', expectedPermission: 'edit' })
              "
              class="text-center"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in professions.data" :key="p.id">
            <td><b class="mobile-tablet-only mr-2">Name:</b>{{ p.name }}</td>
            <td>
              <b class="mobile-tablet-only mr-2">College #:</b
              >{{ p.collegeNumber != "N/A" ? p.collegeNumber : "-" }}
            </td>
            <td><b class="mobile-tablet-only mr-2">Rate:</b>${{ p.rate }}</td>
            <td class="text-center">
              <b class="mobile-tablet-only mr-2">Practitioner:</b>
              <icon
                :type="p.isPractitioner ? 'check' : 'times'"
                :class="p.isPractitioner ? 'text-success' : 'text-danger'"
              />
            </td>
            <td class="text-center">
              <b class="mobile-tablet-only mr-2">Regulated:</b>
              <icon
                :type="p.isRegulated ? 'check' : 'times'"
                :class="p.isRegulated ? 'text-success' : 'text-danger'"
              />
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Created:</b
              >{{ p.created_at | date("YYYY/MM/DD") }}
            </td>
            <td
              v-if="
                !propUserId &&
                user &&
                (user.isAdmin || user.isManagingAdmin) &&
                $can({ key: 'providers', expectedPermission: 'edit' })
              "
              class="text-center td-actions"
            >
              <button
                class="btn btn-sm p-2 border-0 btn-black mr-2"
                @click="openUpdateProfessionModal(p)"
              >
                <b class="mobile-tablet-only mr-2">Edit</b>
                <i class="fa fa-edit actionIcons"></i>
              </button>
              <button
                class="btn btn-sm btn-danger p-2 border-0"
                @click="deleteProfession(p)"
              >
                <b class="mobile-tablet-only mr-2">Delete</b>
                <i class="fa fa-trash actionIcons" v-if="!p.deleting"></i>
                <i class="fas fa-spin fa-circle-notch" v-if="p.deleting"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <modal
      class="update-profession-modal"
      name="update-profession-modal"
      transition="pop-out"
      :height="354"
    >
      <div class="position-relative">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button
            class="btn p-0 pr-3 pt-3 border-0"
            @click="closeUpdateProfessionModal"
          >
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="card-body">
          <form @submit.prevent="UpdateProfession" validate>
            <select-control
              v-model="selectedProfession.id"
              :options="filteredProfession"
              :required="true"
              disabled
              labelClass="col-md-3 col-12"
              wrapClass="align-items-center"
              >Profession</select-control
            >
            <input-control
              v-model="selectedProfession.collegeNumber"
              type="horizontal"
              v-if="regulated"
              :required="true"
              wrapClass="align-items-center"
              labelClass="col-md-3 col-12"
              >College Number</input-control
            >
            <div class="form-group row align-items-center">
              <label class="col-4 col-md-3 col-form-label mr-3"
                >Rate Type</label
              >
              <div class="custom-control custom-radio mr-4">
                <input
                  type="radio"
                  id="hour"
                  value="hour"
                  v-model="selectedProfession.rateType"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="hour">Hourly</label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="flat"
                  value="flat"
                  v-model="selectedProfession.rateType"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="flat">Flat</label>
              </div>
            </div>
            <input-control
              control="number"
              v-model="selectedProfession.rate"
              type="horizontal"
              :required="true"
              step="0.01"
              min="0"
              wrapClass="align-items-center"
              labelClass="col-md-3 col-12"
              >Rate</input-control
            >
            <div class="col-12 p-0 d-flex justify-content-center">
              <save :saving="professions.isSaving">Update Profession</save>
            </div>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "ProviderProfession",
  props: ["propUserId"],
  data() {
    return {
      selectedProfession: {},
    };
  },
  computed: {
    ...mapState({
      provider: (state) => state.providers.provider,
      professions: (state) => state.professions,
      user: (state) => state.auth.user,
    }),
    regulated: function () {
      if (this.selectedProfession) {
        if (!this.selectedProfession.isRegulated) {
          this.clearCollegeNumber();
        }
        return this.selectedProfession.isRegulated;
      }
      return false;
    },
    filteredProfession: function () {
      const array = [];
      this.professions.data.filter((p) => {
        array.push({ label: p.name, value: p.id });
      });
      return array;
    },
  },
  mounted() {
    if (this.propUserId) {
      this.getUserProfessions(this.propUserId);
    } else {
      this.getUserProfessions(this.provider.user_id);
    }
  },
  methods: {
    ...mapActions({
      getUserProfessions: "professions/getUserProfessions",
      attachProfession: "professions/attachProfessionToUser",
      detachProfession: "professions/detachProfessionFromUser",
    }),
    addProfession: function () {
      this.$router.push({
        name: "providers.profession.create",
        params: { id: this.provider.id },
      });
    },
    deleteProfession(profession) {
      profession.deleting = true;
      this.$forceUpdate();
      this.detachProfession({
        ...profession,
        user_id: this.propUserId || this.provider.user_id,
      }).then((success) => {
        profession.deleting = false;
        this.$forceUpdate();
        if (success) {
          this.getUserProfessions(this.provider.user_id);
        }
      });
    },
    openUpdateProfessionModal(selectedProfession) {
      this.selectedProfession = Object.assign({}, selectedProfession);
      this.$modal.show("update-profession-modal");
    },
    closeUpdateProfessionModal() {
      this.$modal.hide("update-profession-modal");
    },
    clearCollegeNumber: function () {
      this.selectedProfession.collegeNumber = null;
    },
    UpdateProfession: function () {
      this.attachProfession({
        id: this.selectedProfession.id,
        profession: `${this.selectedProfession.id}`,
        collegeNumber: this.selectedProfession.collegeNumber,
        user: this.provider.user_id,
        rate: this.selectedProfession.rate,
        rateType: this.selectedProfession.rateType,
      })
        .then(() => {
          this.closeUpdateProfessionModal();
          this.getUserProfessions(this.provider.user_id);
        })

        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text: (err.data || {}).message || "Something went wrong...",
              icon: "error",
            });
          }
          this.saving = false;
          this.closeUpdateProfessionModal();
        });
    },
  },
};
</script>

<style scoped>
.update-profession-modal {
  width: 100%;
  margin: 0 auto;
}
</style>
